import { MutationTree } from 'vuex';
import {
  ProcessingObject,
  ProcessingState,
} from './types';

// eslint-disable-next-line no-shadow
export enum ProcessingMutations {
  SET_PROCESSINGS = 'SET_PROCESSINGS',
  SET_PATCH_PROCESSING_ERROR = 'SET_PATCH_PROCESSING_ERROR',
  SET_PATCH_PROCESSING_LOADING = 'SET_PATCH_PROCESSING_LOADING',
  SET_GET_PROCESSING_LOADING = 'SET_GET_PROCESSING_LOADING',
  SET_GET_PROCESSING_ERROR = 'SET_GET_PROCESSING_ERROR'
}

export const mutations: MutationTree<ProcessingState> = {
  [ProcessingMutations.SET_PROCESSINGS](state, payload: ProcessingObject[]) {
    state.processings = payload;
  },

  [ProcessingMutations.SET_PATCH_PROCESSING_ERROR](state, payload: string | undefined) {
    state.processingEditError = payload;
  },

  [ProcessingMutations.SET_PATCH_PROCESSING_LOADING](state, payload: boolean) {
    state.processingEditLoading = payload;
  },

  [ProcessingMutations.SET_GET_PROCESSING_ERROR](state, payload: string | undefined) {
    state.processingGetError = payload;
  },

  [ProcessingMutations.SET_GET_PROCESSING_LOADING](state, payload: boolean) {
    state.processingGetLoading = payload;
  },
};
