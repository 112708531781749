import './router/componentHooks';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import './global.css';
import { Auth0Plugin } from './auth';
import configSettings from './services/integrityConfiguration';
import { esriApiKey } from '../esri_config.json';

(async () => {
  Vue.use(Auth0Plugin, {
    domain: configSettings.AUTH0_DOMAIN,
    clientId: configSettings.AUTH0_CLIENTID,
    audience: configSettings.AUTH0_AUDIENCE,
    onRedirectCallback: (appState) => {
      router.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname,
      );
    },
  });

  Vue.config.ignoredElements = [
    'a-scene',
    'a-camera',
    'a-box',
    'a-image',
    'a-assets',
    'a-entity',
    'a-videosphere',
  ];

  Vue.config.productionTip = false;
  if (!process.env.production) {
    // eslint-disable-next-line func-names
    Vue.config.warnHandler = function (msg: string, vm: Vue, trace: string) {
      console.warn(`[Vue warn]: ${msg}${trace}`);
    };
  }
  Vue.prototype.$esriApiKey = configSettings.ESRI_API_KEY || esriApiKey;
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
})();
