















































































































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'vue-property-decorator';
import { ServiceCallReportFields } from './types';
import ReportFooter from '../ReportFooter.vue';
import ReportInputCheckboxTable from '../ReportHelpers/ReportInputHelpers/ReportInputCheckboxTable.vue';
import ReportInterface, {
  WorkOrderfieldType,
} from '../ReportHelpers/ReportInterface.vue';
import ReportCalendarInput from '../ReportHelpers/ReportInputHelpers/ReportCalendarInput.vue';
import ReportTextInput from '../ReportHelpers/ReportInputHelpers/ReportTextInput.vue';
import ReportDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportDropDownInput.vue';
import ReportAssignedToDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportAssignedToDropDownInput.vue';
import ReportRadioRowInput from '../ReportHelpers/ReportInputHelpers/ReportRadioRowInput.vue';

@Component({
  components: {
    ReportFooter,
    ReportInputCheckboxTable,
    ReportCalendarInput,
    ReportTextInput,
    ReportDropDownInput,
    ReportAssignedToDropDownInput,
    ReportRadioRowInput,
  },
})
export default class ServiceCallReport extends ReportInterface {
  // #region Abstract fields
  workOrderData: ServiceCallReportFields | any = {};

  workOrderFields = [
    { name: 'assignedTo', value: 'Users' },
    { name: 'activityRemarks', value: 'ActivityRemarks' },
    { name: 'address', value: 'Address' },
    {
      name: 'arrivalDate',
      value: 'ArrivalDate',
      type: WorkOrderfieldType.DATE,
    },
    {
      name: 'arrivalTime',
      value: 'ArrivalTime',
      type: WorkOrderfieldType.TIME,
    },
    { name: 'busPhone', value: 'BusPhone' },
    {
      name: 'callDate',
      value: 'CallDate',
      type: WorkOrderfieldType.DATE,
    },
    { name: 'caller', value: 'Caller' },
    {
      name: 'callTime',
      value: 'CallTime',
      type: WorkOrderfieldType.TIME,
    },
    {
      name: 'causeCapacity',
      value: 'CauseCapacity',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'causeGrease',
      value: 'CauseGrease',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'causeGrit',
      value: 'CauseGrit',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'causeOther',
      value: 'CauseOther',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'causePipeBroken',
      value: 'CausePipeBroken',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'causePipeSag',
      value: 'CausePipeSag',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'causePowerFailure',
      value: 'CausePowerFailure',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'causeRags',
      value: 'CauseRags',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'causeRocks',
      value: 'CauseRocks',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'causeRoots',
      value: 'CauseRoots',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'causeUnknown',
      value: 'CauseUnknown',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'causeVandalism',
      value: 'CauseVandalism',
      type: WorkOrderfieldType.BOOLEAN,
    },
    { name: 'cellPhone', value: 'CellPhone' },
    { name: 'complaintReceivedBy', value: 'ComplaintReceivedBy' },
    {
      name: 'dateAssigned',
      value: 'DateAssigned',
      type: WorkOrderfieldType.DATE,
    },
    {
      name: 'dispatchDate',
      value: 'DispatchDate',
      type: WorkOrderfieldType.DATE,
    },
    { name: 'dispatchDescription', value: 'DispatchDescription' },
    {
      name: 'dispatchTime',
      value: 'DispatchTime',
      type: WorkOrderfieldType.TIME,
    },
    { name: 'estimatedFlowRate', value: 'EstimatedFlowRate' },
    { name: 'explanationIfNoneOther', value: 'ExplanationIfNoneOther' },
    { name: 'followReferralBy', value: 'FollowReferralBy' },
    {
      name: 'followReferralDate',
      value: 'FollowReferralDate',
      type: WorkOrderfieldType.DATE,
    },
    { name: 'followUpReferredTo', value: 'FollowUpReferredTo' },
    { name: 'followUpRemarks', value: 'FollowUpRemarks' },
    { name: 'generalArea', value: 'GeneralArea' },
    { name: 'homePhone', value: 'HomePhone' },
    {
      name: 'inspDate',
      value: 'InspDate',
      type: WorkOrderfieldType.DATE,
    },
    {
      name: 'lOASentDate',
      value: 'LOASentDate',
      type: WorkOrderfieldType.DATE,
    },
    {
      name: 'locationDistrictLateral',
      value: 'LocationDistrictLateral',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'locationLiftStation',
      value: 'LocationLiftStation',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'locationMain',
      value: 'LocationMain',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'locationNothingFound',
      value: 'LocationNothingFound',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'locationOther',
      value: 'LocationOther',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'locationPrivateLateral',
      value: 'LocationPrivateLateral',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'natureofCallAlarm',
      value: 'NatureofCallAlarm',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'natureofCallBackUp',
      value: 'NatureofCallBackUp',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'natureofCallComplaint',
      value: 'NatureofCallComplaint',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'natureofCallCompliment',
      value: 'NatureofCallCompliment',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'natureofCallDoorHanger',
      value: 'NatureofCallDoorHanger',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'natureofCallLiftStation',
      value: 'NatureofCallLiftStation',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'natureofCallNoise',
      value: 'NatureofCallNoise',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'natureofCallOdor',
      value: 'NatureofCallOdor',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'natureofCallOther',
      value: 'NatureofCallOther',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'natureofCallSSO',
      value: 'NatureofCallSSO',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'natureofCallStoppage',
      value: 'NatureofCallStoppage',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'natureofCallStreetSweeping',
      value: 'NatureofCallStreetSweeping',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'natureofCallVermin',
      value: 'NatureofCallVermin',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'notificationDate',
      value: 'NotificationDate',
      type: WorkOrderfieldType.DATE,
    },
    { name: 'notifiedBy', value: 'NotifiedBy' },
    {
      name: 'notifiedByEmail',
      value: 'NotifiedByEmail',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'notifiedByLetter',
      value: 'NotifiedByLetter',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'notifiedByNone',
      value: 'NotifiedByNone',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'notifiedByOther',
      value: 'NotifiedByOther',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'notifiedByPhone',
      value: 'NotifiedByPhone',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'notifiedInPerson',
      value: 'NotifiedInPerson',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'performedCCTV',
      value: 'PerformedCCTV',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'performedChemicals',
      value: 'PerformedChemicals',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'performedCleanup',
      value: 'PerformedCleanup',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'performedEmergencyRelief',
      value: 'PerformedEmergencyRelief',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'performedExcavation',
      value: 'PerformedExcavation',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'performedFlushing',
      value: 'PerformedFlushing',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'performedOtherDept',
      value: 'PerformedOtherDept',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'performedOwner',
      value: 'PerformedOwner',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'performedPermanentRepairs',
      value: 'PerformedPermanentRepairs',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'performedReferral',
      value: 'PerformedReferral',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'performedTemporaryRepairs',
      value: 'PerformedTemporaryRepairs',
      type: WorkOrderfieldType.BOOLEAN,
    },
    { name: 'priority', value: 'Priority' },
    {
      name: 'problemAlarm',
      value: 'ProblemAlarm',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'problemBlockage',
      value: 'ProblemBlockage',
      type: WorkOrderfieldType.BOOLEAN,
    },
    { name: 'problemDescription', value: 'ProblemDescription' },
    {
      name: 'problemNoise',
      value: 'ProblemNoise',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'problemOdor',
      value: 'ProblemOdor',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'problemOther',
      value: 'ProblemOther',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'problemPLC',
      value: 'ProblemPLC',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'problemPowerFailure',
      value: 'ProblemPowerFailure',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'problemSubsidence',
      value: 'ProblemSubsidence',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'recCCTV',
      value: 'RecCCTV',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'recHydroClean',
      value: 'RecHydroClean',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'recOther',
      value: 'RecOther',
      type: WorkOrderfieldType.BOOLEAN,
    },
    { name: 'recRemarks', value: 'RecRemarks' },
    {
      name: 'recRepair',
      value: 'RecRepair',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'recRootInhib',
      value: 'RecRootInhib',
      type: WorkOrderfieldType.BOOLEAN,
    },
    { name: 'referredBy', value: 'ReferredBy' },
    { name: 'referredTo', value: 'ReferredTo' },
    {
      name: 'reportedByAlarm',
      value: 'ReportedByAlarm',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'reportedByBusiness',
      value: 'ReportedByBusiness',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'reportedByClient',
      value: 'ReportedByClient',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'reportedByDistrict',
      value: 'ReportedByDistrict',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'reportedByHomeowner',
      value: 'ReportedByHomeowner',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'reportedByOther',
      value: 'ReportedByOther',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'reportedByTenant',
      value: 'ReportedByTenant',
      type: WorkOrderfieldType.BOOLEAN,
    },
    { name: 'responderName', value: 'ResponderName' },
    {
      name: 'triBoolean_LOARequired',
      value: 'TriBoolean_LOARequired',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'triBoolean_Overflow',
      value: 'TriBoolean_Overflow',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'triBoolean_OverflowToH20',
      value: 'TriBoolean_OverflowToH20',
      type: WorkOrderfieldType.BOOLEAN,
    },
    { name: 'troubleCallNum', value: 'TroubleCallNum' },
    { name: 'wastewater', value: 'Wastewater' },
    { name: 'weatherType', value: 'WeatherType' },
  ];
  // #endregion

  natureOfCallCheckboxValues = [
    {
      label: 'Odor',
      value: 'natureofCallOdor',
    },
    {
      label: 'Street Sweeping',
      value: 'natureofCallStreetSweeping',
    },
    {
      label: 'SSO',
      value: 'natureofCallSSO',
    },
    {
      label: 'Back Up',
      value: 'natureofCallBackUp',
    },
    {
      label: 'Complaint',
      value: 'natureofCallComplaint',
    },
    {
      label: 'Lift Station',
      value: 'natureofCallLiftStation',
    },
    {
      label: 'Compliment',
      value: 'natureofCallCompliment',
    },
    {
      label: 'Noise',
      value: 'natureofCallNoise',
    },
    {
      label: 'Door Hanger',
      value: 'natureofCallDoorHanger',
    },
    {
      label: 'Alarm',
      value: 'natureofCallAlarm',
    },
    {
      label: 'Stoppage',
      value: 'natureofCallStoppage',
    },
    {
      label: 'Other',
      value: 'natureofCallOther',
    },
  ];

  reportedByCheckboxValues = [
    {
      label: 'Homeowner',
      value: 'reportedByHomeowner',
    },
    {
      label: 'Tenant',
      value: 'reportedByTenant',
    },
    {
      label: 'Public Agency',
      value: 'reportedByDistrict',
    },
    {
      label: 'Alarm',
      value: 'reportedByAlarm',
    },
    {
      label: 'Client',
      value: 'reportedByClient',
    },
    {
      label: 'Business',
      value: 'reportedByBusiness',
    },
    {
      label: 'Other',
      value: 'reportedByOther',
    },
  ];

  locationCheckboxValues = [
    {
      label: 'Main',
      value: 'locationMain',
    },
    {
      label: 'Private Lateral',
      value: 'locationPrivateLateral',
    },
    {
      label: 'Nothing Found',
      value: 'locationNothingFound',
    },
    {
      label: 'Lift Station',
      value: 'locationLiftStation',
    },
    {
      label: 'Other',
      value: 'locationOther',
    },
    {
      label: 'District Lateral',
      value: 'locationDistrictLateral',
    },
  ];

  problemCheckboxValues = [
    {
      label: 'Blockage',
      value: 'problemBlockage',
    },
    {
      label: 'Alarm',
      value: 'problemAlarm',
    },
    {
      label: 'Odor',
      value: 'problemOdor',
    },
    {
      label: 'Subsidence',
      value: 'problemSubsidence',
    },
    {
      label: 'Power Failure',
      value: 'problemPowerFailure',
    },
    {
      label: 'Electrical',
      value: 'problemPLC',
    },
    {
      label: 'Noise',
      value: 'problemNoise',
    },
    {
      label: 'Other',
      value: 'problemOther',
    },
  ];

  causeCheckboxValues = [
    {
      label: 'Grease',
      value: 'causeGrease',
    },
    {
      label: 'Grit',
      value: 'causeGrit',
    },
    {
      label: 'Rags',
      value: 'causeRags',
    },
    {
      label: 'Roots',
      value: 'causeRoots',
    },
    {
      label: 'Pipe Broken',
      value: 'causePipeBroken',
    },
    {
      label: 'Pipe Sag',
      value: 'causePipeSag',
    },
    {
      label: 'Capacity',
      value: 'causeCapacity',
    },
    {
      label: 'Rocks',
      value: 'causeRocks',
    },
    {
      label: 'Vandalism',
      value: 'causeVandalism',
    },
    {
      label: 'Weather',
      value: 'causeUnknown',
    },
    {
      label: 'Power',
      value: 'causePowerFailure',
    },
    {
      label: 'Other',
      value: 'causeOther',
    },
  ];

  activityiesCheckboxValues = [
    {
      label: 'Emergency',
      value: 'performedEmergencyRelief',
    },
    {
      label: 'Temp. Repairs',
      value: 'performedTemporaryRepairs',
    },
    {
      label: 'Perm. Repairs',
      value: 'performedPermanentRepairs',
    },
    {
      label: 'Excavation',
      value: 'performedExcavation',
    },
    {
      label: 'Clean Up',
      value: 'performedCleanup',
    },
    {
      label: 'Owner Repons.',
      value: 'performedOwner',
    },
    {
      label: 'Chem. Applied',
      value: 'performedChemicals',
    },
    {
      label: 'Hydro-Clean',
      value: 'performedFlushing',
    },
    {
      label: 'TV Inspection',
      value: 'performedCCTV',
    },
    {
      label: 'Referral',
      value: 'performedReferral',
    },
    {
      label: 'Other Agency',
      value: 'performedOtherDept',
    },
  ];

  activitiesRequestedCheckboxValues = [
    {
      label: 'CCTV',
      value: 'recCCTV',
    },
    {
      label: 'Hydro Clean',
      value: 'recHydroClean',
    },
    {
      label: 'Root Inhib.',
      value: 'recRootInhib',
    },
    {
      label: 'Repair/Rehab',
      value: 'recRepair',
    },
    {
      label: 'Other',
      value: 'recOther',
    },
  ];

  callerNotifiedByCheckboxValues = [
    {
      label: 'Phone',
      value: 'notifiedByPhone',
    },
    {
      label: 'In Person',
      value: 'notifiedInPerson',
    },
    {
      label: 'Email',
      value: 'notifiedByEmail',
    },
    {
      label: 'Letter',
      value: 'notifiedByLetter',
    },
    {
      label: 'None',
      value: 'notifiedByNone',
    },
    {
      label: 'Other',
      value: 'notifiedByOther',
    },
  ];

  weatherConditions = [
    'Dry',
    'Light Rain',
    'Heavy Rain',
  ];
}
