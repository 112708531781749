








































































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-destructuring */
import {
  Component, Prop, Watch,
} from 'vue-property-decorator';
import { BasicSelect } from 'vue-search-select';
import { format } from 'date-fns';
import {
  PRIORITY_HIGH,
  PRIORITY_MEDIUM,
  PRIORITY_LOW,
  FOG_TASK_TYPE,
  CLEANING_TASK_TYPE,
  GENERAL_MAINTENANCE_TASK_TYPE,
  SERVICE_CALL_TASK_TYPE,
  SSO_TASK_TYPE,
  REPAIR_TASK_TYPE,
  TASK_RESULT_DETAILS,
  WORK_ORDER_STATUSES,
} from '@/common/Constants';
import { namespace } from 'vuex-class';
import { UserActions } from '@/store/users/actions';
import { UserData } from '@/store/users/types';
import { Crew, SchedulingData, WorkOrderTableData } from '@/store/planning/types';
import { User } from '@auth0/auth0-spa-js';
import { UserPermission } from '@/store/userpermissions/types';
import { FIELD_CREW, CREW_LEAD } from '../../auth/roles';
import ScheduleWorkOrder from './ScheduleWorkOrder.vue';
import { WorkOrderReqRes, WorkOrderStatus } from './types';
import UserPermissionsMixin from '../UserPermissions/UserPermissionsMixin.vue';

const userModule = namespace('users');
const planningModule = namespace('planning');

@Component({
  components: {
    BasicSelect,
    ScheduleWorkOrder,
  },
})
export default class PlanningSchedule extends UserPermissionsMixin {
  @userModule.Action(UserActions.FETCH_ALL_USER_DATA) fetchAllUserData;

  @userModule.State('allUserData') allUsers: UserData[] | undefined;

  @planningModule.State('workOrderCrewData') workOrderCrewData: Crew[] | undefined;

  @Prop({ default: () => [] }) editItems: WorkOrderTableData[];

  @Prop() successMessage: string;

  @Prop() successMessageStyle: string;

  @Prop() projectGuid: string;

  @Prop({ default: false }) editDialog: boolean;

  valid = true;

  dialog = false;

  pcmEmailDialog = false;

  priorityLevels = ['Low', 'Medium', 'High'];

  selectedDates = [] as string[];

  scheduleDialog = false;

  equipmentConfig = '';

  equipmentConfigOptions: string[] = [];

  selectedDatesRules = [
    (v: string): string | boolean => v !== '' || 'Date range is required',
    (v: string): string | boolean => !!v || 'Date range is required',
  ];

  selectedPriority = '';

  priorityRules = [
    (v: string): string | boolean => v !== '' || 'Priority is required',
    (v: string): string | boolean => !!v || 'Priority is required',
  ];

  editConfirmDialog = false;

  comments = '';

  followUpReason = '';

  crewNameList: Array<{ value: number; text: string; guid: string }> = [];

  // leadNameList: Array<{ value: number; text: string; guid: string }> = [];

  leaderNameModel = { text: '', guid: '' };

  crewNames: Array<{ text: string; guid: string }> = [];

  pcmRecipients = [];

  pcmEmailTextField = '';

  user: User = this.$auth.user;

  schedule: undefined | SchedulingData = undefined;

  validScheduleItemGuids = [
    FOG_TASK_TYPE,
    CLEANING_TASK_TYPE,
    GENERAL_MAINTENANCE_TASK_TYPE,
    SERVICE_CALL_TASK_TYPE,
    SSO_TASK_TYPE,
    REPAIR_TASK_TYPE,
  ];

  workOrderStatus = null;

  workOrderTaskResult = null;

  taskResultDisabled = false;

  scheduleValid = false;

  @Watch('editDialog')
  onSelectedNodesChange(): void {
    this.resetForm();
    if (this.editItems.length === 0 || !this.editDialog) {
      return;
    }
    (this.$refs.form as any).resetValidation();

    const firstNode = this.editItems[0];

    if (
      firstNode.scheduledStartDate !== null
      && firstNode.scheduledDueDate !== null
    ) {
      const firstDate = new Date(firstNode.scheduledStartDate);
      const secondDate = new Date(firstNode.scheduledDueDate);
      this.selectedDates = [
        format(firstDate, 'yyyy-MM-dd'),
        format(secondDate, 'yyyy-MM-dd'),
      ];
    }
    this.selectedPriority = firstNode.priorityDescription;
    this.followUpReason = firstNode.followUpReason;
    this.workOrderStatus = this.workOrderStatusOptions.find(
      (s) => s.description === firstNode.status,
    );
    this.workOrderTaskResult = this.workOrderTaskResultOptions.find(
      (tr) => tr.description === firstNode.taskResultDesc,
    );

    if (this.allUsers && this.allUsers.length > 0) this.onAllUsersUpdate();

    this.planningDialogOpen();
    if (this.doSchedulesMatch()) {
      this.schedule = JSON.parse(this.editItems[0].schedulingData);
    }
    if (firstNode.notes) {
      this.comments = firstNode.notes;
    }
  }

  @Watch('workOrderStatus')
  onWorkOrderStatusChange(): void {
    if (!this.workOrderStatus) return;

    const completed = {
      guid: 'c2ef4e2b-f2f0-4455-bd3f-da14b2ad4686',
      name: 'C',
      description: 'Completed',
    };
    const completedIndex = this.workOrderTaskResultOptions.indexOf(completed);

    this.taskResultDisabled = this.workOrderStatus.description !== 'Incomplete'
      && this.workOrderStatus.description !== 'Follow-Up Required';

    switch (this.workOrderStatus.description) {
      case 'Ready':
      case 'Not Ready':
        this.workOrderTaskResult = null;
        break;
      case 'Completed':
        this.workOrderTaskResultOptions.push(completed);
        this.workOrderTaskResult = completed;
        break;
      case 'Incomplete':
      case 'Follow-Up Required':
        // remove "Completed" task result as an option if
        // status is "Incomplete" or "Follow-Up Required"
        if (completedIndex > -1) {
          this.workOrderTaskResultOptions.splice(completedIndex, 1);
        }

        // clear task result if status is incomplete or follow-up required
        if (
          this.workOrderTaskResult
          && this.workOrderTaskResult.description === 'Completed'
        ) {
          this.workOrderTaskResult = null;
        }
        break;
      default:
        break;
    }
  }

  @Watch('allUsers')
  onAllUsersUpdate(): void {
    const newCrewNameList = [];
    this.allUsers.forEach((user) => {
      if (
        user.role === FIELD_CREW[0]
        || user.role === FIELD_CREW[1]
        || user.role === CREW_LEAD
      ) {
        newCrewNameList.push({
          text: `${user.firstname} ${user.lastname}`,
          guid: user.guid,
        });
      }
    });
    this.crewNameList = newCrewNameList;
    if (this.crewNames.length === 0 || this.leaderNameModel.text === '') this.planningDialogOpen();
  }

  // eslint-disable-next-line class-methods-use-this
  get today(): string {
    return new Date().toISOString().slice(0, 10);
  }

  get disabled(): boolean {
    return !(this.editItems.length > 0);
  }

  get selectedDatesString(): string {
    this.sortDates();
    return this.selectedDates.length === 2
      ? this.selectedDates[0].concat(' to ', this.selectedDates[1])
      : '';
  }

  get canSchedule(): boolean {
    return this.editItems.every((item) => this.validScheduleItemGuids.includes(item.taskTypeGuid));
  }

  get statusAndTaskResultValid(): boolean {
    return this.workOrderStatus
      && ['Completed', 'Incomplete'].includes(this.workOrderStatus.description)
      ? this.workOrderTaskResult !== null
          && this.workOrderTaskResult !== undefined
      : true;
  }

  get createPCM(): boolean {
    return (
      !['Completed', 'Incomplete'].includes(this.workOrderStatus.description)
      && (this.workOrderTaskResult === null
        || this.workOrderTaskResult === undefined)
    );
  }

  get workOrderStatusOptions(): WorkOrderStatus[] {
    // Look for a Redzone work order in the edit items
    // If found, filter out Follow-Up Required status
    // If not, filter out Incomplete status instead
    const filterWorkOrder = this.editItems.some(
      (item) => item.isRedzone === true,
    )
      ? ['FUR']
      : ['INC'];
    if (!this.hasPermissionWorkOrderResetPlanning) {
      const unassignedWorkOrderStatusShortName = 'USG';
      filterWorkOrder.push(unassignedWorkOrderStatusShortName);
    }
    const statuses = WORK_ORDER_STATUSES.filter((a) => !filterWorkOrder.includes(a.short_name));
    return statuses;
  }

  get workOrderTaskResultOptions(): {
    guid: string;
    name: string;
    description: string;
  }[] {
    const isRedzone = !!this.editItems.some((item) => item.isRedzone === true);
    const followUpItemCodes = ['OT', 'TCR', 'CRH', 'CRL'];
    const sharedCodes = ['CNA', 'CNL', 'CNO'];
    if (isRedzone) {
      return TASK_RESULT_DETAILS.filter(
        (trd) => !followUpItemCodes.includes(trd.name),
      );
    }
    // filter to only Follow up required items
    return TASK_RESULT_DETAILS.filter(
      (trd) => followUpItemCodes.includes(trd.name) || sharedCodes.includes(trd.name),
    );
  }

  get isP3dCollection(): boolean {
    return this.editItems.length > 0
           && this.editItems.filter(
             (item) => item.taskTypeGuid === '2180e6e0-1ca9-11ed-b6cf-5f0bd137feea', // P3D Collection
           ).length === this.editItems.length;
  }

  mounted(): void {
    this.fetchAllUserData();
    if (this.user.email != null || this.user.email !== '') {
      this.pcmRecipients.push(this.user.email);
    }
    this.onSelectedNodesChange();
  }

  sortDates(): void {
    if (this.selectedDates.length === 2) {
      const firstDate = new Date(this.selectedDates[0]);
      const secondDate = new Date(this.selectedDates[1]);

      if (firstDate > secondDate) {
        this.selectedDates[0] = format(secondDate, 'yyyy-MM-dd');
        this.selectedDates[1] = format(firstDate, 'yyyy-MM-dd');
      }
    }
  }

  buildDataList(type: string): any {
    const dataList: WorkOrderReqRes[] = [];
    const priority = {
      guid: PRIORITY_LOW,
      category: {
        guid: 'cf12207e-9707-11ea-9cfe-6f062ca617ff',
        name: 'Priority',
      },
      description: 'Low',
      short_name: 'LOW',
      order: 1,
    };

    this.editItems.forEach((node: any) => {
      const data = {
        guid: node.guid,
        priority_item: priority,
        scheduled_start_date: '',
        scheduled_end_date: '',
        notes: '',
        scheduled_start_date_null: true,
        scheduled_end_date_null: true,
        crewLead:
          this.leaderNameModel && this.leaderNameModel.text !== ''
            ? [this.leaderNameModel.guid]
            : [],
        crew: this.crewNames.map((x) => x.guid),
        schedulingData: this.schedule,
        status_item: this.workOrderStatus,
        task_result: this.workOrderTaskResult,
        followUpReason:
          this.workOrderTaskResult?.name === 'OT' && this.followUpReason !== ''
            ? this.followUpReason
            : null,
        equipmentConfig: null,
        equipmentConfigOptions: null,
      };

      if (type === 'save') {
        switch (this.selectedPriority) {
          case 'Medium':
            data.priority_item = {
              guid: PRIORITY_MEDIUM,
              category: {
                guid: 'cf12207e-9707-11ea-9cfe-6f062ca617ff',
                name: 'Priority',
              },
              description: 'Medium',
              short_name: 'MED',
              order: 2,
            };
            break;
          case 'High':
            data.priority_item = {
              guid: PRIORITY_HIGH,
              description: 'High',
              category: {
                guid: 'cf12207e-9707-11ea-9cfe-6f062ca617ff',
                name: 'Priority',
              },
              short_name: 'HI',
              order: 3,
            };
            break;
          default:
            break;
        }

        data.scheduled_start_date_null = false;
        data.scheduled_end_date_null = false;
        data.scheduled_start_date = this.selectedDates[0];
        data.scheduled_end_date = this.selectedDates[1];
      }

      data.priority_item = priority;

      if (this.comments) {
        const note = this.comments;
        data.priority_item = priority;
        data.scheduled_start_date = this.selectedDates[0];
        data.scheduled_end_date = this.selectedDates[1];
        data.notes = note;
      }

      if (this.isP3dCollection) {
        data.equipmentConfig = this.equipmentConfig;
        data.equipmentConfigOptions = this.equipmentConfigOptions;
      }

      dataList.push(data);
    });
    return dataList;
  }

  removeItem(index: number): void {
    this.crewNames.splice(index, 1);
  }

  removePCMEmail(index: number): void {
    this.pcmRecipients.splice(index, 1);
  }

  addEmail(email: string): void {
    if ((this.$refs.pcmEmailForm as HTMLFormElement).validate()) {
      this.pcmRecipients.push(email);
      this.pcmEmailDialog = false;
      this.pcmEmailTextField = '';
    }
  }

  resetForm(): void {
    this.selectedDates = [];
    this.leaderNameModel = { text: '', guid: '' };
    this.crewNames = [];
    this.selectedPriority = '';
    this.pcmRecipients = [];
    if (this.user.email != null || this.user.email !== '') {
      this.pcmRecipients.push(this.user.email);
    }
    this.schedule = undefined;
    this.editConfirmDialog = false;
    this.workOrderStatus = null;
    this.workOrderTaskResult = null;
    this.taskResultDisabled = false;
    (this.$refs.form as any).reset();
  }

  saveScheduling(newSchedule: SchedulingData): void {
    this.schedule = newSchedule;
    this.scheduleDialog = false;
    this.$forceUpdate();
  }

  scheduleString(): string | undefined {
    if (!this.schedule) {
      return null;
    }

    return `Repeats every ${this.schedule.interval} ${
      this.schedule.period
    } starting on ${this.schedule.startDate.toString().substring(0, 10)}`;
  }

  removeScheduling(): void {
    this.schedule = undefined;
    this.$forceUpdate();
  }

  submit(): void {
    (this.$refs.form as any).validate();
    if (!this.valid) return;
    this.$emit('save', {
      emails: this.pcmRecipients,
      requestList: this.buildDataList('save'),
      createPCM: this.createPCM,
    });
    this.resetForm();
  }

  cancel(): void {
    this.$emit('cancel');
    this.resetForm();
  }

  reset(): void {
    this.$emit('reset', {
      emails: this.pcmRecipients,
      requestList: this.buildDataList('reset'),
    });
    this.resetForm();
  }

  doSchedulesMatch(): boolean {
    if (
      this.editItems.length > 0
      && this.editItems[0].schedulingData !== null
    ) {
      const firstData = this.editItems[0].schedulingData;
      return this.editItems.every(
        (item) => item?.schedulingData != null
          && item?.schedulingData === firstData,
      );
    }
    return false;
  }

  planningDialogOpen(): void {
    if (this.workOrderCrewData && this.allUsers) {
      const workerGuids = this.workOrderCrewData.map((crew) => crew.crewGuid);
      const crewLeadGuid = this.workOrderCrewData.find((crew) => crew.isCrewLead)?.crewGuid;
      const newCrew = [];
      this.allUsers.forEach((user) => {
        const foundWorkerIndex = workerGuids.findIndex((worker) => worker === user.guid);
        if (foundWorkerIndex !== -1) {
          // allows crew lead to be found if set, if not checks uses old method
          if (crewLeadGuid && user.guid === crewLeadGuid) {
            this.leaderNameModel = {
              text: `${user.firstname} ${user.lastname}`,
              guid: user.guid,
            };
          } else if (!crewLeadGuid && user.role === CREW_LEAD) {
            this.leaderNameModel = {
              text: `${user.firstname} ${user.lastname}`,
              guid: user.guid,
            };
          } else {
            workerGuids.slice(foundWorkerIndex, 1);
            newCrew.push({
              text: `${user.firstname} ${user.lastname}`,
              guid: user.guid,
            });
          }
        }
      });
      this.crewNames = newCrew;
    }
  }

  /**
  * @returns true if the user has the permission WORK_ORDER_RESET_PLANNING
  */
  get hasPermissionWorkOrderResetPlanning(): boolean {
    return this.hasPermission(UserPermission.WORK_ORDER_RESET_PLANNING);
  }
}
