
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ProjectHeaders, ProjectListing } from '@/store/project/types';

import { ProjectActions } from '@/store/project/actions';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { MetricsActions } from '@/store/metrics/actions';
import {
  ProjectOverviewMetrics,
  OverviewTotalCounts,
  WorkOrderProjectMetrics,
  AssetCountsMetrics,
  DataDeliveryProjectMetrics,
  ClientActionItem,
  NasscoScoreMetrics,
  NasscoCodeCategoryCounts,
  DashboardDefectsByAsset,
  CustomerDashboardWorkOrder,
  CustomerDataWorkOrderCounts,
  DateSelector,
  ReleaseGuidName,
  FilterDefects,
  DefectsData,
} from '@/store/metrics/types';

const metricsModule = namespace('metrics');
const projectModule = namespace('project');
const userPrefsModule = namespace('userPrefs');

@Component({
  components: {},
})
export default class CustomerDashboardMixin extends Vue {
  @projectModule.State('headers') projectHeaders: ProjectHeaders | undefined;

  @projectModule.State('allProjectsData') allProjects:
    | ProjectListing[]
    | undefined;

  @projectModule.Action(ProjectActions.FETCH_HEADER_DATA) fetchProjectHeaders;

  @userPrefsModule.State('displayImperial') displayImperial: boolean;

  @metricsModule.State('dataDeliveryProjectMetrics')
  dataDeliveryProjectMetrics: DataDeliveryProjectMetrics;

  @metricsModule.State('workOrderProjectMetrics')
  workOrderProjectMetrics: WorkOrderProjectMetrics;

  @metricsModule.Action(MetricsActions.FETCH_DATA_DELIVERY_METRICS)
  fetchDataDeliveryMetrics;

  @metricsModule.Action(MetricsActions.FETCH_WORK_ORDER_PROJECT_METRICS)
  fetchWorkOrderProjectMetrics;

  @metricsModule.Action(MetricsActions.FETCH_ASSET_COUNTS_METRICS)
  fetchAssetCountsMetrics;

  @metricsModule.Action(MetricsActions.FETCH_PROJECT_OVERVIEW_METRICS)
  fetchProjectOverviewMetrics;

  @metricsModule.Action(MetricsActions.FETCH_OVERVIEW_TOTAL_COUNTS)
  fetchOverviewTotalCounts;

  @metricsModule.Action(MetricsActions.FETCH_PROJECT_TOTALS) fecthProjectTotals;

  @metricsModule.Action(MetricsActions.FETCH_CUSTOMER_DASHBOARD_WORK_ORDERS)
  fetchCustomerDataWorkOrders;

  @metricsModule.Action(MetricsActions.FETCH_FILTER_DEFECTS) fetchFilterDefects;

  @metricsModule.State('dashboardFilterDefects')
  dashboardFilterDefects: FilterDefects[];

  @metricsModule.State('dashboardFilterDefectsLoading')
  dashboardFilterDefectsLoading: boolean;

  @metricsModule.State('dashboardFilterDefectsError')
  dashboardFilterDefectsError: string;

  @metricsModule.State('loadingAssetCountsMetrics')
  loadingAssetCountsMetrics: boolean;

  @metricsModule.State('assetCountsMetrics')
  assetCountsMetrics: AssetCountsMetrics;

  @metricsModule.State('projectOverviewMetrics')
  projectOverviewMetrics: ProjectOverviewMetrics;

  @metricsModule.State('overviewTotalCounts')
  overviewTotalCounts: OverviewTotalCounts;

  @metricsModule.State('clientActionItems')
  clientActionItems: ClientActionItem[];

  @metricsModule.Action(MetricsActions.FETCH_CLIENT_ACTION_ITEMS)
  fetchClientActionItems;

  @metricsModule.State('loadingClientActionItems') loading: boolean;

  @metricsModule.State('clientActionItemsError') error: boolean;

  @metricsModule.Action(MetricsActions.FETCH_NASSCO_INSPECTION_SCORES)
  fetchNasscoInspectionScores;

  @metricsModule.State('nasscoInspectionScoresLoading')
  nasscoInspectionScoresLoading: boolean;

  @metricsModule.State('nasscoInspectionScoresData')
  nasscoInspectionScoresData: NasscoScoreMetrics | undefined;

  @metricsModule.Action(MetricsActions.FETCH_DEFECTS)
  fetchDefects;

  @metricsModule.State('defectsData')
  defectsData: DefectsData[] | undefined

  @metricsModule.Action(MetricsActions.FETCH_DASHBOARD_DEFECTS)
  fetchDashboardDefects;

  @metricsModule.State('projectDefectsData')
  projectDefectsData: NasscoCodeCategoryCounts;

  @metricsModule.State('projectDefectsLoading') projectDefectsLoading: boolean;

  @metricsModule.Action(MetricsActions.FETCH_PROJECT_DEFECTS)
  fetchDefectMetrics;

  @projectModule.State('headers') projectHeader: ProjectHeaders | undefined;

  @metricsModule.State('dashboardDefectData')
  dashboardDefects: DashboardDefectsByAsset[];

  @metricsModule.State('dashboardDefectLoading')
  dashboardDefectLoading: boolean;

  @metricsModule.State('customerDashboardWorkOrders')
  customerDashboardWorkOrders: CustomerDashboardWorkOrder[] | undefined;

  @metricsModule.State('customerDashboardWorkOrdersLoading')
  customerDashboardWorkOrdersLoading: boolean;

  @metricsModule.State('customerDashboardWorkOrdersError')
  customerDashboardWorkOrdersError: string | undefined;

  @metricsModule.State('customerDataWorkOrderCounts')
  customerDataWorkOrderCounts: CustomerDataWorkOrderCounts | undefined;

  @metricsModule.State('customerDataWorkOrderLoading')
  customerDataWorkOrderLoading: boolean;

  @metricsModule.State('customerDataWorkOrderError')
  customerDataWorkOrderError: string | undefined;

  @metricsModule.Action(MetricsActions.SET_SCHEDULED_DATE)
  setScheduledDate;

  @metricsModule.Action(MetricsActions.SET_COMPLETED_DATE)
  setCompletedDate;

  @metricsModule.State('scheduledDate') scheduledDate: DateSelector;

  @metricsModule.State('completedDate') completedDate: DateSelector;

  @metricsModule.Action(MetricsActions.SET_SELECTED_RELEASE)
  setSelectedRelease;

  @metricsModule.State('selectedRelease') selectedRelease: ReleaseGuidName;
}
