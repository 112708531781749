var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasPermissionProcessingShowTable)?_c('div',[_c('div',{attrs:{"id":"table-header"}},[_c('v-tabs',{staticClass:"assetTabs",attrs:{"height":"100%","color":"#0C6599"},model:{value:(_vm.syncedActiveTab),callback:function ($$v) {_vm.syncedActiveTab=$$v},expression:"syncedActiveTab"}},_vm._l((_vm.tabOptions),function(tab){return _c('v-tab',{key:tab.name,staticClass:"assetTableTab py-2",attrs:{"id":tab.name}},[_vm._v(" "+_vm._s(tab.value)+" ")])}),1),_c('v-spacer'),_c('div',{attrs:{"id":"asset-metrics"}},[_c('div',[_c('span',{staticClass:"metric-label"},[_vm._v("Arrived: ")]),_c('span',{staticClass:"metric-value"},[_vm._v(" "+_vm._s(_vm.arrivedTotal)+" ")])]),_c('span',[_vm._v(" | ")]),_c('div',[_c('span',{staticClass:"metric-label"},[_vm._v("Processed: ")]),_c('span',{staticClass:"metric-value"},[_vm._v(" "+_vm._s(_vm.processedTotal)+" ")])])]),_c('v-text-field',{staticClass:"search-bar",attrs:{"outlined":"","dense":"","hide-details":"","placeholder":"Search","clearable":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.hasPermissionProcessingEditProcessingTable)?_c('v-btn',{class:_vm.isEditMode ? 'blue-btn' : 'outline-btn',attrs:{"color":"#0c6599","outlined":""},on:{"click":_vm.editProcessing}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil-outline")]),_vm._v(" Edit ")],1):_vm._e()],1),_c('div',{staticClass:"table-container"},[_c('IntegrityTable',{attrs:{"data":_vm.processingTableData,"headers":_vm.processingTableHeaders,"loading":false,"matchFilters":_vm.matchFilters,"height":_vm.tableHeight,"items-per-page":100,"footer-props":{
        'disable-items-per-page': false,
        'items-per-page-options': [100, 250, 500, -1],
      },"showSelect":_vm.isEditMode,"selectedItems":_vm.selectedItems,"search":_vm.search,"tableUniqueKey":'processingGuid',"canEdit":_vm.isEditMode,"additionalFilterFunctions":_vm.additionalFilterFunctions,"dataFillFunctions":_vm.fillTableDataFunctions},on:{"update:data":function($event){_vm.processingTableData=$event},"update:matchFilters":function($event){_vm.matchFilters=$event},"update:match-filters":function($event){_vm.matchFilters=$event},"update:height":function($event){_vm.tableHeight=$event},"update:selectedItems":function($event){_vm.selectedItems=$event},"update:selected-items":function($event){_vm.selectedItems=$event},"inlineEdit":_vm.saveProcessing,"onFunctionClearMatchFilter":_vm.tabChanged,"onClearHeader":_vm.userChangedFilter,"onApplyFilter":_vm.userChangedFilter},scopedSlots:_vm._u([{key:"processingStatusItemGuid",fn:function(ref){
      var item = ref.item;
return [(
            item.processingStatusItemGuid != null &&
            item.processingStatusItemGuid !== ''
          )?_c('v-chip',{staticClass:"chip-text",attrs:{"small":"","color":_vm.getStatusColor(item.processingStatusItemGuid)}},[_vm._v(" "+_vm._s(item.processingStatusItemGuid)+" ")]):_vm._e()]}},{key:"actions",fn:function(ref){
          var item = ref.item;
return [(_vm.hasPermissionProcessingReProcessInspection)?_c('v-btn',{staticClass:"reprocess-btn",attrs:{"icon":"","disabled":!_vm.canReprocess(item.processingStatusItemGuid)},on:{"click":function($event){return _vm.reprocess(item)}}},[_c('v-icon',[_vm._v("mdi-reload")])],1):_c('span')]}}],null,false,3246617430)})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }