








































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import CustomerDashboardProjectProgress from '@/components/Dashboards/CustomerDashboardComponents/CustomerDashboardProjectProgress.vue';
import CustomerDashboardSystemAssessment from '@/components/Dashboards/CustomerDashboardComponents/CustomerDashboardSystemAssessment.vue';
import CustomerDashboardWorkOrders from '@/components/Dashboards/CustomerDashboardComponents/CustomerDashboardWorkOrders.vue';
import CustomerDashboardPdf from '@/components/Dashboards/CustomerDashboardPdf/CustomerDashboardPdf.vue';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { UserPermission } from '@/store/userpermissions/types';
import UserPermissionsMixin from '@/components/UserPermissions/UserPermissionsMixin.vue';
import CustomerDashboardMixin from './CustomerDashboardMixin.vue';
import PmDashboard from '../PmDashboard/PmDashboard.vue';

@Component({
  components: {
    CustomerDashboardProjectProgress,
    CustomerDashboardSystemAssessment,
    CustomerDashboardPdf,
    CustomerDashboardWorkOrders,
    PmDashboard,
  },
})
export default class CustomerDashboard
  extends Mixins(CustomerDashboardMixin, UserPermissionsMixin) {
  @Prop() readonly ids!: string;

  selectedTab = 0;

  pmDashboardTabName = 'pmDashboard';

  projectProgressTabName = 'projectProgress';

  systemAssessmentTabName = 'systemAssessment';

  workOrdersTabName = 'workOrders';

  tabOptions = [
    { name: this.pmDashboardTabName, value: 'Project Management' },
    { name: this.projectProgressTabName, value: 'Project Progress' },
    { name: this.systemAssessmentTabName, value: 'System Assessment' },
    { name: this.workOrdersTabName, value: 'Work Orders' },
  ];

  id = '';

  projectGuidList: string[] = [];

  async mounted(): Promise<void> {
    try {
      this.projectGuidList = JSON.parse(this.ids) as string[];
      // eslint-disable-next-line prefer-destructuring
      this.id = this.projectGuidList[0];
    } catch {
      this.id = this.ids;
      this.projectGuidList = [this.id];
    }

    await this.fetchProjectHeaders(this.projectGuidList);
  }

  get projectName(): string {
    if (this.projectHeaders === null) {
      return '';
    }
    return `${this.projectHeaders.cities[0]}, ${this.projectHeaders.states[0]}`;
  }

  /**
   * @returns all tabs that the user is permitted to see
   */
  get allowedTabs(): any[] {
    return this.tabOptions.filter((tab) => this.showDashboardTab(tab.name));
  }

  /**
   * @returns true if the user has the correct permission for showing tabs
   */
  showDashboardTab(tabName: string): boolean {
    switch (tabName) {
      case this.pmDashboardTabName:
        return this.hasPermissionProjectManagementDashboardViewDashboard;
      case this.projectProgressTabName:
        return this.hasPermissionCustomerProjectProgressDashboardViewDashboard;
      case this.systemAssessmentTabName:
        return this.hasPermissionCustomerSystemAssessmentDashboardViewDashboard;
      case this.workOrdersTabName:
        return this.hasPermissionCustomerWorkOrderDashboardViewDashboard;
      default:
        return false;
    }
  }

  /**
   * @returns true if the user has the correct permission for exporting pdfs
   */
  showCustomerDashboardPdf(tabName: string): boolean {
    if (!this.tabOptions || !this.selectedTab) {
      return false;
    }
    switch (tabName) {
      case this.pmDashboardTabName:
        return false;
      case this.projectProgressTabName:
        return false;
      case this.systemAssessmentTabName:
        return this.hasPermissionCustomerSystemAssessmentDashboardCreateAPDFExport;
      case this.workOrdersTabName:
        return this.hasPermissionCustomerWorkOrderDashboardCreateAPDFExport;
      default:
        return false;
    }
  }

  /**
   * @returns true if the user has the permission PROJECT_MANAGEMENT_DASHBOARD_VIEW_DASHBOARD
   */
  get hasPermissionProjectManagementDashboardViewDashboard(): boolean {
    return this.hasPermission(UserPermission.PROJECT_MANAGEMENT_DASHBOARD_VIEW_DASHBOARD);
  }

  /**
   * @returns true if the user has the permission CUSTOMER_PROJECT_PROGRESS_DASHBOARD_VIEW_DASHBOARD
   */
  get hasPermissionCustomerProjectProgressDashboardViewDashboard(): boolean {
    return this.hasPermission(UserPermission.CUSTOMER_PROJECT_PROGRESS_DASHBOARD_VIEW_DASHBOARD);
  }

  /**
   * @returns true if the user has the permission CSA_DASHBOARD_VIEW_DASHBOARD
   */
  get hasPermissionCustomerSystemAssessmentDashboardViewDashboard(): boolean {
    return this.hasPermission(UserPermission.CSA_DASHBOARD_VIEW_DASHBOARD);
  }

  /**
   * @returns true if the user has the permission CSA_DASHBOARD_CREATE_A_PDF_EXPORT
   */
  get hasPermissionCustomerSystemAssessmentDashboardCreateAPDFExport(): boolean {
    return this.hasPermission(UserPermission.CSA_DASHBOARD_CREATE_A_PDF_EXPORT);
  }

  /**
   * @returns true if the user has the permission CWO_DASHBOARD_VIEW_DASHBOARD
   */
  get hasPermissionCustomerWorkOrderDashboardViewDashboard(): boolean {
    return this.hasPermission(UserPermission.CWO_DASHBOARD_VIEW_DASHBOARD);
  }

  /**
   * @returns true if the user has the permission CWO_DASHBOARD_CREATE_A_PDF_EXPORT
   */
  get hasPermissionCustomerWorkOrderDashboardCreateAPDFExport(): boolean {
    return this.hasPermission(UserPermission.CWO_DASHBOARD_CREATE_A_PDF_EXPORT);
  }
}
