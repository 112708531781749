


























import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import IntegrityTable, { FillFunctionContainer } from '@/components/IntegrityTable/IntegrityTable.vue';
import { namespace } from 'vuex-class';
import { BigPipeSegment } from '@/store/deployments/types';
import { uuid } from 'vue-uuid';

const deploymentModule = namespace('deployments');

@Component({
  components: {
    IntegrityTable,
  },
})
export default class BigPipeDropdown extends Vue {
    @Prop({ default: [] }) readonly data: BigPipeSegment[];

    @Prop({ default: false }) readonly loading: boolean;

    @Prop({ default: [] }) readonly projectGuids: string[];

    @Prop({ default: '' }) readonly search: string;

    @deploymentModule.State('bigPipeDeploymentDataLoading') bigPipeDeploymentDataLoading: boolean;

    tableData: BigPipeSegment[] = undefined;

    tableHeaders = [
      {
        text: 'Asset ID',
        value: 'AssetID',
        sortable: true,
        filterable: true,
      },
      {
        text: 'US MH', value: 'UpStreamMh', sortable: true, filterable: true,
      },
      {
        text: 'DS MH', value: 'DnStreamMh', sortable: true, filterable: true,
      },
      {
        text: 'Expected Length', value: 'GisLength', sortable: true, filterable: true,
      },
      {
        text: 'Payout', value: 'Payout', sortable: true, filterable: true,
      },
      {
        text: 'Pipe Material', value: 'PipeMaterial', sortable: true, filterable: true,
      },
      {
        text: 'Pipe Size', value: 'PipeSize', sortable: true, filterable: true,
      },
      {
        text: 'Pipe Shape', value: 'PipeShape', sortable: true, filterable: true,
      },
      {
        text: 'Segment Comments', value: 'SegmentCommments', sortable: true, filterable: true,
      },
      {
        text: 'Coding Status',
        value: 'DoNotCode',
        sortable: true,
        filterable: true,
        editable: true,
        options: [],
      },
      {
        text: 'Active',
        value: 'isActive',
        sortable: true,
        filterable: true,
        editable: true,
        options: [],
      },
    ];

    showSnackbar = false as boolean;

    snackbarColor = '' as string;

    snackbarMessage = '' as string;

    get fillTableDataFunctions(): FillFunctionContainer {
      const returnValue: FillFunctionContainer = {
        updateKey: uuid.v4(),
        fillFunctions: [
          {
            headerValue: 'DoNotCode',
            functionVariables: [],
            fillFunction: function fillDoNotCode(
              item: BigPipeSegment,
            ): string {
              return item.DoNotCode ? 'Yes' : 'No';
            },
          },
          {
            headerValue: 'isActive',
            functionVariables: [],
            fillFunction: function fillIsActive(
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              _item: BigPipeSegment,
            ): string {
              return 'Yes';
            },
          },
        ],
      };
      return returnValue;
    }

    get isLoading(): boolean {
      return this.bigPipeDeploymentDataLoading || this.loading;
    }

    @Watch('data', { deep: true })
    onDataChange(): void {
      this.tableData = this.data != null ? this.data : undefined;
    }

    mounted(): void {
      this.onDataChange();
    }
}
