



















































































import Vue from 'vue';
import { AssetActions } from '@/store/asset/actions';
import { InspectionData, IPDEntry } from '@/store/asset/types';
import { Component, Prop, PropSync } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import util from '../../views/Asset/utils';

const assetModule = namespace('asset');

@Component({})

export default class ManholeTrimDialog extends Vue {
  @assetModule.Action(AssetActions.RESET_MANUAL_IPD_ZERO_TIME) resetManualIPDZeroTime;

  @assetModule.Action(AssetActions.POST_MANUAL_IPD_ZERO_TIME) postManualIPDZeroTime;

  @Prop() readonly isManholeAsset: boolean;

  @Prop() readonly trimPermission: boolean;

  @Prop() readonly manualIPDZeroTimeLoading: boolean;

  @PropSync('syncedInspection') inspection!: InspectionData;

  @Prop() readonly sourceId: string;

  @Prop() readonly ipd: IPDEntry[];

  @Prop() readonly displayImperial: boolean;

  trimDialog = false;

  trimPayout = 0;

  get hasManualIPDTime(): boolean {
    return this.inspection?.manualIPDZeroTime != null;
  }

  get setManualIPDTimeButtonIcon(): string {
    return this.hasManualIPDTime ? 'mdi-arrow-u-left-top' : 'mdi-content-cut';
  }

  get setManualIPDTimeButtonTooltip(): string {
    return this.hasManualIPDTime
      ? 'Reset the Manhole Rim Video Positon'
      : 'Trim to Start Video Here (Set at Manhole Rim)';
  }

  get units(): string {
    return this.displayImperial ? 'feet' : 'meters';
  }

  openTrimDialog(): void {
    this.trimDialog = true;
    const video = document.getElementById(
      this.sourceId,
    ) as HTMLVideoElement;
    const setIpdTime = Math.round(video.currentTime * 1000);
    this.trimPayout = Math.round(
      util.getClosestPayoutValue(this.ipd, setIpdTime / 1000) / 10,
    ) / 10;
  }

  closeTrimDialog(): void {
    this.trimDialog = false;
  }

  async setManualIPDZeroTime(): Promise<void> {
    if (this.inspection != null) {
      const video = document.getElementById(
        this.sourceId,
      ) as HTMLVideoElement;
      const setIpdTime = Math.round(video.currentTime * 1000);
      await this.postManualIPDZeroTime({
        inspectionGuid: this.inspection.guid,
        ipdTime: setIpdTime,
        ipdPayout: util.getClosestPayoutValue(this.ipd, setIpdTime / 1000),
      });
      this.closeTrimDialog();
      window.location.reload();
    }
  }

  async undoManualIPDZeroTime(): Promise<void> {
    if (this.inspection != null) {
      await this.resetManualIPDZeroTime(this.inspection.guid);
      this.closeTrimDialog();
      window.location.reload();
    }
  }
}
