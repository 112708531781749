















import { SnackBarMutations } from '@/store/integritySnackBar/mutations';
import { SnackBarMessage } from '@/store/integritySnackBar/types';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const integritySnackBar = namespace('integritySnackBar');
@Component({})
export default class IntegritySnackBar extends Vue {
    @integritySnackBar.State('snackBarMessage') snackBarMessage: SnackBarMessage | undefined;

    @integritySnackBar.Mutation(SnackBarMutations.SET_SNACK_BAR_MESSAGE) setSnackBarMessage;

    timer = 3000;

    noSnack: SnackBarMessage = {
      message: '',
      color: '',
      showSnackBar: false,
    };
}
