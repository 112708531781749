












































































































































































































































































/* eslint-disable object-curly-newline */
/* eslint-disable @typescript-eslint/no-explicit-any */
import IntegrityTable, { AdditionalFilterFunction, FillFunctionContainer, FilterFunction, processDateWithTableObject } from '@/components/IntegrityTable/IntegrityTable.vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ProjectActions } from '@/store/project/actions';
import { ProjectGraphics, ProjectHeaders } from '@/store/project/types';
import { UserPermission } from '@/store/userpermissions/types';
import { TableMode } from '@/components/ExportDataPopout/types';
import utils from '@/components/IntegrityTable/utils';
import { cloneDeep } from 'lodash';
import { UserActions } from '@/store/users/actions';
import BigPipeDropdown from '@/components/BigPipeDropdown/BigPipeDropdown.vue';
import DeploymentForm from '@/components/ProjectManager/DeploymentForm.vue';
import { DeploymentsActions } from '@/store/deployments/actions';
import { PlanningActions } from '@/store/planning/actions';
import { WorkOrderTableData } from '@/store/planning/types';
import { TASK_TYPE_STRING } from '@/common/Constants';
import { MPSActions } from '@/store/mps/actions';
import { NavigationGuardNext, Route } from 'vue-router';
import { uuid } from 'vue-uuid';
import DeploymentEditSoloPipe from './DeploymentEditSoloPipe.vue';
import { BigPipeDeploymentData, BigPipeSegment, DeploymentData } from '../../store/deployments/types';
import DeploymentsMixin from './DeploymentsMixin.vue';
import DeploymentEditMHVertue from './DeploymentEditMHVertue.vue';

const projectModule = namespace('project');
const userPrefsModule = namespace('userPrefs');
const userModule = namespace('users');
const deploymentModule = namespace('deployments');
const planningModule = namespace('planning');
const mpsModule = namespace('mps');

@Component({
  components: {
    IntegrityTable,
    DeploymentEditSoloPipe,
    DeploymentEditMHVertue,
    BigPipeDropdown,
    DeploymentForm,
  },
})
export default class Deployments extends DeploymentsMixin {
  @Prop() readonly ids!: string;

  @projectModule.State('headers') projectHeaders: ProjectHeaders | undefined;

  @projectModule.State('graphics') projectGraphics: ProjectGraphics[] | undefined;

  @userPrefsModule.State('displayImperial') displayImperial: boolean;

  @projectModule.Action(ProjectActions.FETCH_HEADER_DATA) fetchProjectHeaders;

  @projectModule.Action(ProjectActions.FETCH_GRAPHICS_DATA) fetchProjectGraphics;

  @projectModule.Action(ProjectActions.FETCH_PROJECT_DATA) fetchProjectData;

  @userModule.Action(UserActions.FETCH_ALL_DETAILED_USER_DATA)
  fetchAllDetailedUserData;

  @deploymentModule.State('bigPipeDeploymentData') bigPipeDeploymentData: BigPipeDeploymentData | undefined;

  @deploymentModule.State('bigPipeDeploymentDataLoading') bigPipeDeploymentDataLoading: boolean;

  @deploymentModule.Action(
    DeploymentsActions.FETCH_BIG_PIPE_DEPLOYMENT_DATA,
  ) fetchBigPipeDeploymentData;

  @planningModule.Action(PlanningActions.FETCH_ASSIGNMENTS_DATA) fetchAssignmentsData;

  @planningModule.State('data') analyticsData: WorkOrderTableData[] | undefined;

  @planningModule.State('loading') loadingAnalyticsData;

  @userModule.Action(UserActions.FETCH_ALL_USER_DATA) fetchAllUserData;

  @mpsModule.Action(MPSActions.FETCH_MPS_DATA) fetchMpsData;

  tableMode = TableMode.NORMAL;

  exportPopup = false;

  address = '';

  search = '';

  videoFileNameScheme = '';

  videoFileNameOptions = [
    'ProjectName_Date',
    'GUID',
  ];

  tabOptions = [
    { name: 'Manhole', value: 'Manhole' },
    { name: 'Line Segment', value: 'Line Segment' },
  ]

  BIG_PIPE_PLATFORMS = [
    'CCTV',
    'Profiler',
    'P3D',
    'Responder',
  ]

  taskTypeGuids = TASK_TYPE_STRING.map((tts) => tts.guid)

  valid = false;

  tableHeight = '100%';

  selectedSection = '';

  activeAssetType = '';

  openEditDialog = false;

  editItem: DeploymentData | undefined = null;

  expanded = [];

  isLoadingBigPipeTable = false;

  isDeploymentFormOpen = false;

  editDeploymentGuid = '';

  snackbarMessage = '';

  snackbarColor = '';

  snackbarVisible = false;

  filteredPlanningDataByDeploymentGuid: WorkOrderTableData[] = [];

  bigPipeSegmentData: BigPipeSegment[] = [];

  editDeploymentArray: DeploymentData[] = [];

  tableFriendlyHeaders: any[] = [];

  /**
   * Keeps track of the data being displayed on the deployment table.
   * Used to calculate filteredExpectedTotal and filteredCollectedTotal.
   */
  tableData = [];

  @Watch('selectedSection')
  onSelectedSectionChange(): void {
    this.editItem = null;
    this.openEditDialog = false;

    switch (this.selectedSection) {
      case 'Solo MH':
      case 'Vertue':
        this.activeAssetType = 'Manhole';
        break;
      case 'Solo':
      case 'CCTV':
      case 'Profiler':
      case 'P3D':
      case 'Responder':
        this.activeAssetType = 'Line Segment';
        break;
      default:
        this.activeAssetType = '';
        break;
    }
  }

  @Watch('expanded', { deep: true })
  async onExpandedChange(): Promise<void> {
    if (this.expanded.length > 0) {
      this.isLoadingBigPipeTable = true;
      this.bigPipeSegmentData = [];
      await this.fetchBigPipeDeploymentData(this.expanded[0].deploymentGuid);
      if (this.bigPipeDeploymentData != null) {
        this.bigPipeDeploymentData.Segments.forEach((value) => {
          const jsonParsed: BigPipeSegment = JSON.parse(value);
          this.bigPipeSegmentData.push(jsonParsed);
        });
      }
      this.bigPipeSegmentData.sort((a, b) => a.index - b.index);
      this.isLoadingBigPipeTable = false;
    }
  }

  @Watch('loadingAnalyticsData')
  onLoadingAnalyticsDataChange(): void {
    if (this.editItem != null) {
      this.getFilteredPlanningDataByDeploymentGuid(this.editItem.deploymentGuid);
      this.$forceUpdate();
    }
  }

  get headers(): any {
    switch (this.selectedSection) {
      case 'Solo MH':
      case 'Vertue':
        return this.hasPermissionDeploymentsEditRow
          ? this.soloVertueManholeHeaders : this.soloVertueManholeHeaders.slice(0, -1);
      case 'Solo':
        return this.hasPermissionDeploymentsEditRow
          ? this.soloPipeHeaders : this.soloPipeHeaders.slice(0, -1);
      case 'CCTV':
      case 'Profiler':
      case 'P3D':
      case 'Responder':
        return this.hasPermissionDeploymentsEditRow
          ? this.bigPipeHeaders : this.bigPipeHeaders.slice(0, -1);
      default:
        return this.defaultHeaders;
    }
  }

  get matchFilters(): any {
    switch (this.selectedSection) {
      case 'Solo MH':
      case 'Vertue':
        return this.hasPermissionDeploymentsEditRow
          ? this.soloVertueManholeMatchFilters : this.soloVertueManholeMatchFilters.slice(0, -1);
      case 'Solo':
        return this.hasPermissionDeploymentsEditRow
          ? this.soloPipeMatchFilters : this.soloPipeMatchFilters.slice(0, -1);
      case 'CCTV':
      case 'Profiler':
      case 'P3D':
      case 'Responder':
        return this.hasPermissionDeploymentsEditRow
          ? this.bigPipeMatchFilters : this.bigPipeMatchFilters.slice(0, -1);
      default:
        return this.defaultMatchFilters;
    }
  }

  get isBigPipe(): boolean {
    return this.BIG_PIPE_PLATFORMS.includes(this.selectedSection);
  }

  get fillTableDataFunctions(): FillFunctionContainer {
    const returnValue: FillFunctionContainer = {
      updateKey: uuid.v4(),
      fillFunctions: [
        {
          headerValue: 'date',
          functionVariables: ['date'],
          fillFunction: processDateWithTableObject,
        },
        {
          headerValue: 'dueDate',
          functionVariables: ['dueDate'],
          fillFunction: processDateWithTableObject,
        },
        {
          headerValue: 'direction',
          functionVariables: [],
          fillFunction: function fillDirection(
            item: DeploymentData,
          ): string {
            let direction = '';
            if (item.direction) {
              direction = item.direction.replace('D: downstream', 'Downstream');
              direction = item.direction.replace('U: upstream', 'Upstream');
            }
            return direction;
          },
        },
        {
          headerValue: 'mediaUploaded',
          functionVariables: [],
          fillFunction: function fillMediaUploaded(
            item: DeploymentData,
          ): string {
            return item.mediaUploaded ? 'Yes' : 'No';
          },
        },
        {
          headerValue: 'isActive',
          functionVariables: [],
          fillFunction: function fillIsActive(
            item: DeploymentData,
          ): string {
            return item.isActive ? 'Yes' : 'No';
          },
        },
        {
          headerValue: 'readyToCode',
          functionVariables: [],
          fillFunction: function fillReadyToCode(
            item: DeploymentData,
          ): string {
            return item.readyToCode ? 'Yes' : 'No';
          },
        },
        {
          headerValue: 'macp',
          functionVariables: [],
          fillFunction: function fillMACP(
            item: DeploymentData,
          ): string {
            return item.macp ? 'Yes' : 'No';
          },
        },
        {
          headerValue: 'codingStatus',
          functionVariables: [],
          fillFunction: function fillCodingStatus(
            item: DeploymentData,
          ): string {
            return item.codingStatus === 'true' ? 'Yes' : 'No';
          },
        },
      ],
    };
    return returnValue;
  }

  get additionalFilterFunctions(): AdditionalFilterFunction {
    const filterFunctions: FilterFunction[] = [
      {
        functionVariables: [this.activeAssetType],
        filterFunction: function isCorrectAssetType(item, activeAssetType) {
          return item.assetType === activeAssetType;
        },
      },
      {
        functionVariables: [this.selectedSection],
        filterFunction: function isInSelection(item, selectedSection) {
          let section = selectedSection;
          if (section === 'Solo MH') {
            section = 'Solo';
          }
          return item.platformDesc === section;
        },
      },
    ];
    if (this.$route.query.deploymentGuids) {
      const guids = Array.isArray(this.$route.query.deploymentGuids)
        ? this.$route.query.deploymentGuids
        : [this.$route.query.deploymentGuids];
      filterFunctions.push({
        functionVariables: [guids],
        filterFunction: function filterByDeploymentGuidInRoute(item, deploymentGuids) {
          return deploymentGuids.includes(item.deploymentGuid);
        },
      });
    }
    return {
      updateKey: uuid.v4(),
      filterFunctions,
    };
  }

  get filteredDeploymentsData(): DeploymentData[] {
    if (!this.deploymentsData) return undefined;

    return this.deploymentsData;
  }

  getFilteredPlanningDataByDeploymentGuid(deploymentGuid: string): void {
    if (this.analyticsData == null) {
      this.filteredPlanningDataByDeploymentGuid = [];
      return;
    }
    this.filteredPlanningDataByDeploymentGuid = [...this.analyticsData
      .filter((value) => value.deploymentGuid === deploymentGuid)];
  }

  get isEditable(): boolean {
    return this.tableMode === TableMode.EDIT;
  }

  get sectionOptions(): { platform: string, count: number }[] {
    if (!this.deploymentsData || !this.deploymentCounts) return [];

    const retVal = [];
    const platformChecks = [
      'Solo',
      'Solo MH',
      'Vertue',
      'CCTV',
      'Profiler',
      'P3D',
      'Responder',
    ];

    platformChecks.forEach((platform) => {
      let hasPlatform = this.deploymentsData.some((dd) => dd.platformDesc === platform);

      if (platform === 'Solo') {
        hasPlatform = this.deploymentsData.some((dd) => dd.platformDesc === platform && dd.assetType === 'Line Segment');
      }

      if (platform === 'Solo MH') {
        hasPlatform = this.deploymentsData.some((dd) => dd.platformDesc === 'Solo' && dd.assetType === 'Manhole');
      }

      if (hasPlatform) {
        const { count } = this.deploymentCounts.find((depCount) => depCount.platform === platform);
        retVal.push({ platform, count });
      }
    });

    retVal.sort(({ count: a }, { count: b }) => b - a);

    return retVal;
  }

  /**
   * @returns true if the user has the permission DEPLOYMENTS_COMMON
   */
  get hasPermissionDeploymentsCommon(): boolean {
    return this.hasPermission(UserPermission.DEPLOYMENTS_COMMON);
  }

  /**
   * @returns true if the user has the permission DEPLOYMENTS_EDITINLINE
   */
  get hasPermissionDeploymentsEditInline(): boolean {
    return this.hasPermission(UserPermission.DEPLOYMENTS_EDITINLINE);
  }

  /**
   * @returns true if the user has the permission DEPLOYMENTS_EDITROW
   */
  get hasPermissionDeploymentsEditRow(): boolean {
    return this.hasPermission(UserPermission.DEPLOYMENTS_EDITROW);
  }

  /**
   * @returns true if the user has the permission DEPLOYMENTS_EXPORT
   */
  get hasPermissionDeploymentsExport(): boolean {
    return this.hasPermission(UserPermission.DEPLOYMENTS_EXPORT);
  }

  /**
   * @returns true if the user has the permission DEPLOYMENTS_EDIT_UNINVOLVED_DEPLOYMENT_DETAILS
   */
  get hasPermissionDeploymentsEditUninvolved(): boolean {
    return this.hasPermission(UserPermission.DEPLOYMENTS_EDIT_UNINVOLVED_DEPLOYMENT_DETAILS);
  }

  /**
   * @description Finds if the current user can use the deployment form to edit a deployment
   * @param {DeploymentData} item - deployment item
   * @returns true if the user can edit the deployment
   */
  canEditRow(item: DeploymentData): boolean {
    if (!this.hasPermissionDeploymentsEditRow) {
      return false;
    }

    // Dont let any users edit rfr or released deployment
    if (this.isReadyForRelease(item)) {
      return false;
    }

    if (this.hasPermissionDeploymentsEditUninvolved) {
      // User is allowed to edit deployments they are not involved in
      return true;
    }

    return item.isPartOfMission;
  }

  /**
   * @returns true if the deployment is 'Ready For Release' or 'Released'
   */
  isReadyForRelease(item: DeploymentData): boolean {
    return item.releaseStatus === 'Ready For Release'
      || item.releaseStatus === 'Released';
  }

  /**
   * Returns the total distance or count of the current filtered table data
   * where the deployment's coding status is true.
   */
  get filteredCollectedTotal(): string {
    if (!this.tableData) {
      return '';
    }
    return this.getCollectedTotalString(this.tableData.filter((dep) => dep.codingStatus === 'Yes'));
  }

  get collectedTotal(): string {
    if (!this.filteredDeploymentsData) {
      return '';
    }
    const deploymentsCodingStatusYes = this.filteredDeploymentsData
      .filter((dep) => this.additionalFilterFunctions
        .filterFunctions.every((f) => f.filterFunction(dep, ...f.functionVariables))
        && dep.codingStatus === 'true');
    return this.getCollectedTotalString(deploymentsCodingStatusYes);
  }

  getCollectedTotalString(deploymentsCodingStatusYes: DeploymentData[]): string {
    let retVal = '-';

    switch (this.selectedSection) {
      case 'Solo MH':
      case 'Vertue':
        retVal = deploymentsCodingStatusYes.length.toString();
        break;
      case 'CCTV':
      case 'P3D':
      case 'Responder':
      case 'Profiler':
      case 'Solo':
        retVal = this.getConvertedDistance(
          deploymentsCodingStatusYes.reduce(
            (n, { distance }) => n + Math.round(parseFloat(distance)), 0,
          ),
        );
        break;
      default:
        break;
    }

    return retVal;
  }

  async mounted(): Promise<void> {
    if (!this.hasPermissionDeploymentsCommon) {
      this.goToErrorPage();
    }
    this.fetchAllUserData();
    this.fetchMpsData([JSON.parse(this.ids) as string[]][0]);
    this.fetchAllDetailedUserData();
    // Get earliest date to get all work orders
    const date = new Date(0).toISOString();
    this.fetchAssignmentsData({
      dateCutoff: date,
      taskTypeGuids: this.taskTypeGuids,
      projectGuids: [JSON.parse(this.ids) as string[]],
    });
    this.refreshDeploymentData();

    let projectGuidList: string[];
    let id: string;

    try {
      projectGuidList = JSON.parse(this.ids) as string[];
      [id] = projectGuidList;
    } catch {
      id = this.ids;
      projectGuidList = [id];
    }

    await this.fetchProjectHeaders(projectGuidList);
    this.fetchProjectGraphics(projectGuidList).then(() => {
      this.projectGraphics.filter((graphic) => graphic.type !== 'Lateral').forEach((graphic) => {
        this.assets.push({
          name: graphic.name,
          guid: graphic.guid,
        });
      });
    });
    await this.fetchProjectData(projectGuidList);
  }

  async refreshDeploymentData(): Promise<void> {
    if (!this.$route.params.ids) {
      return;
    }

    const projectGuids = JSON.parse(this.$route.params.ids);

    this.fetchDeploymentCounts(projectGuids);
    this.fetchDeploymentsData(projectGuids).then(() => {
      this.getRouteParams();
    });
  }

  /**
   *  @description Gets the route params and fills the match filters from the query
   */
  getRouteParams(): void {
    if (!this.matchFilters) {
      return;
    }
    const route = this.$route;
    if (Object.keys(route.query).length > 0) {
      const queries = Object.entries(route.query);
      const filterHeaders = this.matchFilters.map((mf) => mf.header);
      queries.forEach((pair) => {
        const [key, value] = pair;
        if (key === 'platform') {
          if (this.sectionOptions.find((so) => so.platform === (value as string))) {
            this.selectedSection = value as string;
          }
        } else if (filterHeaders.includes(key)) {
          const valueArray = Array.isArray(value) ? value : [value];
          this.matchFilters.find((mf) => mf.header === key).options.push(...valueArray);
        }
      });
    }
  }

  formatReason(reason: string): string {
    let retVal = reason;

    switch (reason) {
      case 'MISSION_TURNAROUND_OPEN_CHAMBER':
        retVal = 'Open Chamber';
        break;
      case 'MISSION_TURNAROUND_SURCHARGED_MH/PIPE':
        retVal = 'Surcharged MH / Pipe';
        break;
      case 'MISSION_TURNAROUND_CANNOT_OPEN_MANHOLE':
        retVal = 'Cannot Open Manhole';
        break;
      case 'MISSION_TURNAROUND_MANHOLE_INSPECTION':
        retVal = 'Manhole Inspection';
        break;
      case 'MISSION_TURNAROUND_OTHER':
        retVal = 'Other';
        break;
      default:
        break;
    }

    return retVal;
  }

  toggleEdit(): void {
    if (this.tableMode === TableMode.EDIT) {
      this.saveEdits();
    }
    this.tableMode = this.tableMode !== TableMode.EDIT ? TableMode.EDIT : TableMode.NORMAL;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async saveDeliverable(deliverable: DeploymentData): Promise<void> {
    const deliverableGuid = deliverable.deploymentGuid;
    const foundDeployment = this.editDeploymentArray
      .findIndex((deployment) => deployment.deploymentGuid === deliverableGuid);
    if (foundDeployment === -1) {
      this.editDeploymentArray.push(deliverable);
    } else {
      this.editDeploymentArray[foundDeployment] = deliverable;
    }
  }

  async saveEdits(): Promise<void> {
    if (this.editDeploymentArray.length === 0) {
      return;
    }
    const deliverableClones = this.editDeploymentArray.map((d) => cloneDeep(d));

    deliverableClones.forEach((deliverableClone) => {
      // eslint-disable-next-line no-param-reassign
      deliverableClone.nodeGuid = this.assets
        .find((asset) => asset.name === deliverableClone.name).guid;
      // eslint-disable-next-line no-param-reassign
      deliverableClone.isActive = deliverableClone.isActive === 'Yes';
      // eslint-disable-next-line no-param-reassign
      deliverableClone.macp = deliverableClone.macp === 'Yes';
    });

    await this.patchDeploymentData(deliverableClones)
      .catch((error) => {
        console.error(error);
      });

    this.refreshDeploymentData();
    this.editDeploymentArray = [];
  }

  getConvertedDistance(distance: number): string {
    return utils.getConvertedDistance(distance, this.displayImperial);
  }

  openExportDialogue(): void {
    // eslint-disable-next-line prefer-destructuring
    this.videoFileNameScheme = this.videoFileNameOptions[0];
    this.address = this.$auth.user.email;
    this.exportPopup = true;
    if (this.$refs.emailField) {
      (this.$refs.emailField as any).reset();
    }
  }

  exportProject(): void {
    this.exportDeploymentCsv({
      guids: JSON.parse(this.$route.params.ids),
      email: this.address,
      fileName: this.videoFileNameScheme,
    }).then(() => {
      // eslint-disable-next-line no-alert
      alert('Your export request has been received. The download links will be sent shortly to the email provided.');
      this.exportPopup = false;
    });
  }

  edit(item: DeploymentData): void {
    this.editItem = { ...item };
    if (this.isBigPipe) {
      this.expanded = [];
      this.editDeploymentGuid = item.deploymentGuid;
      this.getFilteredPlanningDataByDeploymentGuid(item.deploymentGuid);
      this.isDeploymentFormOpen = true;
    } else {
      this.openEditDialog = true;
    }
  }

  updateDeploymentData(deployment: DeploymentData): void {
    const deployments = [...this.deploymentsData];
    const depToUpdate = deployments.find((d) => d.deploymentGuid === deployment.deploymentGuid);
    const index = deployments.indexOf(depToUpdate);
    if (index !== -1) {
      deployments[index] = deployment;
      this.setDeploymentsData(deployments);
    }
  }

  async deploymentSaveResults(results: string): Promise<void> {
    if (results === 'OK') {
      this.showSnackbarMessage('Success editing deployment!', true);
      this.refreshDeploymentData();
    } else {
      this.showSnackbarMessage(results, false);
    }
    this.isDeploymentFormOpen = false;
  }

  showSnackBarSuccess(msg: string): void {
    this.showSnackbarMessage(msg, true);
  }

  showSnackBarFailue(msg: string): void {
    this.showSnackbarMessage(msg, false);
  }

  showSnackbarMessage(msg: string, isSuccessful: boolean): void {
    this.snackbarMessage = msg;
    this.snackbarColor = isSuccessful ? 'green' : '#e61e25';
    this.snackbarVisible = true;
    setTimeout(() => {
      this.snackbarMessage = '';
      this.snackbarColor = '';
      this.snackbarVisible = false;
    }, 3000);
  }

  updateTableData(data: unknown[]): void {
    this.tableData = data;
  }

  // #region Unsaved changes
  /**
   * Adds beforeunload listener to window
   * @remarks
   * This is part of the vue Lifecycle
   */
  created(): void {
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  }

  /**
   * Removes beforeunload listener to window
   * @remarks
   * This is part of the vue Lifecycle
   */
  beforeDestroy(): void {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
  }

  /**
   * Show confirm before leaving route
   * @remarks
   * This will only be called if exiting page from a <router-link> tag
   */
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
    if (this.confirmStayInPage()) {
      next(false);
    } else {
      next();
    }
  }

  /**
   * Show popup if user has unsaved changes
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  beforeWindowUnload(e: { preventDefault: () => void; returnValue: string; }): void {
    if (this.hasUnsavedChanges) {
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = '';
    }
  }

  /**
   * Find if user has unsaved changes
   * @returns boolean if the user has unsaved changes
   */
  get hasUnsavedChanges(): boolean {
    return this.tableMode === TableMode.EDIT && this.editDeploymentArray.length > 0;
  }

  /**
   * Show popup with custom text if user has unsaved changes
   */
  confirmStayInPage(): boolean {
    return this.hasUnsavedChanges && !this.confirmLeave();
  }

  /**
   * Show popup with custom text
   */
  confirmLeave(): boolean {
    // eslint-disable-next-line no-alert
    return window.confirm('Unsaved Changes. Would you like to submit all unsaved changes?');
  }
  // #endregion
}
