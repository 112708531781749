













































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import ImportNassco from '@/components/ImportNassco/ImportNassco.vue';
import AddScopeBaseVue from '@/components/ScopeAddition/AddScopeBase.vue';
import { namespace } from 'vuex-class';
import { ProjectActions } from '@/store/project/actions';
import UserPermissionsMixin from '@/components/UserPermissions/UserPermissionsMixin.vue';
import { UserPermission } from '@/store/userpermissions/types';
import { options } from './types';
import ImportAgol from '../../components/ImportAgol/ImportAgol.vue';
import ImportMsi from '../../components/ImportMsi/ImportMsi.vue';

const projectModule = namespace('project');

@Component({
  components: {
    ImportNassco,
    ImportAgol,
    ImportMsi,
  },
})
export default class ImportBase extends UserPermissionsMixin {
  @projectModule.Action(ProjectActions.FETCH_HEADER_DATA) fetchHeaderData;

  @Prop() readonly projectGuid: string | undefined;

  selectedTypeModel: {component: unknown, name: string, id: string} = {
    // component: ImportNassco,
    // name: 'Import DB',
    // id: 'importNassco',
    component: ImportAgol,
    name: 'Import AGOL Feature Server',
    id: 'importAgol',
  };

  async mounted(): Promise<void> {
    if (!this.hasPermissionImportCommon) {
      this.goToErrorPage();
    }
    this.fetchHeaderData([this.projectGuid]);
  }

  async getRoles(): Promise<string[]> {
    return this.$auth.getRoles(`auth0|${this.$auth.user.id}`);
  }

  get options(): options {
    const optionsObj: options = {};

    if (this.hasPermissionImportAgolFeatureServer) {
      optionsObj['importAgol'] = {
        component: ImportAgol,
        name: 'Import AGOL Feature Server',
        id: 'importAgol',
      };
    }
    if (this.hasPermissionImportDb) {
      optionsObj['importNassco'] = {
        component: ImportNassco,
        name: 'Import DB',
        id: 'importNassco',
      };
    }
    if (this.hasPermissionImportMsiData) {
      optionsObj['importMsi'] = {
        component: ImportMsi,
        name: 'Import MSI Data',
        id: 'importMsi',
      };
    }
    if (this.hasPermissionImportScope) {
      optionsObj['addScope'] = {
        component: AddScopeBaseVue,
        name: 'Import Scope',
        id: 'addScope',
      };
    }
    return optionsObj;
  }

  /**
   * @returns true if the user has the permission IMPORT_COMMON
   */
  get hasPermissionImportCommon(): boolean {
    return this.hasPermission(UserPermission.IMPORT_COMMON);
  }

  /**
   * @returns true if the user has the permission IMPORT_AGOL_FEATURE_SERVER
   */
  get hasPermissionImportAgolFeatureServer(): boolean {
    return this.hasPermission(UserPermission.IMPORT_AGOL_FEATURE_SERVER);
  }

  /**
   * @returns true if the user has the permission IMPORT_DB
   */
  get hasPermissionImportDb(): boolean {
    return this.hasPermission(UserPermission.IMPORT_DB);
  }

  /**
   * @returns true if the user has the permission IMPORT_MSI_DATA
   */
  get hasPermissionImportMsiData(): boolean {
    return this.hasPermission(UserPermission.IMPORT_MSI_DATA);
  }

  /**
   * @returns true if the user has the permission IMPORT_SCOPE
   */
  get hasPermissionImportScope(): boolean {
    return this.hasPermission(UserPermission.IMPORT_SCOPE);
  }
}
