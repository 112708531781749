























































































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'vue-property-decorator';
import ReportFooter from '../ReportFooter.vue';
import { CleaningReportFields } from './types';
import CleaningWorkOrderReportHeader from './CleaningWorkOrderReportHeader.vue';
import ReportInterface, {
  WorkOrderfieldType,
} from '../ReportHelpers/ReportInterface.vue';
import ReportRadioTableInput from '../ReportHelpers/ReportInputHelpers/ReportRadioTableInput.vue';
import ReportCalendarInput from '../ReportHelpers/ReportInputHelpers/ReportCalendarInput.vue';
import ReportDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportDropDownInput.vue';
import ReportTextInput from '../ReportHelpers/ReportInputHelpers/ReportTextInput.vue';
import ReportRadioRowInput from '../ReportHelpers/ReportInputHelpers/ReportRadioRowInput.vue';

@Component({
  components: {
    ReportFooter,
    ReportTextInput,
    ReportDropDownInput,
    ReportCalendarInput,
    ReportRadioTableInput,
    CleaningWorkOrderReportHeader,
    ReportRadioRowInput,
  },
})
export default class CleaningWorkOrderReport extends ReportInterface {
  // #region Abstract fields
  workOrderData: CleaningReportFields | any = {};

  workOrderFields = [
    { name: 'assignedTo', value: 'Users' },
    { name: 'frequency', value: 'FrequencyChange_FC' },
    { name: 'cleaningInspTask', value: 'CleaningInspTask' },
    { name: 'workDescription', value: 'Description' },
    { name: 'specialInstructions', value: 'Instructions' },
    { name: 'weatherCondition', value: 'WeatherType' },
    { name: 'structUp', value: 'Wastewater_Structure_Up' },
    { name: 'structDown', value: 'Wastewater_Structure_Dn' },
    { name: 'address', value: 'StreetAddress' },
    { name: 'size', value: 'PipeSize' },
    { name: 'material', value: 'PipeMaterial' },
    { name: 'length', value: 'LengthGIS' },
    { name: 'specialConditions', value: 'SpecialConditions' },
    { name: 'roots', value: 'ConditionLMH_Obs1' },
    { name: 'grease', value: 'ConditionLMH_Obs2' },
    { name: 'odor', value: 'ConditionLMH_Obs3' },
    { name: 'observationOther', value: 'ConditionLMH_Obs4' },
    { name: 'gravel', value: 'ConditionLMH_Obs8' },
    { name: 'grit', value: 'ConditionLMH_Obs7' },
    { name: 'sand', value: 'ConditionLMH_Obs6' },
    { name: 'cleaningComments', value: 'CleanComments' },
    { name: 'rootCut', value: 'FURootTreat', type: WorkOrderfieldType.BOOLEAN },
    { name: 'cctv', value: 'FUCCTV', type: WorkOrderfieldType.BOOLEAN },
    { name: 'repair', value: 'FURepair', type: WorkOrderfieldType.BOOLEAN },
    {
      name: 'manholeInpsection',
      value: 'FUMHInspect',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'perfromInvestigation',
      value: 'FUInvestigate',
      type: WorkOrderfieldType.BOOLEAN,
    },
    {
      name: 'mapChanges',
      value: 'FUMapChanges',
      type: WorkOrderfieldType.BOOLEAN,
    },
    { name: 'followUpOther', value: 'FUOther' },
    { name: 'followUpComments', value: 'FollowUpComments' },
    { name: 'dateIssued', value: 'DateIssued', type: WorkOrderfieldType.DATE },
    { name: 'cleanMoreOrLessOften', value: 'FrequencyChange_FC' },
    { name: 'planDueDate', value: 'PlanDueDate', type: WorkOrderfieldType.DATE },
  ];
  // #endregion

  // #region Local variables

  weatherConditionOptions = ['Dry', 'Light Rain', 'Heavy Rain'];

  tableHeaders = [
    {
      value: 'HEAVY',
      label: 'H',
    },
    {
      value: 'MEDIUM',
      label: 'M',
    },
    {
      value: 'LIGHT',
      label: 'L',
    },
    {
      value: 'NONE',
      label: 'N',
    },
  ];

  firstColumnRows = [
    {
      value: 'roots',
      label: 'Roots',
    },
    {
      value: 'grease',
      label: 'Grease',
    },
    {
      value: 'odor',
      label: 'Odor',
    },
    {
      value: 'observationOther',
      label: 'Other',
    },
  ];

  secondColumnRows = [
    {
      value: 'gravel',
      label: 'Gravel',
    },
    {
      value: 'grit',
      label: 'Debris/ Grit',
    },
    {
      value: 'sand',
      label: 'Sand',
    },
  ];
  // #endregion

  // #region Local functions

  isFrequencyChecked(option: string): boolean {
    return this.workOrderData['frequency'] === option;
  }

  changeFrequencyValue(option: string): void {
    const isChecked = this.isFrequencyChecked(option);
    const newValue = !isChecked ? option : 'Do not adjust Frequency';
    this.changeOption('frequency', newValue);
  }

  selectNone(): void {
    Object.assign(this.workOrderData, {
      ...this.workOrderData,
      rootCut: false,
      cctv: false,
      repair: false,
      manholeInpsection: false,
      perfromInvestigation: false,
      mapChanges: false,
      followUpOther: false,
      frequency: 'Do not adjust Frequency',
    });
  }

  get isNoneSelected(): boolean {
    return (
      this.workOrderData.rootCut === false
      && this.workOrderData.cctv === false
      && this.workOrderData.repair === false
      && this.workOrderData.manholeInpsection === false
      && this.workOrderData.perfromInvestigation === false
      && this.workOrderData.mapChanges === false
      && this.workOrderData.followUpOther === false
      && this.workOrderData.frequency === 'Do not adjust Frequency'
    );
  }
  // #endregion
}
