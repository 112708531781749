import { render, staticRenderFns } from "./FormPage.vue?vue&type=template&id=f218d10e&scoped=true&"
import script from "./FormPage.vue?vue&type=script&lang=ts&"
export * from "./FormPage.vue?vue&type=script&lang=ts&"
import style0 from "./FormPage.vue?vue&type=style&index=0&id=f218d10e&scoped=true&lang=css&"
import style1 from "./FormPage.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f218d10e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';
installComponents(component, {VDatePicker,VMenu,VSelect,VTextField,VTextarea,VTimePicker})
