










































import {
  Component, Prop, VModel, Watch,
} from 'vue-property-decorator';
import { OrganizationData } from '@/store/organizations/types';
import AdminPanelMixin from '../AdminPanelMixin.vue';
import OrganizationEditDialog from '../Organizations/OrganizationEditDialog.vue';

@Component({
  components: {
    OrganizationEditDialog,
  },
})
export default class CustomerDialogSelectOrganization extends AdminPanelMixin {
  @VModel() modelValue!: OrganizationData | undefined;

  /**
   * @description set to true to limit organizations to ones only the current user is a part of.
   * @type {boolean | undefined}
   */
  @Prop({ default: false }) limitOrganizations: boolean;

  @Prop({ default: false }) readonly: boolean;

  @Prop({ default: false }) hasPermissionCreateAnOrganization: boolean;

  showNewOrganizationDialog = false;

  organizationValue = '';

  organizationNameSearch = null;

  currentEditItem = {};

  valid = false as boolean;

  mounted(): void {
    if (!this.organizationDataList) {
      this.fetchOrganizationData();
    }
    if (!this.allUsers) {
      this.fetchAllDetailedUserData();
    }
    if (this.modelValue) {
      this.setLocalValues();
    }
  }

  @Watch('modelValue')
  onModelValueChange(): void {
    this.setLocalValues();
  }

  /**
   * @description Sets all local values from the modelValue.
   */
  setLocalValues(): void {
    this.organizationValue = this.modelValue?.name ?? '';
    this.organizationNameSearch = this.modelValue?.name ?? '';
  }

  @Watch('showNewOrganizationDialog')
  onShowNewOrganizationDialogChange(): void {
    if (!this.showNewOrganizationDialog) {
      // If closing the dialog, then reset the search to the modelValue
      this.setLocalValues();
      this.currentEditItem = {};
    }
  }

  /**
   * @description Checks for a new user in the combobox
   * @remarks If an existing user is selected, then select them. If not, then open up the popup
   */
  checkForNewOrgAdd(): void {
    const foundValue = this.organizationDataList.find(
      (u) => u.name === this.organizationValue,
    );
    if (foundValue) {
      this.modelValue = foundValue;
    }
  }

  /**
   * @description Sets modelValue to value of newOrganization and sets local values
   * @param {OrganizationData} newOrganization
   */
  updateLocalOrganization(newOrganization: OrganizationData): void {
    this.modelValue = this.organizationDataList.find(
      (u) => u.name === newOrganization.name,
    );
    this.setLocalValues();
  }

  /**
   * @description Get a list of all organization names.
   * @remark if limitOrganizations is true,
   *     then only organizations the user is a part of will be returned
   * @returns {string[]} list of user organization names.
   */
  get organizationsList(): string[] {
    if (!this.organizationDataList) {
      return [];
    }
    if (!this.limitOrganizations) {
      return this.organizationDataList.map((org) => org.name);
    }
    if (!this.allUsers) {
      return [];
    }
    const useridentity = this.$auth.user.id;
    const currentUser = this.allUsers.find(
      (u) => u.useridentity === `auth0|${useridentity}`,
    );
    if (currentUser) {
      return this.organizationDataList
        .filter((org) => currentUser.organizationGuids.includes(org.guid))
        .map((org) => org.name);
    }
    return [];
  }

  /**
   * @description On pressing enter, open up new org dialog if the search is not a valid item
   */
  createNewOrganization(): void {
    if (!this.hasPermissionCreateAnOrganization) return;

    const foundValue = this.organizationDataList.find(
      (u) => u.name === this.organizationValue,
    );
    if (!foundValue) {
      this.currentEditItem = {
        organizationName: this.organizationNameSearch,
        address: '',
        systemAdmin: null,
        email: '',
        phoneNumber: null,
      };
      this.modelValue = null;
      this.setLocalValues();
      this.showNewOrganizationDialog = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.organizationValueComboBox as any).isFocused = false;
    }
  }

  /**
   * @description when leaving focus, make sure the search is a valid value
   */
  onBlur(): void {
    if (!this.organizationNameSearch) {
      this.modelValue = null;
      return;
    }
    if (this.modelValue?.name !== this.organizationNameSearch) {
      this.checkForNewOrgAdd();
      this.setLocalValues();
    }
  }
}
