
























































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable object-curly-newline */
import {
  Component, Emit, Prop, PropSync, Vue, Mixins,
} from 'vue-property-decorator';
import { AssetRef, AssetData } from '@/types';
import { namespace } from 'vuex-class';
import { cloneDeep } from 'lodash';
import { ProjectData } from '@/store/project/types';
import { UserPermission } from '@/store/userpermissions/types';
import { uuid } from 'vue-uuid';
import { AssetActions } from '../../store/asset/actions';
import ExportDataPopout from '../ExportDataPopout/ExportDataPopout.vue';
import { DataTableHeader } from './types';
import util from '../Maps/utils';
import { TableMode } from '../ExportDataPopout/types';
import IntegrityTable, { AdditionalFilterFunction, FillFunctionContainer, FilterFunction, processDateWithTableObject } from '../IntegrityTable/IntegrityTable.vue';
import IntegrityDelete from '../IntegrityDelete/IntegrityDelete.vue';
import RouteOptions from '../RouteOptions/RouteOptions.vue';
import UserPermissionsMixin from '../UserPermissions/UserPermissionsMixin.vue';

const projectModule = namespace('project');
const assetModule = namespace('asset');

@Component({
  components: {
    ExportDataPopout,
    IntegrityTable,
    IntegrityDelete,
    RouteOptions,
  },
})
export default class AssetTable extends Mixins(Vue, UserPermissionsMixin) {
  pipeHeaders: DataTableHeader[] = [
    { text: 'Name', value: 'name', sortable: true, filterable: true, class: 'sticky', cellClass: 'sticky', editable: true, stopFilterValueGeneration: true },
    { text: '', value: 'hasCustomerDeliverables', sortable: true, filterable: true, class: 'sticky', cellClass: 'sticky' },
    { text: 'Score', value: 'score', sortable: true, filterable: true },
    { text: 'US ID', value: 'upstreamId', sortable: true, filterable: true, editable: true },
    { text: 'DS ID', value: 'downstreamId', sortable: true, filterable: true, editable: true },
    { text: 'Pipe Size', value: 'pipeSize', sortable: true, filterable: true, editable: true },
    { text: 'Pipe Material', value: 'pipeMaterial', sortable: true, filterable: true, editable: true },
    { text: 'Street', value: 'street', sortable: true, filterable: true, editable: true },
    { text: 'City', value: 'city', sortable: true, filterable: true },
    { text: 'Sewer Use', value: 'sewerUse', sortable: true, filterable: true, editable: true },
    { text: 'Customer WO', value: 'customerWorkOrder', sortable: true, filterable: true, editable: true },
    { text: 'Batch Number', value: 'batchNumber', sortable: true, filterable: true, editable: true },
    { text: 'Drainage Area / Basin', value: 'basin', sortable: true, filterable: true, editable: true },
    { text: 'Last Inspected', value: 'lastInspected', sortable: true, filterable: true },
    { text: 'GIS Length', value: 'gisLength', sortable: true, filterable: true },
    { text: 'Length Surveyed', value: 'lengthSurveyed', sortable: true, filterable: true },
    { text: 'OM Quick', value: 'omQuick', sortable: true, filterable: true },
    { text: 'Structural Quick', value: 'structuralQuick', sortable: true, filterable: true },
    { text: 'Overall Quick', value: 'overallQuick', sortable: true, filterable: true },
    { text: 'OM Index', value: 'omIndex', sortable: true, filterable: true },
    { text: 'Structural Index', value: 'structuralIndex', sortable: true, filterable: true },
    { text: 'Overall Index', value: 'overallIndex', sortable: true, filterable: true },
    { text: 'OM Rating', value: 'omRating', sortable: true, filterable: true },
    { text: 'Structural Rating', value: 'structuralRating', sortable: true, filterable: true },
    { text: 'Overall Rating', value: 'overallRating', sortable: true, filterable: true },
    { text: 'Platform', value: 'platform', sortable: true, filterable: true },
    { text: 'Comments', value: 'comments', sortable: true, filterable: true, editable: true },
    { text: 'Owner', value: 'owner', sortable: true, filterable: true, editable: true },
    { text: 'Coding Status', value: 'codingStatus', sortable: true, filterable: true },
  ];

  manholeHeaders: DataTableHeader[] = [
    { text: 'Name', value: 'name', sortable: true, filterable: true, class: 'sticky', cellClass: 'sticky', editable: true },
    { text: '', value: 'hasCustomerDeliverables', sortable: true, filterable: true, class: 'sticky', cellClass: 'sticky' },
    { text: 'Score', value: 'score', sortable: true, filterable: true },
    { text: 'Street', value: 'street', sortable: true, filterable: true, editable: true },
    { text: 'City', value: 'city', sortable: true, filterable: true },
    { text: 'Sewer Use', value: 'sewerUse', sortable: true, filterable: true, editable: true },
    { text: 'Customer WO', value: 'customerWorkOrder', sortable: true, filterable: true, editable: true },
    { text: 'Batch Number', value: 'batchNumber', sortable: true, filterable: true, editable: true },
    { text: 'Drainage Area / Basin', value: 'basin', sortable: true, filterable: true, editable: true },
    { text: 'Last Inspected', value: 'lastInspected', sortable: true, filterable: true },
    { text: 'OM Quick', value: 'omQuick', sortable: true, filterable: true },
    { text: 'Structural Quick', value: 'structuralQuick', sortable: true, filterable: true },
    { text: 'Overall Quick', value: 'overallQuick', sortable: true, filterable: true },
    { text: 'OM Index', value: 'omIndex', sortable: true, filterable: true },
    { text: 'Structural Index', value: 'structuralIndex', sortable: true, filterable: true },
    { text: 'Overall Index', value: 'overallIndex', sortable: true, filterable: true },
    { text: 'OM Rating', value: 'omRating', sortable: true, filterable: true },
    { text: 'Structural Rating', value: 'structuralRating', sortable: true, filterable: true },
    { text: 'Overall Rating', value: 'overallRating', sortable: true, filterable: true },
    { text: 'Platform', value: 'platform', sortable: true, filterable: true },
    { text: 'Comments', value: 'comments', sortable: true, filterable: true, editable: true },
    { text: 'Owner', value: 'owner', sortable: true, filterable: true, editable: true },
    { text: 'Coding Status', value: 'codingStatus', sortable: true, filterable: true },
  ];

  displayHeaders: DataTableHeader[][] = [
    this.manholeHeaders,
    this.pipeHeaders,
  ];

  matchFilterMethod = {
    number: ['>', '>=', '<=', '<', '=', '!='],
    string: ['Exactly', 'Includes', 'Does Not Include'],
  }

  matchFilters = [
    { header: 'score', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'name', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'codingStatus', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'overallQuick', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'overallIndex', type: 'number', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'overallRating', type: 'number', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'structuralQuick', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'structuralIndex', type: 'number', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'structuralRating', type: 'number', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'omQuick', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'omIndex', type: 'number', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'omRating', type: 'number', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'owner', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'sewerUse', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'customerWorkOrder', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'batchNumber', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'platform', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'comments', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'assetId', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'type', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'upstreamId', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'downstreamId', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'street', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'city', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'basin', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'pipeSize', type: 'number', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'pipeMaterial', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'lastInspected', type: 'date', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'gisLength', type: 'number', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'lengthSurveyed', type: 'number', value: '', method: '', options: [], tempValue: '', open: false },
    { header: 'hasCustomerDeliverables', type: 'string', value: '', method: '', options: [], tempValue: '', open: false },
  ]

  tabOptions = [
    { name: 'Manhole', value: 'Manhole' },
    { name: 'Line Segment', value: 'Line Segment' },
  ]

  activeTab = 0;

  assetBackup = [];

  expandTableLocal = false;

  snack = false;

  snackColor = 'black';

  snackText = '';

  tableMode = TableMode.NORMAL;

  exportAssets = [];

  sortBy = 'name';

  sortByDesc = false;

  blankMatchFilters = {}

  search = '';

  isRoutingOptionsPopupActive = false;

  tableModel = [];

  routeColor = '#FFFFFF'

  routeDesc = ''

  routeName = ''

  routeGuid = ''

  hasMedia = 'Has Media';

  noMediaAvailable = 'No Media Available';

  visibleTableData = [];

  @projectModule.State('data') project: ProjectData | undefined;

  @assetModule.State('editError') loadError;

  @assetModule.State('editState') loading;

  @assetModule.State('edit') assetEditResponse;

  @assetModule.Action(AssetActions.EDIT_ASSET_DATA) editAssetData;

  @assetModule.State('delete') delete: unknown | undefined = undefined;

  @assetModule.State('deleteError') deleteError: string | undefined = undefined;

  @assetModule.State('deleteState') deleteState: boolean | undefined = undefined;

  @assetModule.State('loading') assetModuleLoading: boolean | undefined = undefined;

  @assetModule.Action(AssetActions.DELETE_ASSET) deleteAssetData;

  @Prop() readonly tableData: AssetData[] | undefined;

  @Prop() readonly loadingData: boolean;

  @PropSync('syncedAssetGuidList') readonly assetGuidList: string[] | undefined;

  getAssetParams(item: { guid: string }): any {
    return { idList: JSON.stringify(this.assetGuidList), id: item.guid };
  }

  mounted(): void {
    if (!this.hasPermissionAssetInventoryCommon) {
      this.goToErrorPage();
    }
    this.expandTableLocal = false;
  }

  getAssetMediaAvailableString(asset: AssetData): string {
    return asset.hasCustomerDeliverables ? this.hasMedia : this.noMediaAvailable;
  }

  @Emit()
  // eslint-disable-next-line class-methods-use-this
  onAssetRequest(asset: AssetRef): AssetRef {
    return asset;
  }

  @Emit()
  // eslint-disable-next-line class-methods-use-this
  restoreAssetsState(assets: AssetData[]): AssetData[] {
    return assets;
  }

  get selectedCreateRoutes(): AssetData[] {
    return [...this.tableModel.filter(
      (asset) => asset.type === this.routeAssetType,
    )];
  }

  get routeAssetType(): string {
    return this.tabOptions[this.activeTab].value;
  }

  get tableHeader(): string {
    let retVal = 'Asset Inventory';

    if (this.isEditTable()) retVal += ': EDIT';

    if (this.isExportMode()) retVal += ': EXPORT';

    if (this.isCreateRouteMode()) retVal += ': CREATE ROUTE';
    return retVal;
  }

  get appliedFiltersText(): string {
    const activeFilters = this.matchFilters.filter(
      (m) => m.value
        || (m?.options != null && m.options.length > 0)
        || this.blankMatchFilters[m.header],
    );
    if (activeFilters.length === 0) {
      return null;
    }

    return `Filtered By: ${activeFilters.map((m) => `${m.header} ${m.method} ${m.value ? this.truncate(m.value, 30)
      : `[${this.truncate(m.options.concat(this.blankMatchFilters[m.header] ? ['Blank'] : []) as any as string, 10)}]`}`)
      .join(', ')}`;
  }

  truncate(str: string, maxlength: number): string {
    return (str.length > maxlength)
      ? `${str.slice(0, maxlength - 1)}…` : str;
  }

  clearAllFilters(): void {
    (this.$refs.assetTable as IntegrityTable).clearAllMatchFilters();
  }

  isEditTable(): boolean {
    return this.tableMode === TableMode.EDIT;
  }

  isCreateRouteMode(): boolean {
    return this.tableMode === TableMode.CREATE;
  }

  isExportMode(): boolean {
    return this.tableMode === TableMode.EXPORT;
  }

  // eslint-disable-next-line class-methods-use-this
  getScoreColorTable(score: number | string): string {
    const realScore = Number.parseInt(score.toString(), 10);
    if (Number.isNaN(realScore)) {
      util.getScoreColor(-1);
    }
    return util.getScoreColor(realScore);
  }

  getQuickScoreColor(score: number | string): string {
    const realScore = Number.parseInt(score.toString()[0], 10);
    if (Number.isNaN(realScore)) {
      util.getScoreColor(-1);
    }
    return `${util.getScoreColor(realScore)}33`;
  }

  get featureHeaders(): DataTableHeader[] {
    return this.displayHeaders[this.activeTab];
  }

  get showSelect(): boolean {
    return this.isExportMode()
    || (this.isEditTable() && this.hasPermissionAssetInventoryDeleteAsset)
    || this.isCreateRouteMode();
  }

  get editableHeaders(): DataTableHeader[] {
    return this.isEditTable() ? this.featureHeaders.filter((header) => header.editable) : [];
  }

  get fillTableDataFunctions(): FillFunctionContainer {
    const returnValue: FillFunctionContainer = {
      updateKey: uuid.v4(),
      fillFunctions: [
        {
          headerValue: 'hasCustomerDeliverables',
          functionVariables: [this.hasMedia, this.noMediaAvailable],
          fillFunction: function fillHasCustomerDeliverables(
            item: AssetData,
            hasMedia: string,
            noMediaAvailable: string,
          ): string {
            if (item.hasCustomerDeliverables) {
              return hasMedia;
            }
            return noMediaAvailable;
          },
        },
        {
          headerValue: 'score',
          functionVariables: [],
          fillFunction: function fillScore(
            item: AssetData,
          ): string {
            return item.score === -1 ? 'Unscored' : item.score.toString();
          },
        },
        {
          headerValue: 'lastInspected',
          functionVariables: ['lastInspected'],
          fillFunction: processDateWithTableObject,
        },
      ],
    };
    return returnValue;
  }

  get additionalFilterFunctions(): AdditionalFilterFunction {
    const filterFunctions: FilterFunction[] = [
      {
        functionVariables: [['Manhole', 'Line Segment'], this.activeTab],
        filterFunction: function isAssetType(item, typeStrings, activeTab) {
          return item.type === typeStrings[activeTab];
        },
      },
      {
        functionVariables: [],
        filterFunction: function isAssetVisible(item) {
          return item.visible === true;
        },
      },
    ];
    return {
      filterFunctions,
    };
  }

  get filteredTableData(): AssetData[] {
    return this.tableData;
  }

  toggleEdit(): void {
    this.expandTableLocal = true;
    this.tableMode = this.tableMode !== TableMode.EDIT ? TableMode.EDIT : TableMode.NORMAL;

    if (this.isEditTable()) {
      this.tableModel = [];
    } else {
      this.tableModel = [];
    }
  }

  toggleCreateRoutes(): void {
    this.expandTableLocal = true;
    this.tableMode = this.tableMode !== TableMode.CREATE
      ? TableMode.CREATE : TableMode.NORMAL;

    if (this.isCreateRouteMode()) {
      this.tableModel = [];
    } else {
      this.tableModel = [];
    }
  }

  cancel(): void {
    this.snack = true;
    this.snackColor = '#e61e25';
    this.snackText = 'Canceled Edit';
  }

  async saveAsset(asset: AssetData): Promise<void> {
    const assetGuid = asset.guid;
    const assetOut = this.project.assets.find((ao) => ao.guid === assetGuid);
    let attributes;

    if (typeof assetOut.attributes === 'string') attributes = JSON.parse(assetOut.attributes.replace(/&quot;/g, '"'));
    else if (assetOut.attributes) attributes = assetOut.attributes;
    else attributes = {};

    assetOut.priority = asset.score;
    assetOut.name = asset.name;
    assetOut.status = asset.status;
    if (assetOut.inspections.length > 0) {
      assetOut.inspections[0].codingStatus.description = asset.codingStatus;
    }
    assetOut.type = asset.type;
    // figure out where these come from
    assetOut.assetType = '';
    assetOut.validation = '';

    assetOut.upstream = asset.upstreamId ? asset.upstreamId : '';
    assetOut.downstream = asset.downstreamId ? asset.downstreamId : '';

    attributes.Comments = asset.comments ? asset.comments : '';
    attributes.Owner = asset.owner ? asset.owner : '';
    attributes.SewerUse = asset.sewerUse ? asset.sewerUse : '';
    attributes.DateInstalled = asset.instDate ? asset.instDate : '';
    attributes.ID = asset.assetId ? asset.assetId.toString() : '';
    if (attributes.ID_Wastewater) attributes.ID_Wastewater = attributes.ID_Wastewater.toString();
    attributes.Street = asset.street ? asset.street : '';
    attributes.Basin = asset.basin ? asset.basin : '';
    attributes.PipeMaterial = asset.pipeMaterial ? asset.pipeMaterial : '';
    attributes.PipeSize = asset.pipeSize ? asset.pipeSize : '';
    assetOut.attributes = attributes;

    // Call edit API
    try {
      await this.editAssetData({ assetGuid, assetOut });
      if (this.assetEditResponse.status === 200) {
      // set snack bar
        this.snack = true;
        this.snackColor = 'green';
        this.snackText = 'Data saved';
        // pop off backup state
        this.assetBackup.pop();
      } else {
        this.snack = true;
        this.snackColor = '#e61e25';
        this.snackText = 'Edit Failed';
        // Restore backup state
        this.restoreAssetsState(this.assetBackup.pop());
        this.$forceUpdate();
      }
    } catch {
      this.snack = true;
      this.snackColor = '#e61e25';
      this.snackText = 'Edit Failed';
      // Restore backup state
      this.restoreAssetsState(this.assetBackup.pop());
      this.$forceUpdate();
    }
  }

  async deleteAsset(assetGuid: string): Promise<boolean> {
    // Before deleting local copy, delete cloud copy
    try {
      await this.deleteAssetData([assetGuid]);
      if ((this.delete as any).status === 200) {
        this.deleteLocalAsset(assetGuid);
        return true;
      }
    } catch (e) {
      return false;
    }
    return false;
  }

  deleteLocalAssetList(assetGuids: string[]): void{
    assetGuids.forEach((asset) => {
      this.deleteLocalAsset(asset);
    });
    this.tableModel = [];
  }

  async deleteAssets(): Promise<boolean> {
    try {
      const assetIds: string[] = this.tableModel.map((asset) => asset.guid);
      await this.deleteAssetData(assetIds);
      if ((this.delete as any).status === 200) {
        this.deleteLocalAssetList(assetIds);
        return true;
      }
    } catch (e) {
      return false;
    }
    return false;
  }

  deleteLocalAsset(assetGuid: string): void {
    this.$emit('deleteLocalAsset', this.tableData.find((a) => a.guid === assetGuid));
  }

  pushState(): void {
    this.assetBackup.push(cloneDeep(this.tableData));
  }

  toggleExport(): void {
    this.expandTableLocal = true;

    this.tableMode = this.tableMode !== TableMode.EXPORT ? TableMode.EXPORT : TableMode.NORMAL;

    if (this.isExportMode()) {
      this.exportAssets = [];
      this.exportAssets.push(...this.tableModel);
    } else {
      this.exportAssets = [];
    }
  }

  setFilterMenu(headerValue: string, value: boolean): boolean {
    const filter = this.matchFilters.find((f) => f.header === headerValue);
    filter.open = value;
    return filter.open;
  }

  toggleRoutingOptionsPopupActive(state: boolean): void {
    this.isRoutingOptionsPopupActive = state;
  }

  removeCreateRoutingAsset(asset: AssetData): void {
    const index = this.tableModel.findIndex((sa) => sa.guid === asset.guid);
    this.tableModel.splice(index, 1);
  }

  postCreateRouteResult(result: string): void {
    // empty string means success
    if (result === '') {
      this.snackColor = 'green';
      this.snackText = 'Route Added';
      this.tableModel = [];
    } else {
      this.snackColor = '#e61e25';
      this.snackText = 'Error Adding Route';
    }
    this.snack = true;
  }

  getRouteParams(): void {
    const route = this.$route;
    if (Object.keys(route.query).length > 0) {
      const queries = Object.entries(route.query);
      const filterHeaders = this.matchFilters.map((mf) => mf.header);
      queries.forEach((pair) => {
        const [key, value] = pair;
        if (filterHeaders.includes(key)) {
          // check type before pushing, default is string
          const match = this.matchFilters.find((mf) => mf.header === key);
          let typedValue: string | number | string[] = value as string;
          if (match.type === 'number') {
            typedValue = parseInt(typedValue, 10);
            match.options.push(typedValue);
          } else if (Array.isArray(value) || value as any instanceof Array) {
            typedValue = value as string[];
            match.options = match.options.concat(typedValue);
          } else {
            match.options.push(typedValue);
          }
        } else if (key === 'assetType') {
          switch (value) {
            case 'MH':
              this.activeTab = 0;
              break;
            case 'LS':
              this.activeTab = 1;
              break;
            default:
              break;
          }
        }
      });
    }
  }

  updateTableData(data: unknown[]): void {
    this.visibleTableData = data;
  }

  /**
   * @returns true if the user has the permission INSPECTION_VIEWER_COMMON
   */
  get hasPermissionInspectionViewerCommon(): boolean {
    return this.hasPermission(UserPermission.INSPECTION_VIEWER_COMMON);
  }

  /**
   * @returns true if the user has the permission ASSET_INVENTORY_COMMON
   */
  get hasPermissionAssetInventoryCommon(): boolean {
    return this.hasPermission(UserPermission.ASSET_INVENTORY_COMMON);
  }

  /**
   * @returns true if the user has the permission ASSET_INVENTORY_DELETEASSET
   */
  get hasPermissionAssetInventoryDeleteAsset(): boolean {
    return this.hasPermission(UserPermission.ASSET_INVENTORY_DELETEASSET);
  }

  /**
   * @returns true if the user has the permission ASSET_INVENTORY_EXPORTDATA
   */
  get hasPermissionAssetInventoryExportData(): boolean {
    return this.hasPermission(UserPermission.ASSET_INVENTORY_EXPORTDATA);
  }

  /**
   * @returns true if the user has the permission ASSET_INVENTORY_CREATEROUTE
   */
  get hasPermissionAssetInventoryCreateRoute(): boolean {
    return this.hasPermission(UserPermission.ASSET_INVENTORY_CREATEROUTE);
  }

  /**
   * @returns true if the user has the permission ASSET_INVENTORY_EDIT_ASSET
   */
  get hasPermissionEditAsset(): boolean {
    return this.hasPermission(UserPermission.ASSET_INVENTORY_EDIT_ASSET);
  }
}
