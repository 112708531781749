















































































import { Component, Prop } from 'vue-property-decorator';
import VueHtml2pdf from 'vue-html2pdf';
import SideDrawer from '@/components/SideDrawer/SideDrawer.vue';
import CustomerDashboardMixin from '@/views/Dashboards/CustomerDashboard/CustomerDashboardMixin.vue';
import CustomerDashboardPagePdf from './CustomerDashboardPdfPage.vue';

@Component({
  components: {
    CustomerDashboardPagePdf,
    VueHtml2pdf,
    SideDrawer,
  },
})
export default class CustomerDashboardPdf extends CustomerDashboardMixin {
  @Prop() readonly projectName: string;

  @Prop() readonly reportType!: string;

  drawerOpen = false as boolean;

  hasDownloaded = true;

  get dataLoaded(): boolean {
    if (this.reportType == null || this.reportType === '') return false;

    switch (this.reportType) {
      case 'systemAssessment': // system assessment report
        if (this.nasscoInspectionScoresData && this.projectDefectsData) {
          return true;
        }
        break;
      case 'workOrders': // work order report
        if (this.customerDashboardWorkOrders && this.customerDataWorkOrderCounts) {
          return true;
        }
        break;
      default:
        break;
    }

    return false;
  }

  generateReport(): void {
    this.hasDownloaded = false;
    // eslint-disable-next-line dot-notation
    this.$refs.customerDashboardHtml2Pdf['generatePdf']();
  }

  openDrawer(): void {
    this.drawerOpen = true;
  }
}
