
















































































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import MappingFieldData from './AgolConstantsMixin.vue';

@Component({
  components: {},
})
export default class AgolMappingDialog extends Vue {
  @Prop() readonly updating: boolean;

  @Prop() readonly mappingFieldsPerRow: number;

  @Prop() readonly originalMappingName: string;

  @Prop() readonly groupedMappingFieldData: MappingFieldData[][];

  @Prop() readonly fieldNames: string[];

  @Prop({ default: false }) readonly originalAllowDataPolling: boolean;

  mappingName: string = null;

  allowDataPolling = false;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, arrow-parens
  requiredField = [(v) => !!v || v === 0 || 'This is a required field.'];

  valid = false as boolean;

  @Watch('originalMappingName')
  onOriginalMappingNameChange(value: string): void {
    this.mappingName = value;
  }

  @Watch('originalAllowDataPolling')
  onAllowDataPollingChange(value: boolean): void {
    this.allowDataPolling = value;
  }

  mounted(): void {
    this.onOriginalMappingNameChange(this.originalMappingName);
    this.onAllowDataPollingChange(this.allowDataPolling);
  }

  performCancel(): void {
    this.mappingName = null;
    this.$emit('cancel');
  }

  performSubmit(): void {
    this.$emit('submit', this.mappingName, this.allowDataPolling);
    this.mappingName = null;
  }

  getFieldRules(isRequired: boolean): any {
    if (!isRequired) {
      return [true];
    }
    return this.requiredField;
  }
}
