











































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import InspectionScoresChart from '@/components/MSIGraph/InspectionScoresChart.vue';
import CustomerDashboardMixin from '@/views/Dashboards/CustomerDashboard/CustomerDashboardMixin.vue';
import {
  Component, Prop, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ReleasesActions } from '@/store/releases/actions';
import { ReleaseTableData } from '@/store/releases/types';
import { ReleaseGuidName } from '@/store/metrics/types';
import DashboardDefectTable from './DashboardDefectTable.vue';
import DefectCountTableContainer from './DefectCountTableContainer.vue';

const releaseModule = namespace('releases');
// eslint-disable-next-line no-shadow
enum SystemAssessmentTabs {
    MANHOLE = 'Manholes',
    LINESEGMENTS = 'Line Segments'
}

@Component({
  components: {
    DefectCountTableContainer,
    InspectionScoresChart,
    DashboardDefectTable,
  },
})
export default class CustomerDashboardSystemAssessment extends CustomerDashboardMixin {
    @Prop() readonly projectId: string;

    @releaseModule.Action(ReleasesActions.FETCH_RELEASE_TABLE_DATA) fetchReleaseTableData: any;

    @releaseModule.State('releaseTableData') releaseTableData: ReleaseTableData[];

    selectedTab: SystemAssessmentTabs = SystemAssessmentTabs.MANHOLE

    validScores = [-1, 0, 1, 2, 3, 4, 5];

    selectedGroup = '';

    isStructural: boolean | undefined = null;

    selectedGrade: number | undefined = null;

    isReleaseDropdownOpen = false;

    isReleaseSelected = false;

    assetsFiltered: string[] = [];

    get tabs(): string[] {
      return Object.values(SystemAssessmentTabs);
    }

    get releases(): ReleaseGuidName[] {
      if (this.releaseTableData == null) {
        return [];
      }
      const releaseGuidNames = this.releaseTableData.map((value) => (
        JSON.stringify({ guid: value.releaseGuid, name: value.releaseName })
      ));
      const uniqueReleaseGuidNames = new Set(releaseGuidNames);
      return Array.from(uniqueReleaseGuidNames).map((guidName) => JSON.parse(guidName));
    }

    @Watch('projectId')
    async onProjectIdChange(): Promise<void> {
      if (this.projectId != null && this.projectId !== '') {
        this.fetchDashboardDefects({ projectGuid: this.projectId, scores: this.validScores });
        this.fetchDefects();
        await this.fetchDefectScoringMetrics();
      }
    }

    @Watch('selectedTab')
    onSelectedTabChange(): void {
      this.clearFilters();
    }

    @Watch('selectedGroup')
    onSelectedGroupChange(): void {
      this.isStructural = null;
      this.selectedGrade = null;
    }

    async mounted(): Promise<void> {
      await this.onProjectIdChange();
      this.fetchReleaseTableData(this.projectId);
      this.fetchFilterDefects(this.projectId);
    }

    async fetchDefectScoringMetrics(): Promise<void> {
      this.fetchNasscoInspectionScores({
        projectGuid: this.projectId,
        assetsFiltered: this.assetsFiltered,
      });
      await this.fetchDefectMetrics({
        projectGuid: this.projectId,
        assetsFiltered: this.assetsFiltered,
      });
    }

    InspectionScoreClick(
      isStructural: boolean | undefined,
      scoreValue: number | undefined,
      taskType: string,
    ): void {
      this.selectedGroup = '';
      this.isStructural = isStructural;
      this.selectedGrade = scoreValue;
    }

    clearFilters(): void {
      this.selectedGroup = '';
      this.isStructural = null;
      this.selectedGrade = null;
    }

    pickReleaseSelection(item: ReleaseGuidName): void {
      this.setSelectedRelease(item);
      this.isReleaseSelected = true;
      this.setAssetGuidsFiltered();
      this.fetchDefectScoringMetrics();
      this.isReleaseDropdownOpen = false;
    }

    resetReleaseSelection(): void {
      this.setSelectedRelease(undefined);
      this.isReleaseSelected = false;
      this.setAssetGuidsFiltered();
      this.fetchDefectScoringMetrics();
      this.isReleaseDropdownOpen = false;
    }

    setAssetGuidsFiltered(): void {
      if (!this.isReleaseSelected) {
        this.assetsFiltered = [];
        return;
      }
      this.assetsFiltered = Array.from(new Set(
        this.dashboardFilterDefects
          .filter((value) => value.releaseGuid === this.selectedRelease.guid)
          .map((value) => value.assetGuid),
      ));
    }
}
