















































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, VModel } from 'vue-property-decorator';
import { zip } from 'lodash';
import ReportInputMixin from '../ReportInputMixin.vue';
import { SubInspectionWorkOrder } from '../types';
import ReportTextInput from './ReportTextInput.vue';

@Component({
  components: { ReportTextInput },
})
export default class ReportTableInput extends ReportInputMixin {
  @VModel() modelValue!: any[] | undefined;

  /**
   * Key for accessing data within `modelValue`.
   * @type {string}
   */
  @Prop() modelValueKey!: string;

  /**
   * Label for the table.
   * @type {string}
   */
  @Prop() label!: string;

  /**
   * Array of column names to be displayed in the table.
   * @type {string[]}
   */
  @Prop() columnNames!: string[];

  /**
   * Function to filter table data into their headers.
   * @type {(data: SubInspectionWorkOrder) => string}
   */
  @Prop() filterFunction!: (data: SubInspectionWorkOrder) => string;

  /**
   * Function to create a new asset for a given column.
   * @type {(column: string) => SubInspectionWorkOrder}
   */
  @Prop() newAsset!: (column: string) => SubInspectionWorkOrder;

  /**
   * Computes the rows to be displayed in the table.
   * Each row contains data from the filtered table columns.
   * @returns {any[]}
   */
  get tableRows(): any[] {
    if (!this.modelValue) {
      return [];
    }
    if (!this.filterFunction) {
      console.error('No Filter Function');
      return [];
    }
    const filteredData = this.filteredDataByColumn;
    const rowCount = Object.values(filteredData).reduce(
      (max: number, subArray) => Math.max(max, (subArray as number[]).length),
      0,
    );

    const tableData = this.transpose(
      this.columnNames.map((col) => this.padArray(filteredData[col], rowCount, null)),
    );
    return tableData;
  }

  /**
   * Get a list of data filtered by their columns
   */
  get filteredDataByColumn(): any {
    const filteredData = {};
    this.modelValue.forEach((data, index) => {
      const category = this.filterFunction(data);
      if (!(category in filteredData)) {
        filteredData[category] = [];
      }
      filteredData[category].push(index);
    });
    return filteredData;
  }

  /**
   * Pads an array to a specified length with a filler value.
   * @param {number[]} arr - The array to pad.
   * @param {number} len - The desired length of the array.
   * @param {undefined | number} fill - The value to use as padding.
   * @returns {(undefined | number)[]}
   */
  padArray(
    arr: number[],
    len: number,
    fill: undefined | number,
  ): (undefined | number)[] {
    if (!arr) {
      return [];
    }
    return arr.concat(Array(len).fill(fill)).slice(0, len);
  }

  /**
   * Transposes a 2D array.
   * Converts rows to columns and vice versa.
   * @param {any[]} arr - The 2D array to transpose.
   * @returns {any[]}
   */
  transpose(arr: any[]): any[] {
    if (!arr) {
      return [];
    }
    return zip(...arr);
  }

  /**
   * Adds a new asset to the table in the specified column.
   * @param {string} column - The column where the new asset will be added.
   */
  addAsset(column: string): void {
    if (!this.newAsset) {
      console.error('No New Asset Function');
      return;
    }
    this.modelValue.push(this.newAsset(column));
  }

  /**
   * Removes an asset from the table by its index.
   * @param {number} inspectionDataIndex - The index of the asset to remove.
   * @returns {void}
   */
  removeAsset(inspectionDataIndex: number): void {
    if (this.modelValue.length <= inspectionDataIndex) {
      console.error('Invalid index');
    }
    this.modelValue.splice(inspectionDataIndex, 1);
  }
}
