























































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  MACP_LEVEL_1_COLLECTION_TASK,
  MACP_LEVEL_2_COLLECTION_TASK,
  NODEITEM_MANHOLE_GUID,
  PACP_COLLECTION_TASK,
  TASK_TYPE_STRING,
} from '@/common/Constants';
import { AssetActions } from '@/store/asset/actions';
import { InspectionData } from '@/store/asset/types';
import { InspectionActions } from '@/store/inspection/actions';
import { PlanningActions } from '@/store/planning/actions';
import { WorkOrderTableData } from '@/store/planning/types';
import {
  Component, Prop, Watch, PropSync,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { UserPermission } from '@/store/userpermissions/types';
import { ReportMutations } from '@/store/report/mutations';
import CodingForm from '../CodingForm/CodingForm.vue';
import { CodingFormData, InspectionResponse } from '../CodingForm/types';
import UserPermissionsMixin from '../UserPermissions/UserPermissionsMixin.vue';

const planningModule = namespace('planning');
const inspectionModule = namespace('inspection');
const assetModule = namespace('asset');
const reportModule = namespace('report');

@Component({
  components: {
    CodingForm,
  },
})
export default class WorkOrderForm extends UserPermissionsMixin {
  @planningModule.Action(PlanningActions.GET_ASSIGNMENT_WORK_ORDER) getAssignmentDataStandards;

  @planningModule.Action(PlanningActions.PATCH_ASSIGNMENT_WORK_ORDER)
    patchAssignmentDataStandards;

  @planningModule.State('workOrderData') workOrderData: InspectionResponse;

  @planningModule.State('workOrderLoading') workOrderLoading: boolean;

  @planningModule.State('workOrderError') workOrderError: string;

  @planningModule.State('patchWorkOrderError') patchWorkOrderError: string;

  @inspectionModule.State('codingDetail') codingDetail!: InspectionResponse;

  @inspectionModule.State('fullCodingForm') fullCodingForm: CodingFormData[] | undefined;

  @inspectionModule.State('subFullCodingForm') subFullCodingForm: any[] | undefined;

  @inspectionModule.Action(InspectionActions.SET_CODING_FORM) setCodingForm;

  @inspectionModule.Action(InspectionActions.SET_SUB_CODING_FORM) setSubCodingForm;

  @assetModule.Action(AssetActions.FETCH_ASSET_DATA) fetchAssetData;

  @assetModule.State('inspection') inspectionData: InspectionData | undefined =
  undefined;

  @reportModule.Mutation(ReportMutations.SET_EDIT_STATE) setEditState;

  @Prop() readonly item: WorkOrderTableData;

  @PropSync('editWorkOrderDialog') synchedEditWorkOrderDialog: boolean;

  FIND_TASK_TYPE_ERROR_NAME = 'Report Error';

  snack = false;

  snackBarMessage = '';

  snackColor = 'black';

  workOrderReport = '';

  @Watch('item')
  getDataStandard(): void {
    this.setCodingForm([]);
    this.setSubCodingForm([]);
    this.getAssignmentDataStandards(this.item['guid']);
    this.fetchAssetData({
      assetId: this.item.nodeGuid,
      inspectionId: this.item['guid'],
    });
    if (this.workOrderData != null && this.item?.nodeName != null) {
      this.workOrderData.name = this.item.nodeName;
    }

    if (this.item.taskTypeGuid === PACP_COLLECTION_TASK) {
      this.workOrderReport = 'Observation';
      return;
    }
    if (this.item.taskTypeGuid === MACP_LEVEL_1_COLLECTION_TASK
    || this.item.taskTypeGuid === MACP_LEVEL_2_COLLECTION_TASK) {
      this.workOrderReport = 'MACP2';
      return;
    }
    const taskType = TASK_TYPE_STRING.find((tt) => tt.guid === this.item.taskTypeGuid);
    if (taskType?.desc == null || taskType.desc === '') {
      this.workOrderReport = this.FIND_TASK_TYPE_ERROR_NAME;
      return;
    }
    this.workOrderReport = `${taskType.desc.replaceAll(' ', '')}Report`;
  }

  get isWorkOrderForm() {
    return this.item.taskTypeGuid !== MACP_LEVEL_1_COLLECTION_TASK
    && this.item.taskTypeGuid !== MACP_LEVEL_2_COLLECTION_TASK
    && this.item.taskTypeGuid !== PACP_COLLECTION_TASK;
  }

  get assetType(): string {
    return this.item['nodeTypeGuid'] === NODEITEM_MANHOLE_GUID ? 'Manhole' : 'Pipe';
  }

  /**
   * @description Returns if the form has an error
   * @return true if the form has an error
   */
  get isFormDataError(): boolean {
    return (this.workOrderError == null
      || this.item == null
      || this.workOrderData == null
      || this.workOrderReport === this.FIND_TASK_TYPE_ERROR_NAME);
  }

  closeDialog(): void {
    this.$emit('closeWorkOrder');
  }

  /**
 * @description Let the user know the work order form was updated
 */
  applyPatchWorkOrderSuccess(): void {
    this.snackBarMessage = 'Work Order Form Updated Successfully';
    this.snackColor = 'green';
    this.snack = true;
  }

  /**
 * @description Let the user know an error occured
 */
  applyPatchWorkOrderFail(error: string): void {
    this.snackBarMessage = error;
    this.snackColor = '#e61e25';
    this.snack = true;
  }

  async patchWorkOrder(inspectionData: any): Promise<void> {
    await this.patchAssignmentDataStandards({ guid: this.item['guid'], payload: inspectionData });
    if (!this.patchWorkOrderError) {
      this.getDataStandard();
      this.applyPatchWorkOrderSuccess();
      this.$emit('editWorkOrderPass');
    } else {
      this.applyPatchWorkOrderFail(this.patchWorkOrderError);
    }
  }

  @Watch('synchedEditWorkOrderDialog')
  onSynchedEditWorkOrderDialogChange(): void {
    if (this.synchedEditWorkOrderDialog) {
      this.setEditState(false);
    }
  }

  /**
  * @returns true if the user has the permission WORK_ORDER_PLANNING
  */
  get hasPermissionWorkOrderPlanning(): boolean {
    return this.hasPermission(UserPermission.WORK_ORDER_PLANNING);
  }

  /**
  * @returns true if the user has the permission WORK_ORDER_EDIT_FORM
  */
  get hasPermissionWorkOrderEditForm(): boolean {
    return this.hasPermission(UserPermission.WORK_ORDER_EDIT_FORM);
  }

  /**
  * @returns true if the user has the permission WORK_ORDER_EXPORT_PDF
  */
  get hasPermissionWorkOrderExportPdf(): boolean {
    return this.hasPermission(UserPermission.WORK_ORDER_EXPORT_PDF);
  }
}
