











































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import PlanningBase from '@/components/ScopeNavigation/PlanningBase.vue';
import ScopeHistory from '@/components/ScopeHistory/ScopeHistory.vue';
import { namespace } from 'vuex-class';
import { HistoryEntry } from '@/components/ScopeHistory/types';
import { ScopeHistoryActions } from '@/store/scopeHistory/actions';
import { PlanningActions } from '@/store/planning/actions';
import { AssignmentShort } from '@/store/planning/types';
import { ProjectHeaders } from '@/store/project/types';
import { AssetData } from '@/store/asset/types';
import { ProjectActions } from '@/store/project/actions';

const scopeHistoryModule = namespace('scopeHistory');
const planningModule = namespace('planning');
const projectModule = namespace('project');

@Component({
  components: {
    PlanningBase,
    ScopeHistory,
  },
})
export default class ScopeBase extends Vue {
  @scopeHistoryModule.State('data') scopeHistory: HistoryEntry[];

  @scopeHistoryModule.Action(ScopeHistoryActions.FETCH_SCOPE_HISTORY_DATA) fetchScopeHistoryData;

  @projectModule.State('headers') project: ProjectHeaders | undefined;

  @planningModule.State('patchData') patchData: string | undefined;

  @planningModule.State('patchLoading') patchLoading: boolean;

  @planningModule.Action(PlanningActions.PATCH_ASSIGNMENT) patchAssignmentList;

  @planningModule.Action(PlanningActions.DELETE_ASSIGNMENT) deleteAssignment;

  @planningModule.Action(PlanningActions.EDIT_ASSIGNMENT) editAssignment;

  @projectModule.Action(ProjectActions.FETCH_HEADER_DATA) fetchProjectHeaders;

  selectedType: unknown = ScopeHistory;

  @Prop() readonly assetData: AssetData[];

  @Prop() readonly projectGuids: string;

  projectGuidList: string[] = [];

  get componentProps(): unknown {
    let componentProps = null;
    try {
      if (!this.project) {
        return null;
      }
      switch (this.selectedTypeModel.name) {
        case 'History':
          componentProps = {
            data: this.scopeHistory,
            projectGuids: this.project.guids,
          };
          break;
        default:
          break;
      }
    } catch (err) {
      return componentProps;
    }

    return componentProps;
  }

  selectedTypeModel: {component: unknown, name: string, id: string} = {
    component: ScopeHistory,
    name: 'History',
    id: 'scopeHistoryButton',
  }

  options = {
    scopeHistory: {
      component: ScopeHistory,
      name: 'History',
      id: 'scopeHistoryButton',
    },
  }

  async mounted(): Promise<void> {
    this.projectGuidList = (JSON.parse(this.projectGuids) as string[]);
    await this.fetchProjectHeaders(this.projectGuidList);
    await this.fetchScopeHistoryData(this.project.guids);
  }
}
