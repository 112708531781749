

























































































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import IntegrityTable, {
  AdditionalFilterFunction, FillFunctionContainer, FilterFunction, processDate,
} from '@/components/IntegrityTable/IntegrityTable.vue';
import { namespace } from 'vuex-class';
import { format } from 'date-fns';
import { ReleaseTableData } from '@/store/releases/types';
import { ReleasesActions } from '@/store/releases/actions';
import { SnackBarActions } from '@/store/integritySnackBar/actions';
import utils from '@/components/Report/util';
import { uuid } from 'vue-uuid';
import { ReleaseTableMetrics, ReleaseAssetType } from './types';
import ExportReleaseDialog from './ExportReleaseDialog.vue';

const releaseModule = namespace('releases');
const userPrefsModule = namespace('userPrefs');
const integritySnackBar = namespace('integritySnackBar');

@Component({
  components: {
    IntegrityTable,
    ExportReleaseDialog,
  },
})
export default class ReleaseTable extends Vue {
    @Prop({ default: 'MH' }) readonly releaseAssetType: ReleaseAssetType;

    @Prop() readonly projectGuids;

    @Prop({ default: [] }) releases: ReleaseTableData[];

    @Prop({ default: '' }) releaseName: string;

    @Prop({ default: '' }) releaseGuid: string;

    @Prop({ default: true }) readonly canDelete: boolean;

    @releaseModule.Action(ReleasesActions.DELETE_RELEASE_INSPECTION) deleteReleaseInspection;

    @releaseModule.State('releaseTableDataLoading') releaseTableDataLoading: boolean;

    @releaseModule.State('deleteReleaseInspectionLoading') deleteReleaseInspectionLoading: boolean;

    @releaseModule.State('deleteReleaseInspectionLoading') deleteReleaseInspectionError: boolean;

    @releaseModule.Action(ReleasesActions.FETCH_RELEASES_OVERVIEW) fetchAssignmentsData;

    @releaseModule.Action(ReleasesActions.GET_INSPECTION_RELEASES) getInspectionReleases;

    @releaseModule.Action(ReleasesActions.FETCH_RELEASE_TABLE_DATA) fetchReleaseTableData;

    @integritySnackBar.Action(SnackBarActions.SHOW) showSnackBar;

    @userPrefsModule.State('displayImperial') displayImperial: boolean;

    tableHeight = '100%';

    hasMedia = 'Has Media';

    noMediaAvailable = 'No Media Available';

    exportDialog = false;

    showUndoDialog = false;

    releaseToUndo: ReleaseTableData = null;

    search = '';

    matchFiltersPipe = [
      {
        header: 'assetID', type: 'string', value: '', method: '', options: [], tempValue: '',
      },
      {
        header: 'hasCustomerDeliverables', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
      },
      {
        header: 'score', type: 'string', value: '', method: '', options: [], tempValue: '',
      },
      {
        header: 'usmh', type: 'string', value: '', method: '', options: [], tempValue: '',
      },
      {
        header: 'dsmh', type: 'string', value: '', method: '', options: [], tempValue: '',
      },
      {
        header: 'direction', type: 'string', value: '', method: '', options: [], tempValue: '',
      },
      {
        header: 'length', type: 'number', value: '', method: '', options: [], tempValue: '',
      },
      {
        header: 'dateSurveyed', type: 'date', value: '', method: '', options: [], tempValue: '',
      },
      {
        header: 'dateInspected', type: 'date', value: '', method: '', options: [], tempValue: '',
      },
      {
        header: 'actions', type: 'string', value: '', method: '', options: [], tempValue: '',
      },
    ]

    matchFiltersManhole = [
      {
        header: 'assetID', type: 'string', value: '', method: '', options: [], tempValue: '',
      },
      {
        header: 'hasCustomerDeliverables', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
      },
      {
        header: 'score', type: 'string', value: '', method: '', options: [], tempValue: '',
      },
      {
        header: 'length', type: 'number', value: '', method: '', options: [], tempValue: '',
      },
      {
        header: 'dateSurveyed', type: 'date', value: '', method: '', options: [], tempValue: '',
      },
      {
        header: 'dateInspected', type: 'date', value: '', method: '', options: [], tempValue: '',
      },
      {
        header: 'actions', type: 'string', value: '', method: '', options: [], tempValue: '',
      },
    ]

    pipeTableHeaders = [
      {
        text: 'Asset',
        value: 'assetID',
        sortable: true,
        class: 'sticky',
        cellClass: 'sticky',
      },
      {
        text: '',
        value: 'hasCustomerDeliverables',
        align: 'center',
        sortable: false,
        filterable: true,
        class: 'sticky',
        cellClass: 'sticky',
      },
      {
        text: 'Score',
        value: 'score',
        sortable: true,
        filterable: true,
      },
      {
        text: 'US MH',
        value: 'usmh',
        sortable: true,
      },
      {
        text: 'DS MH',
        value: 'dsmh',
        sortable: true,
      },
      {
        text: 'Direction',
        value: 'direction',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Length',
        value: 'length',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Date Surveyed',
        value: 'dateSurveyed',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Date Inspected',
        value: 'dateInspected',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Actions',
        value: 'actions',
        align: 'center',
        sortable: false,
        class: 'sticky-end',
        cellClass: 'sticky-end',
      },
    ];

    manholeTableHeaders = [
      {
        text: 'Asset',
        value: 'assetID',
        sortable: true,
        class: 'sticky',
        cellClass: 'sticky',
      },
      {
        text: '',
        value: 'hasCustomerDeliverables',
        align: 'center',
        sortable: false,
        filterable: true,
        class: 'sticky',
        cellClass: 'sticky',
      },
      {
        text: 'Score',
        value: 'score',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Length',
        value: 'length',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Date Surveyed',
        value: 'dateSurveyed',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Date Inspected',
        value: 'dateInspected',
        sortable: true,
        filterable: true,
      },
      {
        text: 'Actions',
        value: 'actions',
        align: 'start',
        sortable: false,
        class: 'sticky-end',
        cellClass: 'sticky-end',
      },
    ]

    get fillTableDataFunctions(): FillFunctionContainer {
      const returnValue: FillFunctionContainer = {
        updateKey: uuid.v4(),
        fillFunctions: [
          {
            headerValue: 'hasCustomerDeliverables',
            functionVariables: [this.hasMedia, this.noMediaAvailable],
            fillFunction: function fillHasCustomerDeliverables(
              item: ReleaseTableData,
              hasMedia: string,
              noMediaAvailable: string,
            ): string {
              if (item.hasCustomerDeliverables) {
                return hasMedia;
              }
              return noMediaAvailable;
            },
          },
          {
            headerValue: 'dateInspected',
            functionVariables: [processDate.toString()],
            fillFunction: function fillDateInspected(
              item: ReleaseTableData,
              processDateFunctionString: string,
            ): string {
              // eslint-disable-next-line no-new-func
              return new Function(`return ${processDateFunctionString};`)()(item.dateInspected);
            },
          },
          {
            headerValue: 'dateSurveyed',
            functionVariables: [processDate.toString()],
            fillFunction: function fillDateSurveyed(
              item: ReleaseTableData,
              processDateFunctionString: string,
            ): string {
              // eslint-disable-next-line no-new-func
              return new Function(`return ${processDateFunctionString};`)()(item.dateSurveyed);
            },
          },
        ],
      };
      return returnValue;
    }

    get filteredTableData(): ReleaseTableData[] {
      return this.releases;
    }

    get releaseTableMetrics(): ReleaseTableMetrics {
      let releaseTotals = 0;
      let inspections = 0;
      const dates: string[] = [];
      const seen: string[] = [];
      this.filteredTableData.forEach((value) => {
        if (this.releaseAssetType === ReleaseAssetType.PIPE && value.length != null) {
          releaseTotals += value.length;
        } else if (this.releaseAssetType === ReleaseAssetType.MANHOLE
          && !seen.includes(value.assetGuid)) {
          releaseTotals += 1;
          seen.push(value.assetGuid);
        }

        if (value.dateInspected != null) {
          dates.push(value.dateInspected);
        }

        inspections += 1;
      });
      if (this.releaseAssetType === ReleaseAssetType.PIPE) {
        releaseTotals = utils.getDisplayDistanceFtM(this.displayImperial, releaseTotals);
      }
      const sortedDates = dates.sort((a, b) => Date.parse(a) - Date.parse(b));
      return {
        releaseTotals: Math.round(releaseTotals * 100) / 100,
        inspections,
        earliestInspectionDate: sortedDates.length > 0
          ? format(Date.parse(sortedDates[0]), 'yyyy-MM-dd')
          : 'N/a',
        latestInspectionDate: sortedDates.length > 0
          ? format(Date.parse(sortedDates[sortedDates.length - 1]), 'yyyy-MM-dd')
          : 'N/a',
      };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get tableHeaders(): any {
      return this.releaseAssetType === ReleaseAssetType.PIPE
        ? this.pipeTableHeaders : this.manholeTableHeaders;
    }

    get matchFilters(): any {
      return this.releaseAssetType === ReleaseAssetType.PIPE
        ? this.matchFiltersPipe : this.matchFiltersManhole;
    }

    get unitLabel(): string {
      if (this.releaseAssetType !== ReleaseAssetType.PIPE) return '';
      return this.displayImperial ? 'ft' : 'm';
    }

    onBackButtonClick(): void {
      this.$emit('onReleaseTableBack');
    }

    getNavHref(assetGuid: string): string {
      return this.$router.resolve({
        name: 'assetsList',
        params: {
          idList: this.projectGuids,
          id: assetGuid,
        },
      }).href;
    }

    getReleaseMediaAvailableString(item: any): string {
      return item.hasCustomerDeliverables ? this.hasMedia : this.noMediaAvailable;
    }

    resetStatus(item: ReleaseTableData): void {
      this.showUndoDialog = true;
      this.releaseToUndo = item;
    }

    async undo(): Promise<void> {
      await this.deleteReleaseInspection({
        releaseGuid: this.releaseToUndo.releaseGuid,
        assetGuid: this.releaseToUndo.assetGuid,
      });

      if (this.deleteReleaseInspectionError) {
        this.showSnackBar({
          message: 'Unable to Remove Inspection Release',
          color: 'red',
          showSnackBar: true,
        });
      } else {
        this.showSnackBar({
          message: 'Successfully Able to Remove Inspection Release',
          color: 'green',
          showSnackBar: true,
        });
        await this.$emit('removeReleaseInspection', this.releaseToUndo);

        this.showUndoDialog = false;
        this.releaseToUndo = null;

        const currentProjectGuid = this.projectGuid;
        this.fetchAssignmentsData(currentProjectGuid);
        this.getInspectionReleases(currentProjectGuid);
        this.fetchReleaseTableData(currentProjectGuid);
      }
    }

    openExportDialog(): void {
      this.exportDialog = true;
    }

    get projectGuid(): string {
      return JSON.parse(this.projectGuids)[0];
    }
}
