var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form"},_vm._l((_vm.simpleFormPageData),function(item,i){return _c('div',{key:i,class:_vm.fieldClass(item),attrs:{"value":item}},[(item.InputType === 'Section')?_c('div',{staticClass:"section-label"},[_vm._v(" "+_vm._s(item.description)+" ")]):(item.enum != null)?_c('div',[_c('span',[(item.InputType === 'Dropdown')?_c('div',{class:_vm.getRequiredClass(item)},[_c('v-select',{ref:_vm.setFocus(item, _vm.currentFormPageData),refInFor:true,attrs:{"label":item.headernumber + '. ' + item.description,"color":_vm.getColor(item),"background-color":_vm.getBackgroundColor(item),"item-value":_vm.getEnumValueKey(item.enum),"dense":"","outlined":"","append-icon":"mdi-chevron-down","placeholder":" ","items":item.enum,"multiple":item.multiple,"rules":[_vm.checkError(item)[0] || _vm.getErrorMsg(item)],"readonly":_vm.getIsReadOnlyHeader(item.description) || _vm.readonly || !_vm.canEdit},on:{"change":function($event){return _vm.dropdownChange(item.headername)}},model:{value:(_vm.fullCodingForm.find(function (f) { return f.header === item.headername; }).value),callback:function ($$v) {_vm.$set(_vm.fullCodingForm.find(function (f) { return f.header === item.headername; }), "value", $$v)},expression:"fullCodingForm.find((f) => f.header === item.headername).value"}})],1):(item.InputType === 'Radio')?_c('div',[_c('radioGroup',{attrs:{"radioCodingForm":_vm.fullCodingForm.find(function (f) { return f.header === item.headername; }),"field":item,"canEdit":_vm.canEdit,"errors":_vm.errors,"readonly":_vm.readonly},on:{"radioChange":_vm.radioChange}})],1):_vm._e()])]):(item.InputType === 'Date-Picker')?_c('div',[_c('span',{class:_vm.getRequiredClass(item)},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"date-picker"},[_c('v-text-field',_vm._g({ref:"start-date-input",refInFor:true,attrs:{"value":_vm.getDate(item.headername),"label":item.headernumber + '. ' + item.description,"color":_vm.getColor(item),"background-color":_vm.getBackgroundColor(item),"rules":[_vm.checkError(item)[0] || _vm.getErrorMsg(item)],"append-icon":"mdi-calendar-blank-outline","readonly":"","dense":"","outlined":"","clearable":"","placeholder":" "},on:{"click:clear":function($event){return _vm.clearDate(item.headername)}}},on))],1)]}}],null,true),model:{value:(_vm.indexBools[i]),callback:function ($$v) {_vm.$set(_vm.indexBools, i, $$v)},expression:"indexBools[i]"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","color":"#0c6599","readonly":_vm.readonly || !_vm.canEdit},on:{"input":function($event){_vm.formatDate(_vm.fullCodingForm.find(function (f) { return f.header === item.headername; }), i)}},model:{value:(_vm.fullCodingForm.find(function (f) { return f.header === item.headername; }).value),callback:function ($$v) {_vm.$set(_vm.fullCodingForm.find(function (f) { return f.header === item.headername; }), "value", $$v)},expression:"fullCodingForm.find((f) => f.header === item.headername).value"}})],1)],1)]):(item.InputType === 'Time-Picker')?_c('div',[_c('span',{class:_vm.getRequiredClass(item)},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"date-picker"},[_c('v-text-field',_vm._g({ref:"start-date-input",refInFor:true,attrs:{"value":_vm.getTime(item.headername),"label":item.headernumber + '. ' + item.description,"color":_vm.getColor(item),"background-color":_vm.getBackgroundColor(item),"rules":[_vm.checkError(item)[0] || _vm.getErrorMsg(item)],"append-icon":"mdi-clock-outline","readonly":"","dense":"","outlined":"","clearable":"","placeholder":" "},on:{"click:clear":function($event){return _vm.clearDate(item.headername)}}},on))],1)]}}],null,true),model:{value:(_vm.indexBools[i]),callback:function ($$v) {_vm.$set(_vm.indexBools, i, $$v)},expression:"indexBools[i]"}},[_c('v-time-picker',{attrs:{"value":_vm.getTime(item.headername),"format":"ampm","landscape":"","readonly":_vm.readonly || !_vm.canEdit,"color":"#0c6599"},on:{"input":function($event){_vm.formatTime(_vm.fullCodingForm.find(function (f) { return f.header === item.headername; }), $event)}}})],1)],1)]):(item.InputType === 'Array')?_c('div',{staticClass:"array-field"},[_c('div',[_c('CodingTableForm',{attrs:{"canEdit":_vm.canEdit,"tableItem":item,"codingForm":_vm.tableFullCodingForm(item),"dataStandardSchema":_vm.tableSchema(item),"dataStandardJson":_vm.dataStandardJson}})],1)]):(item.type === 'integer'
    || item.type === 'number'
    || item.type === 'int')?_c('div',[_c('span',{class:_vm.getRequiredClass(item)},[_c('v-text-field',{ref:_vm.setFocus(item, _vm.currentFormPageData),refInFor:true,attrs:{"label":item.headernumber + '. ' + item.description,"color":_vm.getColor(item),"background-color":_vm.getBackgroundColor(item),"dense":"","outlined":"","placeholder":" ","rules":[_vm.checkError(item)[0] || _vm.getErrorMsg(item)],"hint":_vm.getHint(item),"persistent-hint":_vm.hintExists(item),"readonly":_vm.getIsReadOnlyHeader(item.headername) || _vm.readonly || !_vm.canEdit},on:{"change":function($event){return _vm.stringToNumber($event,
            item,
            i
            )}},model:{value:(_vm.fullCodingForm.find(function (f) { return f.header === item.headername; }).value),callback:function ($$v) {_vm.$set(_vm.fullCodingForm.find(function (f) { return f.header === item.headername; }), "value", $$v)},expression:"fullCodingForm.find((f) => f.header === item.headername).value"}})],1)]):(item.type === 'boolean')?_c('div',{staticClass:"checkboxes"},[_c('CheckBoxGroup',{attrs:{"canEdit":_vm.canEdit,"checkBoxFormPageData":_vm.currentFormPageData.filter(
          function (cf) { return cf.headernumber === item.headernumber; }),"checkBoxCodingForm":_vm.fullCodingForm.filter(function (fc) { return fc.headerNumber === item.headernumber; }),"checkBoxGroupTitle":item.headernumber+'. '+_vm.currentFormPageData.filter(
          function (cf) { return cf.headernumber === item.headernumber; })[0].description.split(':')[0],"errors":_vm.errors,"readonly":_vm.readonly},on:{"checkBoxChange":_vm.checkBoxChange}}),_vm._l((_vm.currentFormPageData.filter(
        function (cf) { return cf.headernumber === item.headernumber; })),function(obj,j){return _c('div',{key:j+200,attrs:{"value":obj}},[(_vm.isError(obj))?_c('div',{staticClass:"errors"},[_c('p',[_vm._v(_vm._s(_vm.getErrorMsg(obj)))])]):_vm._e()])})],2):(item.type !== 'boolean')?_c('div',[_c('span',{class:_vm.getRequiredClass(item)},[(item.InputType === 'MultiTextbox')?_c('div',[_c('v-textarea',{ref:_vm.setFocus(item, _vm.currentFormPageData),refInFor:true,attrs:{"label":item.headernumber + '. ' + item.description,"color":_vm.getColor(item),"background-color":_vm.getBackgroundColor(item),"dense":"","outlined":"","auto-grow":"","rows":"2","placeholder":" ","rules":[_vm.checkError(item)[0] || _vm.getErrorMsg(item)],"hint":_vm.getHint(item),"persistent-hint":_vm.hintExists(item),"readonly":_vm.readonly || !_vm.canEdit},on:{"input":_vm.textChange},model:{value:(_vm.fullCodingForm.find(function (f) { return f.header === item.headername; }).value),callback:function ($$v) {_vm.$set(_vm.fullCodingForm.find(function (f) { return f.header === item.headername; }), "value", $$v)},expression:"fullCodingForm.find((f) => f.header === item.headername).value"}})],1):_c('div',[_c('v-text-field',{ref:_vm.setFocus(item, _vm.currentFormPageData),refInFor:true,attrs:{"label":item.headernumber + '. ' + item.description,"color":_vm.getColor(item),"background-color":_vm.getBackgroundColor(item),"dense":"","outlined":"","placeholder":" ","rules":[_vm.checkError(item)[0] || _vm.getErrorMsg(item)],"hint":_vm.getHint(item),"persistent-hint":_vm.hintExists(item),"readonly":_vm.readonly || !_vm.canEdit},on:{"input":_vm.textChange},model:{value:(_vm.fullCodingForm.find(function (f) { return f.header === item.headername; }).value),callback:function ($$v) {_vm.$set(_vm.fullCodingForm.find(function (f) { return f.header === item.headername; }), "value", $$v)},expression:"fullCodingForm.find((f) => f.header === item.headername).value"}})],1)])]):_c('div',{staticClass:"empty-field"})])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }