

























































































/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component, Prop, VModel,
} from 'vue-property-decorator';
import ReportInputMixin from '../ReportInputMixin.vue';

// eslint-disable-next-line no-shadow
export enum InputType {
  TEXT = 'text',
  NUMBER = 'number',
}

@Component({
  components: {},
})
export default class ReportTextInput extends ReportInputMixin {
  @VModel() modelValue!: string | undefined;

  /**
   * The label text displayed next to the input field.
   * @type {string | undefined}
   */
  @Prop() label: string | undefined;

  /**
   * The input type for the text field, such as 'text' or 'number'.
   * Defaults to 'text'.
   * @type {InputType}
   * @default InputType.TEXT
   */
  @Prop({ default: InputType.TEXT }) type: InputType;

  /**
   * If `true`, marks the input field as required and displays a red asterisk.
   * @type {boolean}
   * @default false
   */
  @Prop({ type: Boolean, default: false }) required!: boolean;

  @Prop() color: string | undefined;

  /**
   * The suffix text to append to the input value.
   * @type {string}
   */
  @Prop() suffix: string;

  /**
   * Input’s placeholder text.
   */
  @Prop() placeholder: string;

  /**
   * If `true`, uses v-textarea component to allow for multiline text.
   * @type {boolean}
   * @default false
   */
  @Prop({ default: false }) multiline: boolean;

  /**
   * The computed width of the text field in pixels, based on the content width.
   * @type {number}
   */
  textFieldWidth = 100;

  /**
   * The rules for validating the required field.
   * Returns a function that validates if the field is required.
   * @returns {(v: string) => string | boolean}[]
   */
  get requiredField(): ((v: string) => string | boolean)[] {
    return [
      (v: string): string | boolean => !this.required || !!v || 'This is a required field.',
    ];
  }
}
