



















import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import AddScopeBaseVue from '@/components/ScopeAddition/AddScopeBase.vue';
import PlanningBase from '@/components/ScopeNavigation/PlanningBase.vue';
import ScopeHistory from '@/components/ScopeHistory/ScopeHistory.vue';
import { namespace } from 'vuex-class';
import { PlanningActions } from '@/store/planning/actions';
import { WorkOrderTableData } from '@/store/planning/types';
import { ProjectData, ProjectGraphics, ProjectHeaders } from '@/store/project/types';
import { ProjectActions } from '@/store/project/actions';
import {
  AssetData,
  AssetRef,
  Location,
  LocationLS,
} from '@/types';
import { RoutingActions } from '@/store/routing/actions';
import { RoutingData } from '@/store/routing/types';
import { UserActions } from '@/store/users/actions';
import { UserData } from '@/store/users/types';
import { UserPermission } from '@/store/userpermissions/types';
import UserPermissionsMixin from '../UserPermissions/UserPermissionsMixin.vue';

const planningModule = namespace('planning');
const projectModule = namespace('project');
const routingModule = namespace('routing');
const userModule = namespace('users');

@Component({
  components: {
    AddScopeBaseVue,
    PlanningBase,
    ScopeHistory,
  },
})
export default class WorkOrders extends UserPermissionsMixin {
  @planningModule.State('data') analyticsData: WorkOrderTableData[] | undefined;

  @planningModule.State('loading') loadingAnalyticsData;

  @planningModule.Action(PlanningActions.FETCH_ASSIGNMENTS_DATA) fetchAssignmentsData;

  @projectModule.Action(ProjectActions.FETCH_HEADER_DATA) fetchProjectHeaders;

  @planningModule.State('patchData') patchData: string | undefined;

  @planningModule.State('patchLoading') patchLoading: boolean;

  @planningModule.State('deleteLoading') deleteLoading: boolean;

  @planningModule.Action(PlanningActions.PATCH_ASSIGNMENT) patchAssignmentList;

  @planningModule.Action(PlanningActions.DELETE_ASSIGNMENT) deleteAssignment;

  @planningModule.Action(PlanningActions.EDIT_ASSIGNMENT) editAssignment;

  @projectModule.State('data') project: ProjectData | undefined;

  @projectModule.State('headers') projectHeaders: ProjectHeaders | undefined;

  @projectModule.State('loadError') loadError;

  @projectModule.State('loading') loading;

  @projectModule.Action(ProjectActions.FETCH_PROJECT_DATA) fetchProjectData;

  @routingModule.Action(RoutingActions.FETCH_ROUTING_DATA) fetchRoutingData;

  @routingModule.State('routingData') routingData: RoutingData[] | undefined;

  @userModule.Action(UserActions.FETCH_ALL_USER_DATA) fetchAllUserData;

  @userModule.State('allUserData') allUsers: UserData[] | undefined;

  @Prop() readonly id!: Vue;

  assetData: AssetData[] = [];

  async mounted(): Promise<void> {
    if (!this.hasPermissionWorkOrderCommon) {
      this.goToErrorPage();
    }
    if (!this.allUsers) {
      this.fetchAllUserData();
    }
    this.fetchProjectHeaders([this.id]);
    this.fetchProjectData([this.id]).then(() => {
      this.fillAssetData();
    });
    this.fetchRoutingData(this.id);
  }

  fillAssetData(): void {
    this.assetData = [];

    // Populating the assetData array using the project asset data
    this.project.assets.forEach((asset) => {
      // initial validation
      if (
        asset.guid == null
        || asset.guid === ''
        || asset.name == null
        || asset.name === ''
        // || asset.priority == null
        || asset.type == null
      ) {
        // bad asset, continue
        return;
      }

      let attributes = null;

      if (asset.attributes) {
        if (typeof asset.attributes === 'string') attributes = JSON.parse(asset.attributes.replace(/&quot;/g, '"'));
        else attributes = asset.attributes;
      }

      const assetData: AssetData = {
        guid: asset.guid,
        name: asset.name,
        score: asset.priority == null ? -1 : asset.priority,
        taskResult: asset.taskResult,
        type: asset.type,
        visible: true,
        hasCustomerDeliverables: asset.hasCustomerDeliverables,
        location: [],
        status: asset.status,
        codingStatus: asset.inspections && asset.inspections[0] && asset.inspections[0].codingStatus
          ? asset.inspections[0].codingStatus.description : null,
        overallQuick: asset.overallScoring ? asset.overallScoring.overallQuick : null,
        overallIndex: asset.overallScoring ? asset.overallScoring.overallIndex : null,
        overallRating: asset.overallScoring ? asset.overallScoring.overallRating : null,
        structuralQuick: asset.overallScoring ? asset.overallScoring.structuralQuick : null,
        structuralIndex: asset.overallScoring ? asset.overallScoring.structuralIndex : null,
        structuralRating: asset.overallScoring ? asset.overallScoring.structuralRating : null,
        omQuick: asset.overallScoring ? asset.overallScoring.omQuick : null,
        omIndex: asset.overallScoring ? asset.overallScoring.omIndex : null,
        omRating: asset.overallScoring ? asset.overallScoring.omRating : null,
        instDate: attributes ? new Date(attributes.DateInstalled).toLocaleString() : null,
        owner: asset.owner,
        sewerUse: asset.sewerUse,
        comments: attributes ? attributes.Comments : null,
        assetId: attributes ? attributes.ID : null,
        upstreamId: attributes ? attributes.Wastewater_Structure_Up : null,
        downstreamId: attributes ? attributes.Wastewater_Structure_Dn : null,
        street: attributes ? attributes.Street : null,
        city: attributes ? attributes.City : null,
        basin: attributes ? attributes.Basin : null,
        pipeSize: attributes ? attributes.PipeSize : null,
        pipeMaterial: attributes ? attributes.PipeMaterial : null,
        lastInspected: asset.inspections && asset.inspections[0] ? asset.inspections[0].date : null,
        platform: asset.inspections && asset.inspections[0] ? asset.inspections[0].platform : null,
        mapPage: asset.mapPage,
        batchNumber: asset.batchNumber,
      };

      switch (asset.type) {
        case 'Manhole':
          assetData.location.push(this.getLocationManhole(asset) as Location & LocationLS);
          break;
        case 'Line Segment':
          assetData.location.push(this.getLocationLineSegment(asset) as Location & LocationLS);
          break;
        case 'Lateral':
          assetData.location = asset.location as Location[] & LocationLS[];
          break;
        default:
          // unknown type, continue
          return;
      }

      this.assetData.push(assetData);
    });
  }

  getLocationManhole(asset: AssetRef | ProjectGraphics): Location {
    const location: Location = {
      latitude: 0,
      longitude: 0,
      source: '',
    };

    if (asset.location) {
      if (asset.location.length > 0) {
        // check for customer data
        const cdLocation = asset.location.find((loc) => loc.source === 'CustomerData');
        if (cdLocation !== undefined) {
          location.latitude = cdLocation.latitude;
          location.longitude = cdLocation.longitude;
          location.source = cdLocation.source;
          return location;
        }
      } else {
        // no customer data use inspection
        const inspLocations = asset.location.find((loc) => loc.source === 'Inspections');
        if (inspLocations !== undefined) {
          location.latitude = inspLocations.latitude;
          location.longitude = inspLocations.longitude;
          location.source = inspLocations.source;
          return location;
        }
      }
    }
    return null;
  }

  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  getLocationLineSegment(asset: any): LocationLS {
    const locationLS: LocationLS = {
      upstream: {} as Location,
      downstream: {} as Location,
      hasFlowData: 0,
    };

    try {
      if (asset.location) {
        if (asset.location.length > 1) {
          // check for customer data
          const cdLocations = asset.location.filter((loc) => loc.source === 'CustomerData');
          if (cdLocations.length > 1) {
            [locationLS.upstream, locationLS.downstream] = cdLocations;
          } else {
            // no customer data use inspection
            const inspLocations = asset.location.filter((loc) => loc.source === 'Inspections');
            if (inspLocations.length > 1) {
              [locationLS.upstream, locationLS.downstream] = inspLocations;
            }
          }
        }
      } else if (asset.attributes) {
        const attributes = JSON.parse(asset.attributes.replace(/&quot;/g, '"'));

        if (attributes.Wastewater_Structure_Dn && attributes.Wastewater_Structure_Up) {
          const upmh = this.project.assets.find(
            (a) => a.name === attributes.Wastewater_Structure_Up,
          );
          const dnmh = this.project.assets.find(
            (a) => a.name === attributes.Wastewater_Structure_Dn,
          );

          if (upmh && dnmh) {
            locationLS.upstream = this.getLocationManhole(upmh);
            locationLS.downstream = this.getLocationManhole(dnmh);
            locationLS.hasFlowData = 1;
          }
        }
      }
    } catch (e) {
      console.error(`No location data found: ${asset.name}`);
      console.error(e);
    }
    return locationLS;
  }

  refreshProject(): void{
    this.$emit('refreshProject');
  }

  /**
  * @returns true if the user has the permission WORK_ORDER_COMMON
  */
  get hasPermissionWorkOrderCommon(): boolean {
    return this.hasPermission(UserPermission.WORK_ORDER_COMMON);
  }
}
