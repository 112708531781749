import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { AssetState } from '@/store/asset/types';
import getters from '@/store/asset/getters';
import { mutations } from '@/store/asset/mutations';
import { actions } from '@/store/asset/actions';

const state: AssetState = {
  loading: false,
  loadError: undefined,
  asset: undefined,
  inspection: undefined,
  hudEnabled: true,
  edit: undefined,
  editState: false,
  editError: undefined,
  delete: undefined,
  deleteState: false,
  deleteError: undefined,
  exportState: undefined,
  assetScreenshotUrl: undefined,
  importLoad: false,
  videoOrientationError: undefined,
  videoOrientationLoaded: false,
  reportingFeedbackLoaded: false,
  reportingFeedbackError: undefined,
  lineSegmentAccessPoints: undefined,
  deploymentFormCouldNotInspectLoading: false,
  deploymentFormCouldNotInspectError: undefined,
  availableManholes: undefined,
  availableManholesLoading: false,
  availableManholesError: undefined,
  newLineSegmentAccessPoint: undefined,
  newLineSegmentAccessPointLoading: false,
  newLineSegmentAccessPointError: undefined,
  manualIPDZeroTimeLoading: true,
  manualIPDZeroTimeError: undefined,
};

const asset: Module<AssetState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default asset;
