import { MutationTree } from 'vuex';
import {
  AssetData, AssetState, InspectionData, LineSegmentAccessPoint, ManholeAccessPoint,
} from './types';
import { WorkOrderTableData } from '../planning/types';

// eslint-disable-next-line no-shadow
export enum AssetMutations {
  SET_LOADED_SATE = 'SET_LOADING_STATE',
  SET_LOAD_ERROR = 'SET_LOAD_ERROR',
  SET_ASSET = 'SET_ASSET',
  SET_INSPECTION = 'SET_INSPECTION',
  SET_INSPECTION_BY_ID = 'SET_INSPECTION_BY_ID',
  SET_INITIAL_INSPECTION = 'SET_INITIAL_INSPECTION',
  SET_HUD_ENABLED = 'SET_HUD_ENABLED',
  SET_EDIT_ASSET = 'SET_EDIT_ASSET',
  SET_EDIT_STATE = 'SET_EDIT_STATE',
  SET_EDIT_ERROR = 'SET_EDIT_ERROR',
  DELETE_ASSET = 'DELETE_ASSET',
  SET_DELETE_STATE = 'SET_DELETE_STATE',
  SET_DELETE_ERROR = 'SET_DELETE_ERROR',
  SET_EXPORT_STATE = 'SET_EXPORT_STATE',
  SET_ASSET_SCREENSHOT_URL = 'SET_ASSET_SCREENSHOT_URL',
  SET_IMPORT_LOAD = 'SET_IMPORT_LOAD',
  SET_VIDEO_ORIENTATION_LOADED = 'SET_VIDEO_ORIENTATION_LOADED',
  SET_VIDEO_ORIENTATION_ERROR = 'SET_VIDEO_ORIENTATION_ERROR',
  SET_REPORTING_FEEDBACK_LOADED = 'SET_REPORTING_FEEDBACK_LOADED',
  SET_REPORTING_FEEDBACK_ERROR = 'SET_REPORTING_FEEDBACK_ERROR',
  SET_LINE_SEGMENT_ACCESSPOINTS = 'SET_LINE_SEGMENT_ACCESSPOINTS',
  SET_DEPLOYMENT_FORM_WORK_ORDER_PATCH_LOADING = 'SET_DEPLOYMENT_FORM_WORK_ORDER_PATCH_LOADING',
  SET_DEPLOYMENT_FORM_WORK_ORDER_PATCH_ERROR = 'SET_DEPLOYMENT_FORM_WORK_ORDER_PATCH_ERROR',
  SET_AVAILABLE_MANHOLES = 'SET_AVAILABLE_MANHOLES',
  SET_AVAILABLE_MANHOLES_LOADING = 'SET_AVAILABLE_MANHOLES_LOADING',
  SET_AVAILABLE_MANHOLES_ERROR = 'SET_AVAILABLE_MANHOLES_ERROR',
  SET_NEW_LINE_SEGMENT_ACCESS_POINT = 'SET_NEW_LINE_SEGMENT_ACCESS_POINT',
  SET_NEW_LINE_SEGMENT_ACCESS_POINT_LOADING = 'SET_NEW_LINE_SEGMENT_ACCESS_POINT_LOADING',
  SET_NEW_LINE_SEGMENT_ACCESS_POINT_ERROR = 'SET_NEW_LINE_SEGMENT_ACCESS_POINT_ERROR',
  SET_MANUAL_IPD_ZERO_TIME_LOADING = 'SET_MANUAL_IPD_ZERO_TIME_LOADING',
  SET_MANUAL_IPD_ZERO_TIME_ERROR = 'SET_MANUAL_IPD_ZERO_TIME_ERROR'
}

export const mutations: MutationTree<AssetState> = {
  [AssetMutations.SET_LOADED_SATE](state, payload: boolean) {
    state.loading = payload;
  },

  [AssetMutations.SET_LOAD_ERROR](state, payload: string) {
    state.loadError = payload;
  },

  [AssetMutations.SET_ASSET](state, payload: AssetData) {
    state.asset = payload;
    if (payload.attributes != null && payload.attributes !== '') {
      state.asset.attributes = JSON.parse(payload.attributes as string);
    }
  },

  [AssetMutations.SET_INSPECTION](state, payload: InspectionData) {
    state.inspection = payload;
  },

  [AssetMutations.SET_HUD_ENABLED](state, payload: boolean) {
    state.hudEnabled = payload;
  },

  [AssetMutations.SET_INSPECTION_BY_ID](state, inspectionId: string) {
    // If there are no inspections then do nothing
    if (state.asset.inspections.length === 0) { return; }
    state.inspection = undefined;

    // If we have specified an inspection the try to find it
    if (inspectionId) {
      state.inspection = state.asset.inspections.find((n) => n.guid === inspectionId);
    }
  },

  [AssetMutations.SET_INITIAL_INSPECTION](state, inspectionId: string) {
    // If there are no inspections then do nothing
    if (state.asset === undefined || state.asset.inspections.length === 0) { return; }
    state.inspection = undefined;

    // If we have specified an inspection the try to find it
    if (inspectionId) {
      state.inspection = state.asset.inspections.find((n) => n.guid === inspectionId);
    }

    // If we didnt find an inspection or none was specified select the first
    // eslint-disable-next-line prefer-destructuring
    if (!state.inspection) { state.inspection = state.asset.inspections[0]; }
  },

  [AssetMutations.SET_EDIT_ASSET](state, payload: string) {
    state.edit = payload;
  },

  [AssetMutations.SET_EDIT_STATE](state, payload: boolean) {
    state.editState = payload;
  },

  [AssetMutations.SET_EDIT_ERROR](state, payload: string) {
    state.editError = payload;
  },

  [AssetMutations.SET_DELETE_STATE](state, payload: boolean) {
    state.deleteState = payload;
  },

  [AssetMutations.SET_DELETE_ERROR](state, payload: string) {
    state.deleteError = payload;
  },

  [AssetMutations.DELETE_ASSET](state, payload: unknown) {
    state.delete = payload;
  },
  [AssetMutations.SET_EXPORT_STATE](state, payload: string) {
    state.exportState = payload;
  },
  [AssetMutations.SET_ASSET_SCREENSHOT_URL](state, payload: string) {
    state.assetScreenshotUrl = payload;
  },
  [AssetMutations.SET_IMPORT_LOAD](state, payload: boolean) {
    state.importLoad = payload;
  },
  [AssetMutations.SET_VIDEO_ORIENTATION_ERROR](state, payload: string) {
    state.videoOrientationError = payload;
  },
  [AssetMutations.SET_VIDEO_ORIENTATION_LOADED](state, payload: boolean) {
    state.videoOrientationLoaded = payload;
  },
  [AssetMutations.SET_REPORTING_FEEDBACK_LOADED](state, payload: boolean) {
    state.reportingFeedbackLoaded = payload;
  },
  [AssetMutations.SET_REPORTING_FEEDBACK_ERROR](state, payload: string) {
    state.reportingFeedbackError = payload;
  },
  [AssetMutations.SET_LINE_SEGMENT_ACCESSPOINTS](state, payload: LineSegmentAccessPoint[]) {
    state.lineSegmentAccessPoints = payload;
  },
  [AssetMutations.SET_DEPLOYMENT_FORM_WORK_ORDER_PATCH_LOADING](state, payload: boolean) {
    state.deploymentFormCouldNotInspectLoading = payload;
  },
  [AssetMutations.SET_DEPLOYMENT_FORM_WORK_ORDER_PATCH_ERROR](state, payload: string | undefined) {
    state.deploymentFormCouldNotInspectError = payload;
  },

  [AssetMutations.SET_AVAILABLE_MANHOLES](state, payload: ManholeAccessPoint[]) {
    state.availableManholes = payload;
  },
  [AssetMutations.SET_AVAILABLE_MANHOLES_LOADING](state, payload: boolean) {
    state.availableManholesLoading = payload;
  },
  [AssetMutations.SET_AVAILABLE_MANHOLES_ERROR](state, payload: string | undefined) {
    state.availableManholesError = payload;
  },
  [AssetMutations.SET_NEW_LINE_SEGMENT_ACCESS_POINT](state, payload: WorkOrderTableData) {
    state.newLineSegmentAccessPoint = payload;
  },
  [AssetMutations.SET_NEW_LINE_SEGMENT_ACCESS_POINT_LOADING](state, payload: boolean) {
    state.newLineSegmentAccessPointLoading = payload;
  },
  [AssetMutations.SET_NEW_LINE_SEGMENT_ACCESS_POINT_ERROR](state, payload: string | undefined) {
    state.newLineSegmentAccessPointError = payload;
  },
  [AssetMutations.SET_MANUAL_IPD_ZERO_TIME_LOADING](state, payload: boolean) {
    state.manualIPDZeroTimeLoading = payload;
  },
  [AssetMutations.SET_MANUAL_IPD_ZERO_TIME_ERROR](state, payload: string | undefined) {
    state.manualIPDZeroTimeError = payload;
  },
};
