








































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { DEFECT_CATEGORIES } from '@/common/Constants';
import IntegrityTable, {
  AdditionalFilterFunction,
  FillFunctionContainer,
  FilterFunction,
} from '@/components/IntegrityTable/IntegrityTable.vue';
import { FormattedDashboardDefects } from '@/store/metrics/types';
import { UserPrefsActions } from '@/store/userPrefs/actions';
import CustomerDashboardMixin from '@/views/Dashboards/CustomerDashboard/CustomerDashboardMixin.vue';
import { Component, Prop } from 'vue-property-decorator';
import { uuid } from 'vue-uuid';
import { namespace } from 'vuex-class';

const userPrefsModule = namespace('userPrefs');

@Component({
  components: {
    IntegrityTable,
  },
})
export default class DashboardDefectTable extends CustomerDashboardMixin {
  @userPrefsModule.State('useDefectCodeDesc') useDefectCodeDesc: boolean;

  @userPrefsModule.Action(UserPrefsActions.SET_DEFECT_CODEDESC)
  setDefectCodeDesc;

  @Prop() readonly projectGuid: string;

  @Prop() readonly type: string;

  @Prop() readonly selectedGroup: string;

  @Prop() readonly selectedGrade: number | undefined;

  @Prop() readonly isStructural: boolean | undefined;

  @Prop() readonly assetGuidsFilter: string[];

  search = '';

  headers = [
    {
      text: 'Asset',
      value: 'assetName',
      sortable: true,
      filterable: true,
      stopFilterValueGeneration: true,
    },
    {
      text: 'Defect Code',
      value: 'codeDisplay',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Grade',
      value: 'grade',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Type',
      value: 'type',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Action',
      value: 'dashboardDefectInfo',
      sortable: false,
      filterable: false,
    },
  ];

  matchFilters = [
    {
      header: 'assetName',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'codeDisplay',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'grade',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'type',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
  ];

  get assetGuidsToFilter(): string[] {
    return this.assetGuidsFilter != null ? this.assetGuidsFilter : [];
  }

  get additionalFilterFunctions(): AdditionalFilterFunction {
    const filterFunctions: FilterFunction[] = [];

    if (this.selectedGroup && this.selectedGroup !== '') {
      filterFunctions.push({
        functionVariables: [DEFECT_CATEGORIES, this.selectedGroup],
        filterFunction: function isCodeIncluded(
          item,
          defectCategories,
          selectedGroup,
        ) {
          const defectCodes = defectCategories.find(
            (cat) => cat.name === selectedGroup,
          );
          return defectCodes.defects.includes(item.code);
        },
      });
    }
    if (this.isStructural !== null && this.selectedGrade !== null) {
      filterFunctions.push({
        functionVariables: [
          this.defectsData,
          this.isStructural,
          this.selectedGrade,
        ],
        filterFunction: function isSelectedGrade(
          item,
          defects,
          isStructural,
          selectedGrade,
        ) {
          const isStructuralCodes = defects
            .filter((cat) => cat.isStructural === isStructural)
            .map((cat) => cat.code);
          // eslint-disable-next-line eqeqeq
          return (
            item.grade === selectedGrade && isStructuralCodes.includes(item.code)
          );
        },
      });
    }
    return {
      updateKey: uuid.v4(),
      filterFunctions,
    };
  }

  get fillTableDataFunctions(): FillFunctionContainer {
    const returnValue: FillFunctionContainer = {
      updateKey: uuid.v4(),
      fillFunctions: [
        {
          headerValue: 'grade',
          functionVariables: [],
          fillFunction: function fillGrade(
            item: FormattedDashboardDefects,
          ): string {
            return item?.grade == null || item.grade === -1
              ? 'Unscored'
              : item.grade.toString();
          },
        },
      ],
    };
    return returnValue;
  }

  get tableData(): FormattedDashboardDefects[] {
    const returnValue: FormattedDashboardDefects[] = [];
    if (this.dashboardDefects == null) {
      return undefined;
    }
    this.dashboardDefects.forEach((value) => {
      // Check for a filtered asset first
      if (
        this.assetGuidsToFilter.length === 0
        || this.assetGuidsToFilter.includes(value.assetGuid)
      ) {
        if (value.assetType === this.assetType) {
          value.defects.forEach((defect) => {
            returnValue.push({
              assetName: value.assetName,
              assetGuid: value.assetGuid,
              codeDescription: defect.codeDescription,
              code: defect.code,
              codeDisplay: this.useDefectCodeDesc
                ? defect.codeDescription
                : defect.code,
              grade: defect.grade,
              type: defect.type,
              inspectionGuid: defect.inspectionGuid,
              defectGuid: defect.defectGuid,
              payout: defect.payout,
            });
          });
        }
      }
    });

    return returnValue;
  }

  get assetType(): string | undefined {
    return this.type !== 'Manhole' && this.type !== 'Line Segment'
      ? undefined
      : this.type;
  }

  setInitGradeValues(): void {
    this.matchFilters.find((f) => f.header === 'grade').options = ['4', '5'];
  }

  getTableInfoLink(defect: FormattedDashboardDefects): string {
    return (
      `/assets/["${this.projectGuid}"]/${defect.assetGuid}?`
      + `defectGuid=${defect.defectGuid}`
      + `&defectInspectionGuid=${defect.inspectionGuid}`
      + `&defectCode=${defect.code}`
      + `&defectPayout=${defect.payout.toString()}`
    );
  }

  clearFilters(): void {
    this.$emit('clearFilters');
    (this.$refs.defectdashboardtable as any).clearAllMatchFilters();
  }

  handleOnAssetInventoryClick(): void {
    const table = this.$refs.defectdashboardtable as any;
    if (table != null) {
      const requestOk = table.requestCurrentTableData(
        this.recieveAllTableDataCallbackInventory,
      );
      if (!requestOk) {
        this.recieveAllTableDataCallbackInventory([]);
      }
    }
  }

  handleOnProjectPageClick(): void {
    const table = this.$refs.defectdashboardtable as any;
    if (table != null) {
      const requestOk = table.requestCurrentTableData(
        this.recieveAllTableDataCallbackMap,
      );
      if (!requestOk) {
        this.recieveAllTableDataCallbackMap([]);
      }
    }
  }

  recieveAllTableDataCallbackMap(items: FormattedDashboardDefects[]): void {
    this.$router
      .push({
        path: `/projects/["${this.projectGuid}"]`,
        query: {
          name: [...new Set(items.map((value) => value.assetName))],
        },
      })
      .then(() => {
        this.$router.go(0);
      });
  }

  getFilteredDataFromTable(): FormattedDashboardDefects[] | undefined {
    return [
      ...((this.$refs.defectdashboardtable as any)?.tableDataDisplayed != null
        ? ((this.$refs.defectdashboardtable as any)
          ?.tableDataDisplayed as FormattedDashboardDefects[])
        : []),
    ];
  }

  recieveAllTableDataCallbackInventory(
    items: FormattedDashboardDefects[],
  ): void {
    this.$router
      .push({
        path: `/projects/["${this.projectGuid}"]/inventory`,
        query: {
          name: [...new Set(items.map((value) => value.assetName))],
        },
      })
      .then(() => {
        this.$router.go(0);
      });
  }
}
