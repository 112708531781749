

























































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import IntegrityTable, { AdditionalFilterFunction, FilterFunction } from '@/components/IntegrityTable/IntegrityTable.vue';
import {
  CustomerDashboardWorkOrder,
} from '@/store/metrics/types';
import CustomerDashboardMixin from '@/views/Dashboards/CustomerDashboard/CustomerDashboardMixin.vue';
import {
  Component, Prop, PropSync, Watch,
} from 'vue-property-decorator';
import { uuid } from 'vue-uuid';

@Component({
  components: {
    IntegrityTable,
  },
})
export default class DashboardWorkOrderTable extends CustomerDashboardMixin {
  @Prop() readonly projectGuid: string;

  @Prop() readonly type: string;

  @PropSync('selectedTaskType') synchedSelectedTaskType: string;

  @PropSync('selectedStatus') synchedSelectedStatus: string;

  search = '';

  headers = [
    {
      text: 'Asset',
      value: 'assetName',
      sortable: true,
      filterable: false,
    },
    {
      text: 'WO #',
      value: 'workOrderNumber',
      sortable: true,
      filterable: false,
    },
    {
      text: 'Status',
      value: 'status',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Task Type',
      value: 'taskType',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Issue',
      value: 'issue',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Crew',
      value: 'crewName',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Days Open',
      value: 'daysOpen',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Action',
      value: 'workOrderGuid',
      sortable: false,
      filterable: false,
    },
  ];

  matchFilters = [
    {
      header: 'assetName',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'workOrderNumber',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'status',
      type: 'string',
      value: '',
      method: '',
      alwaysInclude: ['Follow-Up Required'],
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'taskType',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'issue',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'crewName',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'daysOpen',
      type: 'number',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
  ];

  get additionalFilterFunctions(): AdditionalFilterFunction {
    const filterFunctions: FilterFunction[] = [
      {
        functionVariables: [this.type],
        filterFunction: function isAssetType(item, type) {
          return item.assetType === type;
        },
      },
    ];

    if (this.synchedSelectedStatus) {
      filterFunctions.push(
        {
          functionVariables: [this.synchedSelectedStatus],
          filterFunction: function isWorkOrderStatus(item, synchedSelectedStatus) {
            return item.workOrderStatus === synchedSelectedStatus;
          },
        },
      );
    }
    return {
      updateKey: uuid.v4(),
      filterFunctions,
    };
  }

  get assetType(): string | undefined {
    return this.type !== 'Manhole' && this.type !== 'Line Segment'
      ? undefined
      : this.type;
  }

  onTableLoad(): void {
    const statusFilter = this.matchFilters.find((m) => m.header === 'status');
    const followUpRequired = 'Follow-Up Required';
    statusFilter.options = [followUpRequired];
  }

  getTableInfoLink(workOrder: CustomerDashboardWorkOrder): void {
    this.$router
      .push({
        path: `/workOrders/${this.projectGuid}`,
        query: {
          guid: workOrder.workOrderGuid,
        },
      })
      .then(() => {
        this.$router.go(0);
      });
  }

  clearTable(): void {
    this.clearFilters();
    this.synchedSelectedStatus = '';
    this.synchedSelectedTaskType = '';
  }

  clearFilters(): void {
    this.matchFilters.forEach((m) => {
      m.options = [];
    });
  }

  handleOnWorkOrderProjectPageClick(): void {
    this.$router
      .push({
        path: `/workOrders/${this.projectGuid}`,
        query: {
          // replace spaces cuz route does not respect spaces
          matchFilters: encodeURI(JSON.stringify(this.matchFilters)),
          category: '5', // all
        },
      })
      .then(() => {
        this.$router.go(0);
      });
  }

  handleOnProjectPageClick(): void{
    const table = (this.$refs.customerDataWorkOrderTable as any);
    if (table != null) {
      const requestOk = table.requestCurrentTableData(this.recieveAllTableDataCallbackMap);
      if (!requestOk) {
        this.recieveAllTableDataCallbackMap([]);
      }
    }
  }

  recieveAllTableDataCallbackMap(items: CustomerDashboardWorkOrder[]): void {
    this.$router
      .push({
        path: `/projects/["${this.projectGuid}"]`,
        query: {
          name: [
            ...new Set(
              items.map((value) => value.assetName),
            ),
          ],
        },
      })
      .then(() => {
        this.$router.go(0);
      });
  }

  followUpRequiredConversion = {
    CNL: 'Could Not Locate',
    CNA: 'Could Not Access',
    CNO: 'Unable To Open Access Point',
    'Cleaning (Light)': 'Cleaning Required (Light)',
    'Cleaning (Heavy)': 'Cleaning Required (Heavy)',
    'Traffic Control': 'Traffic Prevented Access',
  };

  @Watch('synchedSelectedTaskType')
  onSynchedSelectedTaskTypeChange(): void {
    this.clearFilters();
    if (!this.synchedSelectedTaskType) {
      return;
    }
    const taskTypeMatchFilter = this.matchFilters.find(
      (mf) => mf.header === 'taskType',
    );
    taskTypeMatchFilter.options = [this.synchedSelectedTaskType];
  }

  @Watch('synchedSelectedStatus')
  onSynchedSelectedStatusChange(): void {
    this.clearFilters();
    if (!this.synchedSelectedStatus || this.synchedSelectedStatus === 'Other') {
      return;
    }
    if (this.synchedSelectedStatus in this.followUpRequiredConversion) {
      const issueMatchFilter = this.matchFilters.find(
        (mf) => mf.header === 'issue',
      );
      issueMatchFilter.options = [
        this.followUpRequiredConversion[this.synchedSelectedStatus],
      ];
      const statusMatchFilter = this.matchFilters.find(
        (mf) => mf.header === 'status',
      );
      statusMatchFilter.options = ['Follow-Up Required'];
    }
  }

  get filteredCustomerDashboardWorkOrders():
    | CustomerDashboardWorkOrder[]
    | undefined {
    if (!this.customerDashboardWorkOrders) {
      return undefined;
    }
    return this.customerDashboardWorkOrders;
  }
}
