import { GenericDropDownData } from '@/common/types';

// eslint-disable-next-line no-shadow
export enum FormDataFillFunction {
    ASSIGNED_TO = 'ASSIGNED_TO'
}

/* eslint-disable camelcase */
export interface FormPageData {
    // Original properties
    type: string,
    Order: number,
    Width: number,
    units: string,
    Height: number,
    InputType: string,
    exportable: string,
    exportname: string,
    headername: string,
    description: string,
    headernumber: number,
    relatedtable: string,
    format?: undefined,
    // Custom properties, should all be optional
    enum?: Array<string | GenericDropDownData>,
    prefilled?: boolean,
    fillFunctionMappingString?: string | FormDataFillFunction,
    required?: boolean,
    hint?: string,
    items?: FormPageData[],
    multiple?: boolean,
}

export interface CodingFormData {
    header: string,
    value: string | number | boolean | any[],
    isChanged: boolean,
    headerNumber?: number,
}

export interface paramsObj {
    comparison: string,
    limit: number,
}

export interface ValidationError {
    instancePath: string,
    keyword: string,
    message: string,
    params: paramsObj,
    schemaPath: string,
}

export interface SubInspectionData {
    subInspectionGuid: string,
    jsonData: string,
}

export interface InspectionDetail
{
    name: string,
    inspectionGuid: string,
    latitude: string,
    longitude: string,
    maxPayout: string,
    flatImage: string,
    video: string,
    _3DFile: string,
    model: string,
    soloFiles: string,
    conditionReport: string,
    measurementUnits: string,
    report: string,
    jsonData: string,
    dataStandard_Json?: string | unknown,
    dataStandard_format: string,
    dataStandard_Schema: string,
    subDataStandard_format: string,
    subDataStandard_Schema: string,
    subInspectionJsonData: SubInspectionData[] | string[],
}

export interface InspectionResponse {
    name: string,
    nodeGuid: string,
    assetTypeCodeSet: string,
    inspections: InspectionDetail[],
}

// eslint-disable-next-line no-shadow
export const ConditionalType: string[] = [
  'if',
  'anyOf',
  'allOf',
];

export interface ConditionalRequirement {
    conditionalField: string,
    comparerValue: string[] | string | boolean | number,
    requiredOnElse: string[],
    optionalOnElse: string[],
    conditionalsOnElse: ConditionalRequirement[],
    requiredOnThen: string[],
    optionalOnThen: string[],
    conditionalsOnThen: ConditionalRequirement[],
}
