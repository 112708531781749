var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"id":"table-header"}},[_c('v-tabs',{staticClass:"assetTabs",attrs:{"height":"100%","color":"#0C6599"},model:{value:(_vm.syncedActiveTab),callback:function ($$v) {_vm.syncedActiveTab=$$v},expression:"syncedActiveTab"}},_vm._l((_vm.tabOptions),function(tab){return _c('v-tab',{key:tab.name,staticClass:"assetTableTab py-2",attrs:{"id":tab.name}},[_vm._v(" "+_vm._s(tab.value)+" ")])}),1),_c('v-spacer'),_c('v-text-field',{staticClass:"search-bar",attrs:{"outlined":"","dense":"","hide-details":"","placeholder":"Search","clearable":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"outline-btn",attrs:{"color":"#0c6599","outlined":"","id":"releaseBtn","disabled":_vm.isCustomerPrepReleaseButtonDisabled},on:{"click":_vm.setIsCustomerPrepReleaseDialogOpen}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-send")]),_vm._v(" Release ")],1)],1),_c('div',{staticClass:"table-container"},[_c('IntegrityTable',{key:"cust-prep-table",attrs:{"data":_vm.customerPrepTableData,"headers":_vm.custPrepHeaders,"loading":_vm.loadingInspectionReleases,"matchFilters":_vm.custMatchFilters,"additionalFilterFunctions":_vm.additionalFilterFunctions,"dataFillFunctions":_vm.fillTableDataFunctions,"height":_vm.tableHeight,"itemsPerPage":-1,"footer-props":{
        'disable-items-per-page': false,
        'items-per-page-options': [100, 250, 500, -1],
      },"showSelect":true,"selectedItems":_vm.customerPrepTableModel,"search":_vm.search,"tableId":'customerPrepTable'},on:{"update:data":function($event){_vm.customerPrepTableData=$event},"update:matchFilters":function($event){_vm.custMatchFilters=$event},"update:match-filters":function($event){_vm.custMatchFilters=$event},"update:height":function($event){_vm.tableHeight=$event},"update:selectedItems":function($event){_vm.customerPrepTableModel=$event},"update:selected-items":function($event){_vm.customerPrepTableModel=$event}},scopedSlots:_vm._u([{key:"hasCustomerDeliverables",fn:function(ref){
      var item = ref.item;
return [(item.hasCustomerDeliverables === _vm.hasMedia)?_c('a',{attrs:{"href":_vm.$router.resolve({
              name: 'assetsList',
              params: {
                idList: _vm.projectGuids,
                id: item.assetGuid,
              },
            }).href}},[_c('v-btn',{staticClass:"info-btn",attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-information-outline")])],1)],1):_c('v-btn',{attrs:{"icon":"","disabled":""}},[_c('v-icon',[_vm._v("mdi-information-outline")])],1)]}},{key:"score",fn:function(ref){
            var item = ref.item;
return [_c('span',[_c('v-chip',{staticClass:"defect-score-chip",attrs:{"small":"","color":_vm.getScoreColorTable(item.score)}},[_vm._v(" "+_vm._s(!item.score || item.score === 'Unscored' ? "-" : item.score)+" ")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }