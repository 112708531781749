







































/* eslint-disable @typescript-eslint/no-explicit-any */
import { NasscoCodeCategoryCounts } from '@/store/metrics/types';
import {
  Component, Prop, PropSync, Vue,
} from 'vue-property-decorator';

export type TableData = {
  name: string;
  value: string;
  defectCount: number;
  assetCount: number;
  length: number;
};
@Component({})
export default class DefectCountTable extends Vue {
  @Prop() readonly projectDefectsData: NasscoCodeCategoryCounts;

  @Prop() readonly type: string;

  @PropSync('selectedGroup') synchedSelectedGroup: string;

  @Prop({ default: 0 }) readonly selectedTab: number;

  omTableData: Array<TableData> = [
    {
      name: 'FOG',
      value: 'FOG',
      defectCount: 0,
      assetCount: 0,
      length: 0,
    },
    {
      name: 'Encrustation',
      value: 'Encrustation',
      defectCount: 0,
      assetCount: 0,
      length: 0,
    },
    {
      name: 'Roots',
      value: 'Roots',
      defectCount: 0,
      assetCount: 0,
      length: 0,
    },
    {
      name: 'Obstructions',
      value: 'Obstructions',
      defectCount: 0,
      assetCount: 0,
      length: 0,
    },
    {
      name: 'Crossbore',
      value: 'Crossbore',
      defectCount: 0,
      assetCount: 0,
      length: 0,
    },
  ];

  structTableData: Array<TableData> = [
    {
      name: 'Breaks',
      value: 'Breaks/Broken',
      defectCount: 0,
      assetCount: 0,
      length: 0,
    },
    {
      name: 'Holes',
      value: 'Holes',
      defectCount: 0,
      assetCount: 0,
      length: 0,
    },
    {
      name: 'Collapse',
      value: 'Collapse',
      defectCount: 0,
      assetCount: 0,
      length: 0,
    },
    {
      name: 'Deformation',
      value: 'Deformation',
      defectCount: 0,
      assetCount: 0,
      length: 0,
    },
    {
      name: 'Cracks',
      value: 'Cracks',
      defectCount: 0,
      assetCount: 0,
      length: 0,
    },
    {
      name: 'Fractures',
      value: 'Fractures',
      defectCount: 0,
      assetCount: 0,
      length: 0,
    },
    {
      name: 'SurfaceDamages',
      value: 'Surface Damages',
      defectCount: 0,
      assetCount: 0,
      length: 0,
    },
  ];

  tableData = [this.omTableData, this.structTableData];

  get selectedTable(): Array<TableData> {
    return this.tableData[this.selectedTab];
  }

  async mounted(): Promise<void> {
    this.updateTableData();
  }

  updateTableData(): void {
    if (!this.projectDefectsData) {
      return;
    }

    const defectsData = this.type === 'Manhole'
      ? this.projectDefectsData.manholeDefects
      : this.projectDefectsData.lineSegmentDefects;
    this.omTableData.forEach((row, index) => {
      const matchedDefect = defectsData.om.find(
        (defect) => defect.name === row.name,
      );
      if (matchedDefect !== undefined) {
        this.omTableData[index].defectCount = matchedDefect.defectCount;
        this.omTableData[index].assetCount = matchedDefect.assetCount;
        this.omTableData[index].length = this.formatLength(
          matchedDefect.length,
        );
      }
    });
    this.structTableData.forEach((row, index) => {
      const matchedDefect = defectsData.structural.find(
        (defect) => defect.name === row.name,
      );
      if (matchedDefect !== undefined) {
        this.structTableData[index].defectCount = matchedDefect.defectCount;
        this.structTableData[index].assetCount = matchedDefect.assetCount;
        this.structTableData[index].length = this.formatLength(
          matchedDefect.length,
        );
      }
    });
  }

  /**
   * Returns length in ft at 2 decimal places
   *
   * @param {number} length - Length in feet
   * @return {number} Formatted length
   *
   * @example
   *
   *     formatLength(10.0)
   */
  formatLength(length: number): number {
    return Math.round(length);
  }

  selectGroup(groupName: string): void {
    this.synchedSelectedGroup = groupName !== this.synchedSelectedGroup ? groupName : '';
  }

  isGroupSelected(groupName: string): string {
    return groupName === this.synchedSelectedGroup ? '#0c6599' : 'white';
  }

  get tableHeader(): string {
    return this.selectedTab ? 'Structural' : 'O&M';
  }
}
