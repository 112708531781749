import { render, staticRenderFns } from "./PmDashboard.vue?vue&type=template&id=eba6373a&scoped=true&"
import script from "./PmDashboard.vue?vue&type=script&lang=ts&"
export * from "./PmDashboard.vue?vue&type=script&lang=ts&"
import style0 from "./PmDashboard.vue?vue&type=style&index=0&id=eba6373a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eba6373a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})
