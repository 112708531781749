import { MutationTree } from 'vuex';
import { AdminState } from './types';

// eslint-disable-next-line no-shadow
export enum AdminMutations {
  SET_LOADING_STATE = 'SET_LOADING_STATE',
  SET_LOAD_ERROR = 'SET_LOAD_ERROR',
  SET_RESPONSE = 'SET_RESPONSE',
  SET_UPDATE_USER_KEY = 'SET_UPDATE_USER_KEY'
}

export const mutations: MutationTree<AdminState> = {
  [AdminMutations.SET_LOADING_STATE](state, payload: boolean) {
    state.loading = payload;
  },

  [AdminMutations.SET_LOAD_ERROR](state, payload: string) {
    state.loadError = payload;
  },

  [AdminMutations.SET_RESPONSE](state, payload: string) {
    state.response = payload;
  },

  [AdminMutations.SET_UPDATE_USER_KEY](state, payload: string) {
    state.updateUserKey = payload;
  },
};
