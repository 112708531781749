





































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  Prop,
  PropSync,
  VModel,
  Watch,
} from 'vue-property-decorator';
import { format } from 'date-fns';
import ReportInputMixin from '../ReportInputMixin.vue';

@Component({
  components: {},
})
export default class ReportCalendarInput extends ReportInputMixin {
  @VModel() modelValue!: string | undefined;

  /**
   * Additional prop to model the time selection.
   * @type {string | undefined}
   */
  @PropSync('timeValue') syncedTimeValue!: string | undefined;

  /**
   * The label text displayed next to the date picker input.
   * @type {string | undefined}
   */
  @Prop() label: string | undefined;

  /**
   * Add a date picker to the menu.
   * @type {boolean | undefined}
   */
  @Prop({ type: Boolean, default: true }) readonly datePicker:
    | boolean
    | undefined;

  /**
   * Add a date picker to the menu.
   * @type {boolean | undefined}
   */
  @Prop({ type: Boolean, default: false }) readonly timePicker:
    | boolean
    | undefined;

  /**
   * Controls the visibility of the date picker menu.
   * @type {boolean}
   * @default false
   */
  customToDateMenu = false;

  /**
   * Temporary date selected in the date picker, used before saving.
   * @type {string | null}
   * @default null
   */
  tempDate = null;

  /**
   * Temporary time selected in the time picker, used before saving.
   * @type {string | null}
   * @default null
   */
  tempTime = null;

  /**
   * Set tempDate if menu is opened.
   */
  @Watch('customToDateMenu')
  onCustomToDateMenuChange(): void {
    if (this.customToDateMenu) {
      if (this.datePicker) {
        const parsedDate = new Date(this.modelValue);
        this.tempDate = format(parsedDate, 'yyyy-MM-dd');
        this.tempDate = this.modelValue;
      }
      if (this.timePicker) {
        this.tempTime = this.syncedTimeValue;
      }
      if (!this.datePicker && !this.timePicker) {
        console.error('This component needs either or both set to true: timePicker, datePicker');
      }
    }
  }

  /**
   * Returns the current displayed value of the date input.
   * Displays `tempDate` if the date picker menu is open; otherwise, displays `modelValue`.
   * @type {string | undefined}
   */
  get currentValue(): string | undefined {
    const dateVal = (this.customToDateMenu && this.tempDate
      ? this.tempDate
      : this.modelValue) ?? '';
    if (this.timePicker) {
      const timeVal = (this.customToDateMenu && this.tempTime
        ? this.tempTime
        : this.syncedTimeValue) ?? '';
      if (this.datePicker) {
        return `${dateVal} ${timeVal}`;
      }
      return `${timeVal}`;
    }
    return `${dateVal}`;
  }

  /**
   * Saves the selected date by setting `modelValue` to `tempDate` and closes the date picker menu.
   */
  saveMenu(): void {
    if (this.datePicker) {
      this.modelValue = this.tempDate;
    } else if (this.timePicker) {
      this.modelValue = this.tempTime;
    }
    if (this.timePicker) {
      this.syncedTimeValue = this.tempTime;
      this.modelValue = this.currentValue;
    }
    this.closeMenu();
  }

  /**
   * Closes the date picker menu by setting `customToDateMenu` to `false`.
   */
  closeMenu(): void {
    this.customToDateMenu = false;
  }

  /**
   * Closes the date picker menu by setting `customToDateMenu` to `false`.
   */
  openMenu(): void {
    this.customToDateMenu = true;
  }

  /**
   * Add days to the current tempDate
   * @param {number} days days to add
   */
  addDays(days: number): void {
    if (!this.tempDate) {
      this.tempDate = format(new Date(), 'yyyy-MM-dd');
    }
    const parsedDate = new Date(this.tempDate);
    this.tempDate = format(
      parsedDate.setDate(parsedDate.getDate() + days + 1),
      'yyyy-MM-dd',
    );
  }

  /**
   * On up button press.
   * Move the calendar back 1 week
   */
  up(): void {
    this.addDays(-7);
  }

  /**
   * On down button press.
   * Move the calendar forward 1 week
   */
  down(): void {
    this.addDays(7);
  }

  /**
   * On left button press.
   * Move the calendar back 1 day
   */
  left(): void {
    this.addDays(-1);
  }

  /**
   * On right button press.
   * Move the calendar forward 1 day
   */
  right(): void {
    this.addDays(1);
  }

  /**
   * On enter button press.
   * Save the current date
   */
  enter(): void {
    this.saveMenu();
  }
}
