







































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-alert */
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ExportActions } from '@/store/dbExports/actions';
import { AccessPayload } from '@/store/dbExports/types';

// eslint-disable-next-line no-shadow
const enum SnackBarStates {
  API_ERROR,
  INVALID_INPUT,
  SUCCESS
}

const dbExportModule = namespace('dbExports');

@Component({
  components: {
  },
})
export default class ImportNassco extends Vue {
    items = ['PACP / MACP Access DB'];

    selectedUploadType = this.items[0];

    selectedFile = [];

    snack = false;

    snackColor = 'black';

    snackText = '';

    email = '';

    invalidDatabase = false;

    @Prop() readonly projectGuid: string | undefined;

    @dbExportModule.Action(ExportActions.POST_NASSCO_DB) postNassco;

    @dbExportModule.State('data') exportState: string | undefined;

    async mounted(): Promise<void> {
      const user = await this.$auth.getUser();
      if (user != null) {
        this.email = user.email;
      }
    }

    async onClickUpload(): Promise<void> {
      // check if we can upload
      if (this.selectedFile.length === 0) {
        this.snackBar(SnackBarStates.INVALID_INPUT);
        return;
      }
      // switch items so we upload to right thing
      switch (this.selectedUploadType) {
        case this.items[0]: {
          // post access db
          const formPayload = new FormData();
          formPayload.append('files', this.selectedFile as any);
          formPayload.append('email', this.email);
          formPayload.append('overrideValidationFailure', this.invalidDatabase.toString());
          const payload: AccessPayload = {
            projectGuid: this.projectGuid,
            payload: formPayload,
          };
          try {
            await this.postNassco(payload);
            // check if we failed in response object
            if (this.exportState === 'ERROR: Import Failed') {
              this.snackBar(SnackBarStates.API_ERROR, 'ERROR: Import Failed');
              alert('Database import failed.');
            // otherwise we succeeded
            } else {
              this.snackBar(SnackBarStates.SUCCESS);
              alert('We are processing your import request. You will be sent an email with validation details.');
            }
          } catch (e) {
            this.snackBar(SnackBarStates.API_ERROR);
            alert('An error occurred when importing the database.');
          }
          break;
        }
        default:
          alert('Selected upload type not implemented.');
          break;
      }
      // TODO: send zip file to db
    }

    snackBar(state: SnackBarStates, errorText = ''): void {
      switch (state) {
        case SnackBarStates.INVALID_INPUT:
          this.snackText = errorText === '' ? 'INVALID INPUT' : errorText;
          this.snackColor = 'orange';
          this.snack = true;
          break;
        case SnackBarStates.API_ERROR:
          this.snackText = errorText === '' ? 'API ERROR' : errorText;
          this.snackColor = '#e61e25';
          this.snack = true;
          break;
        case SnackBarStates.SUCCESS:
          this.snackText = 'DB UPLOADED';
          this.snackColor = 'green';
          this.snack = true;
          break;
        default:
          this.snackText = 'IMPLEMENT ME!';
          this.snackColor = '#e61e25';
          this.snack = true;
      }
    }
}
