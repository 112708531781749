




















































import IntegrityTable, { FillFunctionContainer, processDateWithTableObject } from '@/components/IntegrityTable/IntegrityTable.vue';
import UserPermissionsMixin from '@/components/UserPermissions/UserPermissionsMixin.vue';
import { UserPermission } from '@/store/userpermissions/types';
import { AssignmentActions } from '@/store/assignments/actions';
import { ReadyForCodingAssignmentData } from '@/store/assignments/types';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { uuid } from 'vue-uuid';

const assignmentsModule = namespace('assignments');

@Component({
  components: {
    IntegrityTable,
  },
})
export default class Reporting extends UserPermissionsMixin {
  @assignmentsModule.Action(AssignmentActions.GET_READY_FOR_CODING_ASSIGNMENTS) getRFCAssignments;

  @assignmentsModule.State('readyForCodingAssignmentData') readyForCodingAssignmentData: ReadyForCodingAssignmentData[];

  search = '';

  headers = [
    {
      text: 'Asset Name',
      value: 'assetName',
      sortable: true,
      filterable: true,
      class: 'sticky',
      cellClass: 'sticky',
    },
    {
      text: '',
      align: 'center',
      sortable: false,
      filterable: false,
      value: 'guid',
      class: 'sticky',
      cellClass: 'sticky',
    },
    {
      text: 'Asset Type',
      value: 'assetType',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Project Number',
      value: 'projectNumber',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Project Name',
      value: 'projectName',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Task',
      value: 'workOrderTask',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Inspection Date',
      value: 'inspectionDate',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Feedback',
      value: 'feedback',
      sortable: true,
      filterable: false,
    },
  ];

  matchFilters = [
    {
      header: 'assetName',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'guid',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'assetType',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'projectNumber',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'projectName',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'workOrderTask',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'inspectionDate',
      type: 'date',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'feedback',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
  ];

  get fillTableDataFunctions(): FillFunctionContainer {
    const returnValue: FillFunctionContainer = {
      updateKey: uuid.v4(),
      fillFunctions: [
        {
          headerValue: 'inspectionDate',
          functionVariables: ['inspectionDate'],
          fillFunction: processDateWithTableObject,
        },
      ],
    };
    return returnValue;
  }

  mounted(): void {
    if (!this.hasPermissionReportingCommon) {
      this.goToErrorPage();
    }
    this.getRFCAssignments();
  }

  uniqueValues(value: string, index: number, arr: string[]): boolean {
    return arr.indexOf(value) === index;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getAssetParams(item: ReadyForCodingAssignmentData): any {
    return { idList: JSON.stringify([item.projectGuid]), id: item.guid };
  }

  /**
   * @returns true if the user has the permission REPORTING_COMMON
   */
  get hasPermissionReportingCommon(): boolean {
    return this.hasPermission(UserPermission.REPORTING_COMMON);
  }
}
