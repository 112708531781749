















import { INTEGRITY_VERSION } from '@/common/Constants';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class CustomerDashboardFooter extends Vue {
  @Prop() readonly pageNumber!: number;

  @Prop() readonly reportType!: string;

  get title(): string {
    switch (this.reportType) {
      case 'systemAssessment':
        return 'System Assessment';
      case 'workOrders':
        return 'Work Order Summary';
      default:
        return '';
    }
  }

  get integrityVersion(): string {
    return INTEGRITY_VERSION;
  }

  get currentDate(): string {
    return new Date().toISOString().slice(0, 10);
  }
}
