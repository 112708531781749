










































import { NODEITEM_LINESEGMENT_GUID, NODEITEM_MANHOLE_GUID } from '@/common/Constants';
import { RoutingActions } from '@/store/routing/actions';
import { SimpleRoutingData } from '@/store/routing/types';
import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const routingModule = namespace('routing');

@Component({})
export default class RouteSelect extends Vue {
  @routingModule.Action(RoutingActions.FETCH_ROUTE_ASSETS) fetchRouteAssets;

  @Prop({ default: () => [] }) readonly routingData: SimpleRoutingData[];

  @Prop() readonly projectGuid: string;

  @Prop() readonly displayed: boolean;

  readonly baseHeight = 500;

  readonly baseItemHeight = 40;

  tabItems = [
    {
      name: 'Manhole',
      guid: NODEITEM_MANHOLE_GUID,
    },
    {
      name: 'Line Segment',
      guid: NODEITEM_LINESEGMENT_GUID,
    },
  ];

  tab = 0;

  selectedRoute = null;

  get scrollHeight(): number {
    const perferedHeight = this.baseItemHeight * this.routes.length;
    return perferedHeight >= this.baseHeight ? this.baseHeight : perferedHeight;
  }

  get routes(): Array<SimpleRoutingData> {
    if (this.routingData === undefined) {
      return [{
        guid: undefined,
        routeName: 'No Routes Found',
        color: undefined,
        description: undefined,
        categoryItemGuid: undefined,
      }];
    }
    const filteredRoutes = this.routingData.filter(
      (route) => route.categoryItemGuid === this.tabItems[this.tab].guid,
    );
    if (filteredRoutes.length === 0) {
      return filteredRoutes.concat([{
        guid: undefined,
        routeName: 'No Routes Found',
        color: undefined,
        description: undefined,
        categoryItemGuid: undefined,
      }]);
    }
    return filteredRoutes;
  }

  get isDisabled(): boolean {
    return this.routes.length === 1 && this.routes[0].guid == null;
  }

  getRouteDescription(description: string): string {
    const descriptionLength = description != null ? [...description].length : 0;
    if (descriptionLength > 28) {
      return description.substring(0, 25).concat('...');
    }
    return descriptionLength > 0 ? description : '';
  }

  async getRouteAssets(guid: string): Promise<void> {
    if (guid == null) {
      return;
    }
    await this.fetchRouteAssets(guid);
  }
}
