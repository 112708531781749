

















































































import { Component, Prop } from 'vue-property-decorator';
import VueHtml2pdf from 'vue-html2pdf';
import InspectionScoresChart from '@/components/MSIGraph/InspectionScoresChart.vue';
import CustomerDashboardMixin from '@/views/Dashboards/CustomerDashboard/CustomerDashboardMixin.vue';
import CustomerDashboardHeader from './CustomerDashboardHeader.vue';
import DefectCountTable from '../CustomerDashboardComponents/DefectCountTable.vue';
import CustomerDashboardFooter from './CustomerDashboardFooter.vue';
import WorkOrderStatusChart from '../CustomerDashboardComponents/WorkOrderStatusChart.vue';
import CustomerDashboardWorkOrderTable from '../CustomerDashboardComponents/CustomerDashboardWorkOrderTable.vue';

@Component({
  components: {
    CustomerDashboardHeader,
    InspectionScoresChart,
    DefectCountTable,
    CustomerDashboardFooter,
    VueHtml2pdf,
    WorkOrderStatusChart,
    CustomerDashboardWorkOrderTable,
  },
})
export default class CustomerDashboardPagePdf extends CustomerDashboardMixin {
  @Prop() readonly type: string;

  @Prop() readonly projectName: string;

  @Prop() readonly reportType!: string;

  get selectedType(): number {
    return this.type === 'Manhole' ? 0 : 1;
  }
}
