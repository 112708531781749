









































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'vue-property-decorator';
import ReportFooter from '../ReportFooter.vue';
import { GeneralMaintenanceReportFields } from './types';
import ReportInterface, {
  WorkOrderfieldType,
} from '../ReportHelpers/ReportInterface.vue';
import ReportCalendarInput from '../ReportHelpers/ReportInputHelpers/ReportCalendarInput.vue';
import ReportDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportDropDownInput.vue';
import ReportTextInput from '../ReportHelpers/ReportInputHelpers/ReportTextInput.vue';
import ReportAssignedToDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportAssignedToDropDownInput.vue';

@Component({
  components: {
    ReportFooter,
    ReportCalendarInput,
    ReportDropDownInput,
    ReportTextInput,
    ReportAssignedToDropDownInput,
  },
})
export default class GeneralMaintenanceReport extends ReportInterface {
  // #region Abstract fields
  workOrderData: GeneralMaintenanceReportFields | any = {};

  workOrderFields = [
    { name: 'assignedTo', value: 'Users' },
    { name: 'dateIssued', value: 'DateIssued', type: WorkOrderfieldType.DATE },
    { name: 'workDescription', value: 'Description' },
    { name: 'specialInstructions', value: 'Instructions' },
    { name: 'assetNumber', value: 'Wastewater' },
    { name: 'priority', value: 'Priority' },
    {
      name: 'planDueDate',
      value: 'PlanDueDate',
      type: WorkOrderfieldType.DATE,
    },
    { name: 'street', value: 'Street' },
    { name: 'dateCompleted', value: 'InspDate', type: WorkOrderfieldType.DATE },
    { name: 'notCompletedWhy', value: 'ResonNotComplete' },
    { name: 'specialConditions', value: 'SpecialCondition' },
    { name: 'clean', value: 'FUClean', type: WorkOrderfieldType.BOOLEAN },
    { name: 'repair', value: 'FURepair', type: WorkOrderfieldType.BOOLEAN },
    { name: 'inspect', value: 'FUInspect', type: WorkOrderfieldType.BOOLEAN },
    { name: 'activity', value: 'Activity' },
  ];

  // #endregion
}
