




















/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Prop, VModel } from 'vue-property-decorator';
import ReportInputMixin from '../ReportInputMixin.vue';

@Component({
  components: {},
})
export default class ReportInputCheckboxTable extends ReportInputMixin {
  @VModel() modelValue!: any | undefined;

  /**
   * Label for table
   */
  @Prop() label: string;

  /**
   * List of values to fill table
   * label is string shown on table
   * value is key in modelValue
   */
  @Prop() checkboxValues: { label: string; value: string }[];

  /**
   * Number of rows in table
   */
  @Prop() rows: number;

  /**
   * Number of columns in table
   */
  @Prop() columns: number;

  get gridStyle(): any {
    return {
      display: 'grid',
      gridTemplateRows: `repeat(${this.rows}, auto)`,
      gridTemplateColumns: `repeat(${this.columns}, 1fr)`,
    };
  }
}
