










/* eslint-disable @typescript-eslint/no-explicit-any */
import { GenericDropDownData } from '@/common/types';
import {
  Component, Prop, VModel, Vue,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { UserActions } from '@/store/users/actions';
import { UserData } from '@/store/users/types';
import { getAllCrewMembers } from '@/common/utils/WorkOrderUtils';
import UserPermissionsMixin from '@/components/UserPermissions/UserPermissionsMixin.vue';
import { UserPermission } from '@/store/userpermissions/types';
import ReportDropDownInput from './ReportDropDownInput.vue';

const userModule = namespace('users');

  @Component({
    components: { ReportDropDownInput },
  })
export default class ReportAssignedToDropDownInput extends UserPermissionsMixin {
    @userModule.Action(UserActions.FETCH_ALL_USER_DATA) fetchAllUserData;

    @userModule.State('allUserData') allUsers: UserData[] | undefined;

    @userModule.State('loading') allUserDataLoading: boolean;

    @VModel() modelValue!: string | GenericDropDownData | undefined;

    /**
     * The label text displayed next to the date picker input.
     * Defaults to Assign To
     * @type {string | undefined}
     */
    @Prop({ default: 'Assigned To' }) label: string | undefined;

    /**
    * @returns true if the user has the permission WORK_ORDER_PLANNING
    */
    get hasPermissionWorkOrderPlanning(): boolean {
      return this.hasPermission(UserPermission.WORK_ORDER_PLANNING);
    }

    get allCrewMembers(): GenericDropDownData[] {
      if (this.allUsers == null) {
        return [];
      }
      return getAllCrewMembers(this.allUsers);
    }

    async mounted(): Promise<void> {
      if (this.allUsers == null && !this.allUserDataLoading) {
        this.fetchAllUserData();
      }
    }
}
