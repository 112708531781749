export interface UserIdentity{
    connection: string;
    userId: string;
    provider: string;
    isSocial: boolean;
}

export interface DetailedUser{
    email: string;
    isEmailVerified: boolean;
    username: string;
    phoneNumber: string;
    isPhoneVerified: boolean;
    userId: string;
    createdAt: string;
    updatedAt: string;
    identities: Array<UserIdentity>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    appMetadata: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    userMetadata: any;
    picture: string;
    name: string;
    nickname: string;
    multifactor: Array<string>;
    lastIp: string;
    lastLogin: string;
    loginsCount: number;
    blocked: boolean;
    givenName: string;
    familyName: string;
}

export interface RoleUser{
    userId: string;
    picture: string;
    name: string;
    email: string;
    role: string;
}

export class UserAlreadyExists extends Error {
  constructor(message = '') {
    super(message);
    this.message = message;
  }
}
