





































































import {
  Component, Prop, VModel, Vue,
} from 'vue-property-decorator';
import ReportCalendarInput from '../ReportHelpers/ReportInputHelpers/ReportCalendarInput.vue';
import ReportDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportDropDownInput.vue';
import ReportTextInput from '../ReportHelpers/ReportInputHelpers/ReportTextInput.vue';
import ReportAssignedToDropDownInput from '../ReportHelpers/ReportInputHelpers/ReportAssignedToDropDownInput.vue';

@Component({
  components: {
    ReportTextInput,
    ReportDropDownInput,
    ReportCalendarInput,
    ReportAssignedToDropDownInput,
  },
})
export default class FOGInspectionReportHeader extends Vue {
  @VModel() modelValue!: any | undefined;

  @Prop({ default: true }) isEditMode: boolean;
}
