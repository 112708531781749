
















































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import { AssetActions } from '@/store/asset/actions';
import Vue from 'vue';
import { Component, Prop, PropSync } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { SnackBarActions } from '@/store/integritySnackBar/actions';
import {
  AssetData,
  InspectionData,
} from '../../store/asset/types';

const assetModule = namespace('asset');
const integritySnackBar = namespace('integritySnackBar');
@Component
export default class ReportingFeedbackPopout extends Vue {
  @PropSync('popupModel') displayPopup: boolean;

  @PropSync('feedbackItems') feedbackItemsSynced: any;

  @Prop() readonly asset!: AssetData;

  @Prop() readonly inspection!: InspectionData;

  @assetModule.State('reportingFeedbackError') reportingFeedbackError: string =
    undefined;

  @assetModule.State('reportingFeedbackLoaded') reportingFeedbackLoaded:
    | boolean
    | undefined = undefined;

  @assetModule.Action(AssetActions.POST_REPORTING_FEEDBACK)
  postReportingFeedback;

  @integritySnackBar.Action(SnackBarActions.SHOW) showSnackBar;

  requiredField = [
    (v: string): string | boolean => !!v || 'This is a required field.',
  ];

  valid = false;

  mounted(): void {
    this.reset();
  }

  reset(): void {
    if (this.asset.reportFeedback == null) {
      return;
    }
    this.feedbackItemsSynced.forEach((item) => {
      if (this.asset.reportFeedback[item.val] != null) {
        // eslint-disable-next-line no-param-reassign
        item.value = !!this.asset.reportFeedback[item.val];
        if (item.requireReason && item.value) {
          // eslint-disable-next-line no-param-reassign
          item.reason = this.asset.reportFeedback[item.val];
        }
      }
    });
  }

  submit(): void {
    const reportingData = {};
    this.feedbackItemsSynced.forEach((item) => {
      if (item.requireReason) {
        reportingData[item.val] = item.value ? item.reason : null;
      } else {
        reportingData[item.val] = item.value;
      }
    });

    this.postReportingFeedback({
      inspectionGuid: this.inspection.guid,
      reportingData,
    }).then(() => {
      this.asset.reportFeedback = reportingData;
      const feedbackMessage = this.reportingFeedbackError
        ? 'Unable to Submit Feedback'
        : 'Feedback Submitted';
      this.showSnackBar({
        message: feedbackMessage,
        color: this.reportingFeedbackError ? 'red' : 'green',
        showSnackBar: true,
      });
      this.displayPopup = false;
    });
  }

  createCustomReportingDataObject(obj: {
    name: string;
    value: boolean;
    reason: string;
  }): string | undefined {
    return obj.value ? obj.reason : undefined;
  }
}
