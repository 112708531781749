





















































































import CustomerDashboardMixin from '@/views/Dashboards/CustomerDashboard/CustomerDashboardMixin.vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { WorkOrderStatusCount, DateSelector } from '@/store/metrics/types';
import WorkOrderStatusChart from './WorkOrderStatusChart.vue';
import DashboardWorkOrderTable from './DashboardWorkOrderTable.vue';
import TaskTypeTable from './TaskTypeTable.vue';
import CustomerDashboardWorkOrderTable from './CustomerDashboardWorkOrderTable.vue';
import CustomerDashboardWorkOrderBarChart from './CustomerDashboardWorkOrderBarChart.vue';
import DashboardDateSelector from './DashboardDateSelector.vue';

// eslint-disable-next-line no-shadow
enum WorkOrdersTabs {
  MANHOLE = 'Manholes',
  LINESEGMENTS = 'Line Segments',
}

// eslint-disable-next-line no-shadow
enum TaskTypeToggle {
  TABLE = 0,
  CHART = 1,
}

@Component({
  components: {
    TaskTypeTable,
    WorkOrderStatusChart,
    DashboardWorkOrderTable,
    CustomerDashboardWorkOrderTable,
    CustomerDashboardWorkOrderBarChart,
    DashboardDateSelector,
  },
})
export default class CustomerDashboardWorkOrders extends CustomerDashboardMixin {
  @Prop() readonly projectId: string;

  toggle = TaskTypeToggle.TABLE;

  selectedTab = 0;

  selectedTaskType = '';

  selectedStatus = '';

  scheduledItems = [
    {
      value: 'Next 7 Days',
      dateValues: [this.todayString, this.createDateDaysAway(7)],
    },
    {
      value: 'Next 30 Days',
      dateValues: [this.todayString, this.createDateDaysAway(30)],
    },
  ];

  completedItems = [
    {
      value: 'Last 7 Days',
      dateValues: [this.createDateDaysAway(-7), this.todayString],
    },
    {
      value: 'Last 30 Days',
      dateValues: [this.createDateDaysAway(-30), this.todayString],
    },
    {
      value: 'Last 3 Months',
      dateValues: [this.createMonthsAway(-3), this.todayString],
    },
  ];

  createDateDaysAway(daysAway: number): string {
    const today = new Date();
    today.setDate(today.getDate() + daysAway);
    return today.toISOString().split('T')[0];
  }

  createMonthsAway(monthsAway: number): string {
    const today = new Date();
    today.setMonth(today.getMonth() + monthsAway);
    today.setDate(1);
    return today.toISOString().split('T')[0];
  }

  get todayString(): string {
    return new Date().toISOString().split('T')[0];
  }

  get tabs(): string[] {
    return Object.values(WorkOrdersTabs);
  }

  get currentTabString(): string {
    return this.selectedTab ? 'lineSegments' : 'manholes';
  }

  get currentWorkOrderData(): WorkOrderStatusCount | undefined {
    if (!this.customerDataWorkOrderCounts) {
      return null;
    }
    return this.customerDataWorkOrderCounts[this.currentTabString];
  }

  async mounted(): Promise<void> {
    this.fetchCustomerDataWorkOrders({ projectGuid: this.projectId });
  }

  @Watch('selectedTab')
  onSelectedTabChange(): void {
    this.selectedTaskType = '';
    this.selectedStatus = '';
  }

  statusChartClick(status: string): void {
    this.selectedTaskType = '';
    this.selectedStatus = status === this.selectedStatus ? '' : status;
  }

  taskTypeClick(taskType: string): void {
    this.selectedStatus = '';
    this.selectedTaskType = taskType;
  }

  updateScheduleFor(newDate: DateSelector): void {
    this.updateDateFilter(newDate, this.defaultDateSelector);
  }

  updateCompletedBy(newDate: DateSelector): void {
    this.updateDateFilter(this.defaultDateSelector, newDate);
  }

  updateDateFilter(
    newScheduledDate: DateSelector,
    newCompletedDate: DateSelector,
  ): void {
    this.setScheduledDate(newScheduledDate);
    this.setCompletedDate(newCompletedDate);
    this.fetchCustomerDataWorkOrders({
      projectGuid: this.projectId,
      startScheduledForString: this.getDateStringFromArray(newScheduledDate, 0),
      endScheduledForString: this.getDateStringFromArray(newScheduledDate, 1),
      startCompletedByString: this.getDateStringFromArray(newCompletedDate, 0),
      endCompletedByString: this.getDateStringFromArray(newCompletedDate, 1),
    });
  }

  getDateStringFromArray(
    dateSelector: DateSelector,
    index: number,
  ): string | undefined {
    return dateSelector.dateValues && dateSelector.dateValues.length === 2
      ? dateSelector.dateValues[index]
      : null;
  }

  get defaultDateSelector(): DateSelector {
    return { value: 'All', dateValues: null };
  }
}
