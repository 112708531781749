





































































import { Component } from 'vue-property-decorator';
import UserPermissionsMixin from '@/components/UserPermissions/UserPermissionsMixin.vue';
import IntegrityTable from '@/components/IntegrityTable/IntegrityTable.vue';
import { DataTableHeader } from 'vuetify';
import sampleRoleData from './RolePermissions.json';

@Component({
  components: {
    IntegrityTable,
  },
})
export default class PermissionsMatrix extends UserPermissionsMixin {
  dialog = false;

  /**
   *
   * Gets data for the matrix table
   *
   * @returns {DataTableHeader[]} data for the matrix table
   */
  get tableData(): DataTableHeader[] {
    const ret = [];
    const universalUser = 'Super User';
    const universalPermissionData = sampleRoleData[universalUser];
    if (universalPermissionData === undefined) {
      return [];
    }
    Object.keys(universalPermissionData).forEach((key) => {
      universalPermissionData[key].forEach((item) => {
        const newVal = {};
        newVal['category'] = key;
        newVal['header'] = item;
        this.roles.forEach((role) => {
          newVal[role] = this.doesRoleHavePermissions(role, key, item);
        });
        ret.push(newVal);
      });
    });
    return ret;
  }

  /**
   *
   * Gets the headers for the matrix table
   *
   * @returns {DataTableHeader[]} headers for the matrix table
   */
  get headers(): DataTableHeader[] {
    const mappedData: DataTableHeader[] = this.roles.map((x) => ({
      text: x,
      value: x,
      sortable: false,
      align: 'center',
    }));

    mappedData.splice(0, 0, {
      text: '',
      align: 'start',
      sortable: false,
      value: 'header',
      class: 'with-divider sticky',
      cellClass: 'with-divider sticky',
    });
    return mappedData;
  }

  /**
   *
   * Gets a list of roles
   *
   * @returns {String[]} list of roles
   */
  get roles(): string[] {
    return Object.keys(sampleRoleData);
  }

  /**
   *
   * Finds if a given role has permission given the category and permission name
   *
   * @param {string} role user role name
   * @param {string} category category name
   * @param {string} permission permission name
   * @returns String format of date
   */
  doesRoleHavePermissions(
    role: string,
    category: string,
    permission: string,
  ): boolean {
    const roleJson = sampleRoleData[role];
    if (role === undefined) {
      return false;
    }
    const rolePermission = roleJson[category];

    if (rolePermission === undefined) {
      return false;
    }
    return (rolePermission as string[]).includes(permission);
  }

  /**
   *
   * Find the appropriate chip color for the permission and role
   *
   * @param item item from table
   * @param {string} header to check
   * @returns color for chip
   */
  chipColor(item: any, roleHeader: string): string {
    return item[roleHeader] ? '#0c6599' : 'gray';
  }

  /**
   *
   * Opens the dialog
   */
  openDialog(): void {
    this.dialog = true;
    this.closeAll();
  }

  /**
   *
   * Closes all groupings
   */
  closeAll() {
    // Object.keys((this.$refs.table['openCache']))
    //   .forEach((g) => { this.$refs.table['openCache'][g] = false; });
    // this.timeoutStickyColumns();
  }

  /**
   *
   * Call setStickyColumns with timeout in order to wait for update
   */
  timeoutStickyColumns(): void {
    setTimeout(() => {
      this.setStickyColumns();
    }, 1);
  }

  /**
   *
   * Add sticky css back to grouping headers
   */
  setStickyColumns(): void {
    const stickyHeaders = document.querySelectorAll('.headerCell');
    Array.from(stickyHeaders).forEach((_row, index) => {
      const expandCell = stickyHeaders[index] as HTMLElement;
      expandCell.style.position = 'sticky';
      expandCell.style.left = '0';
    });
  }
}
