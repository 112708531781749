/* eslint-disable import/prefer-default-export */
import { CREW_LEAD, CREW_MEMBER_GUID } from '@/auth/roles';
import { UserData } from '@/store/users/types';
import { GenericDropDownData } from '../types';

/**
 * @description Get the crew members and crew leads
 * @param allUsers All the user data from the database
 * @returns The crew and crew lead members
 */
export function getAllCrewMembers(allUsers: UserData[]): GenericDropDownData[] {
  const crew = [];
  allUsers.forEach((user) => {
    if (user.role === CREW_LEAD || user.role === CREW_MEMBER_GUID) {
      crew.push({
        text: `${user.firstname} ${user.lastname}`,
        guid: user.guid,
      });
    }
  });
  return crew;
}
