



















































/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component, Prop, VModel,
} from 'vue-property-decorator';
import ReportInputMixin from '../ReportInputMixin.vue';

// eslint-disable-next-line no-shadow
export enum ReportRadioTableLabelPosition {
  TOP_COLUMN,
  RIGHT,
}

@Component({
  components: {},
})
export default class ReportRadioTableInput extends ReportInputMixin {
  @VModel() modelValue!: any | undefined;

  /**
   * Array of header objects used to generate the table columns.
   * Each header object contains a `value` and `label`.
   * 'value' is the value from the data standard
   * 'label' is the displayed value
   * @type {{value: string, label: string}[]}
   */
  @Prop() headers: { value: string; label: string }[];

  /**
   * Array of radio option objects used to generate rows.
   * Each radio option contains a `value` and `label`.
   * 'value' is the key in modelValue
   * 'label' is the displayed value
   * @type {{value: string, label: string}[]}
   */
  @Prop() radioOptions: { value: string; label: string }[];

  /**
   * Where does the radio button label appear
   * Defaults to TOP_COLUMN
   * @type {ReportRadioTableLabelPosition}
   */
  @Prop(
    { default: ReportRadioTableLabelPosition.TOP_COLUMN },
  ) labelPosition: ReportRadioTableLabelPosition;

  /**
   * Checks if a radio option is currently selected.
   * @param {string} radioOption - The value of the radio option.
   * @param {string} option - The value of the header option to check against.
   * @returns {boolean} `true` if the radio option is selected, otherwise `false`.
   */
  isChecked(radioOption: string, option: string): boolean {
    return this.modelValue[radioOption] === option;
  }

  /**
   * Updates the model with the selected radio option for a specific header.
   * @param {string} itemVal - The key in the modelValue.
   * @param {string} option - The value of the header option to be set.
   * @returns {void}
   */
  changeOption(itemVal: string, option: string): void {
    this.$set(this.modelValue, itemVal, option);
  }

  /**
   * Get the correct rows/columns for the table
   */
  get gridStyle(): {
    gridTemplateColumns: string;
    gridTemplateRows: string;
    } {
    let columns = this.headers?.length ?? 0;
    let rows = this.radioOptions?.length ?? 0;
    if (this.isHeaderLabelTopColumn) {
      rows += 1;
      columns += 1;
    }
    return {
      gridTemplateColumns: `repeat(${columns}, auto)`,
      gridTemplateRows: `repeat(${rows}, 38px)`,
    };
  }

  /**
   * Is the label position in the top of column
   */
  get isHeaderLabelTopColumn(): boolean {
    return this.labelPosition === ReportRadioTableLabelPosition.TOP_COLUMN;
  }

  /**
   * Is the label position right of the radio button
   */
  get isHeaderLabelRightOfRadioButton(): boolean {
    return this.labelPosition === ReportRadioTableLabelPosition.RIGHT;
  }

  /**
   * Gets the class names for the radio button row
   */
  get radioButtonRowClasses(): string {
    const returnValue = ['radioButton'];
    if (this.isHeaderLabelTopColumn) {
      returnValue.push('radioButtonTopColumn');
    }
    if (this.isHeaderLabelRightOfRadioButton) {
      returnValue.push('radioButtonRight');
    }
    return returnValue.join(' ');
  }

  /**
   * Gets the class names for the main table grid
   */
  get gridClasses(): string {
    const returnValue = ['report-radio-table-input'];
    if (this.isHeaderLabelTopColumn) {
      returnValue.push('report-radio-table-input-default-margin');
    }
    if (this.isHeaderLabelRightOfRadioButton) {
      returnValue.push('report-radio-table-input-right');
    }
    return returnValue.join(' ');
  }
}
