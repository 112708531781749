




























































































































































































import { BasicSelect } from 'vue-search-select';
import {
  Component, Prop, PropSync, Watch,
} from 'vue-property-decorator';
import {
  AuthUserRoles, getRoleGuid, getRoleId,
} from '@/auth/roles';
import { UserPermission } from '@/store/userpermissions/types';
import AdminPanelMixin, { UserTableData } from '../AdminPanelMixin.vue';

@Component({
  components: {
    BasicSelect,
  },
})
export default class EditUsers extends AdminPanelMixin {
  @Prop() readonly selectedUsers: UserTableData[];

  @PropSync('data') filteredUsers: Array<UserTableData>;

  selectedOrganization = { value: 0, text: '', guid: '' } as {
    value: number;
    text: string;
    guid: string;
  };

  selectedCustomer = { value: 0, text: '', guid: '' } as {
    value: number;
    text: string;
    guid: string;
  };

  editSelectOpen = false;

  projects: Array<{ value: number; text: string; guid: string }> = [];

  snack = false;

  snackBarMessage = '';

  snackColor = 'black';

  addProjectOpen = false;

  addManagedUserOpen = false;

  selectedProjects = [] as {
    value: number;
    text: string;
    guid: string;
  }[];

  selectedManageUsers: Array<{
    value: number;
    text: string;
    guid: string;
  }> = [];

  editUsersDialog = false as boolean;

  addProjectDialog = false as boolean;

  addProjectErrorDialog = false;

  valid = false as boolean;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  requiredField = [(v) => !!v || v === 0 || 'This is a required field.'];

  canManage: Array<string> = [];

  get managableUsers(): UserTableData[] {
    if (this.filteredUsers == null || this.canManage.length === 0) {
      return [];
    }
    const customerGuids = this.selectedUsers.map(
      (i) => i.detailedUserData.customerGuids,
    ).flat();
    const organizationGuids = this.selectedUsers.map(
      (i) => i.detailedUserData.organizationGuids,
    ).flat();
    const selectedUserGuids = this.selectedUsers.map((u) => u.guid);
    return this.filteredUsers.filter((user) => user.role[0]
    && !selectedUserGuids.includes(user.guid)
    && (user.detailedUserData.organizationGuids.some(
      (o) => organizationGuids.some((u) => u === o),
    )
    || (user.detailedUserData.customerGuids.some(
      (o) => customerGuids.some((u) => u === o),
    )))
    && this.canManage.includes(user.role[0]));
  }

  get organizations(): Array<{value: number; text: string, guid: string}> {
    if (this.selectedUsers.length === 1 && this.managableUsers.length > 0
    ) {
      const managerOrganizationGuids = this.selectedUsers.map(
        (i) => i.detailedUserData.organizationGuids,
      ).flat();
      const managedOrganizationGuids = this.managableUsers.map(
        (i) => i.detailedUserData.organizationGuids,
      ).flat();
      const matchGuids = [...new Set(managerOrganizationGuids.filter(
        (g) => managedOrganizationGuids.includes(g),
      ))];
      return this.organizationDataList.filter((org) => matchGuids.includes(org.guid))
        .map((org, i) => ({ value: i, text: org.name, guid: org.guid }));
    }
    return [];
  }

  get customers(): Array<{value: number; text: string, guid: string}> {
    if (this.selectedUsers.length === 1 && this.managableUsers.length > 0
    ) {
      const managerCustomerGuids = this.selectedUsers.map(
        (i) => i.detailedUserData.customerGuids,
      ).flat();
      const managedCustomerGuids = this.managableUsers.map(
        (i) => i.detailedUserData.customerGuids,
      ).flat();
      const matchGuids = [...new Set(managerCustomerGuids.filter(
        (g) => managedCustomerGuids.includes(g),
      ))];
      return this.customerDataList.filter((cust) => matchGuids.includes(cust.guid))
        .map((cust, i) => ({ value: i, text: cust.name, guid: cust.guid }));
    }
    return [];
  }

  roleIdFromPermissions(permissions: UserPermission[]): string[] {
    const retVal = [];
    const permissionStrings = permissions.map((p) => p.split(':')[1]);
    AuthUserRoles.forEach((role) => {
      const roleName = role.name.replace(' ', '');
      if (permissionStrings.includes(roleName)) {
        retVal.push(role.id);
      }
    });
    return retVal;
  }

  async getCanManage(role: string[] | string): Promise<Array<string>> {
    if (role) {
      let permissions = [];
      if (typeof role === 'string') {
        permissions = await this.$auth.getRolePermission(getRoleId(role));
      } else if (Array.isArray(role)) {
        permissions = await this.$auth.getRolePermission(role[0]);
      } else {
        return [];
      }
      if (this.hasPermissionGroupFromPermissions(permissions, 'AdminCanManage')) {
        return this.roleIdFromPermissions(this.permissionsFromGroupFromPermissions(permissions, 'AdminCanManage'));
      }
    }
    return [];
  }

  /**
   * @description check if all selected users have a common customer
   * @returns {boolean} true if selected users have a common customer, false otherwise
   */
  get selectedUsersHaveSameCustomer(): boolean {
    // get selected user customer guids as arrays
    const selectedUserCustomers = this.selectedUsers.map(
      (user) => user.detailedUserData.customerGuids,
    );

    // filter selected user customer guids down to ones that exist in all arrays
    // this should result in an array of customer guids common to all selected users
    // if there are no common customers between all selected users, commonCustomer would be empty
    const commonCustomer: string[] = selectedUserCustomers
      .shift()
      .filter((c) => selectedUserCustomers.every((uc) => uc.indexOf(c) !== -1));

    return this.selectedUsers.length <= 1 ? true : commonCustomer.length > 0;
  }

  /**
   * @description get the common customers between selected users
   * @returns an array of objects containing a customer name and any users associated with it
   */
  get customerGroupings(): { customer: string, users: string[] }[] {
    const retVal: { customer: string, users: string[] }[] = [];
    let customerName = '';

    this.selectedUsers.forEach((user) => {
      if (user.detailedUserData.customerGuids.length === 0) {
        customerName = 'no assigned customer';
        this.populateCustomerGroupings(user, customerName, retVal);
      } else {
        user.detailedUserData.customerGuids.forEach((customerGuid: string) => {
          const cust = this.customerDataList.find((c) => c.guid === customerGuid);
          if (cust) {
            customerName = cust.name;
            this.populateCustomerGroupings(user, customerName, retVal);
          }
        });
      }
    });

    return retVal;
  }

  @Watch('organizationProjects')
  organizationProjectsChange(): void {
    this.projects = [];
    if (this.organizationProjects != null && this.organizationProjects.length > 0) {
      this.organizationProjects.forEach((proj, i) => {
        this.projects.push({
          value: i,
          text: proj.name,
          guid: proj.guid,
        });
      });
    }

    this.$forceUpdate();
  }

  get canManageUsersSelect(): Array<{ value: number; text: string; guid: string }> {
    if (this.managableUsers.length > 0
        && this.customers.length > 0
        && this.organizations.length > 0) {
      if (this.customers.length === 1) [this.selectedCustomer] = this.customers;
      if (this.organizations.length === 1) [this.selectedOrganization] = this.organizations;
      if (this.selectedCustomer.guid !== '') {
        const matchedUsers = this.managableUsers
          .filter((user) => user.detailedUserData.customerGuids.includes(this.selectedCustomer.guid)
        || user.detailedUserData.organizationGuids.includes(this.selectedOrganization.guid));
        const mappedUsers = matchedUsers.map(
          (user, i) => ({ value: i, text: user.fullName, guid: user.guid }),
        );
        return mappedUsers;
      }
    }
    return [];
  }

  @Watch('selectedUsers')
  async onSelectedUsersChange(): Promise<void> {
    if (this.selectedUsers.length === 0) return;

    this.organizationProjectsChange();
    const selectedUserCustomers = this.selectedUsers.map(
      (user) => user.detailedUserData.customerGuids,
    );

    const commonCustomer: string[] = selectedUserCustomers
      .shift()
      .filter((c) => selectedUserCustomers.every((uc) => uc.indexOf(c) !== -1));

    const isRedzoneOrgMembers = this.isUsersRedzoneOrganizationMember(
      this.selectedUsers.map((u) => u.detailedUserData),
    );

    this.projects = this.projects.filter((p) => {
      // Overide check if all users are redzone organization members
      if (isRedzoneOrgMembers) return true;
      const projCheck = this.organizationProjects.find((proj) => proj.guid === p.guid);
      return commonCustomer.includes(projCheck.customerGuid);
    });

    this.canManage = await this.getCanManage(this.selectedUsers[0].role);
  }

  async mounted(): Promise<void> {
    this.fetchOrganizationProjectsData().catch(() => {
      this.$router.push({
        name: 'Error',
        params: { catchAll: 'Error', message: 'There was an error retrieving your projects. Please try again later. If this issue persists, please contact support.' },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
      }).catch(() => {});
    });
  }

  async submit(): Promise<void> {
    try {
      this.selectedUsers.forEach(async (user) => {
        this.selectedProjects.forEach(async (project) => {
          if (!user.detailedUserData.role.find((role) => role.projectGuid === project.guid)) {
            user.detailedUserData.role.push({
              projectGuid: project.guid,
              roleGuid: getRoleGuid(user.role as string),
              startDate: null,
              endDate: null,
              managerUserGuid: '',
              guid: user.guid,
              isActive: false,
            });
          }
        });
        await this.patchUser({
          Guid: user.detailedUserData.guid,
          Firstname: user.detailedUserData.firstname,
          Lastname: user.detailedUserData.lastname,
          nickname: user.detailedUserData.nickname,
          userRoleObjects: user.detailedUserData.role,
          nasscoId: user.detailedUserData.nasscoid,
        });
      });

      this.snackColor = 'green';
      this.snackBarMessage = 'Project Added';
      this.snack = true;
    } catch (ex) {
      this.snackBarMessage = (ex as Error).message;
      this.snackColor = '#e61e25';
      this.snack = true;
      return;
    }
    this.$forceUpdate();
    await this.resetForm();
    this.addProjectDialog = false;
  }

  async resetForm(): Promise<void> {
    this.selectedProjects = [];
    await (this.$refs.editUsersForm as HTMLFormElement).resetValidation();
  }

  async addManagedUsers(): Promise<void> {
    if (this.customers.length === 1) {
      [this.selectedCustomer] = this.customers;
    }
    if (this.organizations.length === 1) {
      [this.selectedOrganization] = this.organizations;
    }
    const selectedUserGuids = this.selectedManageUsers.map((u) => u.guid);
    const selectedManagedUsers = this.managableUsers.filter(
      (user) => selectedUserGuids.includes(user.guid),
    );

    // eslint-disable-next-line no-restricted-syntax
    for await (const user of selectedManagedUsers) {
      await this.postUserManagement({
        managerUserGuid: this.selectedUsers[0].guid,
        subjectUserGuid: user.guid,
        organizationGuid: this.selectedOrganization.guid,
        customerGuid: this.selectedCustomer.guid,
      });
    }
    this.addManagedUserOpen = false;
  }

  editSelect(): void {
    this.editSelectOpen = !this.editSelectOpen;
  }

  addProject(): void {
    this.addProjectOpen = true;
    this.editSelect();
  }

  addManagedUser(): void {
    this.addManagedUserOpen = true;
    this.editSelect();
  }

  customerGroupingsToString(customerGroup: { customer: string, users: string[] }): string {
    const users = customerGroup.users.toString().replaceAll(',', ', ');
    let predicate = '';

    if (customerGroup.customer === 'no assigned customer') {
      predicate = customerGroup.users.length > 1 ? 'have' : 'has';
    } else {
      predicate = customerGroup.users.length > 1 ? 'are from' : 'is from';
    }

    return `${users} ${predicate} ${customerGroup.customer}`;
  }

  populateCustomerGroupings(
    user: UserTableData, customerName: string, retVal: { customer: string, users: string[] }[],
  ): void {
    const existingCustomerUser = retVal.find((cu) => cu.customer === customerName);

    if (!existingCustomerUser) {
      const newCustomerUsers = {
        customer: customerName,
        users: [user.fullName],
      };

      retVal.push(newCustomerUsers);
    } else {
      existingCustomerUser.users.push(user.fullName);
    }
  }

  /**
   * @returns true if the user has the permission ADMIN_USER_SECTION_EDITMULTIUSERSADDMANAGEDUSERS
   */
  hasPermissionAdminMultiEditManagedUsers(): boolean {
    return this.hasPermission(UserPermission.ADMIN_USER_SECTION_EDITMULTIUSERSADDMANAGEDUSERS);
  }

  /**
   * @returns true if the user has the permission ADMIN_USER_SECTION_EDITMULTIUSERSADDPROJECT
   */
  hasPermissionAdminMultiEditProjects(): boolean {
    return this.hasPermission(UserPermission.ADMIN_USER_SECTION_EDITMULTIUSERSADDPROJECT);
  }
}
