import { ActionTree } from 'vuex';
import { RootState } from '../types';

import storeUtility from '../utils';
import { MetricsMutations } from './mutations';
import { DateSelector, FetchCrewCountsPayload, MetricsState } from './types';

// eslint-disable-next-line no-shadow
export enum MetricsActions {
    FETCH_WORK_ORDER_METRICS = 'FETCH_WORK_ORDER_METRICS',
    FETCH_OM_DEFECT_METRICS = 'FETCH_OM_DEFECT_METRICS',
    FETCH_STRUCT_GRADE_METRICS = 'FETCH_STRUCT_GRADE_METRICS',
    FETCH_ASSIGNMENT_METRICS = 'FETCH_ASSIGNMENT_METRICS',
    FETCH_PROJECT_TOTALS = 'FETCH_PROJECT_TOTALS',
    FETCH_WORK_ORDER_PROJECT_METRICS = 'FETCH_WORK_ORDER_PROJECT_METRICS',
    FETCH_ASSET_COUNTS_METRICS = 'FETCH_ASSET_COUNTS_METRICS',
    FETCH_PROJECT_OVERVIEW_METRICS = 'FETCH_PROJECT_OVERVIEW_METRICS',
    FETCH_OVERVIEW_TOTAL_COUNTS = 'FETCH_OVERVIEW_TOTAL_COUNTS',
    FETCH_CREW_COUNTS_METRICS = 'FETCH_CREW_COUNTS_METRICS',
    FETCH_UPDATE_CREW_COUNT_METRIC = 'FETCH_UPDATE_CREW_COUNT_METRIC',
    FETCH_CREW_COUNTS_AVERAGES_METRICS = 'FETCH_CREW_COUNTS_AVERAGES_METRICS',
    FETCH_UPDATE_CREW_COUNT_AVERAGES_METRIC = 'FETCH_UPDATE_CREW_COUNT_AVERAGES_METRIC',
    FETCH_DATA_DELIVERY_METRICS = 'FETCH_DATA_DELIVERY_METRICS',
    FETCH_DASHBOARD_DEFECTS = 'FETCH_DASHBOARD_DEFECTS',
    FETCH_PROJECT_DEFECTS = 'FETCH_PROJECT_DEFECTS',
    FETCH_NASSCO_INSPECTION_SCORES = 'FETCH_NASSCO_INSPECTION_SCORES',
    FETCH_PLATFORM_CREW_OVERVIEW = 'FETCH_PLATFORM_CREW_OVERVIEW',
    FETCH_CLIENT_ACTION_ITEMS = 'FETCH_CLIENT_ACTION_ITEMS',
    FETCH_CREW_COUNTS_BY_DAY = 'FETCH_CREW_COUNTS_BY_DAY',
    FETCH_CREW_DATA_DELIVERY_PROJECT_METRICS = 'FETCH_CREW_DATA_DELIVERY_PROJECT_METRICS',
    FETCH_CUSTOMER_DASHBOARD_WORK_ORDERS = 'FETCH_CUSTOMER_DASHBOARD_WORK_ORDERS',
    FETCH_FILTER_DEFECTS = 'FETCH_FILTER_DEFECTS',
    SET_SCHEDULED_DATE = 'SET_SCHEDULED_DATE',
    SET_COMPLETED_DATE = 'SET_COMPLETED_DATE',
    SET_SELECTED_RELEASE = 'SET_SELECTED_RELEASE',
    FETCH_DEFECTS = 'FETCH_DEFECTS',
}

export const actions: ActionTree<MetricsState, RootState> = {

  async [MetricsActions.FETCH_WORK_ORDER_METRICS]({ commit }, projectGuid: string): Promise<void> {
    commit(MetricsMutations.SET_LOADING_WORK_ORDERS_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getWorkOrderMetrics(projectGuid);

      commit(MetricsMutations.SET_WORK_ORDER_METRICS, response.data);
    } catch (e) {
      commit(MetricsMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(MetricsMutations.SET_LOADING_WORK_ORDERS_STATE, false);
    }
  },

  async [MetricsActions.FETCH_OM_DEFECT_METRICS]({ commit }, projectGuid: string): Promise<void> {
    commit(MetricsMutations.SET_OM_LOADING_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getOMDefectMetrics(projectGuid);

      commit(MetricsMutations.SET_OM_DEFECT_METRICS, response.data);
    } catch (e) {
      commit(MetricsMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(MetricsMutations.SET_OM_LOADING_STATE, false);
    }
  },

  async [MetricsActions.FETCH_STRUCT_GRADE_METRICS](
    { commit },
    projectGuid: string,
  ): Promise<void> {
    commit(MetricsMutations.SET_LOADING_STRUCT_GRADE_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getStructGradeMetrics(projectGuid);

      commit(MetricsMutations.SET_STRUCT_GRADE_METRICS, response.data);
    } catch (e) {
      commit(MetricsMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(MetricsMutations.SET_LOADING_STRUCT_GRADE_STATE, false);
    }
  },

  async [MetricsActions.FETCH_ASSIGNMENT_METRICS](
    { commit },
    projectGuid: string,
  ): Promise<void> {
    commit(MetricsMutations.SET_LOADING_ASSIGNMENT_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getAssignmentMetrics(projectGuid);

      commit(MetricsMutations.SET_ASSIGNMENT_METRICS, response.data);
    } catch (e) {
      commit(MetricsMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(MetricsMutations.SET_LOADING_ASSIGNMENT_STATE, false);
    }
  },

  async [MetricsActions.FETCH_PROJECT_TOTALS](
    { commit },
    projectGuid: string,
  ): Promise<void> {
    commit(MetricsMutations.SET_PROJECT_TOTALS_LOADING, true);
    commit(MetricsMutations.SET_PROJECT_TOTALS_ERROR, undefined);
    commit(MetricsMutations.SET_PROJECT_TOTALS_DATA, undefined);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getProjectTotals(projectGuid);

      commit(MetricsMutations.SET_PROJECT_TOTALS_DATA, response.data.data);
    } catch (e) {
      if (storeUtility.isDbEntryNotFoundError(e)) {
        commit(MetricsMutations.SET_PROJECT_TOTALS_ERROR, 'The specified project does not exist.');
        throw Error('Project does not exist.');
      }
      commit(MetricsMutations.SET_PROJECT_TOTALS_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(MetricsMutations.SET_PROJECT_TOTALS_LOADING, false);
    }
  },

  async [MetricsActions.FETCH_PROJECT_DEFECTS](
    { commit },
    payload: {
      projectGuid: string,
      assetsFiltered: string[]
    },
  ): Promise<void> {
    commit(MetricsMutations.SET_PROJECT_DEFECTS_LOADING, true);
    commit(MetricsMutations.SET_PROJECT_DEFECTS_ERROR, undefined);
    commit(MetricsMutations.SET_PROJECT_DEFECTS_DATA, undefined);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getDefectCounts(payload.projectGuid, payload.assetsFiltered);
      commit(MetricsMutations.SET_PROJECT_DEFECTS_DATA, response.data);
    } catch (e) {
      if (storeUtility.isDbEntryNotFoundError(e)) {
        commit(MetricsMutations.SET_PROJECT_DEFECTS_ERROR, 'The specified project does not exist.');
        throw Error('Project does not exist.');
      }
      commit(MetricsMutations.SET_PROJECT_DEFECTS_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(MetricsMutations.SET_PROJECT_DEFECTS_LOADING, false);
    }
  },

  async [MetricsActions.FETCH_DEFECTS](
    { commit },
  ): Promise<void> {
    commit(MetricsMutations.SET_DEFECTS_DATA, undefined);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getDefectData();
      commit(MetricsMutations.SET_DEFECTS_DATA, response.data.data);
    } catch (e) {
      throw Error('API Error occurred.');
    }
  },

  async [MetricsActions.FETCH_WORK_ORDER_PROJECT_METRICS](
    { commit },
    projectGuid: string,
  ): Promise<void> {
    commit(MetricsMutations.SET_LOADING_ORDER_PROJECT_METRICS_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getWorkOrderProjectMetrics(projectGuid);
      commit(MetricsMutations.SET_WORK_ORDER_PROJECT_METRICS, response.data);
    } catch (e) {
      commit(MetricsMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(MetricsMutations.SET_LOADING_ORDER_PROJECT_METRICS_STATE, false);
    }
  },

  async [MetricsActions.FETCH_ASSET_COUNTS_METRICS]({ commit }, projGuid: string): Promise<void> {
    commit(MetricsMutations.SET_LOADING_ASSET_COUNTS_METRICS, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getAssetCountsMetrics(projGuid);
      commit(MetricsMutations.SET_ASSET_COUNTS_METRICS, response.data);
    } catch (e) {
      commit(MetricsMutations.SET_ASSET_COUNTS_METRICS_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(MetricsMutations.SET_LOADING_ASSET_COUNTS_METRICS, false);
    }
  },

  async [MetricsActions.FETCH_PROJECT_OVERVIEW_METRICS](
    { commit }, projGuid: string,
  ): Promise<void> {
    commit(MetricsMutations.SET_LOADING_PROJECT_OVERVIEW_METRICS, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getProjectOverviewMetrics(projGuid);

      commit(MetricsMutations.SET_PROJECT_OVERVIEW_METRICS, response.data);
    } catch (e) {
      commit(MetricsMutations.SET_PROJECT_OVERVIEW_METRICS_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(MetricsMutations.SET_LOADING_PROJECT_OVERVIEW_METRICS, false);
    }
  },

  async [MetricsActions.FETCH_OVERVIEW_TOTAL_COUNTS](
    { commit }, projGuid: string,
  ): Promise<void> {
    commit(MetricsMutations.SET_LOADING_OVERVIEW_TOTAL_COUNTS, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getOverviewTotalcounts(projGuid);

      commit(MetricsMutations.SET_OVERVIEW_TOTAL_COUNTS, response.data);
    } catch (e) {
      commit(MetricsMutations.SET_OVERVIEW_TOTAL_COUNTS_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(MetricsMutations.SET_LOADING_OVERVIEW_TOTAL_COUNTS, false);
    }
  },

  async [MetricsActions.FETCH_CREW_COUNTS_METRICS](
    { commit },
    payload: FetchCrewCountsPayload,
  ): Promise<void> {
    commit(MetricsMutations.SET_CREW_COUNTS_LOADING, true);
    commit(MetricsMutations.SET_CREW_COUNTS_ERROR, undefined);
    commit(MetricsMutations.SET_CREW_COUNTS_DATA, undefined);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getCrewCounts(payload);

      commit(MetricsMutations.SET_CREW_COUNTS_DATA, response.data.data);
    } catch (e) {
      if (storeUtility.isDbEntryNotFoundError(e)) {
        commit(MetricsMutations.SET_CREW_COUNTS_ERROR, 'The specified project does not exist.');
        throw Error('Project does not exist.');
      }
      commit(MetricsMutations.SET_CREW_COUNTS_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(MetricsMutations.SET_CREW_COUNTS_LOADING, false);
    }
  },

  async [MetricsActions.FETCH_UPDATE_CREW_COUNT_METRIC](
    { commit },
    payload: FetchCrewCountsPayload,
  ): Promise<void> {
    commit(MetricsMutations.SET_UPDATE_CREW_COUNT_DATA, undefined);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getUpdateCrewCount(payload);

      commit(MetricsMutations.SET_UPDATE_CREW_COUNT_DATA, response.data.data);
    } catch (e) {
      throw Error('API Error occurred.');
    }
  },

  async [MetricsActions.FETCH_DATA_DELIVERY_METRICS](
    { commit },
    projectGuid: string,
  ): Promise<void> {
    commit(MetricsMutations.SET_LOADING_DATA_DELIVERY_PROJECT_METRICS_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getDataDeliveryProjectMetrics(projectGuid);

      commit(MetricsMutations.SET_DATA_DELIVERY_PROJECT_METRICS, response.data);
    } catch (e) {
      commit(MetricsMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(MetricsMutations.SET_LOADING_DATA_DELIVERY_PROJECT_METRICS_STATE, false);
    }
  },

  async [MetricsActions.FETCH_CREW_COUNTS_AVERAGES_METRICS](
    { commit },
    payload: FetchCrewCountsPayload,
  ): Promise<void> {
    commit(MetricsMutations.SET_CREW_COUNTS_AVERAGES_LOADING, true);
    commit(MetricsMutations.SET_CREW_COUNTS_AVERAGES_ERROR, undefined);
    commit(MetricsMutations.SET_CREW_COUNTS_AVERAGES_DATA, undefined);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getCrewCountByAverage(payload);

      commit(MetricsMutations.SET_CREW_COUNTS_AVERAGES_DATA, response.data.data);
    } catch (e) {
      if (storeUtility.isDbEntryNotFoundError(e)) {
        commit(MetricsMutations.SET_CREW_COUNTS_AVERAGES_ERROR, 'The specified project does not exist.');
        throw Error('Project does not exist.');
      }
      commit(MetricsMutations.SET_CREW_COUNTS_AVERAGES_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(MetricsMutations.SET_CREW_COUNTS_AVERAGES_LOADING, false);
    }
  },

  async [MetricsActions.FETCH_UPDATE_CREW_COUNT_AVERAGES_METRIC](
    { commit },
    payload: FetchCrewCountsPayload,
  ): Promise<void> {
    commit(MetricsMutations.SET_UPDATE_CREW_COUNT_AVERAGES_DATA, undefined);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getUpdateCrewCountAverages(payload);

      commit(MetricsMutations.SET_UPDATE_CREW_COUNT_AVERAGES_DATA, response.data.data);
    } catch (e) {
      throw Error('API Error occurred.');
    }
  },

  async [MetricsActions.FETCH_DASHBOARD_DEFECTS](
    { commit },
    payload: {projectGuid: string, scores: number[]},
  ): Promise<void> {
    commit(MetricsMutations.SET_DASHBOARD_DEFECT_LOADING, true);
    commit(MetricsMutations.SET_DASHBOARD_DEFECT_ERROR, undefined);
    commit(MetricsMutations.SET_DASHBOARD_DEFECT_DATA, undefined);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getDashboardDefects(payload);

      commit(MetricsMutations.SET_DASHBOARD_DEFECT_DATA, response.data.data);
    } catch (e) {
      if (storeUtility.isDbEntryNotFoundError(e)) {
        commit(MetricsMutations.SET_DASHBOARD_DEFECT_ERROR, 'The specified project does not exist.');
        throw Error('Project does not exist.');
      }
      commit(MetricsMutations.SET_DASHBOARD_DEFECT_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(MetricsMutations.SET_DASHBOARD_DEFECT_LOADING, false);
    }
  },

  async [MetricsActions.FETCH_NASSCO_INSPECTION_SCORES](
    { commit },
    payload: {
      projectGuid: string,
      assetsFiltered: string[]
    },
  ): Promise<void> {
    commit(MetricsMutations.SET_NASSCO_INSPECTION_SCORES_LOADING, true);
    commit(MetricsMutations.SET_NASSCO_INSPECTION_SCORES_DATA, undefined);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getNasscoInspectionScores(payload.projectGuid,
        payload.assetsFiltered);

      commit(MetricsMutations.SET_NASSCO_INSPECTION_SCORES_DATA, response.data);
      commit(MetricsMutations.SET_NASSCO_INSPECTION_SCORES_LOADING, false);
    } catch (e) {
      throw Error('API Error occurred.');
    }
  },

  async [MetricsActions.FETCH_PLATFORM_CREW_OVERVIEW](
    { commit },
    projectGuid: string,
  ): Promise<void> {
    commit(MetricsMutations.SET_LOADING_PLATFORM_CREW_OVERVIEW_STATE, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getPlatformCrewOverview(projectGuid);

      commit(MetricsMutations.SET_DATA_PLATFORM_CREW_OVERVIEW, response.data.data);
    } catch (e) {
      commit(MetricsMutations.SET_LOAD_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(MetricsMutations.SET_LOADING_PLATFORM_CREW_OVERVIEW_STATE, false);
    }
  },

  async [MetricsActions.FETCH_CLIENT_ACTION_ITEMS]({ commit }, projectGuid: string) {
    commit(MetricsMutations.SET_CLIENT_ACTION_ITEMS, undefined);
    commit(MetricsMutations.SET_LOADING_CLIENT_ACTION_ITEMS, true);
    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getClientActionItems(projectGuid);
      commit(MetricsMutations.SET_CLIENT_ACTION_ITEMS, response.data.data);
    } catch (e) {
      commit(MetricsMutations.SET_CLIENT_ACTION_ITEMS_ERROR, (e as Error).message);
      throw Error('API Error occurred.');
    } finally {
      commit(MetricsMutations.SET_LOADING_CLIENT_ACTION_ITEMS, false);
    }
  },

  async [MetricsActions.FETCH_CREW_COUNTS_BY_DAY]({ commit }, payload: string): Promise<void> {
    commit(MetricsMutations.SET_LOADING_CREW_COUNTS_BY_DAY, true);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getCrewCountsByDay(payload);

      commit(MetricsMutations.SET_CREW_COUNTS_BY_DAY, response.data);
    } catch (e) {
      commit(MetricsMutations.SET_CREW_COUNTS_BY_DAY_ERROR, (e as Error).message);
      throw Error('API Error');
    } finally {
      commit(MetricsMutations.SET_LOADING_CREW_COUNTS_BY_DAY, false);
    }
  },

  async [MetricsActions.FETCH_CREW_DATA_DELIVERY_PROJECT_METRICS]({ commit }, payload: {
    projectGuid: string,
    platformName: string,
  }): Promise<void> {
    commit(MetricsMutations.SET_CREW_DATA_DELIVERY_PROJECT_METRICS_LOADING, true);
    commit(MetricsMutations.SET_CREW_DATA_DELIVERY_PROJECT_METRICS_ERROR, undefined);
    commit(MetricsMutations.SET_CREW_DATA_DELIVERY_PROJECT_METRICS, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getCrewDataDeliveryProjectMetrics(payload);

      commit(MetricsMutations.SET_CREW_DATA_DELIVERY_PROJECT_METRICS, response.data.crews);
    } catch (e) {
      commit(MetricsMutations.SET_CREW_DATA_DELIVERY_PROJECT_METRICS_ERROR, (e as Error).message);
      throw Error('API Error');
    } finally {
      commit(MetricsMutations.SET_CREW_DATA_DELIVERY_PROJECT_METRICS_LOADING, false);
    }
  },

  async [MetricsActions.FETCH_CUSTOMER_DASHBOARD_WORK_ORDERS]({ commit }, payload: {
    projectGuid: string,
    startScheduledForString: string | undefined,
    endScheduledForString: string | undefined,
    startCompletedByString: string | undefined,
    endCompletedByString: string | undefined,
    }): Promise<void> {
    commit(MetricsMutations.SET_CUSTOMER_DASHBOARD_WORK_ORDERS_LOADING, true);
    commit(MetricsMutations.SET_CUSTOMER_DASHBOARD_WORK_ORDERS_ERROR, undefined);
    commit(MetricsMutations.SET_CUSTOMER_DASHBOARD_WORK_ORDERS, undefined);
    commit(MetricsMutations.SET_CUSTOMER_DATA_WORK_ORDER_DATA_LOADING, true);
    commit(MetricsMutations.SET_CUSTOMER_DATA_WORK_ORDER_DATA_ERROR, undefined);
    commit(MetricsMutations.SET_CUSTOMER_DATA_WORK_ORDER_DATA, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getCustomerDashboardWorkOrders(payload);

      commit(MetricsMutations.SET_CUSTOMER_DATA_WORK_ORDER_DATA,
        response.data.customerDataWorkOrderCountsDTO);
      commit(MetricsMutations.SET_CUSTOMER_DASHBOARD_WORK_ORDERS, response.data.customerWorkOrders);
    } catch (e) {
      commit(MetricsMutations.SET_CUSTOMER_DASHBOARD_WORK_ORDERS_ERROR, (e as Error).message);
      throw Error('API Error');
    } finally {
      commit(MetricsMutations.SET_CUSTOMER_DASHBOARD_WORK_ORDERS_LOADING, false);
      commit(MetricsMutations.SET_CUSTOMER_DATA_WORK_ORDER_DATA_LOADING, false);
    }
  },

  async [MetricsActions.FETCH_FILTER_DEFECTS]({ commit }, payload: string): Promise<void> {
    commit(MetricsMutations.SET_DASHBOARD_FILTER_DEFECTS_LOADING, true);
    commit(MetricsMutations.SET_DASHBOARD_FILTER_DEFECTS_ERROR, undefined);
    commit(MetricsMutations.SET_DASHBOARD_FILTER_DEFECTS, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.getCustomerDashboardFilterDefects(payload);
      commit(MetricsMutations.SET_DASHBOARD_FILTER_DEFECTS, response.data.data);
    } catch (e) {
      commit(MetricsMutations.SET_DASHBOARD_FILTER_DEFECTS_ERROR, (e as Error).message);
      throw Error('API Error');
    } finally {
      commit(MetricsMutations.SET_DASHBOARD_FILTER_DEFECTS_LOADING, false);
    }
  },

  async [MetricsActions.SET_SCHEDULED_DATE]({ commit }, payload: DateSelector): Promise<void> {
    commit(MetricsMutations.SET_SCHEDULED_DATE, payload);
  },

  async [MetricsActions.SET_COMPLETED_DATE]({ commit }, payload: DateSelector): Promise<void> {
    commit(MetricsMutations.SET_COMPLETED_DATE, payload);
  },

  async [MetricsActions.SET_SELECTED_RELEASE]({ commit }, payload: string): Promise<void> {
    commit(MetricsMutations.SET_SELECTED_RELEASE, payload);
  },

};
