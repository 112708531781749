






























































import { SnackBarActions } from '@/store/integritySnackBar/actions';
import { ReleasesActions } from '@/store/releases/actions';
import {
  Component, Prop, PropSync, Vue,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const releaseModule = namespace('releases');
const integritySnackBar = namespace('integritySnackBar');

@Component({})
export default class ExportReleaseDialog extends Vue {
  @integritySnackBar.Action(SnackBarActions.SHOW) showSnackBar;

  @releaseModule.Action(ReleasesActions.POST_SEND_CSV_EMAIL) postSendCsvEmail;

  @releaseModule.State('postCsvEmailLoading') postCsvEmailLoading: boolean;

  @releaseModule.State('postCsvEmailError') postCsvEmailError: string | undefined;

  @Prop({}) readonly releaseGuid: string;

  @Prop({}) readonly projectGuid: string;

  @PropSync('isOpen') showReleaseExportDialog: boolean;

  search = null;

  recipientsModel = [];

  get isSubmitDisabled(): boolean {
    return this.recipientsModel.length === 0;
  }

  isSearchEmail(email: string): boolean {
    if (email == null) {
      return false;
    }
    return (
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ) != null
    );
  }

  checkForNewRecipientsAndAdd(): void {
    for (let i = 0; i < this.recipientsModel.length; i += 1) {
      if (!this.isSearchEmail(this.recipientsModel[i])) {
        this.recipientsModel.splice(i, 1);
        break;
      }
    }
  }

  async onSubmit(): Promise<void> {
    await this.postSendCsvEmail({
      projectGuid: this.projectGuid,
      releaseGuid: this.releaseGuid,
      notificationEmails: this.recipientsModel,
    });

    let snackbarMsg = 'Release Successfully Exported';
    let snackBarColor = 'green';
    if (this.postCsvEmailError) {
      snackbarMsg = 'Unable to Export Release';
      snackBarColor = 'red';
    }

    this.showSnackBar({
      message: snackbarMsg,
      color: snackBarColor,
      showSnackBar: true,
    });

    this.closeDialog();
    this.reset();
  }

  reset(): void {
    this.recipientsModel = [];
  }

  closeDialog(): void {
    this.showReleaseExportDialog = false;
  }
}
