var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('IntegrityTable',{ref:"collectionSubTable",attrs:{"data":_vm.tableData,"headers":_vm.tableHeaders,"matchFilters":_vm.matchFilters,"blankMatchFilters":_vm.blankMatchFilters,"dataFillFunctions":_vm.fillTableDataFunctions,"canEdit":_vm.isEditMode,"height":'auto',"hide-default-footer":true,"loading":_vm.loading,"search":'',"sortBy":_vm.sortBy},on:{"update:data":function($event){_vm.tableData=$event},"update:matchFilters":function($event){_vm.matchFilters=$event},"update:match-filters":function($event){_vm.matchFilters=$event},"update:blankMatchFilters":function($event){_vm.blankMatchFilters=$event},"update:blank-match-filters":function($event){_vm.blankMatchFilters=$event},"inlineEdit":_vm.deploymentEdited,"info":_vm.handleInfo},scopedSlots:_vm._u([{key:"hasCustomerDeliverables",fn:function(ref){
var item = ref.item;
return [_c('a',{ref:"asset-request-btn",attrs:{"href":_vm.$router.resolve({
            name: 'deployments',
            params: _vm.getDeploymentPageParams(),
            query: _vm.getDeploymentsPageQuery(item)
          }).href}},[_c('v-btn',{staticClass:"info-btn",attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-information-outline")])],1)],1)]}}])}),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackbarColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
          var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"plain":"","small":""},on:{"click":function($event){_vm.showSnackbar = false}}},'v-btn',attrs,false),[_vm._v("Dismiss")])]}}]),model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }