























































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Component, Prop, PropSync } from 'vue-property-decorator';
import { UserPermission } from '@/store/userpermissions/types';
import { namespace } from 'vuex-class';
import { ProjectListing } from '@/store/project/types';
import { ProjectActions } from '@/store/project/actions';
import { getRoleGuid } from '@/auth/roles';
import AdminPanelMixin from '../AdminPanelMixin.vue';

const projectModule = namespace('project');

@Component({})
export default class AddProjectButton extends AdminPanelMixin {
  @PropSync('selectedUser') readonly synchedSelectedUser;

  @Prop() readonly currentUserRole: string;

  addProjectDialog = false as boolean;

  startDateMenu = false as boolean;

  endDateMenu = false as boolean;

  startDate = null;

  endDate = null;

  saveEditUserValid = false as boolean;

  selectedProjectName = '';

  mounted(): void {
    if (!this.organizationProjects) {
      this.fetchOrganizationProjectsData().catch(() => {
        this.$router.push({
          name: 'Error',
          params: {
            catchAll: 'Error',
            message:
              'There was an error retrieving your projects. Please try again later. If this issue persists, please contact support.',
          },
        });
      });
    }
  }

  saveEditUser(): void {
    const selectedProject = this.organizationProjects.find(
      (p) => p.name === this.selectedProjectName,
    );
    this.synchedSelectedUser.detailedUserData.role.push({
      projectGuid: selectedProject.guid,
      roleGuid: getRoleGuid(this.currentUserRole),
      startDate: this.startDate,
      endDate: this.endDate,
    });

    this.selectedProjectName = '';
    this.startDate = null;
    this.endDate = null;

    this.addProjectDialog = false;
    this.$forceUpdate();
  }

  get filteredProjects(): string[] {
    if (!this.organizationProjects) {
      return [];
    }
    const isRedzoneOrgMember = this.isUserRedzoneOrganizationMember();
    let projects = this.organizationProjects
      .filter(
        (proj) => !this.synchedSelectedUser.detailedUserData.role.find(
          (userProject) => userProject.projectGuid === proj.guid,
        ),
      );
    if (!isRedzoneOrgMember) {
      projects = projects
        .filter((proj) => this.synchedSelectedUser.detailedUserData.customerGuids.find(
          (customerGuid) => customerGuid === proj.customerGuid,
        ));
    }
    return projects.map((project) => project.name).sort();
  }

  /**
   * @returns true if the user has the permission ADMIN_USER_SECTION_DELETEUSER
   */
  get hasPermissionAdminDeleteUser(): boolean {
    return this.hasPermission(UserPermission.ADMIN_USER_SECTION_DELETEUSER);
  }

  /**
   * @returns true if the user has the permission ADMIN_USER_SECTION_EDIT_PROJECT_ACCESS_DATES
   */
  get hasPermissionAdminSetDateRangeForActivation(): boolean {
    return this.hasPermission(UserPermission.ADMIN_USER_SECTION_EDIT_PROJECT_ACCESS_DATES);
  }
}
