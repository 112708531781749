




























/* eslint-disable @typescript-eslint/no-explicit-any */
import { GenericDropDownData } from '@/common/types';
import {
  Component, Prop, VModel,
} from 'vue-property-decorator';
import ReportInputMixin from '../ReportInputMixin.vue';

@Component({
  components: {},
})
export default class ReportDropDownInput extends ReportInputMixin {
  @VModel() modelValue!: string | GenericDropDownData | undefined;

  /**
   * The label text displayed next to the date picker input.
   * @type {string | undefined}
   */
  @Prop() label: string | undefined;

  /**
   * List of options for the dropdown.
   * @type {string[] | undefined}
   */
  @Prop() items: Array<string | GenericDropDownData> | undefined;

  get itemValueKey(): string | undefined {
    return this.items != null
      && this.items.length > 0
      && (this.items[0] as unknown as GenericDropDownData)?.guid != null
      ? 'guid'
      : undefined;
  }

  /**
   * @description Get the display text of the modelValue
   * @returns The text of the modelValue
   */
  get modelValueText(): string {
    if (this.itemValueKey != null) {
      const foundItem = this.items
        .find((item) => (item as GenericDropDownData).guid === this.modelValue);
      if (foundItem != null) {
        return (foundItem as GenericDropDownData).text;
      }
    }
    return this.modelValue as string;
  }
}
