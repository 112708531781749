var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"retain-focus":false,"max-width":"80%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","small":"","color":"#0c6599","id":"permissions-btn"},on:{"click":function($event){return _vm.openDialog()}}},'v-btn',attrs,false),on),[_vm._v(" View Permissions ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-data-table',{ref:"table",staticClass:"matrixTable",attrs:{"headers":_vm.headers,"items":_vm.tableData,"hide-default-footer":"","disable-pagination":"","item-value":"name","height":"80vh","group-by":"category","fixed-header":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"tableTitle"},[_vm._v(" Roles and Permissions "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]},proxy:true},{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('td',{staticClass:"headerCell",attrs:{"colspan":4}},[_c('div',{staticClass:"headerTitle"},[_c('v-icon',{on:{"click":function($event){toggle();
                _vm.timeoutStickyColumns();}}},[_vm._v(" "+_vm._s(isOpen ? "mdi-chevron-up" : "mdi-chevron-down")+" ")]),_vm._v(" "+_vm._s(group)+" ")],1)]),_c('td',{attrs:{"colspan":headers.length - 4}})]}},_vm._l((_vm.roles),function(roleHeader){return {key:("item." + roleHeader),fn:function(ref){
                var item = ref.item;
return [_c('div',{key:("child_" + roleHeader)},[_c('v-chip',{attrs:{"color":_vm.chipColor(item, roleHeader),"small":"","text-color":"white"}})],1)]}}})],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }