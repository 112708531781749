/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutationTree } from 'vuex';
import {
  WorkOrderMetrics,
  MetricsState,
  GradeMetrics,
  ProjectTotalsObject,
  WorkOrderProjectMetrics,
  AssetCountsMetrics,
  ProjectOverviewMetrics,
  OverviewTotalCounts,
  DataDeliveryProjectMetrics,
  CrewCountsWithAverages,
  CrewCountsDTO,
  DashboardDefectsByAsset,
  NasscoCodeCategoryCounts,
  NasscoScoreMetrics,
  ProjectCrewPlatform,
  ClientActionItem,
  CrewCountsByDay,
  CrewDataDeliveryProjectMetrics,
  CustomerDashboardWorkOrder,
  CustomerDataWorkOrderCounts,
  DateSelector,
  ReleaseGuidName,
  FilterDefects,
  DefectsData,
} from './types';

// eslint-disable-next-line no-shadow
export enum MetricsMutations {
    SET_LOADING_WORK_ORDERS_STATE = 'SET_LOADING_WORK_ORDERS_STATE',
    SET_WORK_ORDER_METRICS = 'SET_WORK_ORDER_METRICS',
    SET_LOADING_STRUCT_GRADE_STATE = 'SET_LOADING_STRUCT_GRADE_STATE',
    SET_STRUCT_GRADE_METRICS = 'SET_STRUCT_GRADE_METRICS',
    SET_ASSIGNMENT_METRICS = 'SET_ASSIGNMENT_METRICS',
    SET_LOADING_ASSIGNMENT_STATE = 'SET_ASSIGNMENT_STATE',
    SET_OM_LOADING_STATE = 'SET_OM_LOADING_STATE',
    SET_OM_DEFECT_METRICS = 'SET_OM_DEFECT_METRICS',
    SET_LOADING_DATA_DELIVERY_PROJECT_METRICS_STATE = 'SET_LOADING_DATA_DELIVERY_PROJECT_METRICS_STATE',
    SET_DATA_DELIVERY_PROJECT_METRICS = 'SET_DATA_DELIVERY_PROJECT_METRICS',
    SET_LOADING_ORDER_PROJECT_METRICS_STATE = 'SET_LOADING_ORDER_PROJECT_METRICS_STATE',
    SET_WORK_ORDER_PROJECT_METRICS = 'SET_WORK_ORDER_PROJECT_METRICS',
    SET_LOAD_ERROR = 'SET_LOAD_ERROR',
    SET_PROJECT_TOTALS_DATA = 'SET_PROJECT_TOTALS_DATA',
    SET_PROJECT_TOTALS_ERROR = 'SET_PROJECT_TOTALS_ERROR',
    SET_PROJECT_TOTALS_LOADING = 'SET_PROJECT_TOTALS_LOADING',
    SET_LOADING_ASSET_COUNTS_METRICS = 'SET_LOADING_ASSET_COUNTS_METRICS',
    SET_ASSET_COUNTS_METRICS = 'SET_ASSET_COUNTS_METRICS',
    SET_ASSET_COUNTS_METRICS_ERROR = 'SET_ASSET_COUNTS_METRICS_ERROR',
    SET_LOADING_PROJECT_OVERVIEW_METRICS = 'SET_LOADING_PROJECT_OVERVIEW_METRICS',
    SET_PROJECT_OVERVIEW_METRICS = 'SET_PROJECT_OVERVIEW_METRICS',
    SET_PROJECT_OVERVIEW_METRICS_ERROR = 'SET_PROJECT_OVERVIEW_METRICS_ERROR',
    SET_LOADING_OVERVIEW_TOTAL_COUNTS = 'SET_LOADING_OVERVIEW_TOTAL_COUNTS',
    SET_OVERVIEW_TOTAL_COUNTS = 'SET_OVERVIEW_TOTAL_COUNTS',
    SET_OVERVIEW_TOTAL_COUNTS_ERROR = 'SET_OVERVIEW_TOTAL_COUNTS_ERROR',
    SET_CREW_COUNTS_DATA = 'SET_CREW_COUNTS_DATA',
    SET_CREW_COUNTS_ERROR = 'SET_CREW_COUNTS_ERROR',
    SET_CREW_COUNTS_LOADING = 'SET_CREW_COUNTS_LOADING',
    SET_UPDATE_CREW_COUNT_DATA = 'SET_UPDATE_CREW_COUNT_DATA',
    SET_CREW_COUNTS_AVERAGES_DATA = 'SET_CREW_COUNTS_AVERAGES_DATA',
    SET_CREW_COUNTS_AVERAGES_ERROR = 'SET_CREW_COUNTS_AVERAGES_ERROR',
    SET_CREW_COUNTS_AVERAGES_LOADING = 'SET_CREW_COUNTS_AVERAGES_LOADING',
    SET_UPDATE_CREW_COUNT_AVERAGES_DATA = 'SET_UPDATE_CREW_COUNT_AVERAGES_DATA',
    SET_DASHBOARD_DEFECT_DATA = 'SET_DASHBOARD_DEFECT_DATA',
    SET_DASHBOARD_DEFECT_LOADING = 'SET_DASHBOARD_DEFECT_LOADING',
    SET_DASHBOARD_DEFECT_ERROR = 'SET_DASHBOARD_DEFECT_ERROR',

    SET_PROJECT_DEFECTS_DATA = 'SET_PROJECT_DEFECTS_DATA',
    SET_PROJECT_DEFECTS_ERROR = 'SET_PROJECT_DEFECTS_ERROR',
    SET_PROJECT_DEFECTS_LOADING = 'SET_PROJECT_DEFECTS_LOADING',
    SET_NASSCO_INSPECTION_SCORES_LOADING = 'SET_NASSCO_INSPECTION_SCORES_LOADING',
    SET_NASSCO_INSPECTION_SCORES_DATA = 'SET_NASSCO_INSPECTION_SCORESS_DATA',
    SET_LOADING_PLATFORM_CREW_OVERVIEW_STATE = 'SET_LOADING_PLATFORM_CREW_OVERVIEW_STATE',
    SET_DATA_PLATFORM_CREW_OVERVIEW = 'SET_DATA_PLATFORM_CREW_OVERVIEW',
    SET_LOADING_CLIENT_ACTION_ITEMS = 'SET_LOADING_CLIENT_ACTION_ITEMS',
    SET_CLIENT_ACTION_ITEMS = 'SET_CLIENT_ACTION_ITEMS',
    SET_CLIENT_ACTION_ITEMS_ERROR = 'SET_CLIENT_ACTION_ITEMS_ERROR',
    SET_LOADING_CREW_COUNTS_BY_DAY = 'SET_LOADING_CREW_COUNTS_BY_DAY',
    SET_CREW_COUNTS_BY_DAY = 'SET_CREW_COUNTS_BY_DAY',
    SET_CREW_COUNTS_BY_DAY_ERROR = 'SET_CREW_COUNTS_BY_DAY_ERROR',
    SET_CREW_DATA_DELIVERY_PROJECT_METRICS = 'SET_CREW_DATA_DELIVERY_PROJECT_METRICS',
    SET_CREW_DATA_DELIVERY_PROJECT_METRICS_LOADING = 'SET_CREW_DATA_DELIVERY_PROJECT_METRICS_LOADING',
    SET_CREW_DATA_DELIVERY_PROJECT_METRICS_ERROR = 'SET_CREW_DATA_DELIVERY_PROJECT_METRICS_ERROR',
    SET_CUSTOMER_DASHBOARD_WORK_ORDERS_LOADING = 'SET_CUSTOMER_DASHBOARD_WORK_ORDERS_LOADING',
    SET_CUSTOMER_DASHBOARD_WORK_ORDERS_ERROR = 'SET_CUSTOMER_DASHBOARD_WORK_ORDERS_ERROR',
    SET_CUSTOMER_DASHBOARD_WORK_ORDERS = 'SET_CUSTOMER_DASHBOARD_WORK_ORDERS',
    SET_CUSTOMER_DATA_WORK_ORDER_DATA = 'SET_CUSTOMER_DATA_WORK_ORDER_DATA',
    SET_CUSTOMER_DATA_WORK_ORDER_DATA_LOADING = 'SET_CUSTOMER_DATA_WORK_ORDER_DATA_LOADING',
    SET_CUSTOMER_DATA_WORK_ORDER_DATA_ERROR = 'SET_CUSTOMER_DATA_WORK_ORDER_DATA_ERROR',
    SET_DASHBOARD_FILTER_DEFECTS_LOADING = 'SET_DASHBOARD_FILTER_DEFECTS_LOADING',
    SET_DASHBOARD_FILTER_DEFECTS_ERROR = 'SET_DASHBOARD_FILTER_DEFECTS_ERROR',
    SET_DASHBOARD_FILTER_DEFECTS = 'SET_DASHBOARD_FILTER_DEFECTS',
    SET_SCHEDULED_DATE = 'SET_SCHEDULED_DATE',
    SET_COMPLETED_DATE = 'SET_COMPLETED_DATE',
    SET_SELECTED_RELEASE = 'SET_SELECTED_RELEASE',
    SET_DEFECTS_DATA = 'SET_DEFECTS_DATA',
    }

export const mutations : MutationTree<MetricsState> = {
  [MetricsMutations.SET_LOADING_WORK_ORDERS_STATE](state, payload: boolean) {
    state.loadingWorkOrders = payload;
  },

  [MetricsMutations.SET_WORK_ORDER_METRICS](state, payload: WorkOrderMetrics) {
    state.workOrderMetrics = payload;
  },

  [MetricsMutations.SET_LOADING_STRUCT_GRADE_STATE](state, payload: boolean) {
    state.loadingStructGrade = payload;
  },

  [MetricsMutations.SET_STRUCT_GRADE_METRICS](state, payload: any) {
    state.structGradeMetrics = payload;
  },

  [MetricsMutations.SET_LOADING_ASSIGNMENT_STATE](state, payload: boolean) {
    state.loadingAssignments = payload;
  },

  [MetricsMutations.SET_ASSIGNMENT_METRICS](state, payload: any) {
    state.assignmentMetrics = payload;
  },

  [MetricsMutations.SET_OM_LOADING_STATE](state, payload: boolean) {
    state.loadingOmMetrics = payload;
  },

  [MetricsMutations.SET_OM_DEFECT_METRICS](state, payload: GradeMetrics) {
    state.omDefectMetrics = payload;
  },

  [MetricsMutations.SET_LOADING_DATA_DELIVERY_PROJECT_METRICS_STATE](state, payload: boolean) {
    state.loadingDataDeliveryProjectMetrics = payload;
  },

  [MetricsMutations.SET_DATA_DELIVERY_PROJECT_METRICS](state, payload: DataDeliveryProjectMetrics) {
    state.dataDeliveryProjectMetrics = payload;
  },

  [MetricsMutations.SET_LOADING_ORDER_PROJECT_METRICS_STATE](state, payload: boolean) {
    state.loadingWorkOrderProjectMetrics = payload;
  },

  [MetricsMutations.SET_WORK_ORDER_PROJECT_METRICS](state, payload: WorkOrderProjectMetrics) {
    state.workOrderProjectMetrics = payload;
  },

  [MetricsMutations.SET_LOAD_ERROR](state, payload: string) {
    state.loadError = payload;
  },

  [MetricsMutations.SET_PROJECT_TOTALS_DATA](state, payload: ProjectTotalsObject[]) {
    state.projectTotalsData = payload;
  },

  [MetricsMutations.SET_PROJECT_TOTALS_ERROR](state, payload: string) {
    state.projectTotalsError = payload;
  },

  [MetricsMutations.SET_PROJECT_TOTALS_LOADING](state, payload: boolean) {
    state.projectTotalsLoading = payload;
  },

  [MetricsMutations.SET_LOADING_ASSET_COUNTS_METRICS](state, payload: boolean) {
    state.loadingAssetCountsMetrics = payload;
  },

  [MetricsMutations.SET_ASSET_COUNTS_METRICS](state, payload: AssetCountsMetrics) {
    state.assetCountsMetrics = payload;
  },

  [MetricsMutations.SET_ASSET_COUNTS_METRICS_ERROR](state, payload: string) {
    state.assetCountsMetricsError = payload;
  },

  [MetricsMutations.SET_LOADING_PROJECT_OVERVIEW_METRICS](state, payload: boolean) {
    state.loadingProjectOverviewMetrics = payload;
  },

  [MetricsMutations.SET_PROJECT_OVERVIEW_METRICS](state, payload: ProjectOverviewMetrics) {
    state.projectOverviewMetrics = payload;
  },

  [MetricsMutations.SET_PROJECT_OVERVIEW_METRICS_ERROR](state, payload: string) {
    state.projectOverviewMetricsError = payload;
  },

  [MetricsMutations.SET_LOADING_OVERVIEW_TOTAL_COUNTS](state, payload: boolean) {
    state.loadingOverviewTotalCounts = payload;
  },

  [MetricsMutations.SET_OVERVIEW_TOTAL_COUNTS](state, payload: OverviewTotalCounts) {
    state.overviewTotalCounts = payload;
  },

  [MetricsMutations.SET_OVERVIEW_TOTAL_COUNTS_ERROR](state, payload: string) {
    state.overviewTotalCountsError = payload;
  },

  [MetricsMutations.SET_CREW_COUNTS_DATA](state, payload: CrewCountsDTO[]) {
    state.crewCountsData = payload;
  },

  [MetricsMutations.SET_CREW_COUNTS_ERROR](state, payload: string) {
    state.crewCountsError = payload;
  },

  [MetricsMutations.SET_CREW_COUNTS_LOADING](state, payload: boolean) {
    state.crewCountsLoading = payload;
  },

  [MetricsMutations.SET_UPDATE_CREW_COUNT_DATA](state, payload: CrewCountsDTO) {
    state.crewCountsUpdateData = payload;
  },

  [MetricsMutations.SET_CREW_COUNTS_AVERAGES_DATA](state, payload: CrewCountsWithAverages[]) {
    state.crewCountsAveragesData = payload;
  },

  [MetricsMutations.SET_CREW_COUNTS_AVERAGES_ERROR](state, payload: string) {
    state.crewCountsAveragesError = payload;
  },

  [MetricsMutations.SET_CREW_COUNTS_AVERAGES_LOADING](state, payload: boolean) {
    state.crewCountsAveragesLoading = payload;
  },

  [MetricsMutations.SET_UPDATE_CREW_COUNT_AVERAGES_DATA](state, payload: CrewCountsWithAverages) {
    state.crewCountAveragesUpdateData = payload;
  },

  [MetricsMutations.SET_DASHBOARD_DEFECT_DATA](state,
    payload: DashboardDefectsByAsset[] | undefined) {
    state.dashboardDefectData = payload;
  },

  [MetricsMutations.SET_DASHBOARD_DEFECT_LOADING](state, payload: boolean) {
    state.dashboardDefectLoading = payload;
  },

  [MetricsMutations.SET_DASHBOARD_DEFECT_ERROR](state, payload: string | undefined) {
    state.dashboardDefectError = payload;
  },

  [MetricsMutations.SET_PROJECT_DEFECTS_DATA](state, payload: NasscoCodeCategoryCounts) {
    state.projectDefectsData = payload;
  },

  [MetricsMutations.SET_PROJECT_DEFECTS_ERROR](state, payload: string) {
    state.projectDefectsError = payload;
  },

  [MetricsMutations.SET_PROJECT_DEFECTS_LOADING](state, payload: boolean) {
    state.projectDefectsLoading = payload;
  },

  [MetricsMutations.SET_DEFECTS_DATA](state, payload: DefectsData[]) {
    state.defectsData = payload;
  },

  [MetricsMutations.SET_NASSCO_INSPECTION_SCORES_LOADING](state, payload: boolean) {
    state.nasscoInspectionScoresLoading = payload;
  },

  [MetricsMutations.SET_NASSCO_INSPECTION_SCORES_DATA](state, payload: NasscoScoreMetrics) {
    state.nasscoInspectionScoresData = payload;
  },

  [MetricsMutations.SET_LOADING_PLATFORM_CREW_OVERVIEW_STATE](state, payload: boolean) {
    state.platformCrewCountsLoading = payload;
  },

  [MetricsMutations.SET_DATA_PLATFORM_CREW_OVERVIEW](state, payload: ProjectCrewPlatform[]) {
    state.platformCrewCounts = payload;
  },

  [MetricsMutations.SET_LOADING_CLIENT_ACTION_ITEMS](state, payload: boolean) {
    state.loadingClientActionItems = payload;
  },

  [MetricsMutations.SET_CLIENT_ACTION_ITEMS](state, payload: ClientActionItem[]) {
    state.clientActionItems = payload;
  },

  [MetricsMutations.SET_CLIENT_ACTION_ITEMS_ERROR](state, payload: string) {
    state.clientActionItemsError = payload;
  },

  [MetricsMutations.SET_LOADING_CREW_COUNTS_BY_DAY](state, payload: boolean) {
    state.loadingCrewCountsByDay = payload;
  },

  [MetricsMutations.SET_CREW_COUNTS_BY_DAY](state, payload: CrewCountsByDay) {
    state.crewCountsByDay = payload;
  },

  [MetricsMutations.SET_CREW_COUNTS_BY_DAY_ERROR](state, payload: string) {
    state.crewCountsByDayError = payload;
  },

  [MetricsMutations.SET_CREW_DATA_DELIVERY_PROJECT_METRICS](state,
    payload: CrewDataDeliveryProjectMetrics[]) {
    state.crewDataDeliveryProjectMetrics = payload;
  },

  [MetricsMutations.SET_CREW_DATA_DELIVERY_PROJECT_METRICS_LOADING](state, payload: boolean) {
    state.crewDataDeliveryProjectMetricsLoading = payload;
  },

  [MetricsMutations.SET_CREW_DATA_DELIVERY_PROJECT_METRICS_ERROR](state, payload: string) {
    state.crewDataDeliveryProjectMetricsError = payload;
  },

  [MetricsMutations.SET_CUSTOMER_DASHBOARD_WORK_ORDERS_LOADING](state, payload: boolean) {
    state.customerDashboardWorkOrdersLoading = payload;
  },

  [MetricsMutations.SET_CUSTOMER_DASHBOARD_WORK_ORDERS_ERROR](state, payload: string) {
    state.customerDashboardWorkOrdersError = payload;
  },

  [MetricsMutations.SET_CUSTOMER_DASHBOARD_WORK_ORDERS](state,
    payload: CustomerDashboardWorkOrder[]) {
    state.customerDashboardWorkOrders = payload;
  },
  [MetricsMutations.SET_CUSTOMER_DATA_WORK_ORDER_DATA](state,
    payload: CustomerDataWorkOrderCounts) {
    state.customerDataWorkOrderCounts = payload;
  },

  [MetricsMutations.SET_CUSTOMER_DATA_WORK_ORDER_DATA_LOADING](state, payload: boolean) {
    state.customerDataWorkOrderLoading = payload;
  },

  [MetricsMutations.SET_CUSTOMER_DATA_WORK_ORDER_DATA_ERROR](state, payload: string) {
    state.customerDataWorkOrderError = payload;
  },
  [MetricsMutations.SET_DASHBOARD_FILTER_DEFECTS](state,
    payload: FilterDefects[]) {
    state.dashboardFilterDefects = payload;
  },

  [MetricsMutations.SET_DASHBOARD_FILTER_DEFECTS_LOADING](state, payload: boolean) {
    state.dashboardFilterDefectsLoading = payload;
  },

  [MetricsMutations.SET_DASHBOARD_FILTER_DEFECTS_ERROR](state, payload: string) {
    state.dashboardFilterDefectsError = payload;
  },

  [MetricsMutations.SET_SCHEDULED_DATE](state, payload: DateSelector) {
    state.scheduledDate = payload;
  },

  [MetricsMutations.SET_COMPLETED_DATE](state, payload: DateSelector) {
    state.completedDate = payload;
  },

  [MetricsMutations.SET_SELECTED_RELEASE](state, payload: ReleaseGuidName) {
    state.selectedRelease = payload;
  },
};
