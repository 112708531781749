













/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Component, PropSync } from 'vue-property-decorator';
import AdminPanelMixin, { EditUserData } from '../AdminPanelMixin.vue';
import ProjectList from '../User/ProjectList.vue';

@Component({
  components: {
    ProjectList,
  },
})
export default class EditUserProject extends AdminPanelMixin {
  @PropSync('selectedUser') readonly synchedSelectedUser: EditUserData;

  selectedProjectName = '';

  mounted(): void {
    this.fetchOrganizationProjectsData().catch(() => {
      this.$router.push({
        name: 'Error',
        params: {
          catchAll: 'Error',
          message:
              'There was an error retrieving your projects. Please try again later. If this issue persists, please contact support.',
        },
      });
    });
  }
}
