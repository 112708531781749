




























































































































































































import { SnackBarActions } from '@/store/integritySnackBar/actions';
import { ReleasesActions } from '@/store/releases/actions';
import { ReleaseOverviewObject } from '@/store/releases/types';
import { ScopeHistoryActions } from '@/store/scopeHistory/actions';
import { ScopeHistoryMutations } from '@/store/scopeHistory/mutations';
import {
  Component, Prop, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const scopeHistoryModule = namespace('scopeHistory');
const releaseModule = namespace('releases');
const integritySnackBar = namespace('integritySnackBar');

@Component({})
export default class ReleaseEdit extends Vue {
  @scopeHistoryModule.Action(
    ScopeHistoryActions.FETCH_CUSTOMER_PREP_RELEASE_EMAILS,
  )
  fetchCustomerPrepReleasesEmails;

  @scopeHistoryModule.Mutation(
    ScopeHistoryMutations.SET_CUSTOMER_PREP_RELEASE_EMAILS,
  )
  setCustomerPrepReleasesEmails;

  @releaseModule.Action(ReleasesActions.FETCH_RELEASES_OVERVIEW) fetchAssignmentsData;

  @releaseModule.Action(ReleasesActions.GET_INSPECTION_RELEASES) getInspectionReleases;

  @releaseModule.Action(ReleasesActions.FETCH_RELEASE_TABLE_DATA) fetchReleaseTableData;

  @releaseModule.Action(ReleasesActions.POST_SEND_EXPORT_EMAIL)
  postSendExportEmail;

  @releaseModule.Action(ReleasesActions.POST_SEND_NOTIFICATION_EMAIL)
  postSendNotificationEmail;

  @releaseModule.Action(ReleasesActions.PATCH_RELEASE_INSPECTION)
  patchReleaseInspection;

  @integritySnackBar.Action(SnackBarActions.SHOW) showSnackBar;

  @releaseModule.State('patchReleaseLoading') patchReleaseLoading: boolean;

  @releaseModule.State('notificationEmailLoading')
  notificationEmailLoading: boolean;

  @releaseModule.State('patchReleaseError') patchReleaseError: string | undefined;

  @releaseModule.State('customerPrepReleaseEmails') recipients: string[];

  @Prop({}) readonly projectGuid: string;

  @PropSync('isOpen') showReleaseEditDialog: boolean;

  search = null;

  @Prop({}) readonly editReleaseModel: ReleaseOverviewObject;

  hasTransmittalLetter = true;

  dateShippedMenu = false as boolean;

  recipientsModel = [];

  exportCheckboxModel = true;

  get exportCheckboxText(): string {
    return this.editReleaseModel.assetType === 'Manhole' ? 'MACP' : 'PACP';
  }

  get isSubmitDisabled(): boolean {
    return (
      this.editReleaseModel.releaseName == null
      || this.editReleaseModel.releaseName === ''
      || !this.hasTransmittalLetter
    );
  }

  @Watch('isOpen')
  async onIsOpenChange(): Promise<void> {
    await this.fetchEmailsAndSet();
    this.hasTransmittalLetter = !!this.editReleaseModel.transmittalFileName;
  }

  async mounted(): Promise<void> {
    this.fetchEmailsAndSet();
  }

  async fetchEmailsAndSet(): Promise<void> {
    this.fetchCustomerPrepReleasesEmails(this.projectGuid).then(() => {
      if (this.recipients) this.recipientsModel = [...this.recipients];
    });
  }

  isSearchEmail(email: string): boolean {
    if (email == null) {
      return false;
    }
    return (
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ) != null
    );
  }

  setTransmittalFile(): void {
    const ref = this.$refs.transmittalLetter as HTMLInputElement;
    this.hasTransmittalLetter = ref?.files != null && ref.files.length > 0;
    if (this.hasTransmittalLetter) {
      this.editReleaseModel.transmittalFileName = ref.files[0].name;
    }
  }

  async onSubmit(): Promise<void> {
    const ref = this.$refs.transmittalLetter as HTMLInputElement;
    const postReleaseInspectionObject = {
      releaseGuid: this.editReleaseModel.releaseGuid,
      projectGuid: this.projectGuid,
      releaseName: this.editReleaseModel.releaseName,
      dateShipped: this.editReleaseModel.dateShipped,
      trackingInformation: this.editReleaseModel.trackingInformation,
      shouldExport: this.exportCheckboxModel,
      transmittalLetter: ref.files[0],
      notificationRecipients: this.recipientsModel,
      userEmail: this.$auth.user.email,
    };
    await this.patchReleaseInspection(postReleaseInspectionObject);

    const snackbarMsg = this.patchReleaseError ? 'Unable to edit release' : 'Release successfully edited';
    this.showSnackBar({
      message: snackbarMsg,
      color: this.patchReleaseError ? 'red' : 'green',
      showSnackBar: true,
    });

    this.fetchAssignmentsData(this.projectGuid);
    this.getInspectionReleases(this.projectGuid);
    this.fetchReleaseTableData(this.projectGuid);

    this.closeDialog();
    this.reset();
  }

  checkForNewRecipientsAndAdd(): void {
    for (let i = 0; i < this.recipientsModel.length; i += 1) {
      if (!this.isSearchEmail(this.recipientsModel[i])) {
        this.recipientsModel.splice(i, 1);
        break;
      }
      if (!this.recipients.includes(this.recipientsModel[i])) {
        const tempRecipients = [...this.recipients];
        tempRecipients.push(this.recipientsModel[i]);
        this.setCustomerPrepReleasesEmails(tempRecipients);
        break;
      }
    }
  }

  onSelectFile(): void {
    const ref = this.$refs.transmittalLetter as HTMLInputElement;
    if (ref != null) {
      ref.click();
    }
  }

  reset(): void {
    this.editReleaseModel.releaseName = '';
    this.exportCheckboxModel = true;
    this.fetchEmailsAndSet();

    const ref = this.$refs.transmittalLetter as HTMLInputElement;
    ref.value = null;
  }

  closeDialog(): void {
    this.showReleaseEditDialog = false;
  }

  async downloadExport(): Promise<void> {
    await this.postSendExportEmail({
      releaseGuid: this.editReleaseModel.releaseGuid,
      userEmail: this.$auth.user.email,
    });
    // eslint-disable-next-line no-alert
    alert(
      'Your export request has been received. The download links will be sent shortly to the email associated with this account.',
    );
  }

  async resendNotification(): Promise<void> {
    await this.postSendNotificationEmail({
      releaseGuid: this.editReleaseModel.releaseGuid,
      notificationEmails: this.recipientsModel,
    });

    this.showSnackBar({
      message: 'Email send to notification recipients',
      color: 'green',
      showSnackBar: true,
    });
  }
}
