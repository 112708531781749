
import UserPermissionsMixin from '@/components/UserPermissions/UserPermissionsMixin.vue';
import { namespace } from 'vuex-class';
import { UserActions } from '@/store/users/actions';
import { DetailedUserData, UserManagement } from '@/store/users/types';
import { AdminActions } from '@/store/admin/actions';
import { OrganizationActions } from '@/store/organizations/actions';
import { OrganizationData } from '@/store/organizations/types';
import { CustomerActions } from '@/store/customers/actions';
import { CustomerData } from '@/store/customers/types';
import { Component } from 'vue-property-decorator';
import { REDZONE_ORGANIZATION_GUID } from '@/common/Constants';
import { ProjectActions } from '@/store/project/actions';
import { ProjectListing } from '@/store/project/types';
import { DetailedUser } from '@/auth/auth0Types';

const userModule = namespace('users');
const adminModule = namespace('admin');
const organizationModule = namespace('organizations');
const customerModule = namespace('customers');
const projectModule = namespace('project');

export interface EditUserData
{
  detailedUserData: DetailedUserData,
  auth0Id: string,
}

export interface UserTableData
{
  role: string[] | string,
  auth0Id: string,
  detailedUserData: DetailedUserData,
  detailedUser: DetailedUser,
  isExpanded: boolean
  visible: boolean
  resetDialog: boolean,
  guid?: string,
  fullName?: string
}

@Component({})
export default class AdminPanelMixin extends UserPermissionsMixin {
  @userModule.Action(UserActions.FETCH_ALL_DETAILED_USER_DATA)
  fetchAllDetailedUserData;

  @userModule.State('allDetailedUserData') allUsers: DetailedUserData[];

  @userModule.State('inactiveDetailedUserData')
  inactiveDetailedUserData: DetailedUserData[];

  @userModule.State('userManagements') allUserManagements: UserManagement[];

  @userModule.State('loading') usersLoading;

  @userModule.Action(UserActions.FETCH_INACTIVE_DETAILED_USER_DATA)
  fetchInactiveDetailedUserData;

  @userModule.Action(UserActions.POST_CUSTOMER_USER) postCustomerUser;

  @userModule.Action(UserActions.POST_CUSTOMER_ORGANIZATION) postOrganizationUser;

  @userModule.Action(UserActions.POST_USER_MANAGEMENT) postUserManagement;

  @userModule.Action(UserActions.FETCH_ALL_USER_MANAGEMENTS) fetchAllUserManagements;

  @userModule.Action(UserActions.DELETE_USER_MANAGEMENT) deleteUserManagement;

  @adminModule.Action(AdminActions.PATCH_USER) patchUser;

  @adminModule.Action(AdminActions.DELETE_USER) deleteUserAction;

  @adminModule.Action(AdminActions.POST_NEW_USER) postUserData;

  /**
   * @description Function used to update the user table
   */
  @adminModule.Action(AdminActions.UPDATE_ALL_USERS) updateAllManagedUsers;

  @adminModule.State('response') adminResponse;

  @adminModule.State('loadError') adminLoadError;

  @adminModule.State('loading') adminLoading;

  /**
   * @description UUID that, when changed, will reload the manageusers component table
   */
  @adminModule.State('updateUserKey') updateUserKey;

  @organizationModule.Action(OrganizationActions.FETCH_ORGANIZATION_DATA)
  fetchOrganizationData;

  @organizationModule.Action(OrganizationActions.POST_ORGANIZATION_DATA)
  postOrganizationData;

  @organizationModule.Action(OrganizationActions.PATCH_ORGANIZATION_DATA)
  patchOrganizationData;

  @organizationModule.Action(OrganizationActions.DELETE_ORGANIZATION_DATA)
  deleteOrganizationData;

  @organizationModule.State('getLoading') organizationsGetLoading: boolean;

  @organizationModule.State('organizationData') organizationDataList:
    | OrganizationData[]
    | undefined;

  @organizationModule.State('postLoading') organizationsPostLoading: boolean;

  @organizationModule.State('postorganizationObject') postorganizationObject:
    | OrganizationData
    | undefined;

  @organizationModule.State('patchLoading') organizationsPatchLoading: boolean;

  @organizationModule.State('patchorganizationObject') patchorganizationObject:
    | OrganizationData
    | undefined;

  @organizationModule.State('deleteLoading')
  organizationsDeleteLoading: boolean;

  @customerModule.Action(CustomerActions.FETCH_CUSTOMER_DATA)
  fetchCustomerData;

  @customerModule.Action(CustomerActions.POST_CUSTOMER_DATA)
  postCustomerData;

  @customerModule.Action(CustomerActions.PATCH_CUSTOMER_DATA)
  patchCustomerData;

  @customerModule.Action(CustomerActions.DELETE_CUSTOMER_DATA)
  deleteCustomerData;

  @customerModule.State('getLoading') customersGetLoading: boolean;

  @customerModule.State('customerData') customerDataList:
    | CustomerData[]
    | undefined;

  @customerModule.State('postLoading') customersPostLoading: boolean;

  @customerModule.State('postcustomerObject') postcustomerObject:
    | CustomerData
    | undefined;

  @customerModule.State('patchLoading') customersPatchLoading: boolean;

  @customerModule.State('patchcustomerObject') patchcustomerObject:
    | CustomerData
    | undefined;

  @customerModule.State('deleteLoading') customersDeleteLoading: boolean;

  @projectModule.Action(ProjectActions.FETCH_ORGANIZATION_PROJECTS)
  fetchOrganizationProjectsData;

  @projectModule.State('organizationProjects') organizationProjects:
    | ProjectListing[]
    | undefined;

  @projectModule.State('loadError') loadError;

  @projectModule.State('loading') loading;

  requiredField = [
    (v: string): string | boolean => !!v || 'This is a required field.',
  ];

  emailRules = [
    (v: string): string | boolean => (
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || v === '')
      || 'Email must be valid',
  ];

  isUserRedzoneOrganizationMember(): boolean {
    const currentUser = this.allUsers?.find((user) => {
      if (user.useridentity) {
        return user.useridentity.replace('auth0|', '') === this.$auth.user.id;
      }
      return false;
    });

    if (currentUser == null) {
      return false;
    }

    return currentUser.organizationGuids.includes(REDZONE_ORGANIZATION_GUID);
  }

  isUsersRedzoneOrganizationMember(users: DetailedUserData[]): boolean {
    return users.every((u) => u.organizationGuids.includes(REDZONE_ORGANIZATION_GUID));
  }
}
