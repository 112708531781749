import { IPDEntry, InspectionData } from '@/store/asset/types';
import convert from 'convert-units';

// eslint-disable-next-line class-methods-use-this
function getMaxDepth(payoutIPD: IPDEntry[], maxVideoTimeInSeconds = -1): number {
  if (payoutIPD == null || payoutIPD.length === 0) return 0.0;
  let depth = 0.0;
  if (maxVideoTimeInSeconds === -1) {
    // eslint-disable-next-line no-unused-expressions
    payoutIPD.forEach((val) => {
      if (val.payout > depth) depth = val.payout;
    });

    depth /= 100;
  } else {
    let valueFound = false;
    // eslint-disable-next-line no-unused-expressions
    payoutIPD.forEach((val) => {
      if (!valueFound && val.timeMS >= (maxVideoTimeInSeconds * 1000)) {
        depth = val.payout;
        valueFound = true;
      }
    });
    if (!valueFound) {
      // eslint-disable-next-line no-unused-expressions
      payoutIPD.forEach((val) => {
        if (val.payout > depth) depth = val.payout;
      });
    }

    depth /= 100;
  }
  return depth;
}

function getMaxDepthReached(
  inspectionData: InspectionData,
  maxVideoTimeInSeconds = -1,
): number {
  const maxDepth = getMaxDepth(inspectionData.payoutIPD, maxVideoTimeInSeconds);
  const maxConditionDepth = Math.max(
    ...inspectionData.conditionReport.map((cond) => cond.distance),
  );
  return Math.max(maxConditionDepth, maxDepth);
}

// eslint-disable-next-line class-methods-use-this
function getMaxTime(payoutIPD: IPDEntry[], maxVideoTimeInSeconds = -1): number {
  if (maxVideoTimeInSeconds !== -1) {
    return maxVideoTimeInSeconds;
  }
  if (!payoutIPD || payoutIPD.length === 0) {
    return 0;
  }
  return Math.max(...payoutIPD.map((i) => i.timeMS)) / 1000;
}

// TODO: why are these different
// eslint-disable-next-line class-methods-use-this
function getClosestPayoutValue(payoutIPD: IPDEntry[], timeSeconds: number): number {
  if (payoutIPD == null || payoutIPD.length === 0 || timeSeconds === 0) return 0;
  // Convert time to milliseconds
  const timeMS = timeSeconds * 1000;
  // Search the IPD json to find the closest time value
  const closestPayout = (payoutIPD.find(
    (entry: IPDEntry) => entry.timeMS >= timeMS,
  ) as IPDEntry);

  let closestPayoutValue;
  if (closestPayout != null) {
    closestPayoutValue = closestPayout.payout;
  }

  return closestPayoutValue != null ? closestPayoutValue : payoutIPD[payoutIPD.length - 1].payout;
}

// eslint-disable-next-line class-methods-use-this
function getClosestPayout(payoutIPD: IPDEntry[], timeSeconds: number): IPDEntry {
  if (!payoutIPD || payoutIPD.length === 0) {
    return null;
  }

  const timeMS = timeSeconds * 1000;
  const desiredIpd = payoutIPD.reduce(
    (prev, curr) => (
      Math.abs(curr.timeMS - timeMS) < Math.abs(prev.timeMS - timeMS) ? curr : prev
    ),
  );
  return desiredIpd;
}

// eslint-disable-next-line class-methods-use-this
function getClosestTime(payoutIPD: IPDEntry[], depth: number, time = 0): number {
  if (payoutIPD == null || payoutIPD.length === 0) return 0;
  const depthConv = depth * 100;
  // Search the IPD json to find the closest time value
  const closestTimeVar = (payoutIPD.find(
    (entry: IPDEntry) => entry.payout >= depthConv && entry.timeMS >= time,
  ) as IPDEntry);
  let closestTime;
  if (closestTimeVar) {
    // Convert time to milliseconds
    closestTime = closestTimeVar.timeMS;
  }

  return closestTime != null ? closestTime : payoutIPD[payoutIPD.length - 1].timeMS;
}

function getDisplayTemperatureFtC(displayImperial: boolean, value: number): number {
  return displayImperial
    ? Math.round(value * 10) / 10
    : Math.round(convert(value).from('F').to('C') * 10) / 10;
}

function getDisplayDistanceInMM(displayImperial: boolean, value: number): number {
  return displayImperial
    ? Math.round(value * 10) / 10
    : Math.round(convert(value).from('in').to('mm') * 10) / 10;
}

function getDisplayDistanceFtM(displayImperial: boolean, value: number): number {
  return displayImperial
    ? Math.round(value * 10) / 10
    : Math.round(convert(value).from('ft').to('m') * 10) / 10;
}

function getStartingPayout(inspection: InspectionData, zeroTimeMS: number): number {
  if (inspection?.payoutIPD == null) {
    return 0;
  }
  let startingPayout = getClosestPayoutValue(inspection.payoutIPD,
    zeroTimeMS / 1000);
  if (startingPayout < 0) {
    startingPayout = 0;
  }
  return startingPayout;
}

export default {
  getMaxDepth,
  getMaxDepthReached,
  getMaxTime,
  getClosestPayoutValue,
  getClosestPayout,
  getClosestTime,
  getDisplayTemperatureFtC,
  getDisplayDistanceInMM,
  getDisplayDistanceFtM,
  getStartingPayout,
};
