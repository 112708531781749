






































































































































































































































/* eslint-disable no-unreachable */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
import Vue from 'vue';
import {
  Prop, Component, Emit, Watch, PropSync,
} from 'vue-property-decorator';
import convert from 'convert-units';
import { MSIInspectionData, PVDData } from '@/store/crossSections/types';
import { namespace } from 'vuex-class';
import { DefectCodingModel, DefectValidationObject } from '@/store/defectCoding/types';
import { CrossSectionsActions } from '@/store/crossSections/actions';
import { msiPayoutActions } from '@/store/msiPayout/actions';
import getMaxDepth from '../../views/Asset/utils';
import {
  AssetData, InspectionData, IPDEntry, URLDefect,
} from '../../store/asset/types';
import { Marker, MSIDefectGraphState } from './types';
import ConditionMarker from './ConditionMarker';
import mapUtils from '../Maps/utils';
import util from '../Report/util';

const userPrefsModule = namespace('userPrefs');
const defectCodingModule = namespace('defectCoding');
const crossSectionModule = namespace('crossSections');
const msiPayoutModule = namespace('msiPayout');

@Component
export default class DefectGraph extends Vue {
  @userPrefsModule.State('useDefectCodeDesc') useDefectCodeDesc: boolean;

  @defectCodingModule.State('defectCodingData') defectCodingData: DefectCodingModel[] | undefined;

  @crossSectionModule.Action(CrossSectionsActions.GET_PVD_DETAILS) getPVDDetails;

  @crossSectionModule.State('pvdData') pvdData: PVDData | undefined;

  @msiPayoutModule.Action(msiPayoutActions.SET_CURRENT_IPD) setCurrentPayout;

  @Prop() readonly inspection!: InspectionData;

  @Prop() readonly deadzone!: number;

  @Prop() readonly asset!: AssetData;

  @Prop() readonly fullVideoTime!: number;

  @Prop() readonly maxDepth!: number;

  @Prop() readonly displayImperial!: boolean;

  @Prop() readonly ipd!: IPDEntry[];

  @Prop() readonly MSIDefectData!: MSIInspectionData;

  @Prop() readonly MSIState!: MSIDefectGraphState;

  @Prop() readonly isMSIView: boolean;

  @Prop() readonly timeToZero: number;

  @Prop() currentVideoTime: number;

  @Prop({ default: undefined }) readonly defectToGoTo: URLDefect | undefined;

  @PropSync('syncdIsDefectGraphValid') isDefectGraphValid!: boolean;

  originalDefectState = MSIDefectGraphState.ORIGINAL;

  timeRequestMade = 0;

  attributes = null;

  distanceReadout = 0;

  scrubbing = false;

  currentMarker: Marker | undefined = this.markers[0];

  conditionReportMarker: Marker | undefined = this.markers[0];

  defects: Map<Marker, Array<Marker>> = new Map();

  defectsModel: Array<boolean> = [];

  defectOriginalHeights: Array<number> = [];

  maxVideoTime = 0;

  dpp = 0;

  HeightStyle = '';

  manholeSVGStyle = '';

  stopTimeUpdate = false;

  currentInspectionGuid = '';

  startingPayout = 0;

  @Watch('MSIState')
  onStateChange(): void{
    this.updateDefects();
  }

  @Watch('useDefectCodeDesc')
  onDefectCodeDescChange(): void{
    this.updateDefects();
  }

  @Watch('defectToGoTo')
  onDefectToGoToChange(): void {
    if (this.defectToGoTo != null) {
      this.goToDefect(this.defectToGoTo);
    }
  }

  @Watch('inspection', { deep: true })
  onInspectionChange(): void {
    Array.from(this.defects.keys()).forEach((element) => {
      this.defects.delete(element);
    });
    if (this.inspection != null && this.inspection.guid !== this.currentInspectionGuid) {
      this.distanceReadout = 0;
      this.currentInspectionGuid = this.inspection.guid;
    }

    this.startingPayout = getMaxDepth.getStartingPayout(this.inspection, this.timeToZero);

    this.scrubbing = false;

    // eslint-disable-next-line prefer-destructuring
    this.currentMarker = this.markers[0];

    // eslint-disable-next-line prefer-destructuring
    this.conditionReportMarker = this.markers[0];

    this.defects = new Map();

    this.defectsModel = [];

    this.defectOriginalHeights = [];
    this.$forceUpdate();
    this.updateDefects();
  }

  @Watch('fullVideoTime')
  onFullVideoTimeUpdate(value: number): void {
    if (Number.isNaN(value)) return;

    this.maxVideoTime = getMaxDepth.getMaxTime(this.ipd, this.fullVideoTime);

    this.updateDefects();
  }

  @Watch('currentVideoTime')
  onCurrentVideoTimeChange(): void {
    if (this.stopTimeUpdate) {
      this.stopTimeUpdate = false;
      return;
    }
    if (this.currentVideoTime === this.maxVideoTime) {
      this.onPayoutRequest(this.maxDepth * 100);
      return;
    }
    // update depthMarker to match closest avilable time in ipd
    this.distanceReadout = getMaxDepth.getClosestPayoutValue(this.ipd,
      this.currentVideoTime);
    if (this.distanceReadout / 100 > this.maxDepth) {
      this.distanceReadout = this.maxDepth * 100;
    }
    if (this.isMSIView) {
      this.setCurrentPayout(getMaxDepth.getClosestPayout(this.ipd,
        this.currentVideoTime));
    }
  }

  @Watch('defectModel')
  onDefectModelChange(markers: Array<string>): void{
    if (!this.defects) {
      return;
    }
    const keys = Array.from(this.defects.keys());
    for (let i = 0; i < markers.length; i += 1) {
      const values = (this.defects.get(keys[i]) as Array<Marker>);
      this.defects.delete(keys[i]);
      this.defects.set(
        values.find((element) => element.getGuid() === markers[i]) as Marker, values,
      );
    }
    this.$forceUpdate();
  }

  @Watch('displayImperial')
  onDisplayImperialChange(): void{
    this.updateDefects();
  }

  @Watch('currentMarker')
  onCurrentMarkerChanged(marker: Marker): void {
    this.onMarkersSelected(marker);
  }

  @Emit()
  // eslint-disable-next-line class-methods-use-this
  onMarkersSelected(e: Marker): Marker {
    return e;
  }

  @Emit()
  // eslint-disable-next-line class-methods-use-this
  onMarkersClicked(e: Marker): Marker {
    return e;
  }

  @Emit()
  // eslint-disable-next-line class-methods-use-this
  onPayoutRequest(payout: number): number {
    this.distanceReadout = payout;
    return payout;
  }

  @Emit()
  // eslint-disable-next-line class-methods-use-this
  onPayoutRequestIpd(payout: IPDEntry): IPDEntry {
    if (this.isMSIView) {
      this.setCurrentPayout(payout);
    }
    this.distanceReadout = payout.payout;
    return payout;
  }

  // this is a computed property that provides the array of markers
  get markers(): Marker[] {
    if (this.MSIState === MSIDefectGraphState.ORIGINAL) {
      const conditionMarkers = this.OriginalMarkers;

      conditionMarkers.sort((a, b) => ((a.getDistance() < b.getDistance())
        ? -1 : 1));
      return conditionMarkers;
    }
    if (this.MSIState === MSIDefectGraphState.MSI) {
      const conditionMarkers = this.MSIMakers;

      conditionMarkers.sort((a, b) => ((a.getDistance() < b.getDistance())
        ? -1 : 1));
      return conditionMarkers;
    }
    const tempArr = this.OriginalMarkers;
    const conditionMarkers = tempArr.concat(this.MSIMakers);

    conditionMarkers.sort((a, b) => ((a.getDistance() < b.getDistance())
      ? -1 : 1));
    return conditionMarkers;
  }

  get OriginalMarkers(): Marker[] {
    this.inspection.conditionReport.sort((a, b) => ((a.distance < b.distance)
      ? b.distance : a.distance));
    // Creating markers using the conditions in the inspection
    const conditionMarkers: Marker[] = this.inspection.conditionReport.map(
      (n): ConditionMarker => new ConditionMarker(n),
    );
    return conditionMarkers;
  }

  get MSIMakers(): Marker[] {
    const conditionReports = [];
    const defects = this.MSIDefectData?.defects != null ? this.MSIDefectData.defects : [];
    defects.forEach((data) => {
      const report: Record<string, unknown | undefined> = {};
      report['code'] = data.code;
      report['description'] = data.description;
      report['distance'] = data.distance;
      report['grade'] = data.grade;
      report['clockStartFrom'] = data.clock1;
      report['clockTo'] = data.clock2;
      report['firstValue'] = data.value1;
      report['secondValue'] = data.value2;
      report['valuePercent'] = data.percent;
      report['continuousIndex'] = data.continuous;
      report['step'] = '';
      report['remarks'] = data.remarks;
      report['withinEightOfJoint'] = data.joint;
      report['image'] = data.photoFileName;
      report['isMSIData'] = true;
      conditionReports.push({
        guid: '',
        type: '',
        description: data.description,
        distance: data.distance,
        severity: '',
        code: data.code,
        report,
      });
    });
    conditionReports.sort((a, b) => ((a.distance < b.distance)
      ? b.distance : a.distance));
    // Creating markers using the conditions in the inspection
    const conditionMarkers: Marker[] = conditionReports.map(
      (n): ConditionMarker => new ConditionMarker(n),
    );
    return conditionMarkers;
  }

  get ManholeISOImage(): any {
    const retVal = require.context('../../assets/', false, /\.png$/);
    return retVal('./redzoneisomanhole.png');
  }

  get ManholeImage(): any {
    const retVal = require.context('../../assets/', false, /\.png$/);
    return retVal('./redzonemanhole.png');
  }

  get isDamLevee(): boolean {
    if (this.inspection.initialPipeUse != null
    && this.inspection.initialPipeUse !== 'Dam Pipe'
    && this.inspection.initialPipeUse !== 'Levee Gravity Pipe'
    && this.inspection.initialPipeUse !== 'Levee Pressure Pipe') {
      return false;
    }
    return true;
  }

  get conditionImages(): { distance: string, image: HTMLImageElement }[] {
    const retVal = [];
    this.inspection.conditionReport.forEach((cr) => {
      if (cr.image && cr.image !== '') {
        const condImage = new Image();
        condImage.src = cr.image;
        condImage.id = 'hoverImage';
        retVal.push({
          distance: cr.distance,
          image: condImage,
        });
      }
    });

    return retVal;
  }

  // eslint-disable-next-line class-methods-use-this
  getSelectedDefects(def: Array<Marker>): unknown {
    return def.map((element) => ({
      value: element.getGuid(),
      text: `${element.getGrade()} ${element.getTitle()} ${element.getDescription()}`,
    }));
  }

  mounted(): void {
    if (this.inspection.msiExist) {
      this.getPVDDetails(this.inspection.guid);
    }
    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.updateDefects();
      }, 100);
    });
    const image = document.getElementById('hoverImage') as HTMLImageElement;
    const imageDiv = document.getElementById('imageDiv') as HTMLDivElement;
    if (image != null) {
      image.onerror = function () {
        imageDiv.style.display = 'none';
      };
      image.onload = function () {
        imageDiv.style.display = 'block';
      };
    }

    this.startingPayout = getMaxDepth.getStartingPayout(this.inspection, this.timeToZero);
    if (this.asset == null || this.asset.attributes == null) {
      return;
    }
    // try {
    //   this.attributes = JSON.parse(this.asset.attributes as string);
    // } catch (ex) {
    //   console.error(ex);
    // }

    this.maxVideoTime = getMaxDepth.getMaxTime(this.ipd, this.fullVideoTime);
  }

  async goToDefect(defect: URLDefect): Promise<void> {
    if (defect == null) {
      return;
    }
    if (this.inspection.guid !== defect.inspectionGuid) {
      this.$emit('setInspection', defect.inspectionGuid);
      await this.$nextTick();
      if (this.inspection.guid !== defect.inspectionGuid) {
        return;
      }
    }
    if (defect.defectGuid) {
      const foundDefect = this.markers.find((value) => value.getGuid() === defect.defectGuid);
      if (foundDefect) {
        this.updatePayout(foundDefect);
        return;
      }
    }
    if (defect.payout != null) {
      this.onPayoutRequest(defect.payout);
      return;
    }
    if (defect.code) {
      const foundDefect = this.markers.find((value) => value.getDescription() === defect.code);
      if (foundDefect) {
        this.updatePayout(foundDefect);
      }
    }
  }

  setIsDefectGraphValid(): void{
    let setToTrue = true;
    this.markers.forEach((marker) => {
      if (this.getIsValidContinuous(marker) != null
        || this.getDamLeveeValid(marker) != null
        || this.getAccessAndMsaValid(marker) != null) {
        this.isDefectGraphValid = false;
        setToTrue = false;
      }
    });
    if (setToTrue) {
      this.isDefectGraphValid = true;
    }
  }

  getDamLeveeValid(marker: Marker): string | undefined {
    if (this.defectCodingData == null) {
      return null;
    }
    const codeObject = this.defectCodingData.find((value) => value.code === marker.getReportData()['code']);
    if (codeObject == null) {
      return null;
    }
    const DVO = this.getDVO(codeObject);
    if (DVO == null || DVO.DamLevee !== true) {
      return null;
    }
    if (!this.isDamLevee) {
      return 'This code can only be used with Dam/Levee Pipe Use';
    }
    return null;
  }

  getDVO(mockDataObject: DefectCodingModel): DefectValidationObject {
    if (mockDataObject == null && mockDataObject.jsonAttributes == null) {
      return null;
    }

    const rawJSONAttributes = mockDataObject.jsonAttributes;
    rawJSONAttributes.replaceAll('\\', '');

    return JSON.parse(rawJSONAttributes) as DefectValidationObject;
  }

  getDefectMenuOpenStyle(defect: any[]): string {
    let returnValue = '';

    if (defect.length > 1) {
      returnValue = 'z-index: 30;';
    }

    return returnValue;
  }

  UpdateHeightStyle(): void {
    let returnValue = `height: ${50 * this.markers.length}px;`;
    if (document.getElementsByClassName('breakdown')[0].clientHeight - 125 > 50 * this.markers.length) {
      returnValue = `height: ${document.getElementsByClassName('breakdown')[0].clientHeight - 125}px;`;
    }
    this.HeightStyle = returnValue;
  }

  hoverConditionReport(event: MouseEvent, marker: Marker): void{
    this.conditionReportMarker = marker;
    const conditionReport = (document.getElementById('conditionReport') as HTMLElement);
    const conditionReportCard = document.getElementById('conditionReportCard');
    const conditionImageCard = document.getElementById('image-card');
    const conditionImage = document.getElementById('hoverImage');

    if (conditionReportCard == null) {
      return;
    }

    if (conditionImage) {
      conditionImage.remove();
    }

    conditionImageCard.append(
      this.conditionImages.find((img) => parseFloat(img.distance) === marker.getDistance()).image,
    );

    const conditionReportCardHeight = parseInt(
      window.getComputedStyle(conditionReportCard, null).getPropertyValue('height'),
      10,
    );

    conditionReport.style.display = 'block';
    if ((event.clientY - 5 - conditionReportCardHeight) < 0) {
      conditionReport.style.top = '12px';
      conditionReport.style.left = `${event.clientX + 5}px`;
    } else if ((event.clientY + 5 + conditionReportCardHeight) > document.body.clientHeight) {
      conditionReport.style.top = `${event.clientY - 5 - conditionReportCardHeight}px`;
      conditionReport.style.left = `${event.clientX + 5}px`;
    } else {
      conditionReport.style.top = `${event.clientY + 5}px`;
      conditionReport.style.left = `${event.clientX + 5}px`;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  leaveConditionReport(): void{
    const conditionReport = (document.getElementById('conditionReport') as HTMLElement);
    conditionReport.style.display = 'none';
    conditionReport.style.top = '0px';
    conditionReport.style.left = '0px';
  }

  findLocationOfMarker(marker: Marker): number {
    const counter = 0;
    const keys = Array.from(this.defects.keys());
    for (let i = 0; i < keys.length; i += 1) {
      if (keys[counter].getDistance() === marker.getDistance()) {
        return counter;
      }
    }
    return -1;
  }

  // eslint-disable-next-line class-methods-use-this
  getReportDataForHtml(report: Record<string, unknown>, node: string): string | unknown {
    return report[node];
  }

  markersInRange(payout: number): Marker[] {
    return this.markers.filter((n) => this.isMarkerInRange(n, payout));
  }

  isMarkerInRange(marker: Marker, payout: number): boolean {
    let inRange = true;
    inRange = inRange && payout >= marker.getDistance() - this.deadzone;
    inRange = inRange && payout <= marker.getDistance() + this.deadzone;
    return inRange;
  }

  changeDefect(marker: Marker, key: Marker): void {
    const tempMap: Map<Marker, Array<Marker>> = new Map();

    Array.from(this.defects.keys()).forEach((element) => {
      if (element === key) {
        tempMap.set(marker, this.defects.get(key) as Array<Marker>);
      } else {
        tempMap.set(element, this.defects.get(element) as Array<Marker>);
      }
    });

    Array.from(this.defects.keys()).forEach((element) => {
      this.defects.delete(element);
    });

    Array.from(tempMap.keys()).forEach((element) => {
      this.defects.set(element as Marker, tempMap.get(element) as Array<Marker>);
    });
    this.$forceUpdate();
  }

  async updateDefects(): Promise<void> {
    if (this.MSIState !== MSIDefectGraphState.ORIGINAL && this.MSIMakers == null) {
      return;
    }
    if (document.getElementById('svgDefectText') == null || document.getElementsByClassName('breakdown').length === 0) {
      return;
    }

    this.UpdateHeightStyle();
    const svgns = 'http://www.w3.org/2000/svg';
    const svg = (this.$refs.svgDefectText as any);
    const svgDom = document.getElementById('svgDefectText');
    document.getElementById('svgDefectText').innerHTML = '';
    const lastReport = this.inspection.conditionReport[this.inspection.conditionReport.length - 1];
    const graph = document.getElementsByClassName('centerColumn')[0];

    this.manholeSVGStyle = `width: ${(graph as HTMLElement).getBoundingClientRect().width + 8}px;`;

    const textSpacing = 10;
    const minLineSpace = 28;
    let fontSize = 0;

    const actualMaxDepth = (() => {
      if (this.inspection.maxPayout != null) {
        if (this.inspection.maxPayout < (lastReport != null ? lastReport.distance : 0)) {
          return (lastReport != null ? lastReport.distance : 0);
        }
        return this.inspection.maxPayout;
      }
      if (getMaxDepth.getMaxDepth(this.ipd, this.maxVideoTime)
        < (lastReport != null ? lastReport.distance : 0)) {
        return (lastReport != null ? lastReport.distance : 0);
      }
      return getMaxDepth.getMaxDepth(this.ipd, this.maxVideoTime);
    })();

    const height = parseInt(this.HeightStyle.split(' ')[1], 10) - 3;
    this.dpp = height / actualMaxDepth;

    let yPos = 16;

    const displayGridWidth = document.getElementsByClassName('displayGrid')[0].getBoundingClientRect().width;

    const underLine = document.createElementNS(svgns, 'line');
    underLine.setAttribute('x1', '10%');

    let largestWidth = 0;
    let largestString = '';
    const differenceOfOnePixel = this.getTextWidth(largestString, '11px Roboto') - this.getTextWidth(largestString, '10px Roboto');
    const actualWidth = ((displayGridWidth - (displayGridWidth * 0.08 - 30))
    - (776 * 0.15) + 30)
    - (displayGridWidth * 0.2);

    this.setIsDefectGraphValid();

    this.markers.forEach((marker) => {
      let text = this.useDefectCodeDesc ? `<b>${marker.getTitle()}</b>   ${marker.getDescription()}` : `<b>${marker.getTitle()}</b>`;
      text = marker.getDistance() != null
        ? `${this.getDisplayDistanceFtM(Math.round((marker.getDistance()
          + Number.EPSILON) * 10) / 10)} ${text}`
        : '';
      const currentWidth = this.getTextWidth(text, '10px Roboto');
      if (currentWidth > largestWidth) {
        largestWidth = currentWidth;
        largestString = text;
      }
    });
    const spaceLeft = actualWidth - largestWidth;
    const howManyGrow = Math.floor(spaceLeft / differenceOfOnePixel);

    this.markers.forEach((marker) => {
      const line = document.createElementNS(svgns, 'line');

      if (!svg || !line) {
        return;
      }

      line.setAttribute('x1', '0px');
      let markerDistance = marker.getDistance();
      if (markerDistance < 0) {
        markerDistance = 0;
      }
      let y1Val = this.payoutToPercentage(markerDistance);

      if (y1Val > height) {
        y1Val = height;
      }

      line.setAttribute('y1', `${y1Val.toString()}%`);

      const x2Val = 30;
      line.setAttribute('x2', x2Val.toString());
      line.setAttribute('y2', `${y1Val.toString()}%`);
      line.setAttribute('stroke', this.severityLookup(marker.getReportData()));
      line.setAttribute('stroke-width', '3');
      svg.appendChild(line);

      const angleLine = document.createElementNS(svgns, 'line');
      angleLine.setAttribute('x1', x2Val.toString());
      angleLine.setAttribute('y1', `${y1Val.toString()}%`);
      angleLine.setAttribute('x2', '10%');
      angleLine.setAttribute('y2', (yPos + textSpacing).toString());
      angleLine.setAttribute('stroke', this.severityLookup(marker.getReportData()));
      angleLine.setAttribute('stroke-width', '3');
      svg.appendChild(angleLine);

      underLine.setAttribute('stroke', this.severityLookup(marker.getReportData()));
      underLine.setAttribute('y1', (yPos + textSpacing).toString());
      underLine.setAttribute('y2', (yPos + textSpacing).toString());
      underLine.setAttribute('stroke-width', '3');

      const fObj = document.createElementNS(svgns, 'foreignObject');
      fObj.setAttribute('x', '15%');
      fObj.setAttribute('height', '30');
      let text = this.useDefectCodeDesc ? `<b>${marker.getTitle()}</b>   ${marker.getDescription()}` : `<b>${marker.getTitle()}</b>`;
      text = markerDistance != null
        ? `${this.getDisplayDistanceFtM(Math.round((marker.getDistance()
          + Number.EPSILON) * 10) / 10)} ${text}`
        : '';
      fObj.setAttribute('width', `${15 + this.getTextWidth(text, `${Math.max(Math.min(10 + howManyGrow, 16), 10)}px Roboto`)}px`);
      const tArea = document.createElement('p');
      tArea.innerHTML = text;
      if (this.useDefectCodeDesc) {
        fontSize = Math.max(Math.min(10 + howManyGrow, 16), 10);
        tArea.setAttribute('style', `font-family: "Roboto"; font-size: ${fontSize}px; white-space: nowrap;
          overflow: hidden; max-height: fit-content; max-width: fit-content; text-overflow: ellipsis;
          color: ${this.getIsValidContinuous(marker) == null ? 'black' : '#e61e25'}`);
        fObj.setAttribute('y', (yPos - fontSize).toString());
      } else {
        tArea.setAttribute('style', `font-family: "Roboto"; font-size: 18px; max-width: fit-content; color: ${
          this.getIsValidContinuous(marker) != null
          || this.getDamLeveeValid(marker) != null
          || this.getAccessAndMsaValid(marker) != null
            ? '#e61e25' : 'black'}`);
        fObj.setAttribute('y', (yPos - 18).toString());
      }
      tArea.addEventListener('mousedown', () => this.updatePayout(marker));
      tArea.addEventListener('mouseenter', (event) => this.hoverConditionReport(event, marker));
      tArea.addEventListener('mouseleave', this.leaveConditionReport);
      tArea.addEventListener('mousemove', (event) => this.hoverConditionReport(event, marker));
      fObj.appendChild(tArea);
      svg.appendChild(fObj);

      const circle = document.createElementNS(svgns, 'circle');
      circle.setAttribute('cx', '13.2%');
      circle.setAttribute('cy', (yPos - 5).toString());
      circle.setAttribute('r', '11');
      circle.setAttribute('fill', this.severityLookup(marker.getReportData()));
      const funct = () => {
        if (marker.getGrade() !== -1) {
          return marker.getGrade().toString();
        }
        return '-';
      };
      circle.textContent = funct();
      circle.addEventListener('mousedown', () => this.updatePayout(marker));
      circle.addEventListener('mouseenter', (event) => this.hoverConditionReport(event, marker));
      circle.addEventListener('mouseleave', this.leaveConditionReport);
      circle.addEventListener('mousemove', (event) => this.hoverConditionReport(event, marker));
      svg.appendChild(circle);

      const code = document.createElementNS(svgns, 'text');
      code.setAttribute('x', '12.6%');
      code.setAttribute('y', yPos.toString());
      code.setAttribute('font-weight', 'bold');
      code.setAttribute('fill', 'white');
      code.setAttribute('stroke', '#383838');
      if (marker.getGrade() < 3 || marker.getGrade() > 4) {
        code.setAttribute('stroke-opacity', '.7');
      }
      code.setAttribute('stroke-width', '1px');
      code.textContent = funct();
      code.addEventListener('mousedown', () => this.updatePayout(marker));
      code.addEventListener('mouseenter', (event) => this.hoverConditionReport(event, marker));
      code.addEventListener('mouseleave', this.leaveConditionReport);
      code.addEventListener('mousemove', (event) => this.hoverConditionReport(event, marker));
      svg.appendChild(code);
      const textWidth = parseInt(
        window.getComputedStyle(tArea, null).getPropertyValue('width'),
        10,
      );
      const svgWidth = parseInt(
        window.getComputedStyle(svgDom, null).getPropertyValue('width'),
        10,
      );
      underLine.setAttribute('x2', `${(0.126 * svgWidth) + 20 + textWidth}`);
      svg.appendChild(underLine.cloneNode(true));

      const tempHeight = (height - 25) / (this.markers.length - 1);
      yPos += tempHeight < minLineSpace + fontSize ? minLineSpace + fontSize : tempHeight;
    });
    this.onDefectToGoToChange();
  }

  getIsValidContinuous(model: Marker): string {
    if (model == null) {
      return null;
    }
    let index = model.getReportData()['continuousIndex'] != null && model.getReportData()['continuousIndex'] !== ''
      && (model.getReportData()['continuousIndex'] as string).indexOf('S') !== -1
      ? model.getReportData()['continuousIndex'] as string : null;

    if (index != null) {
      if (this.inspection.conditionReport.find(
        (value) => (value != null || value.report != null ? value.report['continuousIndex'] === index.replace('S', 'F') : false),
      ) == null) {
        return 'This defect does not have an ending index.';
      }
    } else {
      index = model.getReportData()['continuousIndex'] != null && model.getReportData()['continuousIndex'] !== ''
      && (model.getReportData()['continuousIndex'] as string).indexOf('F') !== -1
        ? model.getReportData()['continuousIndex'] as string : null;
      if (index == null) {
        return null;
      }
      if (this.inspection.conditionReport.find(
        (value) => (value != null || value.report != null ? value.report['continuousIndex'] === index.replace('F', 'S') : false),
      ) == null) {
        return 'This defect does not have a starting index.';
      }
    }
    return null;
  }

  getAccessAndMsaValid(condition: Marker): string | undefined {
    if (this.asset.type === 'Manhole') {
      return null;
    }
    if (condition.getReportData()['code'] !== 'AMH' && condition.getReportData()['code'] !== 'MSA') {
      if (this.inspection.conditionReport == null) {
        return null;
      }
      const allAccessAndMSA = this.inspection.conditionReport.filter((value) => value.code.charAt(0) === 'A' || value.code === 'MSA');
      allAccessAndMSA.sort((a, b) => ((a.distance < b.distance)
        ? -1 : 1));
      if (allAccessAndMSA.length <= 0) {
        return null;
      }

      if (allAccessAndMSA.length === 1) {
        return 'There must be a starting Access Point and an ending Access Point or MSA code.';
      }

      if (allAccessAndMSA.length > 0) {
        if (allAccessAndMSA.length === 1 && allAccessAndMSA[0].code.charAt(0) === 'A') {
          const lastAccessPoint = allAccessAndMSA[0];
          if (condition.getDistance() > lastAccessPoint.distance) {
            return 'This defect must be before an ending Access Point or MSA code.';
          }
        } if (allAccessAndMSA.length === 1 && allAccessAndMSA[0].code === 'MSA') {
          const lastMSA = allAccessAndMSA[0];
          if (condition.getDistance() > lastMSA.distance) {
            return 'This defect cannot be at a greater distance than the ending MSA code.';
          }
        } else if (allAccessAndMSA.length > 1) {
          const firstMSA = allAccessAndMSA.find((value) => value.code === 'MSA');
          if (firstMSA != null) {
            if (condition.getDistance() > firstMSA.distance) {
              return 'This defect cannot be at a greater distance than the ending MSA code.';
            }
          } else {
            const lastAccessPoint = allAccessAndMSA[1];
            if (condition.getDistance() > lastAccessPoint.distance) {
              return 'This defect cannot be at a greater distance than the ending Access Point code.';
            }
          }
        }
      }

      return null;
    }
    const allAccessAndMSA = this.inspection.conditionReport.filter((value) => value.code.charAt(0) === 'A' || value.code === 'MSA');
    allAccessAndMSA.sort((a, b) => ((a.distance < b.distance)
      ? -1 : 1));
    if (allAccessAndMSA.length > 2) {
      return 'There may only be two Access Point codes or one of each Access Point and MSA codes.';
    }
    for (let i = 0; i < allAccessAndMSA.length; i += 1) {
      if (allAccessAndMSA[i].code === 'MSA' && i !== allAccessAndMSA.length - 1) {
        return 'Code MSA must be the last defect to occur';
      }
    }
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  severityLookup(report: any): string {
    if (report['isMSIData'] != null && report['isMSIData']) {
      return this.msiDefectColorLookup(report);
    }
    if (report['grade'] != null) {
      return mapUtils.getScoreColor(parseInt(report['grade'], 10));
    }
    if (report['structuralGrade'] == null && report['omGrade'] == null) {
      return '#0C6599';
    }

    if (report['structuralGrade'] != null && report['omGrade'] == null) {
      return mapUtils.getScoreColor(parseInt(report['structuralGrade'], 10));
    }

    if (report['structuralGrade'] == null && report['omGrade'] != null) {
      return mapUtils.getScoreColor(parseInt(report['omGrade'], 10));
    }

    const om = parseInt(report['omGrade'], 10);
    const structural = parseInt(report['structuralGrade'], 10);
    if (om > structural) {
      return mapUtils.getScoreColor(om);
    }
    return mapUtils.getScoreColor(structural);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  msiDefectColorLookup(report: any): string {
    if (report['remarks'] == null || !this.pvdData) {
      return 'gray';
    }
    const remark = report['remarks'] as string;
    const r = /\d+(\.\d+)?/;
    if (remark.includes('Corrosion')) {
      const corrosionValue = report['remarks'].match(r)[0];
      if (corrosionValue >= this.pvdData.flatRed) {
        return '#ea3323';
      }
      if (corrosionValue >= this.pvdData.flatOrange) {
        return '#f19b38';
      }
      if (corrosionValue >= this.pvdData.flatYellow) {
        return '#ffff57';
      }
    }
    if (remark.includes('Debris')) {
      const debrisValue = report['remarks'].match(r)[0];
      if (debrisValue >= this.pvdData.flatDarkBlue) {
        return '#bedfec';
      }
      if (debrisValue >= this.pvdData.flatBlue) {
        return '#6999c8';
      }
      if (debrisValue >= this.pvdData.flatLightBlue) {
        return '#30486c';
      }
    }
    return 'gray';
  }

  getDisplayDistanceFtM(value: number): string {
    return value || value === 0
      ? util.getDisplayDistanceFtM(
        this.displayImperial, Math.round((value + Number.EPSILON) * 10) / 10,
      )
        + util.getDistanceUnitsFtM(this.displayImperial) : '';
  }

  getTopPixels(value: number, isPayout = false): number {
    const graph = document.getElementsByClassName('centerColumn')[0];

    let dpp = this.maxDepth;

    let height = 0;
    if (graph) {
      height = parseInt(
        window.getComputedStyle(graph, null).getPropertyValue('height'),
        10,
      );
      dpp /= height;
      this.dpp = dpp;
    }

    if (isPayout) {
      if (value > this.maxDepth) {
        return height;
      }
      return height * (this.payoutToPercentage(value) / 100);
    }

    if (value > this.maxDepth) {
      return (this.maxDepth * dpp);
    }

    return (value * dpp);
  }

  getPayoutLabel(position: string): string {
    let retVal = '';
    if (this.asset === null || this.asset.type === null || this.asset.type === '') {
      return retVal;
    }
    retVal = position === 'min'
      ? `0 ${this.getDistanceUnits()}`
      : `${this.getDisplayDistance(this.maxDepth)} ${this.getDistanceUnits()}`;

    return retVal;
  }

  mousePositionToPayout(event: MouseEvent): IPDEntry | undefined {
    if (this.ipd.length === 0) return null;
    const cColumn = this.$refs.centerColumn as Element;
    const { height, y } = cColumn.getBoundingClientRect();
    const relY = event.clientY - y;
    const percent = (relY / height);
    const timeToZero = getMaxDepth.getClosestTime(this.ipd, 0);
    const desiredTime = (((this.maxVideoTime * 1000) - timeToZero) * percent) + timeToZero;
    const desiredIpd = getMaxDepth.getClosestPayout(this.ipd, desiredTime / 1000);
    return desiredIpd;
  }

  updatePayout(marker: Marker): void {
    const timeAtMarker = getMaxDepth.getClosestTime(this.ipd, marker.getDistance());
    const payoutIPD = getMaxDepth.getClosestPayout(this.ipd, timeAtMarker / 1000);

    const maxIPDDepth = getMaxDepth.getMaxDepth(this.ipd, this.maxVideoTime);

    this.onMarkersClicked(marker);

    if (marker.getDistance() > maxIPDDepth) {
      // If clicking a defect beyond the ipd, then set the defect reading to the defect value
      const copyIPD = { ...payoutIPD };
      copyIPD.payout = marker.getDistance() * 100;

      this.stopTimeUpdate = true;
      this.onPayoutRequestIpd(copyIPD);
      this.distanceReadout = copyIPD.payout;

      return;
    }

    this.onPayoutRequestIpd(payoutIPD);
  }

  recursivePercentFinder(perfectPercent: number, currentPayout: number): number {
    const perfPerc = perfectPercent > 100 ? 100 : perfectPercent;

    if (this.payoutToPercentage(currentPayout / 100) < perfPerc) {
      return this.recursivePercentFinder(perfPerc, currentPayout + 1);
    }
    return currentPayout;
  }

  handleScrub(event: MouseEvent): void {
    let trackingbar = document.getElementsByClassName('trackingBar')[0];
    if (!trackingbar) trackingbar = (this.$refs.trackingBar as HTMLElement);
    if (!trackingbar) return;
    const width = parseInt(
      window.getComputedStyle(trackingbar, null).getPropertyValue('width'),
      10,
    );
    if (event.clientX - trackingbar.getBoundingClientRect().left > width) {
      return;
    }
    if (this.ipd.length > 0) {
      const payout = this.mousePositionToPayout(event);
      if (this.scrubbing) this.onPayoutRequestIpd(payout);
    }
  }

  handleClick(event: MouseEvent): void {
    this.$emit('on-markers-selected', this.conditionReportMarker);
    if (this.ipd && this.ipd.length > 0) {
      this.stopTimeUpdate = true;
      let trackingbar = document.getElementsByClassName('trackingBar')[0];
      if (!trackingbar) trackingbar = (this.$refs.trackingBar as HTMLElement);
      if (!trackingbar) return;
      const width = parseInt(
        window.getComputedStyle(trackingbar, null).getPropertyValue('width'),
        10,
      );
      if (event.clientX - trackingbar.getBoundingClientRect().left > width) {
        return;
      }
      const payout = this.mousePositionToPayout(event);
      this.onPayoutRequestIpd(payout);
    }
  }

  // Convert a payout value to a percentage of max payout
  payoutToPercentage(payout: number): number {
    let retVal = payout;

    if (retVal === 0 || !this.ipd || this.ipd.length === 0 || !this.maxVideoTime) {
      return 0;
    }

    if (retVal > this.maxDepth) {
      retVal = this.maxDepth;
    }

    const videoTime = getMaxDepth.getClosestTime(this.ipd, retVal) / 1000;
    const timeToZero = getMaxDepth.getClosestTime(this.ipd, 0) / 1000;

    return ((videoTime - timeToZero) / (this.maxVideoTime - timeToZero)) * 100;
  }

  getBoarderStyle(payout: number, color: string): string {
    let returnValue = `top: ${payout}px;`;
    if (color != null) {
      returnValue = `${returnValue} border-style: solid; border-color: ${color == null ? 'none' : color}; 
    border-width: 2px;`;
    }
    return returnValue;

    // let returnString = '';
    // if (this.payoutToPercentage(payout) >= 90) {
    //   const offset = -2;
    //   returnString = `${this.payoutToTopStyle(payout, offset)};`;
    // } else if (this.payoutToPercentage(payout) >= 40 && this.payoutToPercentage(payout) < 90) {
    //   const offset = -1;
    //   returnString = `${this.payoutToTopStyle(payout, offset)};`;
    // } else {
    //   const offset = 0;
    //   returnString = `${this.payoutToTopStyle(payout, offset)};`;
    // }

    // returnString =
    // `${returnString} border-style: solid; border-color: ${color}; border-width: 2px;`;

    // return returnString;
  }

  getPayoutStyle(payout: number, color: string): string {
    let returnString = `${this.payoutToTopStyle(payout, 0)};`;

    returnString = `${returnString}`;

    if (color !== null) {
      returnString = `${returnString} border-style: solid; border-width: 2px; border-color: ${color};`;
    }

    return returnString;
  }

  getPayoutCardStyle(value: number, color: string): string {
    const graph = document.getElementsByClassName('centerColumn')[0];

    let height = 0;
    if (graph) {
      height = parseInt(
        window.getComputedStyle(graph, null).getPropertyValue('height'),
        10,
      );
    }
    if (value > height) {
      // eslint-disable-next-line no-param-reassign
      value = height;
    }
    let returnString = `top: ${value - 15}px;`;

    if (color !== null) {
      returnString = `${returnString} border-style: solid; border-width: 2px; border-color: ${color};`;
    }

    return returnString;
  }

  // Generates a style section to set the 'top' atribute
  // Can also take an offset in pixels
  payoutToTopStyle(payout: number, offset = 0): string {
    const percentage = this.payoutToPercentage(payout);
    return `top: calc( ${percentage}% + ${offset}px)`;
  }

  payoutToTopnumber(payout: number): number {
    return this.payoutToPercentage(payout) / 100;
  }

  // Generates a style section to set the 'height' atribute
  payoutToHeightStyle(): string {
    if (!this.maxVideoTime) return 'height: 0%';
    const timeToZero = getMaxDepth.getClosestTime(this.ipd, 0) / 1000;
    let percentage = ((this.currentVideoTime - timeToZero)
      / (this.maxVideoTime - timeToZero)) * 100;
    if (Math.abs(this.currentVideoTime - this.maxVideoTime) < 1) {
      percentage = 100;
    }
    percentage = Math.max(0, Math.min(percentage, 100));
    this.$emit('updateCurrentVideoPercent', percentage);
    return `height: ${percentage}%`;
  }

  // eslint-disable-next-line class-methods-use-this
  calculateMultiMarkerStyle(markers: Marker[]): string {
    let style = '; background:linear-gradient(to right ';
    const colors = new Set();
    markers.forEach((m) => colors.add(m.getColor()));

    let i = 0;
    colors.forEach((c) => {
      const p = 100 / colors.size;
      style += `, ${c} ${p * i}% `;
      style += `, ${c} ${p * (i + 1)}% `;
      i += 1;
    });

    style += ')';
    return style;
  }

  currentPayoutDynamicStyle(): string {
    let style = this.payoutToTopStyle(this.maxDepth, -11);
    if (this.currentMarker) { style += '; opacity: 0.0'; }
    return style;
  }

  markerDynamicStyle(marker: Marker): string {
    if (marker.getDistance() === -1) {
      return '';
    }
    // this style dictates at what height the marker will be drawn
    let style = `top: ${this.getTopPixels(marker.getDistance(), true)}px;`;

    // If there are multiple markers in the same spot,
    // we are going to create a color gradient
    style += `${'; background-color:'}${marker.getColor()}`;

    return style;
  }

  getDistanceUnits(): string {
    return this.displayImperial ? 'ft' : 'm';
  }

  getDisplayDistance(value: number): number {
    return this.displayImperial
      ? Math.round(value * 10) / 10
      : Math.round(convert(value).from('ft').to('m') * 100) / 100;
  }

  getTextWidth(text: string, font = '16px Roboto'): number {
    const element = document.createElement('canvas');
    const context = element.getContext('2d');
    context.font = font;
    return context.measureText(text).width;
  }

  getDistanceReadout(): string {
    const depth = this.getDisplayDistance(this.distanceReadout / 100);
    return `${depth} ${this.getDistanceUnits()}`;
  }

  get entryManholeLabel(): string {
    if (this.asset.type === 'Manhole') {
      return this.asset.name;
    }
    return this.isUpstreamInspection ? this.downstreamMH : this.upstreamMH;
  }

  get destinationManholeLabel(): string {
    if (this.asset.type === 'Manhole') {
      return '';
    }
    return this.isUpstreamInspection ? this.upstreamMH : this.downstreamMH;
  }

  get upstreamMH(): string {
    return this.asset?.attributes['Wastewater_Structure_Up'] ?? '';
  }

  get downstreamMH(): string {
    return this.asset?.attributes['Wastewater_Structure_Dn'] ?? '';
  }

  get isUpstreamInspection(): boolean {
    const { direction } = this.inspection.report['generalInformation'] as any;
    return direction === 'Upstream';
  }
}
