






































/* eslint-disable @typescript-eslint/no-explicit-any */
import CustomerDashboardMixin from '@/views/Dashboards/CustomerDashboard/CustomerDashboardMixin.vue';
import { WorkOrderStatusCount } from '@/store/metrics/types';
import { Component, Prop, PropSync } from 'vue-property-decorator';

@Component({})
export default class CustomerDashboardWorkOrderTable extends CustomerDashboardMixin {
  @PropSync('selectedGroup') synchedSelectedGroup: string;

  @Prop() readonly selectedTab: number;

  tableHeaders = [
    { value: 'unassigned', title: 'Unassigned' },
    { value: 'assigned', title: 'Assigned' },
    { value: 'followUpReq', title: 'Follow-Up' },
    { value: 'complete', title: 'Complete' },
    { value: 'total', title: 'Total' },
  ];

  get workOrderStatusCount(): WorkOrderStatusCount {
    if (!this.customerDataWorkOrderCounts) {
      return null;
    }
    return this.customerDataWorkOrderCounts[this.selectedTab ? 'lineSegments' : 'manholes'];
  }

  get tableData(): any[] {
    const tableCounts = Object.entries(
      this.workOrderStatusCount.taskTypeStatuses,
    ).map((obj) => ({ name: obj[0], ...obj[1] }));
    return tableCounts;
  }

  getRowValue(rowVal: any): string {
    return typeof rowVal === 'object' ? rowVal.count : rowVal;
  }

  selectGroup(groupName: string): void {
    const selectedGroup = groupName !== this.synchedSelectedGroup ? groupName : '';
    this.$emit(
      'taskTypeClick',
      selectedGroup,
    );
  }
}
