






















import { Component, Prop, Vue } from 'vue-property-decorator';
import { CodingFormData, ValidationError } from '../../types';

  @Component({})
export default class radioGroup extends Vue {
    @Prop() field: any;

    @Prop() errors: ValidationError[];

    @Prop({ default: 'Yes' }) trueLabel: string;

    @Prop({ default: 'No' }) falseLabel: string;

    @Prop({ default: false }) readonly: boolean;

    @Prop() readonly canEdit: boolean;

    @Prop() radioCodingForm: CodingFormData;

    radioChange(event: string | number | boolean, headername:string, headernumber: number): void {
      this.$emit('radioChange', headername, event, headernumber);
    }

    isMultipleOptions<T>(item: {enum: Array<T>}): boolean {
      return item.enum && item.enum.length > 1;
    }

    get radioClasses(): string {
      const fpd = this.field.required != null && this.field.required;
      const noErrors = fpd && !this.errors.find((e) => e.instancePath === `/${fpd.headername}`);
      const required = noErrors ? 'required-title' : 'radio-title';
      const multipleOptions = this.isMultipleOptions(this.field) ? 'multi-line' : 'single-line';

      return `${required} ${multipleOptions}`;
    }
}
