

















import { Component, Prop, PropSync } from 'vue-property-decorator';
import IntegrityTable from '@/components/IntegrityTable/IntegrityTable.vue';
import { SelectProjectData } from '@/store/users/types';
import { ProjectListing } from '@/store/project/types';
import { namespace } from 'vuex-class';
import AdminPanelMixin from '../AdminPanelMixin.vue';

const projectModule = namespace('project');

@Component({
  components: {
    IntegrityTable,
  },
})
export default class SelectProjects extends AdminPanelMixin {
  @PropSync('selectedProjects') synchedSelectedProjects: SelectProjectData[];

  @Prop() newUserCustomerGuids: string[];

  @projectModule.State('allProjectsData') allProjects:
    | ProjectListing[]
    | undefined;

  get selectProjectData(): SelectProjectData[] {
    if (!this.allProjects) {
      return [];
    }
    return this.allProjects
      .map((project) => ({
        project: project.name,
        projectGuid: project.guid,
        customer: project.customerGuid,
      }))
      .filter((project) => this.newUserCustomerGuids.includes(project.customer));
  }

  headers = [
    {
      text: 'Project',
      value: 'project',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Project Guid',
      value: 'projectGuid',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Customer',
      value: 'customer',
      sortable: true,
      filterable: true,
    },
  ];

  matchFilters = [
    {
      header: 'project',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'projectGuid',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'customer',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
  ];

  /**
   * @description fill out the filter values for the table.
   * @returns filter values
   */
  get filterValues(): any {
    if (!this.selectProjectData) {
      return;
    }

    const localFilterValues = {};

    this.headers.forEach((header) => {
      if (!header.filterable) return;

      localFilterValues[header.value] = [];

      this.selectProjectData.forEach((projectData: SelectProjectData) => {
        let checkVal: string;

        try {
          checkVal = projectData[header.value];
        } catch {
          checkVal = '';
        }

        if (
          checkVal
          && checkVal !== ''
          && Array.isArray(localFilterValues[header.value])
          && !localFilterValues[header.value].includes(checkVal)
        ) {
          localFilterValues[header.value].push(checkVal);
        }
      });
      localFilterValues[header.value].sort();
    });
    // eslint-disable-next-line consistent-return
    return localFilterValues;
  }
}
