
import { Prop, Watch } from 'vue-property-decorator';
import { InspectionResponse } from '@/components/CodingForm/types';
import { PRIORITY_ITEMS, WORK_ORDER_STATUSES } from '@/common/Constants';
import { namespace } from 'vuex-class';
import { ProjectActions } from '@/store/project/actions';
import { DeploymentsActions } from '@/store/deployments/actions';
import ReportInputMixin from './ReportInputMixin.vue';
import { SubInspectionWorkOrder } from './types';

// eslint-disable-next-line no-shadow
export enum WorkOrderfieldType {
  TEXT = 'text',
  BOOLEAN = 'boolean',
  DATE = 'date',
  TIME = 'time',
  DATETIME = 'datetime',
  NUMBER = 'number',
}

const projectModule = namespace('project');

const deploymentModule = namespace('deployments');

export default abstract class ReportInterface extends ReportInputMixin {
  @projectModule.Action(ProjectActions.FETCH_NAMES) getProjectNames;

  @projectModule.State('names') projectNames: string[];

  @projectModule.State('loadNames') isProjectNameLoading: boolean;

  @projectModule.State('projectGuids') projectGuids: string[];

  @deploymentModule.State('deploymentId') deploymentId: string | undefined;

  @deploymentModule.Action(DeploymentsActions.FETCH_DEPLOYMENT_ID) fetchDeploymentId;

  // #region Props
  @Prop() codingDetail: InspectionResponse;

  @Prop() assetType: string;

  @Prop({ default: '' }) workOrderReport: string;

  @Prop({ default: null }) item: any;

  @Prop({ default: true }) readonly displayImperial!: boolean;

  @Prop({ default: true }) canPlan: boolean;

  @Prop() dataStandardFormat: any;

  @Prop() dataStandardSchema: any;
  // #endregion

  // #region Abstract fields

  /**
   * Fields of the work order, containing name, value, and optional type.
   * To be defined by the subclass.
   */
  abstract workOrderFields: {
    name: string;
    value: string;
    type?: WorkOrderfieldType;
  }[];

  /**
   * The data for the work order, structure to be defined by subclass.
   */
  abstract workOrderData;
  // #endregion

  /**
   * JSON representation of the work order.
   */
  private workOrderJson: any = {};

  /**
   * Array of sub inspection data objects
   */
  subInspectionData: SubInspectionWorkOrder[] = [];

  private hasInitialized = false;

  mounted(): void {
    if (this.codingDetail?.inspections == null
        || this.codingDetail.inspections.length === 0) {
      return;
    }
    const inspection = this.codingDetail.inspections[0];
    if (!inspection?.jsonData) {
      return;
    }
    if (this.projectNames == null
      && !this.isProjectNameLoading
      && this.projectGuids?.length !== 0
    ) {
      this.getProjectNames(this.projectGuids);
    }
    this.workOrderJson = JSON.parse(inspection.jsonData);
    this.fillWorkOrderData();
  }

  /**
   * Update the report data by merging it with jsonData.
   *
   * @param jsonData - The JSON data to merge with the current work order data.
   */
  updateReportData(jsonData: any): void {
    Object.assign(this.workOrderJson, jsonData);
    this.fillWorkOrderData();
  }

  /**
   * Overwrite sub inspection data from codingForm.
   * @param jsonData - The JSON data to override subInspectionData with.
   */
  updateReportSubData(jsonData: any[]): void {
    this.subInspectionData = jsonData;
  }

  /**
   * Emits a 'updateWorkOrderJson event.
   */
  updateWorkOrderJson(): void {
    this.$emit('updateWorkOrderJson', this.workOrderJson);
  }

  fillWorkOrderData(): void {
    // Avoid triggering watcher multiple times by creating temp variable
    const tempWorkOrderData = this.getAdditionalWorkOrderData();
    Object.assign(tempWorkOrderData, this.addWorkOrderFieldData());
    this.workOrderData = tempWorkOrderData;
  }

  /**
   * Watches for changes in `workOrderData`
   * and updates the work order JSON and any additional report data.
   */
  @Watch('workOrderData', { deep: true })
  onWorkOrderDataChange(): void {
    // Subclass will initialize workOrderData
    // But we don't want to send that null data to the coding form
    // So ignore initialization since mount will handle that
    if (!this.hasInitialized) {
      this.hasInitialized = true;
      return;
    }
    if (!this.workOrderJson) {
      return;
    }
    this.workOrderFields.forEach((field) => {
      if (!(field.name in this.workOrderData)) {
        return;
      }
      const val = this.workOrderData[field.name];
      this.$set(this.workOrderJson, field.value, val);
    });
    this.updateWorkOrderJson();
    this.updateAdditionalReportData();
  }

  // TODO maybe abstract function if there is a lot of differences between reports
  /**
   * This function updates the updateAdditionalReportData emit
   * This is used to send non schema related data to the coding form
   * Mostly for planning data in the header
   */
  updateAdditionalReportData(): void {
    const statusGuid = WORK_ORDER_STATUSES.find(
      (wo) => wo.description === this.workOrderData['status'],
    )?.guid;
    const priorityGuid = PRIORITY_ITEMS.find(
      (p) => p.name === this.workOrderData['priority'],
    )?.guid;
    const obj = {
      assignedTo: this.workOrderData['assignedTo'],
      scheduledDueDate: this.workOrderData['completedBy'],
      completeDate: this.workOrderData['dateCompleted'],
      statusGuid,
      priorityGuid,
      scheduledStartDate: this.workOrderData['startBy'],
    };
    this.$emit('updateAdditionalReportData', obj);
  }

  // TODO maybe abstract function if there is a lot of differences between reports
  /**
   * Get any additional fields not found in work order json in coding form
   * Mostly for planning data in the item object
   */
  getAdditionalWorkOrderData(): any {
    return {
      name: this.item && this.item.nodeName ? this.item.nodeName : '',
      route: this.item && this.item.routeName ? this.item.routeName : '',
      status: this.item && this.item.status ? this.item.status : '',
      workOrderNumber:
        this.item && this.item.workOrderNumber ? this.item.workOrderNumber : '',
      completedBy:
        this.item && this.item.scheduledDueDate
          ? this.formatDate(this.item.scheduledDueDate)
          : '',
      startBy:
        this.item && this.item.scheduledDueDate
          ? this.formatDate(this.item.scheduledStartDate)
          : '',
      dateCompleted:
        this.item && this.item.completeDate
          ? this.formatDate(this.item.completeDate)
          : '',
      workOrderType:
        this.item && this.item.taskTypeDefinition
          ? this.item.taskTypeDefinition
          : '',
      schedulingData: this.getSchedulingString(this.item),
      taskTypeGuid: this.item?.taskTypeGuid ?? '',
      priority: this.item?.priorityDescription ?? '',
      notCompletedWhy: this.item?.taskResultDesc ?? '',
      assignedTo: undefined,
      projectNameString: this.projectNameString ?? '',
    };
  }

  /**
   * Adds data for work order fields, formatting date fields as necessary.
   *
   * @returns A temporary object containing the work order field data.
   */
  addWorkOrderFieldData(): any {
    const tempWorkOrderData = {};
    if (!this.workOrderFields) {
      return tempWorkOrderData;
    }
    // eslint-disable-next-line consistent-return
    this.workOrderFields.forEach((field) => {
      if (!this.workOrderJson) {
        if (field.type === WorkOrderfieldType.BOOLEAN) {
          return false;
        }
        return '';
      }
      let val = this.workOrderJson[field.value];
      if (field.type === WorkOrderfieldType.DATE) {
        val = this.formatDate(val);
      }
      tempWorkOrderData[field.name] = val;
    });
    return tempWorkOrderData;
  }

  // #region util functions

  /**
   * Formats a date string to 'YYYY-MM-DD' format, adjusting for timezone offset.
   *
   * @param {string} dateString - The date string to format, typically in ISO format.
   * @returns {string} The formatted date string in 'YYYY-MM-DD' format.
   */
  formatDate(dateString: string): string {
    if (!dateString) {
      return '';
    }

    let date = new Date(dateString.substring(0, 10));

    if (date.toString() === 'Invalid Date') {
      return null;
    }

    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    date = new Date(date.getTime() + userTimezoneOffset);

    return date.toISOString().substring(0, 10);
  }

  /**
   * Retrieves the enumeration options for a specified property name.
   *
   * @param {string} propertyName - The name of the property for which to retrieve enum options.
   * @returns {string[]} An array of string values representing the enum options.
   */
  getEnumOptions(propertyName: string): string[] {
    const dataSchemaProperties = this.dataStandardSchema?.properties;
    if (dataSchemaProperties && propertyName in dataSchemaProperties) {
      return dataSchemaProperties[propertyName]?.enum ?? [];
    }
    return [];
  }

  getSchedulingString(item): string {
    if (item?.schedulingData === null) {
      return '';
    }
    try {
      const parsedData = JSON.parse(item?.schedulingData);
      return `Every ${parsedData.Interval} ${
        parsedData.Period
      } starting on ${parsedData.StartDate.substring(0, 10)}`;
    } catch {
      return '';
    }
  }

  changeOption(itemVal: string, option: string): void {
    this.$set(this.workOrderData, itemVal, option);
  }

  get workOrderStatusOptions(): string[] {
    return WORK_ORDER_STATUSES.map((x) => x.description);
  }

  get priorityStatusOptions(): string[] {
    return PRIORITY_ITEMS.map((x) => x.name);
  }

  get projectNameString(): string {
    let returnValue = '';

    if (this.projectNames?.length) {
      // eslint-disable-next-line prefer-destructuring
      returnValue = this.projectNames[0];
    }

    return returnValue;
  }
  // #endregion
}
