



























































































































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import IntegrityTable, {
  AdditionalFilterFunction, FillFunctionContainer, FilterFunction, processDate,
} from '@/components/IntegrityTable/IntegrityTable.vue';
import { namespace } from 'vuex-class';
import { ReleasesActions } from '@/store/releases/actions';
import { ReleaseOverviewObject, ReleaseTableData } from '@/store/releases/types';
import utils from '@/components/Report/util';
import { format } from 'date-fns';
import { ScopeHistoryActions } from '@/store/scopeHistory/actions';
import { uuid } from 'vue-uuid';
import ReleaseEdit from '../ProjectManager/ReleaseEdit/ReleaseEdit.vue';
import { DeliveryTotal, ProjectDeliveryTotals } from '../ScopeHistory/types';

const releaseModule = namespace('releases');
const scopeHistoryModule = namespace('scopeHistory');
const userPrefsModule = namespace('userPrefs');

@Component({
  components: {
    IntegrityTable,
    ReleaseEdit,
  },
})
export default class ReleaseOverview extends Vue {
    @Prop() readonly unitLabel;

    @Prop() readonly projectGuids;

    @Prop({ default: true }) readonly canEdit: boolean;

    @Prop({ default: true }) readonly canDelete: boolean;

    @releaseModule.State('releaseTableData') releaseTableData: ReleaseTableData[];

    @releaseModule.State('releaseOverview') releaseOverview: ReleaseOverviewObject[];

    @releaseModule.Action(ReleasesActions.FETCH_RELEASES_OVERVIEW) fetchAssignmentsData;

    @releaseModule.State('deleteReleaseLoading') deleteReleaseLoading: boolean;

    @releaseModule.Action(ReleasesActions.DELETE_RELEASE) deleteRelease;

    @releaseModule.State('deleteReleaseResponse') deleteReleaseResponse: string;

    @releaseModule.Action(ReleasesActions.GET_INSPECTION_RELEASES) getInspectionReleases;

    @scopeHistoryModule.Action(ScopeHistoryActions.FETCH_PROJECT_DELIVERY_TOTALS)
    fetchProjectDeliveryTotals;

    @scopeHistoryModule.State('projectDeliveryTotalsLoading') projectDeliveryTotalsLoading: boolean;

    @scopeHistoryModule.State('projectDeliveryTotalsError') projectDeliveryTotalsError: string | undefined;

    @scopeHistoryModule.State('projectDeliveryTotals') projectDeliveryTotals: ProjectDeliveryTotals;

    @userPrefsModule.State('displayImperial') displayImperial: boolean;

    activeTab = 0;

    openReleaseEdit = false;

    editReleaseModel = {};

    search = '';

    tabOptions = [
      { name: 'Manhole', value: 'Manhole' },
      { name: 'Line Segment', value: 'Line Segment' },
    ];

    tableHeaders = [
      {
        text: 'Release', value: 'releaseName', sortable: true, filterable: false, class: 'sticky', cellClass: 'sticky',
      },
      {
        text: '', value: 'navigation', sortable: false, filterable: false, class: 'sticky', cellClass: 'sticky',
      },
      {
        text: 'Total', value: 'totalReleased', sortable: true, filterable: true,
      },
      {
        text: 'Date Released', value: 'dateReleased', sortable: true, filterable: true,
      },
      {
        text: 'Date Shipped', value: 'dateShipped', sortable: true, filterable: true,
      },
      {
        text: 'Tracking Number', value: 'trackingInformation', sortable: true, filterable: true,
      },
      {
        text: 'Export Type', value: 'exportType', sortable: true, filterable: true,
      },
      {
        text: 'Transmittal Letter', value: 'transmittalFileName', sortable: true, filterable: true,
      },
      {
        text: 'Actions', value: 'actions', sortable: false, filterable: false, class: 'sticky-end', cellClass: 'sticky-end',
      },
    ];

    matchFilters = [
      {
        header: 'totalReleased', type: 'number', value: '', method: '', options: [], tempValue: '', open: false,
      },
      {
        header: 'dateReleased', type: 'date', value: '', method: '', options: [], tempValue: '', open: false,
      },
      {
        header: 'dateShipped', type: 'date', value: '', method: '', options: [], tempValue: '', open: false,
      },
      {
        header: 'trackingInformation', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
      },
      {
        header: 'exportType', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
      },
      {
        header: 'transmittalFileName', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
      },
    ]

    sortBy = 'dateShipped';

    tableHeight = '100%';

    showUndoDialog = false;

    releaseToUndo = '';

    releaseGuidToUndo = '';

    blankMatchFilters = {}

    snackbarMessage = '';

    snackbarColor = '';

    snackbarShow = false;

    get filteredTableData(): ReleaseOverviewObject[] {
      const assetTypes = ['Manhole', 'Line Segment'];
      if (this.releaseOverview) {
        return this.releaseOverview;
      }
      return undefined;
    }

    get additionalFilterFunctions(): AdditionalFilterFunction {
      const filterFunctions: FilterFunction[] = [
        {
          functionVariables: [['Manhole', 'Line Segment'], this.activeTab],
          filterFunction: function isAssetType(item, typeStrings, activeTab) {
            return item.assetType === typeStrings[activeTab];
          },
        },
      ];
      return {
        updateKey: uuid.v4(),
        filterFunctions,
      };
    }

    get fillTableDataFunctions(): FillFunctionContainer {
      const returnValue: FillFunctionContainer = {
        updateKey: uuid.v4(),
        fillFunctions: [
          {
            headerValue: 'dateShipped',
            functionVariables: [processDate.toString()],
            fillFunction: function fillDateShipped(
              item: ReleaseOverviewObject,
              processDateFunctionString: string,
            ): string {
            // eslint-disable-next-line no-new-func
              return new Function(`return ${processDateFunctionString};`)()(item.dateShipped);
            },
          },
          {
            headerValue: 'dateReleased',
            functionVariables: [processDate.toString()],
            fillFunction: function fillDateReleased(
              item: ReleaseOverviewObject,
              processDateFunctionString: string,
            ): string {
            // eslint-disable-next-line no-new-func
              return new Function(`return ${processDateFunctionString};`)()(item.dateReleased);
            },
          },
        ],
      };
      return returnValue;
    }

    mounted(): void {
      this.fetchProjectDeliveryTotals(this.projectGuidsList);
      this.fetchAssignmentsData(this.projectGuidsList[0]);
      this.updateTotalHeaderString();
      this.updateTotalValues();
    }

    @Watch('releaseOverview')
    onReleaseOverviewChange(): void{
      this.updateTotalValues();
    }

    @Watch('displayImperial')
    onDisplayImperialChange(): void {
      this.updateTotalHeaderString();
      this.updateTotalValues();
    }

    @Watch('releaseTableData')
    onReleaseTableDataChange(): void {
      this.updateTotalValues();
    }

    @Watch('activeTab')
    onActiveTabChange(): void {
      this.updateTotalHeaderString();
    }

    getTransmittalFileName(item: any): string {
      return item.transmittalFileName;
    }

    clearAllFilters(): void {
      (this.$refs.releaseOverviewTable as IntegrityTable).clearAllMatchFilters();
    }

    get projectGuidsList(): string[] {
    // TODO: ... ew
      return JSON.parse(this.projectGuids);
    }

    getFormattedDate(date: string): string {
      return format(new Date(date), 'yyyy-MM-dd');
    }

    edit(releaseOverviewObject: ReleaseOverviewObject): void {
      this.editReleaseModel = { ...releaseOverviewObject };
      if (this.editReleaseModel['dateShipped']) {
        this.editReleaseModel['dateShipped'] = this.getFormattedDate(releaseOverviewObject['dateShipped']);
      }
      this.openReleaseEdit = true;
    }

    toggleUndoDialog(name: string, guid: string): void {
      this.showUndoDialog = true;
      this.releaseToUndo = name;
      this.releaseGuidToUndo = guid;
    }

    async undo(): Promise<void> {
      try {
        await this.deleteRelease(this.releaseGuidToUndo);
        this.snackbarMessage = `${this.releaseToUndo} deleted`;
        this.snackbarColor = 'success';
      } catch (e) {
        this.snackbarMessage = (e as Error).message;
        this.snackbarColor = 'error';
      } finally {
        this.snackbarShow = true;
        this.showUndoDialog = false;
        await this.fetchAssignmentsData(JSON.parse(this.projectGuids)[0]);
        await this.getInspectionReleases(JSON.parse(this.projectGuids)[0]);
      }
    }

    /**
    * @description gets the delivery metrics from the current tab
    * @returns Delivery metrics for tab
    */
    get currentDeliveryTab(): DeliveryTotal | undefined {
      if (!this.projectDeliveryTotals) {
        return null;
      }
      if (this.activeTab === 0) {
        return this.projectDeliveryTotals.manhole;
      }
      return this.projectDeliveryTotals.lineSegment;
    }

    /**
    * @description format the delivery count to add commas and convert feet/meters
    * @returns formatted delivery count string
    */
    formatDeliveryValue(count: number): string {
      if (this.activeTab === 0) return utils.numberWithCommas(count);
      return `${utils.numberWithCommas(
        utils.getDisplayDistanceFtM(this.displayImperial, count),
      )} ${utils.getDistanceUnitsFtM(this.displayImperial)}`;
    }

    /**
    * @description gets the formatted delivery totals from the current tab
    * @returns formatted delivery total
    */
    get deliveryTotal(): string {
      return this.formatDeliveryValue(this.currentDeliveryTab?.total ?? 0);
    }

    /**
    * @description gets the formatted delivery coded values from the current tab
    * @returns formatted delivery coded values
    */
    get deliveryCoded(): string {
      return this.formatDeliveryValue(this.currentDeliveryTab?.coded ?? 0);
    }

    /**
    * @description gets the formatted delivery released values from the current tab
    * @returns formatted delivery released values
    */
    get deliveryReleased(): string {
      return this.formatDeliveryValue(this.currentDeliveryTab?.released ?? 0);
    }

    /**
    * @description Updates the 'totalReleased' values
    */
    updateTotalValues(): void {
      if (!this.releaseOverview) {
        return;
      }
      this.releaseOverview.forEach((data) => {
        // eslint-disable-next-line no-param-reassign
        data['totalReleased'] = this.calculateReleaseTotal(data);
      });
    }

    /**
    * @description Updates the 'totalReleased' header to include units for line segments
    */
    updateTotalHeaderString(): void {
      const totalString = 'Total';
      const isManholeTab = this.tabOptions[this.activeTab].name === 'Manhole';
      const totalHeaderString = isManholeTab ? totalString : `${totalString} (${utils.getDistanceUnitsFtM(this.displayImperial)})`;
      const totalReleasedHeader = this.tableHeaders.find((header) => header.value === 'totalReleased');
      if (totalReleasedHeader) {
        totalReleasedHeader.text = totalHeaderString;
      }
    }

    /**
    * @description gets the 'totalReleased' value for the table
    * @returns the 'totalReleased' for the table
    */
    calculateReleaseTotal(item: ReleaseOverviewObject): number {
      const releases = this.releaseTableData.filter(
        (value) => value.releaseGuid === item.releaseGuid,
      );

      if (item.assetType === 'Manhole') {
        return releases.length;
      }

      const totalLength = releases.reduce((a, b) => a + b.length ?? 0, 0);
      return utils.getDisplayDistanceFtM(this.displayImperial, totalLength);
    }
}
