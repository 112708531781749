














import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ProjectData } from '@/store/project/types';
import { ProjectActions } from '@/store/project/actions';
import { RoutingActions } from '@/store/routing/actions';
import { AssetData, AssetRef, Inspection } from '@/types';
import { RoutingData, RoutingDTONode } from '@/store/routing/types';
import AssetTable from '../../components/AssetTable/AssetTable.vue';

const projectModule = namespace('project');
const routingModule = namespace('routing');

@Component({ components: { AssetTable } })
/* eslint-disable no-nested-ternary */
export default class Inventory extends Vue {
  @projectModule.State('data') project: ProjectData | undefined;

  @projectModule.State('loadError') loadError;

  @projectModule.State('loading') loading: boolean;

  @projectModule.Action(ProjectActions.FETCH_PROJECT_TABLE_DATA) fetchProjectTableData;

  @routingModule.Action(RoutingActions.FETCH_ROUTING_DATA) fetchRoutingData;

  @routingModule.Action(RoutingActions.FETCH_ROUTE_ASSETS) fetchRouteAssets;

  @routingModule.State('routingData') routingData: RoutingData[] | undefined;

  @routingModule.State('routeAssets') routingAssets: RoutingDTONode[];

  assetData: AssetData[] = [];

  hasAssetDataFilled = false;

  roles: string[] = [];

  get projectIds(): string[] {
    return JSON.parse(this.$route.params.ids);
  }

  get tableData(): AssetData[] {
    return this.loading || !this.hasAssetDataFilled ? undefined : this.assetData;
  }

  async mounted(): Promise<void> {
    this.fetchProjectTableData(this.projectIds).then(async () => {
      if (this.project) {
        this.fillAssetData();
        await this.fetchRoutingData(this.projectIds[0]);
        this.routingData.forEach(async (route, index) => {
          await this.fetchRouteAssets(route.guid);
          this.routingData[index].nodes = [...this.routingAssets];
        });
      }
    });
  }

  fillAssetData(): void {
    this.hasAssetDataFilled = false;
    this.assetData = [];
    // Populating the assetData array using the project asset data
    this.project.assets.forEach((asset) => {
      // initial validation
      if (
        asset.guid == null
        || asset.guid === ''
        || asset.name == null
        || asset.name === ''
        || asset.type == null
      ) {
        // bad asset, continue
        return;
      }

      let attributes = null;

      if (asset.attributes) {
        if (typeof asset.attributes === 'string') attributes = JSON.parse(asset.attributes.replace(/&quot;/g, '"'));
        else attributes = asset.attributes;
      }

      asset.inspections.sort((i) => Date.parse(i.date));

      const latestInspection = asset.inspections[0];

      const assetData: AssetData = {
        guid: asset.guid,
        name: asset.name,
        score: this.getScore(asset),
        taskResult: asset.taskResult,
        type: asset.type,
        visible: true,
        hasCustomerDeliverables: asset.hasCustomerDeliverables,
        location: [],
        status: asset.status,
        codingStatus: latestInspection && latestInspection.codingStatus
          ? latestInspection.codingStatus.description
          : null,
        overallQuick: asset.overallScoring ? asset.overallScoring.overallQuick : null,
        overallIndex: asset.overallScoring ? asset.overallScoring.overallIndex : null,
        overallRating: asset.overallScoring ? asset.overallScoring.overallRating : null,
        structuralQuick: asset.overallScoring ? asset.overallScoring.structuralQuick : null,
        structuralIndex: asset.overallScoring ? asset.overallScoring.structuralIndex : null,
        structuralRating: asset.overallScoring ? asset.overallScoring.structuralRating : null,
        omQuick: asset.overallScoring ? asset.overallScoring.omQuick : null,
        omIndex: asset.overallScoring ? asset.overallScoring.omIndex : null,
        omRating: asset.overallScoring ? asset.overallScoring.omRating : null,
        instDate: attributes ? this.getDate(attributes.DateInstalled) : null,
        owner: asset.owner,
        sewerUse: latestInspection && latestInspection.sewerUse
          ? latestInspection.sewerUse
          : asset.sewerUse,
        comments: attributes ? attributes.Comments : null,
        assetId: attributes ? attributes.ID : null,
        upstreamId: attributes ? attributes.Wastewater_Structure_Up : null,
        downstreamId: attributes ? attributes.Wastewater_Structure_Dn : null,
        street: latestInspection && latestInspection.street
          ? latestInspection.street
          : attributes
            ? attributes.Street
            : null,
        city: attributes ? attributes.City : null,
        basin: attributes ? attributes.Basin : null,
        pipeSize: this.getPipeSize(latestInspection, attributes),
        pipeMaterial: latestInspection && latestInspection.material
          ? latestInspection.material
          : attributes
            ? attributes.PipeMaterial
            : null,
        lastInspected: latestInspection ? latestInspection.date : null,
        platform: asset.platform ? asset.platform : null,
        customerWorkOrder: asset.customerWorkOrder ? asset.customerWorkOrder : null,
        batchNumber: asset.batchNumber ? asset.batchNumber : null,
        gisLength: asset.gisLength ?? 0,
        lengthSurveyed: asset.lengthSurveyed ?? 0,
      };

      this.assetData.push(assetData);
    });
    this.hasAssetDataFilled = true;
  }

  getScore(asset: AssetRef): number {
    if (asset.priority !== null) {
      return asset.priority;
    }

    // Find most recent asset with score
    const latestInspection = asset.inspections.find((i) => i.defectScore);
    if (latestInspection?.defectScore) {
      return latestInspection.defectScore;
    }

    return -1;
  }

  getPipeSize(latestInspection: Inspection, attributes: any): string {
    let retVal = latestInspection && latestInspection.pipeSize
      ? latestInspection.pipeSize
      : attributes
        ? attributes.PipeSize
        : null;

    if (typeof retVal === 'number') return retVal.toString();

    // Sanitize "number x number" entries to a single digit
    if (retVal && retVal.includes('x')) {
      retVal = retVal.replace(/\s[x]\s\d/g, '');
    }

    // remove quotes
    if (retVal && retVal.includes('"')) {
      retVal = retVal.replaceAll('"', '');
    }

    // remove decimals
    if (retVal && retVal.includes('.')) {
      // eslint-disable-next-line prefer-destructuring
      retVal = retVal.split('.')[0];
    }

    return retVal;
  }

  deleteLocalAsset(asset: AssetData): void {
    // delete asset from local list
    this.assetData.splice(this.assetData.indexOf(asset), 1);
    this.project.assets.splice(this.project.assets.findIndex((as) => as.guid === asset.guid), 1);
  }

  getDate(date: string): string {
    if (new Date(date) > new Date('1/1/1920')) {
      const formattedDate = new Date(date).toLocaleString();
      return formattedDate !== 'Invalid Date' ? formattedDate : null;
    }

    return '';
  }
}
