






























































































































































































































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { UserActions } from '@/store/users/actions';
import { UserData } from '@/store/users/types';
import {
  Component, Prop, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { BasicSelect } from 'vue-search-select';
import { ClientInfo, InvoicingInfo, TeamMember } from './types';

const userModule = namespace('users');

@Component({
  components: {
    BasicSelect,
  },
})
export default class MPSSectionContacts extends Vue {
  @Prop() readonly canEditMPS: boolean;

  @Prop() readonly requiredField;

  @PropSync('valid') synchedValid;

  @PropSync('clientInfo') syncedClientInfo: ClientInfo[];

  @PropSync('teamMembers') syncedTeamMembers: TeamMember[];

  @PropSync('crewAccommodations') syncedCrewAccommodations: string;

  @PropSync('invoicingInfo') syncedInvoicingInfo;

  @userModule.Action(UserActions.FETCH_ALL_USER_DATA) fetchAllUserData;

  @userModule.Action(UserActions.FETCH_ALL_DETAILED_USER_DATA) fetchAllDetailedUserData;

  @userModule.State('allUserData') allUsers: UserData[] | undefined;

  @userModule.State('loadError') loadUsersError;

  @Watch('newClientPhone')
  onNewClientPhoneChange(): void {
    this.newClientPhone = this.formatPhone(this.newClientPhone);
  }

  @Watch('newInvoicingPhone')
  onNewInvoicingPhoneChange(): void {
    this.newInvoicingPhone = this.formatPhone(this.newInvoicingPhone);
  }

  @Watch('newMemberPhone')
  onNewMemberPhoneChange(): void {
    this.newMemberPhone = this.formatPhone(this.newMemberPhone);
  }

  @Watch('newPhoneRegion')
  onNewPhoneRegionChange(): void {
    this.newClientPhone = this.formatPhone(this.newClientPhone);
    this.newInvoicingPhone = this.formatPhone(this.newInvoicingPhone);
    this.newMemberPhone = this.formatPhone(this.newMemberPhone);
    this.formatRegionCode();
  }

  @Watch('newPhoneCode')
  onNewPhoneRegionCodeChange(): void {
    this.formatRegionCode();
  }

  userNameList = [];

  newClientFirstName = '';

  newClientLastName = '';

  newClientTitle = '';

  newClientPhone = '';

  newClientEmail = '';

  newClientRole = '';

  newClientCompany = '';

  newClientAddress = '';

  newClientSystemOwner = false

  addClientDialog = false

  userNameModel = { value: -1, text: '' };

  validName = false;

  validInvoice = false;

  validUser = false;

  newMemberName = '';

  newMemberTitle = '';

  newMemberPhone = '';

  newMemberEmail = '';

  newMemberDept = '';

  addMemberDialog = false;

  addCustomFieldDialog = false;

  validClient = false as boolean;

  newInvoicingFirstName = '';

  newInvoicingLastName = '';

  newInvoicingTitle = '';

  newInvoicingPhone = '';

  newInvoicingEmail = '';

  newInvoicingDept = '';

  newInvoicingAddress = '';

  newInvoicingCompany = '';

  addInvoicingInfoDialog = false;

  isEditing = false;

  editIndex = -1;

  newPhoneRegion = {
    name: 'United States',
    placeholder: '(123) 456-7890',
    rules: [(v: string): string | boolean | number => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) || 'Phone number not valid'],
  };

  newPhoneCode = '+1';

  departments = [
    'Project Management',
    'Operations',
    'Reporting / Internal Support',
    'Sales Support',
    'Client Information',
  ];

  roles = [
    'Primary Contact',
    'Deliverable Contact',
    'Invoicing Contact',
  ]

  rzTitles = [
    'Director Of Operations',
    'Project Manager',
    'Reporting Manager',
    'Reporting Technician',
    'Field Supervisor',
    'Crew Lead',
  ];

  phoneRegions = [
    {
      name: 'United States',
      placeholder: '(123) 456-7890',
      rules: [(v: string): string | boolean | number => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) || 'Phone number not valid'],
    },
    {
      name: 'International',
      placeholder: '123 456 7890',
      rules: [(v: string): string | boolean | number => /^(\+[1-9]{1,2}\s)?(\d{3}\s){2}\d{4}(\s\d{4})?$/.test(v) || 'Phone number not valid'],
    },
  ];

  emailRules = [(v: string): string | boolean => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email must be valid'];

  get formValid(): boolean {
    return this.syncedClientInfo.some((ci) => ci.isSystemOwner === true);
  }

  @Watch('syncedClientInfo', { immediate: true, deep: true })
  onSyncedClientInfoChange(): void {
    this.synchedValid = this.formValid;
  }

  @Watch('newMemberName')
  onNewMemberName(): void {
    if (this.newMemberName != null && this.newMemberName !== '') {
      this.validName = true;
    } else {
      this.validName = false;
    }
  }

  mounted(): void {
    // eslint-disable-next-line prefer-const
    let dupeCheck = [];
    this.fetchAllUserData().then(() => {
      this.allUsers.forEach((user) => {
        if (dupeCheck.indexOf(`${user.firstname} ${user.lastname}`) === -1 && user.firstname.length > 0 && user.lastname.length > 0) {
          dupeCheck.push(`${user.firstname} ${user.lastname}`);
          this.userNameList.push(`${user.firstname} ${user.lastname}`);
        }
      });
    });

    if (this.syncedTeamMembers.length <= 0) {
      this.addUserAsMember();
    }
  }

  async addUserAsMember(): Promise<void> {
    if (this.allUsers == null || this.allUsers.length === 0) {
      await this.fetchAllUserData();
    }

    const user = this.allUsers.find((usr) => usr.useridentity === `auth0|${this.$auth.user.id}`);

    if (user == null) {
      return;
    }

    this.newMemberName = `${user.firstname} ${user.lastname}`;
    this.newMemberTitle = 'Project Manager';
    this.newMemberPhone = '';
    this.newMemberEmail = this.$auth.user.email;
    this.newMemberDept = 'Project Management';
    this.addMember();
  }

  removeClient(index: number): void {
    this.syncedClientInfo.splice(index, 1);
  }

  addClient(): void {
    const newClient = {
      firstName: this.newClientFirstName,
      lastName: this.newClientLastName,
      title: this.newClientTitle,
      phone: this.newClientPhone,
      phoneCode: this.newPhoneCode,
      phoneDetails: this.newPhoneRegion,
      email: this.newClientEmail,
      dept: '',
      role: this.newClientRole,
      address: this.newClientAddress,
      company: this.newClientCompany,
      isSystemOwner: this.newClientSystemOwner,
    };

    if (this.isEditing) {
      this.removeClient(this.editIndex);
    }

    this.syncedClientInfo.push(newClient);

    this.newClientFirstName = '';
    this.newClientLastName = '';
    this.newClientTitle = '';
    this.newClientPhone = '';
    this.newClientEmail = '';
    this.newClientRole = '';
    this.newClientCompany = '';
    this.newClientAddress = '';
    this.newClientSystemOwner = false;
    this.addClientDialog = false;
    this.resetPhone();
    this.isEditing = false;
  }

  editContact(info: ClientInfo, index: number): void {
    this.newClientFirstName = info.firstName;
    this.newClientLastName = info.lastName;
    this.newClientTitle = info.title;
    this.newClientPhone = info.phone;
    this.newPhoneCode = info.phoneCode;
    this.newPhoneRegion = info.phoneDetails;
    this.newClientEmail = info.email;
    this.newClientRole = info.role;
    this.newClientAddress = info.address;
    this.newClientCompany = info.company;
    this.newClientSystemOwner = info.isSystemOwner;
    this.addClientDialog = true;
    this.isEditing = true;
    this.editIndex = index;
  }

  removeMember(index: number): void {
    this.syncedTeamMembers.splice(index, 1);
  }

  addMember(): void {
    const newMember = {
      firstName: this.newMemberName.split(' ')[0],
      lastName: this.newMemberName.split(' ').length > 1 ? this.newMemberName.split(' ')[1] : '',
      title: this.newMemberTitle,
      phone: this.newMemberPhone,
      phoneCode: this.newPhoneCode,
      phoneDetails: this.newPhoneRegion,
      email: this.newMemberEmail,
      dept: this.newMemberDept,
    };

    if (this.isEditing) {
      this.removeMember(this.editIndex);
    }

    this.syncedTeamMembers.push(newMember);
    this.resetMemberForm();
    this.isEditing = false;
  }

  editMember(info: TeamMember, index: number): void {
    this.newMemberName = `${info.firstName} ${info.lastName}`;
    this.newMemberTitle = info.title;
    this.newMemberPhone = info.phone;
    this.newPhoneCode = info.phoneCode;
    this.newPhoneRegion = info.phoneDetails;
    this.newMemberEmail = info.email;
    this.newMemberDept = info.dept;
    this.addMemberDialog = true;
    this.isEditing = true;
    this.editIndex = index;
  }

  resetMemberForm(): void {
    this.newMemberName = '';
    this.validName = false;
    this.validInvoice = false;
    this.validUser = false;
    this.newMemberTitle = '';
    this.newMemberPhone = '';
    this.newMemberEmail = '';
    this.newMemberDept = '';
    this.addMemberDialog = false;
    this.addCustomFieldDialog = false;
    this.syncedCrewAccommodations = '';

    this.resetPhone();
  }

  addInvoicingInfo(): void {
    const newInvoicingInfo = {
      firstName: this.newInvoicingFirstName,
      lastName: this.newInvoicingLastName,
      title: this.newInvoicingTitle,
      phone: this.newInvoicingPhone,
      phoneCode: this.newPhoneCode,
      phoneDetails: this.newPhoneRegion,
      email: this.newInvoicingEmail,
      dept: this.newInvoicingDept,
      address: this.newInvoicingAddress,
      company: this.newInvoicingCompany,
    };

    if (this.isEditing) {
      this.removeInvoicingInfo(this.editIndex);
    }

    this.syncedInvoicingInfo.push(newInvoicingInfo);

    this.newInvoicingFirstName = '';
    this.newInvoicingLastName = '';
    this.newInvoicingTitle = '';
    this.newInvoicingPhone = '';
    this.newInvoicingEmail = '';
    this.newInvoicingDept = '';
    this.newInvoicingCompany = '';
    this.newInvoicingAddress = '';
    this.addInvoicingInfoDialog = false;
    this.resetPhone();
    this.isEditing = false;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  editInvoicing(info: InvoicingInfo, index: number): void {
    this.newInvoicingFirstName = info.firstName;
    this.newInvoicingLastName = info.lastName;
    this.newInvoicingTitle = info.title;
    this.newInvoicingDept = info.dept;
    this.newInvoicingPhone = info.phone;
    this.newPhoneCode = info.phoneCode;
    this.newPhoneRegion = info.phoneDetails;
    this.newInvoicingEmail = info.email;
    this.newInvoicingAddress = info.address;
    this.newInvoicingCompany = info.company;
    this.addInvoicingInfoDialog = true;
    this.isEditing = true;
    this.editIndex = index;
  }

  removeInvoicingInfo(index: number): void {
    this.syncedInvoicingInfo.splice(index, 1);
  }

  resetValidation(): void {
    (this.$refs.contactsForm as any).reset();
  }

  resetPhone(): void {
    this.newClientPhone = '';
    this.newInvoicingPhone = '';
    this.newMemberPhone = '';
    [this.newPhoneRegion] = this.phoneRegions;
    this.newPhoneCode = '+1';
  }

  formatRegionCode(): void {
    if (this.newPhoneRegion.name === 'United States') {
      this.newPhoneCode = '+1';
    } else if (!this.newPhoneCode.includes('+')) {
      this.newPhoneCode = `+${this.newPhoneCode}`;
    }
  }

  formatPhone(phone: string): string {
    if (this.newPhoneRegion.name === 'United States') {
      const x = phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      return !x[2]
        ? x[1]
        : `(${x[1]}) ${x[2]}${x[3]
          ? `-${x[3]}`
          : ''}`;
    }

    if (this.newPhoneRegion.name === 'International') {
      const x = phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,4})/);
      // eslint-disable-next-line no-nested-ternary
      return !x[2]
        ? x[1]
        : x[4]
          ? `${x[1]} ${x[2]} ${x[3]} ${x[4]}`
          : `${x[1]} ${x[2]} ${x[3]}`;
    }

    return '';
  }
}
