









































































































































































import {
  Component, Emit, Prop, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import {
  AssetData, Condition, InspectionData, IPDEntry,
} from '@/store/asset/types';
import convert from 'convert-units';
import { namespace } from 'vuex-class';
import { DefectCodingAction } from '@/store/defectCoding/actions';
import { InspectionActions } from '@/store/inspection/actions';
import { MSIInspectionData } from '@/store/crossSections/types';
import { AssetActions } from '@/store/asset/actions';
import { AssetMutations } from '@/store/asset/mutations';
import { uuid } from 'vue-uuid';
import util from '../Maps/utils';
import DefectCoding from '../DefectCoding/DefectCoding.vue';
import { MSIDefectGraphState } from '../DefectGraph/types';
import IntegrityDelete from '../IntegrityDelete/IntegrityDelete.vue';
import IntegrityTable, { FillFunctionContainer } from '../IntegrityTable/IntegrityTable.vue';
import { DataTableHeader } from '../AssetTable/types';

const inspectionModule = namespace('inspection');
const defectCodingModule = namespace('defectCoding');
const assetModule = namespace('asset');

@Component({
  components: {
    DefectCoding,
    IntegrityDelete,
    IntegrityTable,
  },
})
export default class DefectTable extends Vue {
  vShow(vShow: any) {
    throw new Error('Method not implemented.');
  }

  @inspectionModule.Action(InspectionActions.FETCH_CONDITION_DETAILS) fetchConditionDetails;

  @inspectionModule.State('conditionDetails') conditionDetails;

  @defectCodingModule.Action(DefectCodingAction.DELETE_DEFECT_DATA) deleteDefectData;

  @defectCodingModule.Action(DefectCodingAction.VALIDATE_AND_SCORE) validateAndScoreDefect;

  @defectCodingModule.Action(DefectCodingAction.CLEAR_VALIDATE_AND_SCORE)
    clearValidateAndScoreDefect;

  @defectCodingModule.State('validateAndScoreError') validateAndScoreError: unknown | undefined;

  @defectCodingModule.State('validateAndScoreLoading') validateAndScoreLoading: boolean;

  @defectCodingModule.State('defectCodingDeleteLoading') defectCodingDeleteLoading: boolean;

  @assetModule.Action(AssetActions.FETCH_ASSET_DATA) fetchAssetData;

  @assetModule.Mutation(AssetMutations.SET_INSPECTION_BY_ID) setInspectionById;

  expandTable = false;

  sortBy = 'distance';

  sortDesc = false;

  isEditing = false;

  isValidating = false;

  isMounted = false;

  editCondition: Condition = null;

  @PropSync('syncedInspection') realInspection!: InspectionData;

  @Prop() readonly jestInspection!: InspectionData;

  @Prop() readonly maxDepth!: number | undefined;

  @Prop() readonly displayImperial!: boolean;

  @Prop() readonly asset!: AssetData;

  @Prop() isDefectGraphValid!: boolean;

  @Prop() readonly msiDefectData: MSIInspectionData | undefined;

  @Prop() readonly defectState: string;

  @Prop() readonly ipd: IPDEntry[];

  @Prop() readonly canView: boolean;

  @Prop() readonly canEdit: boolean;

  @Prop() readonly canValidateScore: boolean;

  selectedItems = [];

  search = '';

  deleteText = 'Deleting defects will reset the Overall Inspection Score until it is validated';

  get inspection(): InspectionData {
    if (this.realInspection != null) {
      return this.realInspection;
    }
    return this.jestInspection;
  }

  get validateAndScoreMessage(): string {
    if (this.validateAndScoreError != null && typeof this.validateAndScoreError === 'string'
      && this.validateAndScoreError.indexOf('500') === -1) {
      if (this.validateAndScoreError === 'Succesful scoring!') {
        this.fetchConditionDetails(this.inspection.guid).then(() => {
          this.realInspection.conditionReport = this.conditionDetails;
        });
      }
      return (this.validateAndScoreError as string);
    }
    return 'An error occured';
  }

  get featureHeaders(): DataTableHeader[] {
    const returnValue = [
      { text: 'Code', value: 'code', filterable: true },
      { text: 'Distance', value: 'distance', filterable: true },
      { text: 'Structural Grade', value: 'structuralGrade', filterable: true },
      { text: 'O&M Grade', value: 'omGrade', filterable: true },
      { text: 'Clock Start/From', value: 'clockStartFrom', filterable: true },
      { text: 'Clock To', value: 'clockTo', filterable: true },
      { text: '1st Value', value: 'firstValue', filterable: true },
      { text: '2nd Value', value: 'secondValue', filterable: true },
      { text: 'Value Percent', value: 'valuePercent', filterable: true },
      { text: 'Continuous Index', value: 'continuousIndex', filterable: true },
    ] as DataTableHeader[];

    const originalOnlyHeaders = [
      { text: 'Step', value: 'step', filterable: true },
      { text: 'Remarks', value: 'remarks' },
      { text: 'Within Eight of Joint', value: 'withinEightOfJoint', filterable: true },
    ];

    const msiOnlyHeaders = [
      { text: 'Description', value: 'description' },
      { text: 'Diameter', value: 'diameter' },
      { text: 'Diameter (Width)', value: 'diameter2' },
      { text: 'Joint', value: 'joint' },
      { text: 'Remarks', value: 'remarks' },
    ];

    switch (this.defectState) {
      case MSIDefectGraphState.ORIGINAL:
        returnValue.push(...originalOnlyHeaders);
        break;
      case MSIDefectGraphState.MSI:
        returnValue.push(...msiOnlyHeaders);
        break;
      case MSIDefectGraphState.ALL:
        returnValue.push(...originalOnlyHeaders);
        returnValue.push(...msiOnlyHeaders);
        break;
      default:
        break;
    }

    if (this.canValidateScore) {
      returnValue.push(
        { text: 'Actions', value: 'guid', sortable: false },
      );
    }

    return returnValue;
  }

  matchFilters = [
    {
      header: 'code', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'distance', type: 'number', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'structuralGrade', type: 'number', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'omGrade', type: 'number', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'clockStartFrom', type: 'number', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'clockTo', type: 'number', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'firstValue', type: 'number', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'secondValue', type: 'number', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'valuePercent', type: 'number', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'continuousIndex', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'step', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'remarks', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
    {
      header: 'withinEightOfJoint', type: 'string', value: '', method: '', options: [], tempValue: '', open: false,
    },
  ];

  @Emit()
  // eslint-disable-next-line class-methods-use-this
  onDefectClickUpdatePanTiltZoom(report: Record<string, unknown>): Record<string, unknown> {
    return report;
  }

  get tableData(): Condition[] {
    let retVal: Condition[] = [];

    switch (this.defectState) {
      case MSIDefectGraphState.ORIGINAL:
        if (this.inspection !== undefined) retVal = this.inspection.conditionReport;
        break;
      case MSIDefectGraphState.MSI:
        if (this.msiDefectData) retVal = this.msiDefectTableData;
        break;
      case MSIDefectGraphState.ALL:
        retVal.push(...this.inspection.conditionReport);
        retVal.push(...this.msiDefectTableData);
        break;
      default:
        break;
    }

    // Flatten report to make it easier to use with integrity table
    // Can't be filled in filler object since the key is variable here.
    retVal.forEach((ret) => {
      Object.keys(ret.report).forEach((key) => {
        const value = ret.report[key];
        if (!ret[key]) {
          // eslint-disable-next-line no-param-reassign
          ret[key] = value;
        }
      });
    });

    return retVal;
  }

  get msiDefectTableData(): Condition[] {
    const retVal: Condition[] = [];

    if (this.msiDefectData !== undefined) {
      this.msiDefectData.defects.forEach((defect) => {
        retVal.push({
          guid: '',
          type: '',
          description: defect.description,
          distance: defect.distance,
          severity: '',
          code: defect.code,
          component: '',
          report: {
            clockStartFrom: defect.clock1,
            clockTo: defect.clock2,
            code: defect.code,
            continuousIndex: defect.continuous,
            description: defect.description,
            distance: defect.distance,
            firstValue: defect.value1,
            image: defect.photoFileName,
            omGrade: defect.grade,
            remarks: defect.remarks,
            secondValue: defect.value2,
            step: '',
            structuralGrade: defect.grade,
            valuePercent: defect.percent,
            withinEightOfJoint: '',
          },
        });
      });
    }

    return retVal;
  }

  @Watch('isMounted')
  onIsMounted(): void{
    if (this.isMounted && this.editCondition != null) {
      (this.$refs.DefectCodingComponentOnDefectTable as DefectCoding)
        .setFromModel(this.editCondition);
    }
  }

  @Watch('validateAndScoreError')
  onValidateAndScoreErrorChange(): void{
    if (this.validateAndScoreError != null) {
      this.isValidating = true;
    }
  }

  @Watch('isValidating')
  onValidatingChange(): void{
    if (!this.isValidating) {
      this.clearValidateAndScoreDefect();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getScoreColorTable(score: number): string {
    return util.getScoreColor(score);
  }

  // eslint-disable-next-line class-methods-use-this
  onDefectClick(rowVal: Record<string, unknown>): void {
    if (rowVal.distance as number > this.maxDepth) {
      return;
    }
    this.$emit('onDefectClick', rowVal.distance);
    this.onDefectClickUpdatePanTiltZoom(rowVal);
  }

  onEdit(model: Condition): void {
    this.isEditing = true;
    this.editCondition = model;
    if (this.isMounted) {
      (this.$refs.DefectCodingComponentOnDefectTable as DefectCoding)
        .setFromModel(this.editCondition);
    }
  }

  getDistance(distance: number): number {
    return this.displayImperial
      ? Math.round(distance * 10) / 10
      : Math.round(convert(distance).from('ft').to('m') * 10) / 10;
  }

  toggleTable(): void {
    this.expandTable = !this.expandTable;
    this.$emit('onToggleTable', this.expandTable);
  }

  async deleteSelectedItems(inspectionGuid: string): Promise<boolean> {
    const res = await this.deleteDefectDataClick(inspectionGuid, this.selectedItems);
    this.selectedItems = [];
    return res;
  }

  async deleteDefectDataClick(inspectionGuid: string, items: Condition[]): Promise<boolean> {
    try {
      const itemGuids = items.map((item) => item.guid);
      await this.deleteDefectData({ inspectionGuid, defectGuids: itemGuids }).then(() => {
        itemGuids.forEach((itemGuid) => {
          this.inspection.conditionReport.splice(this.inspection
            .conditionReport.indexOf(
              this.inspection.conditionReport.find((value) => value.guid === itemGuid),
            ), 1);
        });
      });
    } catch {
      return false;
    }
    return true;
  }

  closeValidationMessageMenu(): void {
    this.isValidating = false;

    if (this.validateAndScoreError != null && typeof this.validateAndScoreError === 'string'
      && this.validateAndScoreError.indexOf('500') === -1) {
      if (this.validateAndScoreError === 'Succesful scoring!') {
        const request = {
          assetId: this.asset.guid,
          inspectionId: this.inspection.guid,
        };
        this.fetchAssetData(request);
        this.setInspectionById(this.inspection.guid);
      }
    }
  }

  validateAndScore(): void{
    this.validateAndScoreDefect(this.inspection.guid);
  }
}
