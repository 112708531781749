









































import IntegrityTable, { FillFunctionContainer } from '@/components/IntegrityTable/IntegrityTable.vue';
import { Component, Prop } from 'vue-property-decorator';
import { OrganizationData } from '@/store/organizations/types';
import phoneNumberToPhoneInfo from '@/components/IntegrityPhoneNumberInput/types';
import IntegrityDelete from '@/components/IntegrityDelete/IntegrityDelete.vue';
import { uuid } from 'vue-uuid';
import { DetailedUserData } from '@/store/users/types';
import AdminPanelMixin from '../AdminPanelMixin.vue';
import OrganizationEditDialog from './OrganizationEditDialog.vue';

@Component({
  components: {
    IntegrityTable,
    OrganizationEditDialog,
    IntegrityDelete,
  },
})
export default class OrganizationsTable extends AdminPanelMixin {
  @Prop() readonly search: string;

  @Prop() readonly canEdit: boolean;

  @Prop() readonly canDelete: boolean;

  showEditDialog = false;

  currentEditItem = {};

  headers = [
    {
      text: 'Organization',
      value: 'name',
      sortable: true,
      filterable: true,
      class: 'sticky',
      cellClass: 'sticky',
    },
    {
      text: 'Address',
      value: 'address',
      sortable: true,
      filterable: true,
    },
    {
      text: 'System Admin',
      value: 'systemAdmin',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Email',
      value: 'email',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Phone',
      value: 'phoneNumber',
      sortable: true,
      filterable: true,
    },
    {
      text: 'Actions',
      value: 'actions',
      align: 'left',
      sortable: false,
      class: 'sticky-end',
      cellClass: 'sticky-end',
    },
  ];

  matchFilters = [
    {
      header: 'name',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'address',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'systemAdmin',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'email',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
    {
      header: 'phoneNumber',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
  ];

  get fillTableDataFunctions(): FillFunctionContainer {
    const returnValue: FillFunctionContainer = {
      updateKey: uuid.v4(),
      fillFunctions: [
        {
          headerValue: 'systemAdmin',
          functionVariables: [this.allUsers],
          fillFunction:
        /**
         * @description Find the user in the all users object based on their guid.
         * @param {string} userGuid User guid to search
         * @returns {string} first and last name of user
         */
        function findUserFromUserGuid(
          item: OrganizationData,
          allUsers: DetailedUserData[],
        ): string {
          if (!allUsers) {
            return '';
          }
          const foundUser = allUsers.find((u) => u.guid === item.systemAdminGuid);
          if (foundUser) {
            return `${foundUser.firstname} ${foundUser.lastname}`;
          }
          return '';
        },
        },
      ],
    };
    return returnValue;
  }

  async mounted(): Promise<void> {
    this.$root.$on('clearUserTableFilters', () => {
      (this.$refs.organizationTable as IntegrityTable).clearAllMatchFilters();
    });
  }

  /**
   * @description Get the data for the table that includes the System Admin's name.
   * @returns {OrganizationData[]} List of organizations
   */
  get organizationTableData(): OrganizationData[] {
    if (!this.organizationDataList) {
      return undefined;
    }
    return this.organizationDataList;
  }

  /**
   * @description Sets currentEditItem from the selected item guid.
   * @param {string} itemGuid
   */
  handleEdit(itemGuid: string): void {
    const foundItem = this.organizationDataList.find(
      (org) => org.guid === itemGuid,
    );
    const foundUser = this.allUsers.find(
      (user) => user.guid === foundItem.systemAdminGuid,
    );

    this.currentEditItem = {
      guid: foundItem.guid,
      organizationName: foundItem.name,
      address: foundItem.address,
      systemAdmin: foundUser,
      email: foundItem.email,
      phoneNumber: phoneNumberToPhoneInfo(foundItem.phoneNumber),
    };
    this.showEditDialog = true;
  }

  /**
   * @description Calls endpoint to delete organization and removes it from organizationDataList.
   * @param {OrganizationData} item OrganizationData object to remove.
   * @returns {Promise<boolean>} Returns true if organization was successfully deleted.
   */
  async handleDelete(item: OrganizationData): Promise<boolean> {
    try {
      const deleteIndex = this.organizationDataList.findIndex((value) => value.guid === item.guid);

      if (deleteIndex === -1) {
        // If we can not find the item in the organizationDataList
        return false;
      }

      await this.deleteOrganizationData(item.guid);
      this.organizationDataList.splice(
        deleteIndex,
        1,
      );
    } catch (e) {
      return false;
    }
    return true;
  }
}
