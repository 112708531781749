import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { uuid } from 'vue-uuid';
import { mutations } from './mutations';
import { actions } from './actions';
import { AdminState } from './types';

const state: AdminState = {
  data: undefined,
  loading: undefined,
  loadError: undefined,
  response: undefined,
  updateUserKey: uuid.v4(),
};

const admin: Module<AdminState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default admin;
