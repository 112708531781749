

















































































































import { ProcessingObject } from '@/store/processing/types';
import { Component, PropSync, Watch } from 'vue-property-decorator';
import IntegrityTable, {
  AdditionalFilterFunction,
  FillFunctionContainer,
  FilterFunction,
  processDateWithTableObject,
} from '@/components/IntegrityTable/IntegrityTable.vue';
import { uuid } from 'vue-uuid';
import { namespace } from 'vuex-class';
import { SnackBarActions } from '@/store/integritySnackBar/actions';
import { PROCESSING_STATUS } from '@/common/Constants';
import { ProcessingActions } from '@/store/processing/actions';
import { UserPermission } from '@/store/userpermissions/types';
import UserPermissionsMixin from '../UserPermissions/UserPermissionsMixin.vue';

const integritySnackBar = namespace('integritySnackBar');
const processingModule = namespace('processing');

@Component({
  components: {
    IntegrityTable,
  },
})
export default class Processing extends UserPermissionsMixin {
  @PropSync('activeTab') readonly syncedActiveTab: number;

  @processingModule.Action(ProcessingActions.PATCH_PROCESSINGS)
  patchProcessingData;

  @processingModule.Action(ProcessingActions.FETCH_PROCESSINGS)
  fetchProcessingData;

  @processingModule.State('processingEditError') processingEditError:
    | string
    | undefined;

  @processingModule.State('processingEditLoading  ')
  processingEditLoading: boolean;

  @processingModule.State('processings') processings: ProcessingObject[];

  @integritySnackBar.Action(SnackBarActions.SHOW) showSnackBar;

  tableHeight: string | number = '100%';

  search = '';

  selectedItems = [];

  editMode = false;

  editProcessingArray: ProcessingObject[] = [];

  tabOptions = [
    { name: 'Manhole', value: 'Manhole' },
    { name: 'Line Segment', value: 'Line Segment' },
  ];

  changedStatusFiltersFlag = false;

  get fillTableDataFunctions(): FillFunctionContainer {
    const returnValue: FillFunctionContainer = {
      updateKey: uuid.v4(),
      fillFunctions: [
        {
          headerValue: 'lastStatusUpdate',
          functionVariables: ['lastStatusUpdate'],
          fillFunction: processDateWithTableObject,
        },
        {
          headerValue: 'inspectionDate',
          functionVariables: ['inspectionDate'],
          fillFunction: processDateWithTableObject,
        },
        {
          headerValue: 'processingStatusItemGuid',
          functionVariables: [PROCESSING_STATUS],
          fillFunction:
            /**
             * @description Get the name of the processing status
             * @param {ProcessingObject} this item
             * @returns {object} maps the status guid to the name
             */
            function getStatusFromGuid(
              item: ProcessingObject,
              processingStatusMap: {
                name: string;
                guid: string;
              }[],
            ): string {
              const status = processingStatusMap.find(
                (s) => s.guid === item.processingStatusItemGuid,
              );
              if (status) {
                return status.name;
              }
              return '';
            },
        },
      ],
    };
    return returnValue;
  }

  get additionalFilterFunctions(): AdditionalFilterFunction {
    const filterFunctions: FilterFunction[] = [
      {
        functionVariables: [this.syncedActiveTab],
        filterFunction: function isCorrectAssetType(item, syncedActiveTab) {
          return (
            (syncedActiveTab === 0 && item.assetType === 'Manhole')
            || (syncedActiveTab === 1 && item.assetType === 'Line Segment')
          );
        },
      },
      {
        functionVariables: [],
        filterFunction: function filterDoNotProcessStatus(item) {
          return (
            item.processingStatusItemGuid !== '655bb960-ac3c-11ef-95c4-2332c74a73a5'
          );
        },
      },
    ];
    return {
      updateKey: uuid.v4(),
      filterFunctions,
    };
  }

  get processingTableData(): ProcessingObject[] {
    return this.processings;
  }

  processingTableHeaders = [
    {
      text: 'Asset ID',
      value: 'assetID',
      filterable: true,
      class: 'sticky',
      cellClass: 'sticky',
    },
    {
      text: 'Status',
      value: 'processingStatusItemGuid',
      editable: true,
      filterable: true,
      options: PROCESSING_STATUS,
    },
    { text: 'Inspection Date', value: 'inspectionDate', filterable: true },
    { text: 'Platform', value: 'platform', filterable: true },
    {
      text: 'Deployment Number',
      value: 'deploymentNumber',
      filterable: true,
    },
    { text: 'Failure Reason', value: 'failureReason', filterable: true },
    {
      text: 'Last Status Update',
      value: 'lastStatusUpdate',
      filterable: true,
    },
    {
      text: 'Processing Version',
      value: 'processingVersion',
      filterable: true,
    },
    { text: 'Processing Count', value: 'processingCount', filterable: true },
    {
      text: 'Actions',
      value: 'actions',
      filterable: false,
      sortable: false,
      align: 'start',
      class: 'sticky-end',
      cellClass: 'sticky-end',
    },
  ];

  processStatusPreFilledOptions = [
    'Pending Media',
    'Ready for Processing',
    'In Process',
    'Processing Failed',
    'With Processing Support',
    'Re-processing Needed',
  ];

  matchFilters = [
    {
      header: 'assetID',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
      filterable: true,
    },
    {
      header: 'processingStatusItemGuid',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
      filterable: true,
    },
    {
      header: 'inspectionDate',
      type: 'date',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
      filterable: true,
    },
    {
      header: 'platform',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
      filterable: true,
    },
    {
      header: 'deploymentNumber',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
      filterable: true,
    },
    {
      header: 'failureReason',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
      filterable: true,
    },
    {
      header: 'lastStatusUpdate',
      type: 'date',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
      filterable: true,
    },
    {
      header: 'processingVersion',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
      filterable: true,
    },
    {
      header: 'processingCount',
      type: 'number',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
      filterable: true,
    },
    {
      header: 'actions',
      type: 'string',
      value: '',
      method: '',
      options: [],
      tempValue: '',
      open: false,
    },
  ];

  getStatusColor(status: string): string {
    switch (status) {
      case 'Processed':
        return '#00800033';
      case 'Processing Failed':
      case 'Re-processing Needed':
        return '#FF000033';
      case 'Pending Media':
      case 'Ready for Processing':
      case 'In Process':
      case 'With Processing Support':
        return '#FFFF0033';
      default:
        return '';
    }
  }

  canReprocess(status: string): boolean {
    if (!this.hasPermissionProcessingReProcessInspection) return false;

    switch (status) {
      case 'Processing Failed':
      case 'Re-processing Needed':
      case 'With Processing Support':
        return true;
      default:
        return false;
    }
  }

  getStatus(processing: ProcessingObject): any {
    const processingToPush = { ...processing };
    return PROCESSING_STATUS
      .find((p) => p.name === processingToPush.processingStatusItemGuid);
  }

  saveProcessing(processing: ProcessingObject): void {
    this.saveProcessingStatus(processing, this.getStatus(processing));
  }

  saveProcessingStatus(processing: ProcessingObject, status: any): void {
    const { processingGuid } = processing;
    const foundEntryIndex = this.editProcessingArray.findIndex(
      (p) => p.processingGuid === processingGuid,
    );
    if (foundEntryIndex !== -1) {
      this.editProcessingArray.splice(foundEntryIndex, 1);
    }
    const processingToPush = { ...processing };
    if (status != null) {
      processingToPush.processingStatusItemGuid = status.guid;
    }
    this.editProcessingArray.push(processingToPush);
  }

  editProcessing(): void {
    if (this.isEditMode) {
      this.saveEdits();
    }
    this.editMode = !this.editMode;
  }

  async saveEdits(): Promise<void> {
    if (this.editProcessingArray.length === 0) {
      return;
    }

    await this.patchProcessingData(this.editProcessingArray)
      .then(() => {
        this.showSnackBar({
          message: 'Processing(s) Saved',
          color: 'green',
          showSnackBar: true,
        });
        this.editProcessingArray = [];
      })
      .catch((error) => {
        this.showSnackBar({
          message: this.processingEditError,
          color: 'red',
          showSnackBar: true,
        });
        console.error(error);
      });
  }

  async reprocess(processing: ProcessingObject): Promise<void> {
    const readyStatus = PROCESSING_STATUS
      .find((p) => p.name === 'Ready for Processing');
    this.saveProcessingStatus(processing, readyStatus);
    this.saveEdits();
    this.processingTableData.find(
      (p) => p.processingGuid === processing.processingGuid,
    )['processingStatusItemGuid'] = readyStatus.guid;
  }

  get arrivedTotal(): string {
    return '100ft';
  }

  get processedTotal(): string {
    return '100ft';
  }

  get isEditMode(): boolean {
    return this.editMode;
  }

  get statusFilter(): {options: any[], header: string} {
    return this.matchFilters.find((f) => f.header === 'processingStatusItemGuid');
  }

  mounted(): void {
    const projectGuid = JSON.parse(this.$route.params.projectGuids)[0];
    if (projectGuid == null || projectGuid.length === 0) console.error('Project Guid not found');
    this.fetchProcessingData(projectGuid).then(() => {
      this.tabChanged();
    });
    // if user can't set process to re-processing filter it out of the list
    if (this.userPermissions && !this.hasPermissionProcessingReProcessInspection) {
      const statusHeader = this.processingTableHeaders.find((h) => h.value === 'processingStatusItemGuid');
      if (statusHeader) {
        statusHeader.options = PROCESSING_STATUS.filter((status) => status.guid !== 'aaad14e0-4a9f-11ef-850d-cb39825b153b');
      }
    }
  }

  /**
   * @description Triggered when users switches between Manhole and Line Segment
   */
  tabChanged(): void {
    if (this.statusFilter === undefined) {
      console.error('Missing filter: processingStatusItemGuid');
    } else {
      this.statusFilter.options = [...this.processStatusPreFilledOptions];
    }
  }

  /**
   * @description Triggered when user applies a filter or clears filters
   * @param eventArgs Arguments passed by emitted event
   */
  userChangedFilter(eventArgs: string[]): void {
    if (eventArgs && eventArgs.length !== 0) {
      const headerValue = eventArgs[0];
      if (headerValue === 'processingStatusItemGuid') {
        this.processStatusPreFilledOptions = [...this.statusFilter.options];
      }
    }
  }

  /**
   * @returns true if the user has the permission ASSET_HISTORY_PROCESSING_VIEW_TABLE
   */
  get hasPermissionProcessingShowTable(): boolean {
    return this.hasPermission(
      UserPermission.ASSET_HISTORY_PROCESSING_VIEW_TABLE,
    );
  }

  /**
   * @returns true if the user has the permission ASSET_HISTORY_PROCESSING_EDIT_PROCESSING_STATUS
   */
  get hasPermissionProcessingEditProcessingTable(): boolean {
    return this.hasPermission(
      UserPermission.ASSET_HISTORY_PROCESSING_EDIT_PROCESSING_STATUS,
    );
  }

  /**
   * @returns true if the user has the permission
   * ASSET_HISTORY_PROCESSING_SUBMIT_INSPECTION_FOR_REPROCESSING
   */
  get hasPermissionProcessingReProcessInspection(): boolean {
    return this.hasPermission(
      UserPermission.ASSET_HISTORY_PROCESSING_SUBMIT_INSPECTION_FOR_REPROCESSING,
    );
  }
}
