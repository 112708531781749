



















































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { DateSelector } from '@/store/metrics/types';
import { format } from 'date-fns';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class DashboardDateSelector extends Vue {
  @Prop() selectedDate: DateSelector;

  @Prop() scheduledItems: DateSelector[];

  @Prop() title: string;

  scheduledValue: DateSelector = { ...this.itemsSelect[0] };

  get itemsSelect(): DateSelector[] {
    return [
      {
        value: 'All',
        dateValues: null,
      },
      ...this.scheduledItems,
      {
        value: 'Custom',
        dateValues: [],
      },
    ];
  }

  mounted(): void {
    this.setScheduledValue();
  }

  @Watch('selectedDate')
  onselectedDateChange(): void {
    this.setScheduledValue();
  }

  setScheduledValue(): void {
    this.scheduledValue = { ...this.selectedDate };
  }

  updateCustom(dates: string[]): void {
    this.itemsSelect[this.itemsSelect.length - 1].dateValues = dates;
    this.updateDateFilter();
  }

  updateSelection(item: DateSelector): void {
    this.scheduledValue = { ...item };
    if (item.value === 'Custom') {
      return;
    }
    this.updateDateFilter();
  }

  updateDateFilter(): void {
    this.$emit('updateDateFilter', {
      value: this.scheduledValue.value,
      dateValues: this.scheduledValue.dateValues
        ? [...this.scheduledValue.dateValues].sort()
        : null,
    });
  }

  get customDateRange(): string {
    if (this.scheduledValue.value === 'Custom' && this.scheduledValue.dateValues.length === 2) {
      return `(${this.scheduledValue.dateValues.join(' to ')})`;
    }
    return '';
  }
}
