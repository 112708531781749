













































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from 'chart.js';
import { WorkOrderProjectMetrics } from '@/store/metrics/types';
import { Pie as PieGenerator } from 'vue-chartjs/legacy';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

ChartJS.defaults.font.size = 16;
ChartJS.defaults.font.family = 'Roboto';

@Component({ components: { PieGenerator } })
export default class WorkOrderPieChart extends Vue {
  @Prop({ default: 'label' }) readonly datasetIdKey: string;

  @Prop() readonly workOrderProjectMetrics: WorkOrderProjectMetrics | undefined;

  @Prop() readonly projectGuid: string;

  @Prop({ default: false }) readonly isCustomerView: boolean;

  chartId = 'workOrderPieChart';

  chartClass = 'work-order-pie-chart';

  plugins = [];

  colors = {
    // 'Not Ready': 'grey',
    // Ready: 'grey',
    // Assignment: 'grey',
    Assigned: '#55B8F1',
    Complete: '#0C6599',
    Collection: '#CCCCCC',
    // Cancelled: 'grey',
    'Follow-Up Required': '#E61E25',
    // Incomplete: '#E61E25',
    // InProgress: 'grey',
    // 'On Hold': 'grey',
    Unassigned: '#FFC300',
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get chartOptions(): any {
    return {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          bodyFont: {
            size: 12,
          },
        },
      },
    };
  }

  get chartDataList(): any[] {
    return this.workOrderStatusKeys.map((d) => (
      {
        color: this.colors[d] ?? 'grey',
        data: this.getWorkOrderProjectMetricsData(d),
        label: d,
      }
    ));
  }

  get workOrderStatusKeys(): string[] {
    if (!this.workOrderProjectMetrics) return [];
    return Object.keys(this.workOrderProjectMetrics.collectionStatusItemDictionary);
  }

  get chartData(): any {
    const chartData = this.chartDataList;
    return {
      labels: chartData.map((d) => d.label),
      datasets: [
        {
          backgroundColor: chartData.map((d) => d.color),
          data: chartData.map((d) => d.data),
        },
      ],
    };
  }

  getWorkOrderProjectMetricsData(status: string): number {
    if (!this.workOrderProjectMetrics) {
      return 0;
    }
    return this.workOrderProjectMetrics.collectionStatusItemDictionary[status];
  }

  getWorkOrderProjectMetricsTotal(): number {
    return this.workOrderProjectMetrics?.total ?? 0;
  }

  formatPercent(val: number): string {
    if (!this.workOrderProjectMetrics) {
      return '';
    }
    const total = this.getWorkOrderProjectMetricsTotal();

    if (total === 0) {
      return '0%';
    }

    const percent = Math.round((val * 100) / total);
    return `(${percent}%)`;
  }
}
