import { ActionTree } from 'vuex';
import { uuid } from 'vue-uuid';
import { RootState } from '../types';
import { AdminState, EdititableUser, User } from './types';
import { AdminMutations } from './mutations';
import { UserRole } from '../users/types';
import storeUtility from '../utils';

// eslint-disable-next-line no-shadow
export enum AdminActions {
  POST_NEW_USER = 'POST_NEW_USER',
  PATCH_USER = 'PATCH_USER',
  DELETE_USER = 'DELETE_USER',
  DELETE_USER_ROLE = 'DELETE_USER_ROLE',
  PATCH_USER_ROLE = 'PATCH_USER_ROLE',
  UPDATE_ALL_USERS = 'UPDATE_ALL_USERS'
}

export const actions: ActionTree<AdminState, RootState> = {
  async [AdminActions.POST_NEW_USER]({ commit }, payload: User): Promise<void> {
    commit(AdminMutations.SET_LOADING_STATE, true);
    commit(AdminMutations.SET_LOAD_ERROR, undefined);
    commit(AdminMutations.SET_RESPONSE, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.postNewUser(payload);

      commit(AdminMutations.SET_RESPONSE, response);
    } catch (e) {
      commit(AdminMutations.SET_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(AdminMutations.SET_LOADING_STATE, false);
    }
  },

  async [AdminActions.PATCH_USER]({ commit }, payload: EdititableUser): Promise<void> {
    commit(AdminMutations.SET_LOADING_STATE, true);
    commit(AdminMutations.SET_LOAD_ERROR, undefined);
    commit(AdminMutations.SET_RESPONSE, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.patchUser(payload);

      commit(AdminMutations.SET_RESPONSE, response);
    } catch (e) {
      commit(AdminMutations.SET_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(AdminMutations.SET_LOADING_STATE, false);
    }
  },

  async [AdminActions.DELETE_USER]({ commit }, payload: string): Promise<void> {
    commit(AdminMutations.SET_LOADING_STATE, true);
    commit(AdminMutations.SET_LOAD_ERROR, undefined);
    commit(AdminMutations.SET_RESPONSE, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.deleteUser(payload);

      commit(AdminMutations.SET_RESPONSE, response);
    } catch (e) {
      if ((e as Error).message.includes('400')) {
        commit(AdminMutations.SET_LOAD_ERROR, 'Error: User does not exist');
        throw new Error('Error: User does not exist');
      } else {
        commit(AdminMutations.SET_LOAD_ERROR, (e as Error).message);
      }
    } finally {
      commit(AdminMutations.SET_LOADING_STATE, false);
    }
  },

  async [AdminActions.DELETE_USER_ROLE]({ commit }, payload: string): Promise<void> {
    commit(AdminMutations.SET_LOADING_STATE, true);
    commit(AdminMutations.SET_LOAD_ERROR, undefined);
    commit(AdminMutations.SET_RESPONSE, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.deleteUserRole(payload);

      commit(AdminMutations.SET_RESPONSE, response);
    } catch (e) {
      commit(AdminMutations.SET_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(AdminMutations.SET_LOADING_STATE, false);
    }
  },

  async [AdminActions.PATCH_USER_ROLE]({ commit }, payload: UserRole): Promise<void> {
    commit(AdminMutations.SET_LOADING_STATE, true);
    commit(AdminMutations.SET_LOAD_ERROR, undefined);
    commit(AdminMutations.SET_RESPONSE, undefined);

    try {
      const api = await storeUtility.useIntegrityAPI();
      const response = await api.patchUserRole(payload);

      commit(AdminMutations.SET_RESPONSE, response);
    } catch (e) {
      commit(AdminMutations.SET_LOAD_ERROR, (e as Error).message);
      throw new Error('API Error occurred.');
    } finally {
      commit(AdminMutations.SET_LOADING_STATE, false);
    }
  },

  async [AdminActions.UPDATE_ALL_USERS]({ commit }): Promise<void> {
    commit(AdminMutations.SET_UPDATE_USER_KEY, uuid.v4());
  },
};
