var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"dashboardDefectsCard"},[_c('div',{staticClass:"header"},[_c('span',{staticClass:"section-header"},[_vm._v("Defects")]),_c('v-spacer'),_c('v-text-field',{staticClass:"search-bar",attrs:{"outlined":"","dense":"","hide-details":"","placeholder":"Search","clearable":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(!_vm.dashboardDefectLoading)?_c('div',{staticClass:"titleButtons"},[_c('v-btn',{staticClass:"outline-btn",attrs:{"outlined":"","id":"clearBtn"},on:{"click":_vm.clearFilters}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-remove-outline")]),_vm._v(" Clear ")],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"button",attrs:{"outlined":"","color":"#0c6599","id":"codeDescBtn"},on:{"click":function($event){return _vm.setDefectCodeDesc(!_vm.useDefectCodeDesc)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.useDefectCodeDesc ? "mdi-alphabetical-variant" : "mdi-text")+" ")])],1)]}}],null,false,4171747248)},[_c('span',[_vm._v(_vm._s(_vm.useDefectCodeDesc ? "Show Code" : "Show Code Description"))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"button",attrs:{"outlined":"","color":"#0c6599","id":"mapBtn"},on:{"click":_vm.handleOnProjectPageClick}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-map-outline")])],1)]}}],null,false,3480173964)},[_c('span',[_vm._v("View in Map")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"button",attrs:{"outlined":"","color":"#0c6599","id":"tableBtn"},on:{"click":_vm.handleOnAssetInventoryClick}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-table")])],1)]}}],null,false,3335417309)},[_c('span',[_vm._v("View in Asset Inventory")])])],1):_vm._e()],1),_c('IntegrityTable',{ref:"defectdashboardtable",attrs:{"data":_vm.tableData,"headers":_vm.headers,"height":'100%',"matchFilters":_vm.matchFilters,"additionalFilterFunctions":_vm.additionalFilterFunctions,"dataFillFunctions":_vm.fillTableDataFunctions,"itemsPerPage":-1,"search":_vm.search,"footer-props":{
      'disable-items-per-page': false,
      'items-per-page-options': [25, 50, 100, -1],
    },"loading":_vm.dashboardDefectLoading},on:{"update:data":function($event){_vm.tableData=$event},"update:headers":function($event){_vm.headers=$event},"update:matchFilters":function($event){_vm.matchFilters=$event},"update:match-filters":function($event){_vm.matchFilters=$event},"onLoad":_vm.setInitGradeValues},scopedSlots:_vm._u([{key:"dashboardDefectInfo",fn:function(ref){
    var item = ref.item;
return [_c('a',{attrs:{"href":_vm.getTableInfoLink(item)}},[_c('v-btn',{staticClass:"info-btn",attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-information-outline")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }